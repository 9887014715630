<template>
  <div class="card card-carousel overflow-hidden h-100 p-0">
    <div
      id="carouselExampleCaptions"
      class="carousel slide h-100 carousel-fade"
      data-bs-ride="false"
      data-bs-interval="false"
    >
      <div class="carousel-inner border-radius-lg h-100">
        <div
          :class="`carousel-item h-100 bg-gradient-success ${
            index == 0 ? 'active' : ''
          }`"
          v-for="(project, index) in projectHighlightData"
          :key="index"
          data-bs-interval="false"
        >
          <!-- {{ project }} -->
          <div
            class="carousel-caption d-none d-md-block text-start start-0 ms-5"
          >
            <h5 class="text-white mb-1">{{ project.project.name }}</h5>
            <h6>{{ project.company.name }}</h6>
            <hr class="horizontal light" />

            <p class="text-center">
              <b>Target</b>
            </p>
            <div class="component-row-flex-space-between">
              <div>
                <b class="text-dark text-center">Quantity</b>
                <p>{{ project.project.target.quantity ?? 0 }}</p>
              </div>
              <div>
                <b class="text-dark text-center">Revenue</b>
                <p>
                  IDR {{ numeralFormat(project.project.target.revenue) ?? 0 }}
                </p>
              </div>
              <div>
                <b class="text-dark text-center">Profit</b>
                <p>
                  IDR {{ numeralFormat(project.project.target.profit) ?? 0 }}
                </p>
              </div>
            </div>
            <div class="carousel-body mb-2">
              <div class="progress" style="height: 20px">
                <div
                  class="progress-bar progress-bar-striped"
                  role="progressbar"
                  :style="`width: ${project.project.progress}%`"
                  :aria-valuenow="project.project.progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ project.project.progress }}%
                </div>
              </div>
            </div>

            <hr class="horizontal light" />

            <div
              class="row my-3"
              style="justify-content: center; align-content: center"
            >
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="bg-gradient-light border-radius-md text-center">
                  <b class="text-dark">
                    <i class="fa fa-phone"></i>
                    {{ project.project.activity.call ?? 0 }}</b
                  >
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="bg-gradient-light border-radius-md text-center">
                  <b class="text-dark">
                    <i class="fas fa-envelope"></i>
                    {{ project.project.activity.email ?? 0 }}</b
                  >
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="bg-gradient-light border-radius-md text-center">
                  <b class="text-dark">
                    <i class="fas fa-user-friends"></i>
                    {{ project.project.activity.visit ?? 0 }}</b
                  >
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="bg-gradient-light border-radius-md text-center">
                  <b class="text-dark">
                    <i class="fas fa-laptop"></i>
                    {{ project.project.activity.online_meeting ?? 0 }}</b
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ArgonProgress from "@/components/ArgonProgress.vue";

export default {
  name: "carousel",
  components: {
    ArgonProgress,
  },

  beforeMount() {
    this.fetchProjectHighlight();
  },

  methods: {
    ...mapActions({
      fetchProjectHighlight: "eraforce/fetchProjectHighlight",
    }),
  },

  computed: {
    projectHighlightData() {
      return this.$store.state.eraforce.project_highlight;
    },
  },
};
</script>
