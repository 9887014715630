<template>
  <div class="mt-5">
    <div class="row">
      <Card :title="`${activityName} Activity`">
        <template #header>
          <div class="">
            <div class="row mb-3">
              <div class="col-lg-6 col-md-12 d-flex justify-content-center">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  start-placeholder="start date"
                  end-placeholder="end date"
                  value-format="YYYY-MM-DD"
                  @change="changeDateFilter"
                />
              </div>
              <div class="col-lg-6 col-md-12">
                <argon-button
                  variant="gradient"
                  color="info"
                  fullWidth
                  size="sm"
                  @click="clickAdd"
                >
                  <div>
                    <el-icon style="margin-right: 10px"><Plus /></el-icon>
                    Add {{ activityName }} Activity
                  </div>
                </argon-button>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="mt-3" v-if="isAdmin">
      <div class="card">
        <div class="m-3">
          <div class="col-12">
            <argon-input
              type="text"
              placeholder="Search employee.."
              name="password"
              size="lg"
              fullWidth
              :value="search_placeholder"
              v-model="search_placeholder"
              @click="handleOpenSearchEmployee"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <CallCard v-else />
    </div>

    <el-dialog
      v-model="$store.state.eraforce.create_activity_modal"
      :title="`Create ${activityName}`"
      width="50%"
      :show-close="false"
    >
      <CallForm
        v-if="
          $store.state.eraforce.activity_id == 3 ||
          $store.state.eraforce.activity_id == 5
        "
      />
      <VisitForm
        v-if="
          $store.state.eraforce.activity_id == 4 ||
          $store.state.eraforce.activity_id == 9
        "
      />
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="closeAdd">Cancel</el-button> -->
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="open_list_employee"
      title="Select Employee"
      width="30%"
      :show-close="false"
    >
      <div>
        <argon-input
          type="text"
          placeholder="Search.."
          name="name"
          size="lg"
          fullWidth
          :value="search"
          v-model="search"
          @change="handleSearchEmployee"
        />

        <div
          class="d-flex justify-content-center"
          v-if="loading_search_employee"
        >
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <el-scrollbar height="400px" v-else>
          <div
            style="margin: 10px"
            v-for="(user, index) in getUserEraforce"
            :key="index"
          >
            <div
              class="bg-grey-soft p-3 cursor-pointer"
              @click="handleSelectEmployee(user)"
            >
              <span>
                {{ user.full_name }}
              </span>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleCancelSearch">Cancel</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  ArrowLeft,
  ArrowRight,
  Delete,
  Edit,
  Share,
} from "@element-plus/icons-vue";
import { ElButton } from "element-plus";
import Swal from "sweetalert2";
import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import CallCard from "@/views/GooSales/components/activity/CallCard.vue";
import CallForm from "@/views/GooSales/components/activity/CallForm.vue";
import VisitForm from "@/views/GooSales/components/activity/VisitForm.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyyy-MM-dd"),
      ],
      dialogVisible: false,
      location: null,
      gettingLocation: false,
      errorStr: null,
      search_placeholder: "",
      open_list_employee: false,
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    CallCard,
    CallForm,
    VisitForm,
    ElButton,
  },
  mounted() {},
  beforeMount() {
    this.getCallActivity();
    this.fetchEmployeeCompany();
  },
  methods: {
    ...mapActions({
      fetchAllActivity: "eraforce/fetchAllActivity",
      fetchEmployeeCompany: "eraforce/fetchEmployeeCompany",
      doCheckIn: "eraforce/doCheckIn",
      fetchUserEraforceAuth: "eraforce/fetchUserEraforceAuth",
    }),
    ...mapMutations({
      SET_ALREADY_CHECKIN: "eraforce/SET_ALREADY_CHECKIN",
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
      SET_ACTIVITY_MODAL_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_ACTIVE",
      SET_ACTIVITY_MODAL_NOT_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_NOT_ACTIVE",
    }),

    handleSearchEmployee() {
      this.loading_search_employee = true;
      const payload = {
        filter: "",
        name: this.search,
      };
      this.fetchUserEraforceAuth(payload).then((response) => {
        this.loading_search_employee = false;
      });
    },

    handleSelectEmployee(data) {
      this.selected_employee = data.id;
      this.search_placeholder = data.full_name;
      this.open_list_employee = false;
      this.getCallActivity();
    },

    handleOpenSearchEmployee() {
      const payload = {
        filter: "",
        name: this.search,
      };
      this.fetchUserEraforceAuth(payload).then((response) => {
        this.open_list_employee = true;
      });
    },

    updateHandler(page) {},

    changeDateFilter(range) {
      if (range) {
        this.startDate = `${range[0]} 00:00:00`;
        this.endDate = `${range[1]} 23:59:59`;
      } else {
        const today = format(new Date(), "yyyy-MM-dd");
        this.startDate = `${today} 00:00:00`;
        this.endDate = `${today} 23:59:59`;
      }
      this.getCallActivity();
    },

    getCallActivity() {
      let call_payload;
      if (this.getUserLoginData.status_id == 5) {
        call_payload = {
          activity_id: this.$store.state.eraforce.activity_id || 3, //Call
          employee_id: null,
          first_date: this.startDate,
          last_date: this.endDate,
        };
      } else {
        call_payload = {
          activity_id: this.$store.state.eraforce.activity_id || 3, //Call
          employee_id: this.selected_employee,
          first_date: this.startDate,
          last_date: this.endDate,
        };
      }
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchAllActivity(call_payload).then((response) => {
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },

    clickAdd() {
      if (this.checkinStatus) {
        this.SET_ACTIVITY_MODAL_ACTIVE();
      } else {
        Swal.fire({
          icon: "error",
          title: "Ooops... You are not checkin yet!",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Checkin",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.checkInOut();
          } else if (result.isDenied) {
            Swal.fire("Please checkin to do activity!", "", "info");
          }
        });
      }
    },
    closeAdd() {
      this.SET_ACTIVITY_MODAL_NOT_ACTIVE();
    },

    checkInOut() {
      this.locateMe();
      if (this.location == null) {
        // this.locateMe();
        Swal.fire({
          title: "Oops... Failed get location!",
          text: "Do you want to try again?",
          icon: "error",
          showDenyButton: true,
          confirmButtonText: "Try Again",
          denyButtonText: `No`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.checkInOut();
          } else if (result.isDenied) {
            Swal.fire("Location get failed", "", "error");
          }
        });
      } else {
        this.checkIn();
        console.log(`check in`);
      }
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },

    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
      } catch (e) {
        this.gettingLocation = false;
        Swal.fire({
          icon: "error",
          title: "Oops...Failed getting current location!",
          text: `${e.message}`,
        });
        this.errorStr = e.message;
      }
    },

    checkIn() {
      const payload = {
        id: this.getUserLoginData.id,
        latitude: this.location.coords.latitude,
        longitude: this.location.coords.longitude,
      };
      this.doCheckIn(payload)
        .then((response) => {
          Swal.fire({
            title: "Horay! Success Check In!",
            text:
              "Please wait, will automatically redirect to create activity!",
            icon: "success",
            showConfirmButton: false,
            timer: 4000,
          });
          this.SET_ALREADY_CHECKIN();
          setTimeout(() => {
            this.clickAdd();
          }, 4000);
        })
        .catch((error) => {
          Swal.fire({
            title: "Ooops... Failed checkin!",
            text: `${error}`,
            icon: "error",
            showConfirmButton: true,
          });
          this.SET_NOT_CHECKIN();
          console.log(`resp ${JSON.stringify(error)}`);
        });
    },
  },
  computed: {
    activityName() {
      const id_activity = this.$store.state.eraforce.activity_id;

      let name = "";
      if (id_activity == 3) {
        name = "Call";
      } else if (id_activity == 4) {
        name = "Visit";
      } else if (id_activity == 5) {
        name = "Email";
      } else if (id_activity == 9) {
        name = "Online Meeting";
      } else {
        name = "";
      }
      return name;
    },

    checkinStatus() {
      return this.$store.state.eraforce.is_already_checkin;
    },

    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },

    isAdmin() {
      return this.$store.state.eraforce.eraforce_user_login[0].status_id == 7;
    },

    getUserEraforce() {
      return this.$store.state.eraforce.user_eraforce;
    },
  },
  watch: {},
};
</script>
