<template>
  <div class="row">
    <div class="card mb-3" v-if="presenceData.length == 0">
      <div class="card-header px-3">
        <h6 class="mb-0 text-center">No data found</h6>
      </div>
    </div>
    <div
      v-else
      class="card mb-3"
      v-for="(presence, index) in presenceData"
      :key="index"
    >
      <div class="card-header pb-0 px-3">
        <!-- <h6 class="mb-0">{{ activity.detail[0].project.name }}</h6> -->
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-1 col-md-12">
            <el-button type="info">
              {{ presence.date }}
            </el-button>
          </div>
          <div class="col-lg-11 col-md-12">
            <el-timeline>
              <el-timeline-item
                v-for="(detail, index) in presence.detail"
                :key="index"
                icon="Clock"
                size="large"
                :type="`${index == 0 ? 'success' : ''}`"
                :color="`success`"
              >
                <div
                  :class="`border-0 p-4 ${backgroundColour(
                    presence.date,
                    detail
                  )} border-radius-lg align-items-center`"
                >
                  <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                      <span class="mb-2 text-xs">
                        <i class="fas fa-user"></i> {{ detail.full_name }}
                      </span>
                      <span class="mb-2 text-xs d-flex">
                        <i class="fas fa-door-open"></i>
                        <div class="d-flex flex-column">
                          <span>
                            {{ detail.time_in }}
                          </span>
                          <span> Office 88: {{ detail.dist_kokas }} m </span>
                          <span> Alaydrus: {{ detail.dist_alaydrus }} m </span>
                        </div>
                      </span>
                      <span class="mb-2 text-xs d-flex">
                        <i class="fas fa-door-closed"></i>
                        {{ detail.time_out ?? "--:--" }}
                      </span>
                    </div>
                    <div class="ms-auto">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        :color="`${detail.is_match ? 'success' : 'danger'}`"
                        size="md"
                        @click="showClientMap(detail)"
                      >
                        <div>
                          <i class="fas fa-map-marker-alt"></i>
                        </div>
                      </argon-button>
                    </div>
                  </div>
                  <argon-button
                    class="mt-3"
                    variant="gradient"
                    :color="`info`"
                    size="md"
                    fullWidth
                    @click="goToPresenceRequest(detail)"
                  >
                    <div><i class="far fa-clock"></i> Correction</div>
                  </argon-button>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElTimeline, ElButton } from "element-plus";
import { UserFilled, Message, Phone, Monitor } from "@element-plus/icons-vue";
import { format } from "date-fns";

import ArgonButton from "@/components/ArgonButton.vue";

import { mapActions, mapMutations } from "vuex";
export default {
  name: "call-card",

  components: {
    ElButton,
    ElTimeline,
    ArgonButton,
  },

  data() {
    return {
      image: "",
    };
  },

  methods: {
    ...mapMutations({
      SELECTED_PRESENCE_DATA: "eraforce/SELECTED_PRESENCE_DATA",
    }),
    ...mapActions({}),

    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },

    showClientMap(detail) {
      window.open(
        `https://maps.google.com/?q=${detail.latitude_in},${detail.longitude_in}`,
        "_blank"
      );
    },

    backgroundColour(date, detail) {
      let color = "";

      if (date != null && detail != null) {
        let time_in = detail.time_in.split(" ")[0];

        let date2_in = new Date(date + " " + time_in + ":00");
        let date1_in = new Date(date + " " + "08:00:00");
        const checkinDiffTime = Math.abs(date2_in - date1_in);
        const checkinDiffHours = Math.floor(checkinDiffTime / (1000 * 60 * 60));

        let checkoutDiffHours;
        if (detail.time_out != null) {
          let time_out = detail.time_out.split(" ")[0];

          let date2_out = new Date(date + " " + time_out + ":00");
          let date1_out = new Date(date + " " + "17:00:00");
          const checkoutDiffTime = Math.abs(date1_out - date2_out);
          checkoutDiffHours = Math.round(checkoutDiffTime / (1000 * 60 * 60));
        } else {
          checkoutDiffHours = 0;
        }

        if (checkinDiffHours > 0 || checkoutDiffHours > 0) {
          color = "pink-background";
        } else {
          color = "bg-gray-100";
        }
      } else {
        color = "bg-gray-100";
      }
      return color;
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      // console.log(12121, routeArr[2]);
      return routeArr[1];
    },

    async goToPresenceRequest(detail) {
      await this.SELECTED_PRESENCE_DATA(detail);
      const url = "/" + this.getRoute() + "/transaksi/attendance/request";
      this.$router.push(url);
    },
  },

  computed: {
    presenceData() {
      return this.$store.state.eraforce.list_check_in_data;
    },
  },

  beforeMount() {},
};
</script>

<style scoped>
.pink-background {
  background-image: linear-gradient(to bottom right, #ee542f, #e3cdd3);
}
</style>
