<template>
  <div class="row">
    <Card :title="'Monthly Target'">
      <template #body>
        <div>
          <div
            class="loading d-flex justify-content-center mt-3"
            v-if="loading_employee"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="p-0" v-else>
            <el-scrollbar height="400px">
              <div class="p-3">
                <div
                  class="card p-3 mb-3"
                  v-for="(data, index) in getDataProfit"
                  :key="index"
                >
                  <div>
                    <h6>
                      {{ data.sales_name }}
                    </h6>
                    <div class="mb-1">
                      <label for="">Profit Target</label>
                      <argon-input
                        type="text"
                        placeholder="Profit Target"
                        size="lg"
                        :value="profit[index]"
                        v-model="profit[index]"
                      />
                    </div>
                    <div class="mb-1">
                      <label for="">Revenue Target</label>
                      <argon-input
                        type="text"
                        placeholder="Revenue Target"
                        size="lg"
                        :value="revenue[index]"
                        v-model="revenue[index]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
            <argon-button
              class="mt-4"
              variant="gradient"
              color="success"
              fullWidth
              size="md"
              @click="handleUpdateInfo()"
            >
              <div>Save</div>
            </argon-button>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

import { format } from "date-fns";

export default {
  name: "Employee",
  data() {
    return {
      loading_employee: false,
      dialogVisible: false,
      target_team: null,
      selected_record: null,
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
      profit: [],
      revenue: [],
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
  },
  mounted() {},
  beforeMount() {
    this.fetchMonthlyTargetData();
  },
  methods: {
    ...mapActions({
      fetchMonthlyTarget: "eraforce/fetchMonthlyTarget",
    }),
    ...mapMutations({}),
    fetchMonthlyTargetData() {
      this.loading_employee = true;
      //   this.SET_SELECTED_EMPLOYEE_DATA("");
      let payload = {
        month: format(new Date(), "MM"),
        year: format(new Date(), "yyyy"),
        team_id: this.getUserData.team_id,
      };
      this.fetchMonthlyTarget(payload).then((response) => {
        for (let i = 0; i < response.length; i++) {
          this.profit[i] =
            "IDR " + numeral(response[i].profit_target).format("0,0");
          this.revenue[i] =
            "IDR " + numeral(response[i].revenue_target).format("0,0");
        }
        this.loading_employee = false;
      });
    },
  },
  computed: {
    getDataProfit() {
      return this.$store.state.eraforce.list_monthly_target;
    },

    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
  watch: {
    search(val) {
      this.fetchAllEmployee();
    },
    revenue: {
      handler: function (val, oldVal) {
        for (let i = 0; i < val.length; i++) {
          this.revenue[i] = "IDR " + numeral(val[i]).format("0,0");
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.img-rank {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 30px;
}
</style>
