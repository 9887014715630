<template>
  <Card title="Add Document" />

  <div class="card mt-4 row">
    <div class="card-body">
      <div class="d-flex flex-column">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Company Name</label>
              <el-form-item>
                <el-autocomplete
                  class="inline-input"
                  v-model="state1"
                  :fetch-suggestions="querySearch"
                  placeholder="Input Company Name"
                  clearable
                  @select="handleSelect"
                  style="width: 100%"
                ></el-autocomplete>
              </el-form-item>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Project Name</label>
              <el-form-item>
                <el-autocomplete
                  class="inline-input"
                  v-model="search_project"
                  :fetch-suggestions="querySearchProject"
                  placeholder="Input Project Name"
                  clearable
                  :disabled="disable_project"
                  @select="handleSelectProjectPipeline"
                  style="width: 100%"
                ></el-autocomplete>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="">Document</label>
              <br />
              <el-select
                v-model="form.document"
                multiple
                placeholder="Select"
                style="width: 100%"
              >
                <el-option
                  v-for="item in getDocumentData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Notes</label>
              <argon-input
                type="text"
                placeholder="Input notes"
                size="lg"
                :value="form.notes"
                v-model="form.notes"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Last Progress: {{ form.progress }}%</label>
              <br />
              <input
                style="width: 100%"
                type="range"
                placeholder="Input notes"
                v-model="form.progress"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <argon-button
              class="mt-4"
              variant="gradient"
              color="success"
              fullWidth
              size="md"
              @click="handleCreateDocument()"
            >
              <div>Save</div>
            </argon-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="createProjectDialog"
      title="Input Project Name"
      width="50%"
      :show-close="false"
    >
      <div>
        <div class="row">
          <div class="col-12">
            <argon-input
              type="text"
              placeholder="Input notes"
              size="lg"
              width="100%"
              :value="search_project"
              v-model="search_project"
              @change="handleSearchProject()"
            />
          </div>
          <div class="col-12">
            <div class="mb-3">Project name that already exist:</div>
            <el-scrollbar max-height="300px" v-if="projects.length > 0">
              <div style="margin: 10px">
                <div
                  class="card mb-2"
                  v-for="(project, index) in projects"
                  :key="index"
                >
                  <div class="card-body">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>{{ project.project_name }}</h6>
                      <h6>
                        <span class="badge badge-pill badge-dark">
                          {{ project.project_progress }}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
            <h6 class="text-center" v-else>
              Project name not found for this company.
            </h6>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createProjectDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleConfirmCreateProject()"
            :disabled="disabled_confirm_create_project"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import Card from "@/components/base/BaseCard.vue";
import exportFromJSON from "export-from-json";

import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

import { format } from "date-fns";

export default {
  data() {
    return {
      disable_project: true,
      form: {
        state1: "",
        state2: "",
        value: null,
        profit: 0,
        quantity: 0,
        notes: "I just add a document",
        progress: 0,
        document: [],
      },
      state1: "",
      state2: "",
      companies: [],
      projects: [],
      selectedCompany: {},
      selectedProject: {},
      createProjectDialog: false,
      search_project: "",
      disabled_confirm_create_project: false,
    };
  },

  components: {
    Card,
    ArgonButton,
    ArgonInput,
  },

  methods: {
    ...mapActions({
      fetchEmployeeCompany: "eraforce/fetchEmployeeCompany",
      fetchEmployeeProject: "eraforce/fetchEmployeeProject",
      fetchProjectCompany: "eraforce/fetchProjectCompany",
      doCreateLeadPipeline: "eraforce/doCreateLeadPipeline",
      doUpdatePipeline: "eraforce/doUpdatePipeline",

      fetchAllDocument: "eraforce/fetchAllDocument",
      doAddDocument: "eraforce/doAddDocument",
    }),
    ...mapMutations({
      SET_EXISTING_PROJECT: "eraforce/SET_EXISTING_PROJECT",
    }),

    handleChangeCompany() {},

    handleSearchProject() {
      this.fetchEmployeeProjects();
    },

    handleConfirmCreateProject() {
      this.createProjectDialog = false;
      this.state2 = this.search_project;
      this.form.state2 = this.search_project;
    },

    resetInitData() {
      this.form.state1 = "";
      this.form.state2 = "";
      this.form.progress = 0;
      this.form.document = [];
      this.search_project = "";
      this.selectedCompany = {};
      this.selectedProject = {};
      this.state1 = "";
      this.state2 = "";
    },

    handleChangeType() {
      this.noteData;
      this.resetInitData();
    },

    fetchCompanyData() {
      this.fetchEmployeeCompany().then((response) => {
        this.companies = response;
      });
    },

    fetchEmployeeProjects() {
      let payload = {
        project_name: this.selectedCompany.name,
        employee_id: this.getUserLoginData.id,
        status_project: "deal",
        page: 1,
        target_status: null,
      };
      this.fetchEmployeeProject(payload).then((response) => {
        this.projects = response;
        this.disable_project = false;
      });
    },

    querySearch(query, cb) {
      var companies = this.companies;
      var results = query
        ? companies.filter(this.createFilter(query))
        : companies;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.name}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilter(queryString) {
      return (company) => {
        return (
          company.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelect(item) {
      this.search_project = "";
      this.disable_project = true;
      this.selectedCompany = item.detail;
      this.form.state1 = this.selectedCompany.id;
      this.fetchEmployeeProjects();
    },

    handleSelectProject() {
      this.createProjectDialog = true;
    },

    querySearchProject(query, cb) {
      var projects = this.projects;
      var results = query
        ? projects.filter(this.createFilterProject(query))
        : projects;
      var res = [];
      console.log(`res ${JSON.stringify(results)}`);
      results.forEach((result) => {
        res.push({
          value: `${result.project.job} - ${result.project.progress}%`,
          detail: result,
        });
      });
      if (res.length == 0) {
        this.disable_project = true;
      }
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilterProject(queryString) {
      return (project) => {
        return (
          project.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelectProjectPipeline(item) {
      this.selectedProject = item.detail.project;
      this.form.state2 = this.selectedProject.number;
      this.form.date1 = this.selectedProject.start_date;
      this.form.progress = this.selectedProject.progress;
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },

    handleCreateDocument() {
      if (this.form.state1 == "" || this.form.state1 == null) {
        return Swal.fire({
          title: "Oopss! Company name is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
      if (this.form.state2 == "" || this.form.state2 == null) {
        return Swal.fire({
          title: "Oopss! Project name is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
      if (this.form.document.length == 0) {
        return Swal.fire({
          title: `Oopss! Document is required!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
      if (this.form.progress == 0 || this.form.progress == null) {
        return Swal.fire({
          title: `Oopss! Progress is required!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      }
      if (this.form.progress < this.selectedProject.progress) {
        return Swal.fire({
          title: `Oopss! Progress must higher than ${this.selectedProject.progress}%!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.progress = this.selectedProject.progress;
          }
        });
      }
      let payload;
      payload = {
        company_id: this.form.state1,
        project_id: this.form.state2,
        note: this.form.notes,
        progress: this.form.progress,
        reminder_date: this.form.date1,
        documents: this.form.document,
      };

      this.doAddDocument(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! ${response}!`,
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            const url = "/" + this.getRoute() + "/dashboard";
            this.$router.push(url);
            this.resetInitData();
          });
        })
        .catch((error) => {
          Swal.fire({
            title: `Oopss! Something went wrong!`,
            text: `Error: ${error}`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "OK",
          });
        });
    },
  },
  mounted() {
    this.fetchAllDocument();
    this.fetchCompanyData();
    this.noteData;
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
    getDocumentData() {
      return this.$store.state.eraforce.document_data;
    },
  },

  watch: {
    state1(val) {
      if (val.length == 0) {
        this.resetInitData();
        this.disable_project = true;
      }
    },
  },
};
</script>
