<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>Gender</h6>
      <p class="text-sm">Employee distribution by gender.</p>
    </div>
    <div class="p-3 card-body">
      <div class="d-flex justify-content-center" v-if="is_loading">
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_t9gkkhz4.json"
          background="transparent"
          speed="1"
          style="width: 300px; height: 300px"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="chart">
        <canvas
          id="hrd-chart-gender"
          class="chart-canvas"
          height="300"
        ></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { mapActions } from "vuex";

export default {
  name: "gradient-line-chart",

  data() {
    return {
      is_loading: false,
    };
  },

  props: {
    title: {
      type: String,
      default: "Sales overview",
    },
    detail1: {
      type: String,
      default: "4% more",
    },
    detail2: {
      type: String,
      default: "in 2021",
    },
  },

  methods: {
    ...mapActions({
      fetchGenderChart: "hrd/fetchGenderChart",
    }),

    getDataChart() {
      this.is_loading = true;
      this.fetchGenderChart().then((resp) => {
        var ctx1 = document.getElementById("hrd-chart-gender").getContext("2d");
        new Chart(ctx1, {
          plugins: [ChartDataLabels],
          type: "bar",
          data: {
            labels: this.getData.label,
            datasets: [
              {
                datalabels: {
                  align: "end",
                  anchor: "end",
                  display: function (context) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value != 0; // display labels with an odd index
                  },
                },
                label: "Count",
                tension: 0.4,
                borderWidth: 0,
                pointRadius: 0,
                backgroundColor: [
                  "rgba(255, 99, 132, 0.2)",
                  "rgba(255, 159, 64, 0.2)",
                  "rgba(255, 205, 86, 0.2)",
                ],
                borderColor: [
                  "rgb(255, 99, 132)",
                  "rgb(255, 159, 64)",
                  "rgb(255, 205, 86)",
                ],
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 1,
                fill: true,
                data: this.getData.result,
                // maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#fbfbfb",
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#000000",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
        this.is_loading = false;
      });
    },
  },

  computed: {
    getData() {
      return this.$store.state.hrd.gender_chart;
    },
  },

  mounted() {
    this.getDataChart();
  },
};
</script>
