<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="row d-flex justify-content-between align-items-center">
        <span class="text-bold">Employee</span>
      </div>
    </div>

    <!-- 88 -->
    <div class="card p-3 mb-3">
      <div class="row demo-date-picker">
        <div class="block col-md-6 col-sm-12">
          <div class="demonstration text-center">Join Date</div>
          <el-date-picker
            v-model="params.join_date.date_range"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            @change="handleChangeJoinDate"
            :size = "small"
          />
        </div>
        <div class="block col-md-6 col-sm-12">
          <div class="demonstration text-center">Out Date</div>
          <el-date-picker
            v-model="params.out_date.date_range"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            @change="handleChangeOuDate"
            :size = "small"
          />
        </div>
      </div>
    </div>
    <!-- END 88 -->
    

    <div class="card p-3 mb-3">
      <div class="row demo-date-picker">
        <div class="block col-md-6 col-sm-12">
          <div class="demonstration text-center">Employeement Status</div>
          <el-select
            v-model="params.employeement_status"
            class="m-2 w-80"
            placeholder="Select Employeement Status"
            @change="getEmployeeData"
          >
            <el-option
              v-for="item in options_employeement"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
        <div class="block col-md-6 col-sm-12">
          <div class="demonstration text-center">Office Location</div>
          <el-select
            v-model="params.office_location"
            class="m-2 w-80"
            placeholder="Select Office Location"
            @change="getEmployeeData"
          >
            <el-option
              v-for="item in getOfficeFilter"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            />
          </el-select>
        </div>
      </div>
    </div>

    <!-- <div class="card p-3 mb-3">
      <b>Import Employee</b>
        <div class="alert alert-warning" role="alert">
          <b> *Username, Email, HP tidak bisa diubah</b>
        </div> 
      <div class="row">
        <input type="file" id="uploadCaptureInputFile" @change="handleImportEmployee">       
      </div>
    </div> -->

    <div class="card p-3 mb-3">
      <div class="row">
        <div class="col-md-8 col-sm-12">
          
            <el-input
            v-model="params.search"
            class="w-100 m-2"
            size="md"
            placeholder="Search by employee name/nik/employee number"
            :prefix-icon="Search"
            @change="getEmployeeData"
          />
        </div>

        <div class="col-md-2 col-sm-12">
          <argon-button
            class="w-85 m-2"
            variant="gradient"
            color="info"
            size="sm"
            @click="handleExportEmployee"
          >
            <div>Export</div>
          </argon-button>
        </div>
        <div class="col-md-2 col-sm-12">
          <argon-button
            class="w-85 m-2"
            variant="gradient"
            color="info"
            size="sm"
            @click="handleResetSearch"
          >
            <div>Reset</div>
          </argon-button>
        </div>

      </div>
    </div>

    <div class="card p-3">
      <div v-if="getEmployee.length == 0">
        <h6 class="text-center">No Data Found!</h6>
      </div>
      <div v-else>
        <!-- for developement remove v-loading="is_table_loading" because it makes the table can't be loaded -->
        <!-- {{ getEmployee }} -->
        <!-- original code -->
        <!-- <el-table
          :data="getEmployee"
          style="width: 100%"
          height="500"
          v-loading="is_table_loading"
        > -->
        <!-- end original code -->
        <el-table :data="getEmployee" style="width: 100%" height="500">
          <el-table-column fixed type="index" label="No" width="60"/>
          <el-table-column prop="fullname" label="Name" sortable/>
          <el-table-column prop="nik" label="NIK" sortable/>
          <el-table-column prop="employee_no" label="ID" sortable/>
          <el-table-column label="Empl Status">
            <template #default="scope">
              <div>
                <span
                  class="badge rounded-pill bg-success"
                  v-if="scope.row.end_date == null"
                  >Active</span
                >
                <span class="badge rounded-pill bg-danger"
                  v-else-if="scope.row.end_date != null"
                  >Inactive</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Acc Status">
            <template #default="scope">
              <div>
                <span
                  class="badge rounded-pill bg-success"
                  v-if="scope.row.is_activate == 1"
                  >Active</span
                >
                <span class="badge rounded-pill bg-danger"
                  v-else-if="scope.row.is_activate == 0"
                  >Inactive</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Type">
            <template #default="scope">
              <div>
                <div v-if="scope.row.type == 'non'">
                  Non Sales
                </div>
                <div v-else-if="scope.row.type == 'sales'">
                  Sales
                </div>
                <div v-else>
                  -
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Action">
            <template #default="scope">
              <!-- <div class="" v-if="scope.row.type != null"> -->
              <div class="">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <i class="fas fa-caret-down"></i>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="handleOpenEdit(scope.row)">
                        Edit
                      </el-dropdown-item>
                      <el-dropdown-item
                        @click="handleConfirmRemoveEmployee(scope.row)"
                      >
                        Activate/Deactivate Employee
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="this.is_deactive_acc_disabled == true"
                        v-bind:disabled="!deactiveAccNotAllowed"
                        @click="handleActivationEmployee(scope.row)"
                      >
                        Activate/Deactivate Access
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-else-if="this.is_deactive_acc_disabled == false"
                        @click="handleActivationEmployee(scope.row)"
                      >
                        Activate/Deactivate Access
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- #region cellaSectionTable -->
        <!-- <table class="table"> -->
        <!-- <thead>
              <tr>
                <th scope="col-2">Name</th>
                <th scope="col-2">NIK</th>
                <th scope="col-2">ID</th>
                <th scope="col-2">Empl Status</th>
                <th scope="col-2">Acc status</th>
                <th scope="col-2">Action</th>
              </tr>
            </thead> -->
        <!-- <tbody>
              <tr v-for="item in getEmployee" :key="item.id">
                <td>
                  {{ item.fullname }}
                </td>
                <td>
                  {{ item.employee_no }}
                </td>
                <td>
                  {{ item.id_user }}
                </td>
                <td>
                  <div>
                    <span
                      class="badge rounded-pill bg-success"
                      v-if="item.delete_date === null"
                      >Active</span
                    >
                    <span class="badge rounded-pill bg-danger" v-else
                      >Inactive</span
                    >
                  </div>
                </td>
                <td>
                  <div>
                    <span
                      class="badge rounded-pill bg-success"
                      v-if="item.is_activate == 1"
                      >Active</span
                    >
                    <span class="badge rounded-pill bg-danger" v-else
                      >Inactive</span
                    >
                  </div>
                </td>
                <td class="select">>
                  <select class="form-select" aria-label="select action" name="customerName" id="" v-on:change="handleCella($event,item)">
                    <option selected>Please Choose</option>
                    <option value="1">Edit</option>
                    <option value="2">Active/Remove Employee</option>
                    <option value="3">Active/Deactive Account</option>
                  </select>
                </td>
              </tr>
            </tbody> -->
        <!-- </table> -->
        <!-- end #region cellaSectionTable -->
        <!-- <li v-for="item in getEmployee" :key="item.id">{{ item }}</li> -->

        <div class="m-4 d-flex justify-content-center">
          <el-button
            v-if="!loading_more && !is_no_more && !is_table_loading"
            @click="getEmployeeDataMore"
          >
            Load More
          </el-button>
          <el-button v-if="loading_more"> Loading ... </el-button>
        </div>
      </div>
    </div>

    <!-- Edit Position -->
    <el-dialog
      v-model="edit_dialog"
      title="Edit Employee"
      width="40%"
      :before-close="handleCloseEdit"
    >
    <div >
      <p class="alert alert-warning" role="alert" v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error, index in errors" :key="index">{{ error }}</li>
        </ul>
      </p>
    </div>

      <el-scrollbar height="500px" class="p-2">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="d-flex justify-content-center align-items-center">
              <img
                src="@/assets/img/card-visa.jpg"
                class="m-2 rounded"
                width="200"
                height="200"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="d-flex flex-column">
              <span class="m-2 text-sm">Employee Number</span>
              <el-input
                v-model="form.employee_no"
                class="m-2 w-100"
                style="width: 100%"
                placeholder="Input employee_no"
                :suffix-icon="EditPen"
              />
              <span class="m-2 text-sm">NIK</span>
              <el-input
                v-model="form.nik"
                class="m-2 w-100"
                style="width: 100%"
                placeholder="Input nik name"
                :suffix-icon="EditPen"
              />
              <span class="m-2 text-sm">Fullname</span>
              <el-input
                v-model="form.fullname"
                class="m-2 w-100"
                style="width: 100%"
                placeholder="Input fullname"
                :suffix-icon="EditPen"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Department</span>
            <el-autocomplete
              v-model="search_divisi"
              :fetch-suggestions="querySearch"
              clearable
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Please Input Divisi"
              @select="handleSelect"
              v-on:change="validateDept($event, item)"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Office Location</span>
            <el-select
              v-model="form.id_office_location"
              class="m-2 w-100"
              placeholder="Select Office"
            >
              <el-option
                v-for="item in getOffice"
                :key="item.id_office_location"
                :label="item.office_name"
                :value="item.id_office_location"
              />
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Educations</span>
            <el-select
              v-model="form.edu"
              class="m-2 w-100"
              placeholder="Select Education"
            >
              <el-option
                v-for="item in options_edu"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Username</span>
            <el-input
              v-model="form.username"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input username"
              :suffix-icon="EditPen"
              readonly
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Email</span>
            <el-input
              type="email"
              v-model="form.email"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input email"
              :suffix-icon="EditPen"
              readonly
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Phone</span>
            <el-input
              v-model="form.phone"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input phone"
              :suffix-icon="EditPen"
              readonly
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Province</span>
            <!-- cella fix province -->
            <!-- @select="handleSelectProvince" -->
            <el-autocomplete
              v-if="this.form.province === null"
              v-model="search_province"
              :fetch-suggestions="querySearchProvince"
              clearable
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Please Input Province"
              @select="handleSelectProvince"
              v-on:change="validateProvince($event, item)"
            />
            <el-autocomplete
              v-else
              v-model="form.province"
              :fetch-suggestions="querySearchProvince"
              clearable
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Please Input Province"
              @select="handleSelectProvince"
              v-on:change="validateProvince($event, item)"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">City (Select Province First)</span>
            <!-- ori -->
            <!-- <el-autocomplete
              v-model="search_city"
              :fetch-suggestions="querySearchCity"
              clearable
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Please Input City"
              @select="handleSelectCity"
              :disabled="selected_province == null"
            /> -->
            <!-- cella fix city -->
            <!-- :disabled="selected_province == null" -->
            <el-autocomplete
              v-if="this.form.city === null"
              v-model="search_city"
              :fetch-suggestions="querySearchCity"
              clearable
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Please Input City"
              v-on:change="validateCity($event, item)"
              @select="handleSelectCity"
              :disabled="selected_province == null"
            />
            <el-autocomplete
              v-else
              v-model="form.city"
              :fetch-suggestions="querySearchCity"
              clearable
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Please Input City"
              v-on:change="validateCity($event, item)"
              @select="handleSelectCity"
              :disabled="selected_province == null"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Date of Birth (YYYY-MM-DD)</span>
            <el-date-picker
              v-model="form.dob"
              value-format="YYYY-MM-DD"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input date of birth"
              :suffix-icon="EditPen"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Gender</span>
            <el-select
              v-model="form.gender"
              class="m-2 w-100"
              placeholder="Select Gender"
            >
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">Start Date (YYYY-MM-DD)</span>
            <el-date-picker
              type="date"
              value-format="YYYY-MM-DD"
              v-model="form.start_date"
              v-on:change="validateEndDate($event, item)"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input start date"
              :suffix-icon="EditPen"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <span class="m-2 text-sm">End Date (YYYY-MM-DD)</span>
            <el-date-picker
              type="date"
              value-format="YYYY-MM-DD"
              v-model="form.end_date"
              v-on:change="validateEndDate($event, item)"
              picker-options="dueDatePickerOptions"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input end date"
              :suffix-icon="EditPen"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="m-2 text-sm">Address</span>
            <el-input
              v-model="form.address"
              class="m-2 w-100"
              style="width: 100%"
              placeholder="Input address"
              :suffix-icon="EditPen"
            />
          </div>
        </div>
      </el-scrollbar>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button
            type="success"
            @click="handleUpdateEmployee"
            class="bg-gradient-success"
          >
            Confirm
          </el-button> -->
          <el-button
            v-if="this.is_confirm_disabled == true"
            v-bind:disabled="!saveNotAllowed"
            type="success"
            class="bg-gradient-success"
          >
            Confirm
          </el-button>
          <el-button
            v-else-if="this.is_confirm_disabled == false"
            type="success"
            @click="handleUpdateEmployee"
            class="bg-gradient-success"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { format } from "date-fns";

import { mapActions, mapMutations } from "vuex";
import { ElNotification } from "element-plus";
import { async } from "vuejs3-google-maps";
import ArgonButton from "@/components/ArgonButton.vue";
import readXlsxFile from 'read-excel-file'

export default {
  name: "GoappsAdminEmployee",

  components: {
    ArgonButton,
  },

  data() {
    // for initial state
    return {
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      // employee: [],
      options: ["Male", "Female"],
      options_employeement: ["All", "Active", "Inactive"],
      options_edu: ["SMA/SMK", "D3", "S1", "S2", "S3", "Others"],
      params_divisi: {
        id: "",
        search: "",
        order: "ASC",
      },
      params_office: {
        id: "",
        search: "",
        order: "ASC",
      },
      params: {
        id: "",
        search: "",
        page: 1,
        order: "ASC",
        join_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        out_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        employeement_status: "All",
        office_location: "All",
        // page_limit: 10,
      },
      form: {
        id: null,
        id_sso: null,
        employee_no: null,
        id_departement: null,
        nik: null,
        fullname: null,
        province: null,
        city: null,
        address: null,
        dob: null,
        gender: null,
        start_date: new Date(),
        end_date: new Date(),
        delete_date: null,
        image: null,
        email: null,
        username: null,
        phone: null,
        edu: null,
        id_office_location: null,
      },
      dueDatePickerOptions: {
        disabledDate: this.disabledDueDate,
      },
      edit_dialog: false,
      is_table_loading: false,
      loading_more: false,
      is_no_more: false,
      search_divisi: "",
      divisi_data: [],
      selected_divisi: null,
      selected_office: "",
      province_data: [],
      search_province: "",
      selected_province: null,
      city_data: [],
      search_city: "",
      selected_city: null,
      is_confirm_disabled: false,
      is_deactive_acc_disabled: false,
      errors: [],
    };
  },

  beforeMount() {
    this.getEmployeeData();
    this.getDivisiData();
    this.getOfficeData();
    this.getProvinceData();
  },

  mounted() {
    this.getEmployeeData();
    // this.$store.dispatch('hrd/SET_EMPLOYEE');
  },

  methods: {
    ...mapActions({
      fetchExcelEmployee: "hrd/fetchExcelEmployee",
      fetchEmployee: "hrd/fetchEmployee",
      doUpdateEmployee: "hrd/doUpdateEmployee",
      doDeleteEmployee: "hrd/doDeleteEmployee",
      doActivationEmployee: "hrd/doActivationEmployee",
      fetchDivisi: "hrd/fetchDivisi",
      fetchOffice: "hrd/fetchOffice",
      fetchProvinceData: "fetchProvinceData",
      fetchCityByProvinceData: "fetchCityByProvinceData",
      fetchMealAllowanceOfficeExcel: "hrd/fetchMealAllowanceOfficeExcel",
      doUpdateActiveEmployee: "eraforce/doUpdateActiveEmployee",
      doUpdateNonActiveEmployee: "eraforce/doUpdateNonActiveEmployee",
      doUpdateStatus: "hrd/doUpdateStatus",
    }),
    ...mapMutations({
      SET_EMPLOYEE: "hrd/SET_EMPLOYEE",
    }),

    handleResetSearch() {
      this.params.search = ""
      this.params.join_date.start_date = ""
      this.params.join_date.end_date = ""
      this.params.join_date.date_range = "";
      this.params.out_date.start_date = ""
      this.params.out_date.end_date = ""
      this.params.out_date.date_range = "";
      this.params.employeement_status = "All";
      this.params.office_location= "All";
      document.getElementById("uploadCaptureInputFile").value = "";

      this.getEmployeeData();
    },

  handleImportEmployee(event) {
    // Header -> json
    // test update by string/number
    const schema = {
      'No': {
        // JSON object property name.
        prop: 'rowIndex',
        type: Number,
      },
      'Name': {
        // JSON object property name.
        prop: 'fullname',
        type: String,
      },
      'NIK': {
        prop: 'nik',
        type: Number,
        // required: true
      },
      'ID': {
        prop: 'employee_no',
        type: String,
      },
      'Department': {
        prop: 'departement',
        type: String,
      },
      'Office Location': {
        prop: 'office_name',
        type: String,
      },
      'Educations': {
        prop: 'edu',
        type: String,
      },
      'Province': {
        prop: 'province',
        type: String,
      },
      'City': {
        prop: 'city',
        type: String,
      },
      'Date of Birth': {
        prop: 'dob_fm',
        type: String,
      },
      'Gender': {
        prop: 'gender',
        type: String,
      },
      'Start Date': {
        prop: 'start_date_fm',
        type: String,
      },
      'End Date': {
        prop: 'end_date_fm',
        type: String,
      },
      'Address': {
        prop: 'address',
        type: String,
      },
    };

    //array get kode 1x

    //     // No	Name	NIK	ID	Acc Status	Department	Office Location	Educations	Username	Email	Phone	Province	City	
    //     // Date of Birth	Gender	Start Date	End Date	Address

    //     // "rowIndex", "fullname", "nik", "employee_no", "is_activate", "departement", "office_name", "edu", "username", "email", 
    //     // "phone", "province", "city", "dob_fm", "gender", "start_date_fm", "end_date_fm", "address"
    // };
    let xlsxfile = event.target.files ? event.target.files[0] : null;
    readXlsxFile(xlsxfile, { schema }).then(({ rows, errors }) => {
      // `errors` list items have shape: `{ row, column, error, reason?, value?, type? }`.
      errors.length === 0

      // rows === [{
      //   fullname: 'Cella',
      //   nik: 88,
      // }];
      // console.log("rows:", rows);
      // console.log("ini row: ", rows[0].start_date_fm);
      this.handleConfirmImportEmployee(rows);
    })

    // readXlsxFile(xlsxfile).then((rows) => {
    //   console.log("rows:", rows)
    // })
    // .catch((error) => {
    //   console.log("This is error: ", error)
    // });
  },

  handleTest(value) {
    const payload = {
      username: value.username
    };

    // Check Non Sales Or Sales
    const type = value.type;
    if(type == 'sales') {
      this.doUpdateActiveEmployee(payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
    } else {
      console.log('ANDA Non Sales');
    }
  },

  stringToDate: function (_date,_format,_delimiter)
  {
    var formatLowerCase=_format.toLowerCase();
    var formatItems=formatLowerCase.split(_delimiter);
    var dateItems=_date.split(_delimiter);
    var monthIndex=formatItems.indexOf("mm");
    var dayIndex=formatItems.indexOf("dd");
    var yearIndex=formatItems.indexOf("yyyy");
    var month=parseInt(dateItems[monthIndex]);
    month-=1;
    var formatedDate = new Date(dateItems[yearIndex],month,dateItems[dayIndex]);
    return formatedDate;
  },

  async handleConfirmImportEmployee(data) {
    //cella date convert
    let start_date_c = this.stringToDate(data[0].start_date_fm,"dd-mm-yyyy","-");
    let start_date_cf = format(new Date(start_date_c), "yyyy-MM-dd");
    // console.log("ini data: ", data[0].start_date_fm, " ## ", start_date_c, " ## ", start_date_cf);

    let end_date_c = this.stringToDate(data[0].end_date_fm,"dd-mm-yyyy","-");
    let end_date_cf = format(new Date(end_date_c), "yyyy-MM-dd");
    // console.log("ini data: ", data[0].end_date_fm, " ## ", end_date_c, " ## ", end_date_cf);

    let dob_fm_c = this.stringToDate(data[0].dob_fm,"dd-mm-yyyy","-");
    let dob_fm_cf = format(new Date(dob_fm_c), "yyyy-MM-dd");
    // console.log("ini data: ", data[0].dob_fm, " ## ", dob_fm_c, " ## ", dob_fm_cf);

    await this.$swal({
      title: "Are you sure?",
      text: "Once updated, you will not be able to recover the data of employee!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.doUpdateEmployee(data)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: "Success update employee!",
            type: "success",
          });
          this.getEmployeeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error update employee: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
        this.$swal.fire('Success batch update employee!', '', 'success');
        this.handleResetSearch();
      } else if (result.isDenied) {
        this.$swal.fire('Changes are not saved', '', 'info');
      }
    });
  },

  handleImportEmployeeOld(event) {
    let xlsxfile = event.target.files ? event.target.files[0] : null;
    readXlsxFile(xlsxfile).then((rows) => {
      console.log("rows:", rows);
    })
    
    .catch((error) => {
      console.log("This is error: ", error)
    });
  },

    async handleExportEmployee() {
    await import('@/plugins/Export2Excel.js')
      .then((excel) => {

      const sd = "1950-01-01 00:00:00";
      const ed = "2099-12-31 23:59:59";
      
      const start = format(new Date(sd), "dd-MMM-yyyy");
      const end = format(new Date(ed), "dd-MMM-yyyy");

      // console.log("ini date1: ", start);
      // console.log("ini date2: ", end);

      // Task 1. replace with today now
      const today = format(new Date(), "yyyy-MMM-dd || HHmm");
      const format_name = `Employee Data_${today}`;
      ElMessage({
        message: "Please wait downloading employees data..",
        type: "info",
        duration: 5000,
      });

      this.params.page = 1;
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
        order: "ASC",
      };

        // this.fetchEmployee(params)
        this.fetchExcelEmployee(params)
        .then((response) => {

            //excel
            const OBJ = response; 
            const Header = ["No", "Name", "NIK", "ID", "Acc Status", "Department", "Office Location", "Educations", "Username", "Email", "Phone", "Province", "City", "Date of Birth", "Gender", "Start Date", "End Date", "Address"];
            const Field = ["rowIndex", "fullname", "nik", "employee_no", "is_activate", "departement", "office_name", "edu", "username", "email", "phone", "province", "city", "dob_fm", "gender", "start_date_fm", "end_date_fm", "address"];
            const Data = this.formatJson(Field, OBJ);
            // console.log("ini data", Data);

            excel.export_json_to_excel({
              header: Header,
              data: Data,
              sheetName: "Employee",
              filename: format_name,
              autoWidth : true,
              bookType : "xlsx",
            })
            ElNotification({
              title: "Voila!",
              message: "Success download all employees!",
              type: "success",
            });


            //end excel
          })

      })

      .catch((error) => {
        console.log("This is error: ", error)
      });
    },
    
    formatJson(filterData, jsonData){
      return jsonData.map((v) => 
        filterData.map(j => {
          return v[j];
        })
      )
    },

    // #region Validation
    disabledDueDate(time) {
      return time.getTime() < this.form.start_date;
    },

    validateEndDate: function (e, data) {
      if (this.form.start_date > this.form.end_date) {
        // this.form.end_date = this.form.start_date;
        this.is_confirm_disabled = true;
        ElNotification({
          title: "Oops!",
          message: `End Date should > = Start Date!`,
          type: "error",
          duration: 5000,
        });
      } else {
        this.is_confirm_disabled = false;
      }
    },

    validateProvince: function (e, data) {
      this.is_confirm_disabled = false;
      // console.log("ini province: ", this.province_data);
        for (let i = 0; i < this.province_data.length; i++) {
          const element = this.province_data[i];
          // console.log("ini element == "+element.nama);
          if (this.form.province != element.nama) {
            this.form.province = "";
            ElNotification({
              title: "Oops!",
              message: `Province is required and should be in fetch suggestion`,
              type: "error",
              duration: 3000,
            });
            return;
          } else {
            this.form.city = "";
          }
        }
    },

    validateCity: function (e, data) {
      this.is_confirm_disabled = false;
      // handleSelectCity(data);
      // console.log("ini city: ", this.city_data);
        for (let i = 0; i < this.city_data.length; i++) {
        const element = this.city_data[i];
        // console.log("ini element == "+element.nama);
        if (this.form.city != element.nama) {
          this.form.city = "";
          ElNotification({
            title: "Oops!",
            message: `City is required and should be in fetch suggestion`,
            type: "error",
            duration: 3000,
          });
          // this.is_confirm_disabled = true;
          return;
          } else {
            this.search_city = this.form.city;
            // this.is_confirm_disabled = false;
          }
      }
     
    },

    validateDept: function (e, data) {
      this.is_confirm_disabled = false;
      // console.log("ini dept: ", this.divisi_data);

        for (let i = 0; i < this.divisi_data.length; i++) {
        const element = this.divisi_data[i];
        // console.log("ini element == "+element.nama);
        if (this.form.id_departement != element.nama) {
          this.form.id_departement = "";
          ElNotification({
            title: "Oops!",
            message: `Dept is required and should be in fetch suggestion`,
            type: "error",
            duration: 3000,
          });
          return;
        }
      }
      
    },

    checkForm: function (e) {
      if (
        this.form.employee_no && 
        this.form.id_departement && 
        this.form.nik && 
        this.form.fullname && 
        this.form.province && 
        this.form.city && 
        this.form.address && 
        this.form.dob && 
        this.form.gender && 
        this.form.start_date &&

        // this.form.email && -> no need because it has already filled from HP
        // this.form.username && -> no need because it has already filled from HP
        // this.form.phone && -> no need because it has already filled from HP
        this.form.edu && 
        this.form.id_office_location && 
        this.search_divisi
        ) return true;
      this.errors = [];

      if (!this.form.employee_no) this.errors.push("Employee no. is required.");
      // if (!this.form.search_divisi) this.errors.push("Department is required.")
      if (!this.form.id_departement) this.errors.push("Department is required and should be in fetch suggestion.");
      if (!this.form.nik) this.errors.push("NIK is required.");
      if (!this.form.fullname) this.errors.push("Fullname is required.");
      if (!this.form.province) this.errors.push("Province is required and should be in fetch suggestion.");
      if (!this.form.city) this.errors.push("City is required and should be in fetch suggestion.");
      if (!this.form.address) this.errors.push("Address is required.");
      if (!this.form.dob) this.errors.push("DOB is required.");
      if (!this.form.gender) this.errors.push("Gender is required.");
      if (!this.form.start_date) this.errors.push("Start date is required.");

      // if (!this.form.email) this.errors.push("Email is required.");  -> no need because it has already filled from HP
      // if (!this.form.username) this.errors.push("Username is required.");  -> no need because it has already filled from HP
      // if (!this.form.phone) this.errors.push("Phone is required.");  -> no need because it has already filled from HP
      if (!this.form.edu) this.errors.push("Edu is required.");
      if (!this.form.id_office_location) this.errors.push("Office Location is required.");
      
      // if (this.form.search_city ="Please Input City") this.errors.push("City Depends on Province.");
      e.preventDefault();
      // return this.errors;
    },

    // #endregion Validation

    getProvinceData() {
      this.fetchProvinceData().then((resp) => {
        this.province_data = resp;
      });
    },

    getCityData(id) {
      this.fetchCityByProvinceData(id).then((resp) => {
        this.city_data = resp;
      });
    },

    getDivisiData() {
      const params = {
        id: this.params_divisi.id,
        search: this.params_divisi.search,
        order: "ASC",
      };
      this.fetchDivisi(params).then((response) => {
        this.divisi_data = response;
      });
    },

    getOfficeData() {
      const params = {
        id: this.params_office.id,
        search: this.params_office.search,
        order: "ASC",
      };
      this.fetchOffice(params);
    },

    getEmployeeData() {
      this.is_table_loading = true;
      this.params.page = 1;
      // console.log("ini limit: ", this.params.page_limit);
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
        order: "ASC",
        // page_limit: this.params.page_limit,
      };
      this.fetchEmployee(params)
        .then((response) => {
          if (response.length < 10) {
            this.is_no_more = true;
          } else {
            this.is_no_more = false;
          }
          // useless code
          // emplogyee = response;
          this.SET_EMPLOYEE(response);
          this.is_table_loading = false;
        })
        .catch((error) => {
          this.is_no_more = true;
          this.is_table_loading = false;
          this.SET_EMPLOYEE([]);
        });
    },

    getEmployeeDataMore() {
      this.loading_more = true;
      const page = 1;
      this.params.page += page;
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
      };
      this.fetchEmployee(params)
        .then((response) => {
          if (response.length < 10) {
            this.is_no_more = true;
          } else {
            this.is_no_more = false;
          }
          let result = this.getEmployee;
          const merged = [...result, ...response];
          this.SET_EMPLOYEE(merged);
          this.loading_more = false;
        })
        .catch((error) => {
          this.is_no_more = true;
          this.loading_more = false;
        });
    },

    handleChangeJoinDate() {
      if (this.params.join_date.date_range == null) {
        this.params.join_date.date_range = ["", ""];
      }
      this.params.join_date.start_date = this.params.join_date.date_range[0];
      this.params.join_date.end_date = this.params.join_date.date_range[1];
      this.getEmployeeData();
    },

    handleChangeOuDate() {
      if (this.params.out_date.date_range == null) {
        this.params.out_date.date_range = ["", ""];
      }
      this.params.out_date.start_date = this.params.out_date.date_range[0];
      this.params.out_date.end_date = this.params.out_date.date_range[1];
      this.getEmployeeData();
    },

    handleOpenEdit(data) {
      this.errors = [];
      this.is_confirm_disabled = false;
      if (data.id_departement != null) {
        // cella
        const dept = this.getDivisi.filter(
          (div) => data.id_departement == div.id_departement
        );
        if (dept.length > 0) {
          this.search_divisi = dept[0].departement;
        }
      }
      this.form = {
        id: data.id_user,
        id_sso: data.id_sso,
        employee_no: data.employee_no,
        id_departement: data.id_departement,
        nik: data.nik,
        fullname: data.fullname,
        province: data.province,
        city: data.city,
        address: data.address,
        dob: data.dob,
        gender: data.gender,
        start_date: data.start_date,
        end_date: data.end_date,
        delete_date: data.delete_date,
        image: data.image,
        email: data.email,
        username: data.username,
        phone: data.phone,
        edu: data.edu,
        id_office_location: data.id_office_location,
      };
      this.edit_dialog = true;
    },

    handleUpdateEmployee() {
      this.checkForm();
      const payload = {
        id_user: this.form.id,
        id_sso: this.form.id_sso,
        employee_no: this.form.employee_no,
        id_departement: this.form.id_departement,
        nik: this.form.nik,
        fullname: this.form.fullname,
        province: this.form.province,
        city: this.form.city,
        address: this.form.address,
        dob: this.form.dob,
        gender: this.form.gender,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        delete_date: this.form.delete_date,
        image: this.form.image,
        email: this.form.email,
        username: this.form.username,
        phone: this.form.phone,
        edu: this.form.edu,
        id_office_location: this.form.id_office_location,
      };

      if (this.error != []) {
        // alert("Success update employee");
        this.doUpdateEmployee(payload)
        .then((response) => {
          
          if (this.form.end_date != null) {
            //end date is filled, emp is terminate, access is deactivated (0)
            payload.status = "0";
            payload.id_sso = this.form.id_sso;
            // console.log("ini pay: ", payload.status, " - ", payload.is_sso);
            this.doActivationEmployee(payload);
          }

          this.handleCloseEdit();
          ElNotification({
            title: "Voila!",
            message: "Success update employee!",
            type: "success",
          });
          this.getEmployeeData();
          this.is_confirm_disabled = false;
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error update employee: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });

      }  
      else
      {
        console.log("Required: ", this.error);
      }
      
    },

    handleCloseEdit() {
      this.form = {
        id: null,
        id_sso: null,
        employee_no: null,
        id_departement: null,
        nik: null,
        fullname: null,
        province: null,
        city: null,
        address: null,
        dob: null,
        gender: null,
        start_date: null,
        end_date: null,
        delete_date: null,
        image: null,
        email: null,
        username: null,
        phone: null,
        edu: null,
        id_office_location: null,
      };
      this.search_divisi = "";
      this.search_province = "";
      this.search_city = "";
      this.selected_divisi = null;
      this.selected_province = null;
      this.selected_city = null;
      this.edit_dialog = false;
    },

    // async handleConfirmRemoveEmployeeYes(data) {
    //   await this.$swal({
    //     title: "Are you sure?",
    //     text: "Once updated, you will not be able to recover the end date of employee!",
    //     icon: "warning",
    //     buttons: true,
    //     dangerMode: true,
    //   })
    //   .then(this.handleRemoveEmployee(data));
    // },

    async handleConfirmRemoveEmployee(data) {
      await this.$swal({
        title: "Are you sure?",
        text: "Once updated, you will not be able to recover the end date of employee!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          actions: 'my-actions',
          cancelButton: 'order-1 right-gap',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleRemoveEmployee(data);
          // this.$swal.fire('Success update employee state!', '', 'success')
        } else if (result.isDenied) {
          this.$swal.fire('Changes are not saved', '', 'info')
        }
      });
    },

    handleRemoveEmployee(data) {
      let date;
      if (data.end_date == null) {
        date = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        //parsing function #3 here
        data.is_activate = "1";
        this.handleActivationEmployee(data);
      } else {
        date = null;
      }
      const payload = {
        id_user: data.id_user,
        date: date,
      };
      this.doDeleteEmployee(payload)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: "Success update employee stat!",
            type: "success",
          });
          this.getEmployeeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error update employee stat: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    async updateStatusNonSales(data) {
      if(data.is_activate == "1") {
        console.log('Non Active');
        const payload = {
          username: data.username,
          status: 0
        };

        this.doUpdateStatus(payload)
          .then((response) => {
            console.log(response);
            ElNotification({
              title: "Voila!",
              message: "Success non active employee on gooCheck!",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          })
      } else {
        console.log('Active');
        const payload = {
          username: data.username,
          status: 1,
        };

        this.doUpdateStatus(payload)
          .then((response) => {
            console.log(response);
            ElNotification({
              title: "Voila!",
              message: "Success active employee on gooCheck!",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          })

        console.log(payload);
      }
    },

    async updateStatusSales(data) {
      // Jika 1 = Active And 0 = Deactive

      if(data.is_activate == "1") {
        console.log('Non Active');
        const payload = {
          username: data.username
        };

        await this.doUpdateNonActiveEmployee(payload)
          .then((response) => {
            ElNotification({
              title: "Voila!",
              message: "Success non active employee on gooSales!",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          })
        
      } else {
        console.log('ingin aktif', data);
        const payload = {
          username: data.username
        };

        await this.doUpdateActiveEmployee(payload)
          .then((response) => {
            ElNotification({
              title: "Voila!",
              message: "Success active employee on gooSales!",
              type: "success",
            });
          })
          .catch((error) => {
            console.log(error);
          })
      }
    },

    async handleActivationEmployee(data) {
      console.log(data);
      if (data.is_activate == "1") {
        //check end date
        // if end date is filled -> can
        // if end date is not filled -> can
        this.is_deactive_acc_disabled = false;
      }
      else if (data.is_activate == "0") 
      {
        // if end date is filled -> can't
        if (data.end_date != null) {
          this.is_deactive_acc_disabled == true;
          ElNotification({
            title: "Oops!",
            message: `Not allowed to activate account for inactive employee!`,
            type: "error",
            duration: 5000,
          });
          return;
        } else {
          // if end date is not filled -> can
          this.is_deactive_acc_disabled = false;
        }
      }

      let stat;
      if (data.is_activate == "1") {
        stat = "0";
      } else {
        stat = "1";
      }

      const payload = {
        id_sso: data.id_sso,
        status: stat,
      };
      await this.doActivationEmployee(payload)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: "Success update access",
            type: "success",
          });
          if(data.type == 'sales') {
            this.updateStatusSales(data);
          }

          if(data.type == 'non') {
            this.updateStatusNonSales(data);
          }
          this.getEmployeeData();
        })
        .catch((error) => {
          //if delete date is null then error msg
          if (data.end_date != null) {
            ElNotification({
              title: "Oops!",
              message: `Error update access: ${error.message}`,
              type: "error",
              duration: 5000,
            });
          }
        });
    },

    // #region cellaSection
    // handleClaOri(actionID) {
    //   if(actionID == 1) {
    //     console.log("ini edit");
    //   }
    //   else if(actionID == 2) {
    //     console.log("ini active / deactive emp");
    //   }
    //   else if(actionID == 3) {
    //     console.log("ini active / deactive acc");
    //   }
    // },
    // handleCella: function(e, data){
    //     var id = e.target.value;
    //     var name = e.target.options[e.target.options.selectedIndex].text;
    //     console.log('id ',id );
    //     console.log('name ',name );
    //     console.log("ini data: ", data);

    //     if(id == 1) {
    //       console.log("ini edit emp");
    //       this.handleOpenEditCla(data);
    //     }
    //     else if(id == 2) {
    //       console.log("ini active / deactive emp");
    //       this.handleRemoveEmployeeCla(data);
    //     }
    //     else if(id == 3) {
    //       console.log("ini active / deactive acc");
    //       this.handleActivationEmployeeCla(data);
    //     }
    // },

    // handleOpenEditCla(data) {
    //   if (data.id_departement != null) {
    //     const dept = this.getDivisi.filter(
    //       (div) => data.id_departement == div.id_departement
    //     );
    //     if (dept.length > 0) {
    //       this.search_divisi = dept[0].departement;
    //     }
    //   }
    //   this.form = {
    //     id: data.id_user,
    //     id_sso: data.id_sso,
    //     employee_no: data.employee_no,
    //     id_departement: data.id_departement,
    //     nik: data.nik,
    //     fullname: data.fullname,
    //     province: data.province,
    //     city: data.city,
    //     address: data.address,
    //     dob: data.dob,
    //     gender: data.gender,
    //     start_date: data.start_date,
    //     end_date: data.end_date,
    //     delete_date: data.delete_date,
    //     image: data.image,
    //     email: data.email,
    //     username: data.username,
    //     phone: data.phone,
    //     edu: data.edu,
    //     id_office_location: data.id_office_location,
    //   };
    //   this.edit_dialog = true;
    //   //this.handleUpdateEmployee
    // },

    // async handleRemoveEmployeeCla(data)  {
    //   let date;
    //   if (data.delete_date == null) {
    //     date = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    //   } else {
    //     date = null;
    //   }
    //   const payload = {
    //     id_user: data.id_user,
    //     date: date,
    //   };
    //   this.getEmployeeData();

    //   await this.doDeleteEmployee(payload);
    //     await this.$swal.fire(
    //       {
    //         title: 'Voila',
    //         html: 'Success!',
    //         type: 'success',
    //         timer: 1000
    //       }
    //     ).then((result) => {
    //       // Reload the Page
    //       this.getEmployeeData();
    //       location.reload();
    //     })
    //     .catch((error) => {
    //     this.$swal.fire(
    //       {
    //         title: 'Oops',
    //         html: 'Error!',
    //         type: 'error',
    //         timer: 1000
    //       }
    //     )
    //   });
    //   //await this.getEmployeeData();
    //   //window.location.href = "/EDX09/master-data/employee";
    // },

    // async handleActivationEmployeeCla(data) {
    //   let stat;
    //   if (data.is_activate == "1") {
    //     stat = "0";
    //   } else {
    //     stat = "1";
    //   }
    //   const payload = {
    //     id_sso: data.id_sso,
    //     status: stat,
    //   };
    //   this.getEmployeeData();
    //   await this.doActivationEmployee(payload);
    //     await this.$swal.fire(
    //       {
    //         title: 'Voila',
    //         html: 'Success!',
    //         type: 'success',
    //         timer: 1000
    //       }
    //     ).then((result) => {
    //       // Reload the Page
    //       this.getEmployeeData();
    //       location.reload();
    //     })
    //     .catch((error) => {
    //     this.$swal.fire(
    //       {
    //         title: 'Oops',
    //         html: 'Error!',
    //         type: 'error',
    //         timer: 1000
    //       }
    //     )
    //   });
    // },
    //#end region cellaSection

    querySearch(query, cb) {
      var divisi_data = this.divisi_data;
      var results = query
        ? divisi_data.filter(this.createFilter(query))
        : divisi_data;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.departement}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilter(queryString) {
      return (divisi) => {
        return (
          divisi.departement.toLowerCase().indexOf(queryString.toLowerCase()) ==
          0
        );
      };
    },

    handleSelect(item) {
      this.selected_divisi = item.detail;
      this.form.id_departement = item.detail.id_departement;
      // this.search_project = "";
      // this.disable_project = true;
      // this.selectedCompany = item.detail;
      // this.form.state1 = this.selectedCompany.id;
      // this.fetchEmployeeProjects();
    },

    querySearchProvince(query, cb) {
      var province_data = this.province_data;
      var results = query
        ? province_data.filter(this.createFilterProvince(query))
        : province_data;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.nama}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilterProvince(queryString) {
      return (province) => {
        return (
          province.nama.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelectProvince(item) {
      this.selected_province = item;
      this.form.province = item.detail.nama;
      this.form.city = "";
      this.getCityData(item.detail.id);
    },

    querySearchCity(query, cb) {
      var city_data = this.city_data;
      var results = query
        ? city_data.filter(this.createFilterCity(query))
        : city_data;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.nama}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilterCity(queryString) {
      return (city) => {
        return city.nama.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
      };
    },

    handleSelectCity(item) {
      this.selected_city = item;
      this.form.city = item.detail.nama;
    },
  },

  computed: {
    saveNotAllowed: function () {
      if (this.search_divisi == "Please Input Divisi") {
        this.disabled = this.is_confirm_disabled == true;
      }

      if (this.search_city == "Please Input City") {
        this.disabled = this.is_confirm_disabled == true;
      }

    },
    deactiveAccNotAllowed: function () {
      this.disabled = this.is_deactive_acc_disabled == true;
    },
    getEmployee() {
      console.log("ini statenya emp: ", this.$store.state.hrd.employee);
      return this.$store.state.hrd.employee;
    },
    getDivisi() {
      return this.$store.state.hrd.divisi;
    },
    getOffice() {
      return this.$store.state.hrd.office;
    },
    getOfficeFilter() {
      const office = this.$store.state.hrd.office;
      let office_filter = [
        {
          id: "All",
          value: "All",
        },
      ];
      if (office.length > 0) {
        office.forEach((of) => {
          const data = {
            id: of.id_office_location,
            value: of.office_name,
          };
          office_filter.push(data);
        });
      }
      return office_filter;
    },
    getProvince() {
      return this.$store.state.province;
    },
  },
};
</script>
<style scoped>
errorMsg {
  text-align: center;
  text-transform: uppercase;
  color: #921f36;
}

.demo-date-picker {
  display: flex;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
.demo-date-picker .block {
  padding: 5px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.demo-date-picker .block:last-child {
  border-right: none;
}
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-primary);
  font-size: 14px;
  margin-bottom: 1px;
}
</style>
