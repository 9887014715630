import { format } from "date-fns";
import { mapActions } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "GoappsAdminAttendance",
  components: {
    ArgonButton
  },
  data() {
    return {
      params: {
        date_range: [format(new Date(), "yyyy-MM-dd 00:00:00"), format(new Date(), "yyyy-MM-dd 23:59:59")],
        start_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        end_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
        id_user: "",
        filter: "",
        page: 1,
        name: ""
      },
      attendance_sales_data: [],
      attendance_data: [],
      attendance_office: [],
      attendance_sales: [],
      limit: 5,
      start: 0,
      end: 0,
      pop_over_content: "This is contain list attendance of sales and non-sales",
      is_table_loading_office: false,
      loading_more_office: false,
      is_no_more_office: false,
      is_table_loading_sales: false,
      loading_more_sales: false,
      is_no_more_sales: false,
      is_click_load_more: false,
      downloading_sales: false,
      downloading_office: false
    };
  },
  beforeMount() {
    this.getAttendanceOffice();
    // this.getAttendanceSales();
    this.getAttendanceSls();
  },
  mounted() {
    // this.getAttendanceData();
  },
  methods: {
    ...mapActions({
      fetchAttendanceOffice: "hrd/fetchAttendanceOffice",
      fetchAttendanceSales: "hrd/fetchAttendanceSales",
      fetchAttendanceOfficeExcel: "hrd/fetchAttendanceOfficeExcel",
      fetchAttendanceSalesExcel: "hrd/fetchAttendanceSalesExcel"
    }),
    goToMap(lat, long) {
      window.open(`https://maps.google.com/?q=${lat},${long}`, "_blank");
    },
    getAttendanceOffice() {
      this.is_table_loading_office = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      const params = {
        start: start,
        end: end,
        user_id: this.params.id_user,
        page: this.params.page,
        name: this.params.name
      };
      // console.log("ini params: ", params);
      this.fetchAttendanceOffice(params).then(response => {
        // if (response.data.length < 5) {
        //   this.is_no_more_office = true;
        // } else {
        //   this.is_no_more_office = false;
        // }
        let new_data = [];
        response.data.forEach(data => {
          const date = format(new Date(), "yyyy-MM-dd");
          let time_in = data.time_in;
          let date2_in = new Date(date + " " + time_in);
          let date1_in = new Date(date + " " + "08:00:00");
          const checkinDiffTime = date2_in - date1_in;
          const checkinDiffHours = Math.ceil(checkinDiffTime / (1000 * 60 * 60));
          let is_late = 0;
          if (checkinDiffHours > 0) {
            is_late = 1;
          }
          const payload = {
            name: data.user.sales_name,
            date: data.attendance_date,
            time_in: data.time_in,
            time_out: data.time_out,
            lat_in: data.latitude_in,
            long_in: data.longitude_in,
            lat_out: data.latitude_out,
            long_out: data.longitude_out,
            is_match_in: data.is_match_in,
            is_match_out: data.is_match_out,
            is_late: is_late,
            departement: "Non-Sales"
          };
          new_data.push(payload);
        });
        const old_data = this.attendance_data;
        this.attendance_office = new_data;
        this.attendance_data = [...old_data, ...new_data];
        this.is_table_loading_office = false;
        this.is_click_load_more = false;
        console.log("ini att: ", this.attendance_data);
      }).catch(error => {
        this.is_click_load_more = false;
        this.is_table_loading_office = false;
        this.is_no_more_office = true;
        this.attendance_office.push([]);
        console.log(`err office ${JSON.stringify(error)}`);
      });
    },
    getAttendanceSls() {
      this.is_table_loading_sales = true;
      var sd = format(new Date(this.params.start_date), "yyyy-MM-dd");
      var ed = format(new Date(this.params.end_date), "yyyy-MM-dd");
      const start = sd;
      const end = ed;
      const params = {
        start_date: start,
        end_date: end,
        user_id: this.params.id_user,
        filter: this.params.filter,
        name: this.params.name
      };
      // console.log("ini params: ", params);
      this.fetchAttendanceSales(params).then(response => {
        var json_data = response.result;
        // console.log("ini: ", response.result);
        // var result = [];
        for (var i in json_data) {
          // result.push([i, json_data[i]]);
          // console.log("ini cla: ", i, "- " ,json_data[i]);

          // const date = format(new Date(), "yyyy-MM-dd");
          var date = json_data[i].time_in.split(" ")[0],
            date = format(new Date(date), "yyyy-MM-dd");
          let time_in = json_data[i].time_in;
          time_in = format(new Date(time_in), "yyyy-MM-dd HH:mm:ss");
          let date2_in = new Date(time_in);
          let date1_in = new Date(date + " " + "08:00:00");
          const checkinDiffTime = date2_in - date1_in;
          const checkinDiffHours = Math.ceil(checkinDiffTime / (1000 * 60 * 60));
          let is_late = 0;
          if (checkinDiffHours > 0) {
            is_late = 1;
          }

          // console.log("ini time: ", json_data[i].employee_fullname, date2_in, date1_in, checkinDiffHours, is_late)

          console.log("ini match_out: ", json_data[i].is_match_out);
          const payload = {
            name: json_data[i].employee_fullname,
            date: json_data[i].time_in.split(" ")[0],
            time_in: json_data[i].time_in.split(" ")[1],
            time_out: json_data[i].time_out != null ? json_data[i].time_out.split(" ")[1] : null,
            lat_in: json_data[i].latitude_in,
            long_in: json_data[i].longitude_in,
            lat_out: json_data[i].latitude_out,
            long_out: json_data[i].longitude_out,
            is_match_in: json_data[i].is_match,
            is_match_out: json_data[i].is_match_out != null ? json_data[i].is_match_out : null,
            is_late: is_late,
            departement: "Sales"
          };
          this.attendance_sales_data.push(payload);
        }
        // console.log("ini cel: ", result);
        // console.log("ini marcella: ", this.attendance_sales_data);

        this.attendance_sales = this.attendance_sales_data.slice();
        this.start = (this.params.page - 1) * this.limit;
        this.end = this.params.page * this.limit;
        const sales_data = this.attendance_sales.splice(this.start, this.end);
        const old_data = this.attendance_data;
        this.attendance_data = [...old_data, ...sales_data];
        this.is_table_loading_sales = false;
        this.is_click_load_more = false;
        console.log("ini data :", this.attendance_sales_data);
        console.log("ini mid :", this.start, " sampai ", this.end);
        console.log("ini mid sales_data :", this.sales_data);
        console.log("ini final 1 :", this.attendance_data);
      }).catch(error => {
        this.is_click_load_more = false;
        this.is_table_loading_sales = false;
        this.is_no_more_sales = true;
        this.attendance_sales.push([]);
        console.log(`err sales ${JSON.stringify(error)}`);
      });
    },
    getAttendanceSlsOld() {
      this.is_table_loading_sales = true;
      var sd = format(new Date(this.params.start_date), "yyyy-MM-dd");
      var ed = format(new Date(this.params.end_date), "yyyy-MM-dd");
      const start = sd;
      const end = ed;
      const params = {
        start_date: start,
        end_date: end,
        user_id: this.params.id_user,
        filter: this.params.filter,
        name: this.params.name
      };
      console.log("ini params: ", params);
      this.fetchAttendanceSales(params).then(response => {
        var json_data = response.result;
        console.log("ini: ", response.result);
        var result = [];
        for (var i in json_data) {
          result.push([i, json_data[i]]);
          console.log(result);
          console.log("ini cla: ", i, "- ", json_data[i]);
        }

        // const date = format(new Date(), "yyyy-MM-dd");
        // let time_in = data.details[index].time_in;

        // let date2_in = new Date(time_in);
        // let date1_in = new Date(date + " " + "08:00:00");
        // const checkinDiffTime = date2_in - date1_in;
        // const checkinDiffHours = Math.ceil(
        //   checkinDiffTime / (1000 * 60 * 60)
        // );

        // let is_late = 0;
        // if (checkinDiffHours > 0) {
        //   is_late = 1;
        // }

        // const payload = {
        //   name: data.name,
        //   date: data.details[index].time_in.split(" ")[0],
        //   time_in: data.details[index].time_in.split(" ")[1],
        //   time_out:
        //     data.details[index].time_out != null
        //       ? data.details[index].time_out.split(" ")[1]
        //       : null,
        //   lat_in: data.details[index].latitude_in,
        //   long_in: data.details[index].longitude_in,
        //   lat_out: data.details[index].latitude_out,
        //   long_out: data.details[index].longitude_out,
        //   is_match_in: is_match_in,
        //   is_match_out: is_match_out,
        //   is_late: is_late,
        //   departement: "Sales",
        // };
        // this.attendance_sales_data.push(payload);
        // console.log("ini cel: ", result);
        response.result.forEach(data => {

          // const index = data.details.length - 1;

          // let is_match_in = 0;
          // if (
          //   data.details[index].dist_kokas <= 150 ||
          //   data.details[index].dist_alaydrus <= 150
          // ) {
          //   is_match_in = 1;
          // }

          // let is_match_out = 0;
          // if (
          //   data.details[index].dist_kokas_out <= 150 ||
          //   data.details[index].dist_alaydrus_out <= 150
          // ) {
          //   is_match_out = 1;
          // }

          // const date = format(new Date(), "yyyy-MM-dd");
          // let time_in = data.details[index].time_in;

          // let date2_in = new Date(time_in);
          // let date1_in = new Date(date + " " + "08:00:00");
          // const checkinDiffTime = date2_in - date1_in;
          // const checkinDiffHours = Math.ceil(
          //   checkinDiffTime / (1000 * 60 * 60)
          // );

          // let is_late = 0;
          // if (checkinDiffHours > 0) {
          //   is_late = 1;
          // }

          // const payload = {
          //   name: data.name,
          //   date: data.details[index].time_in.split(" ")[0],
          //   time_in: data.details[index].time_in.split(" ")[1],
          //   time_out:
          //     data.details[index].time_out != null
          //       ? data.details[index].time_out.split(" ")[1]
          //       : null,
          //   lat_in: data.details[index].latitude_in,
          //   long_in: data.details[index].longitude_in,
          //   lat_out: data.details[index].latitude_out,
          //   long_out: data.details[index].longitude_out,
          //   is_match_in: is_match_in,
          //   is_match_out: is_match_out,
          //   is_late: is_late,
          //   departement: "Sales",
          // };
          // this.attendance_sales_data.push(payload);
        });

        // const old_data = this.attendance_data;
        // this.attendance_sales = new_data;
        // this.attendance_data = [...old_data, ...new_data];
        // this.is_table_loading_sales = false;
        // this.is_click_load_more = false;
      }).catch(error => {
        this.is_click_load_more = false;
        this.is_table_loading_sales = false;
        this.is_no_more_sales = true;
        // this.attendance_sales.push([]);

        console.log(`err sales ${JSON.stringify(error)}`);
      });
    },
    getAttendanceSales() {
      this.is_table_loading_sales = true;
      const start = this.params.start_date;
      const end = this.params.end_date;
      const params = {
        first_date: start,
        last_date: end,
        user_id: this.params.id_user,
        filter: this.params.filter,
        name: this.params.name
      };
      this.fetchAttendanceSales(params).then(response => {
        response.result.forEach(data => {
          const index = data.details.length - 1;
          let is_match_in = 0;
          if (data.details[index].dist_kokas <= 150 || data.details[index].dist_alaydrus <= 150) {
            is_match_in = 1;
          }
          let is_match_out = 0;
          if (data.details[index].dist_kokas_out <= 150 || data.details[index].dist_alaydrus_out <= 150) {
            is_match_out = 1;
          }
          const date = format(new Date(), "yyyy-MM-dd");
          let time_in = data.details[index].time_in;
          let date2_in = new Date(time_in);
          let date1_in = new Date(date + " " + "08:00:00");
          const checkinDiffTime = date2_in - date1_in;
          const checkinDiffHours = Math.ceil(checkinDiffTime / (1000 * 60 * 60));
          let is_late = 0;
          if (checkinDiffHours > 0) {
            is_late = 1;
          }
          const payload = {
            name: data.name,
            date: data.details[index].time_in.split(" ")[0],
            time_in: data.details[index].time_in.split(" ")[1],
            time_out: data.details[index].time_out != null ? data.details[index].time_out.split(" ")[1] : null,
            lat_in: data.details[index].latitude_in,
            long_in: data.details[index].longitude_in,
            lat_out: data.details[index].latitude_out,
            long_out: data.details[index].longitude_out,
            is_match_in: is_match_in,
            is_match_out: is_match_out,
            // is_match_out:
            //   data.details[index].is_match_out != null
            //     ? data.details[index].is_match_out
            //     : null,
            is_late: is_late,
            departement: "Sales"
          };
          this.attendance_sales_data.push(payload);
        });
        this.attendance_sales = this.attendance_sales_data.slice();
        this.start = (this.params.page - 1) * this.limit;
        this.end = this.params.page * this.limit;
        const sales_data = this.attendance_sales.splice(this.start, this.end);
        const old_data = this.attendance_data;
        this.attendance_data = [...old_data, ...sales_data];
        this.is_table_loading_sales = false;
        this.is_click_load_more = false;
        console.log("ini data :", this.attendance_sales_data);
        console.log("ini mid :", this.start, " sampai ", this.end);
        console.log("ini mid sales_data :", this.sales_data);
        console.log("ini final 1 :", this.attendance_data);
      }).catch(error => {
        this.attendance_sales.push([]);
        this.is_click_load_more = false;
        this.is_table_loading_sales = false;
        console.log(`err sales ${JSON.stringify(error)}`);
      });
    },
    getAttendanceSalesMore() {
      this.is_table_loading_sales = true;
      const length = this.attendance_sales_data.length;
      const slice = this.attendance_sales_data.slice();
      this.start = (this.params.page - 1) * this.limit;
      this.start += 1;
      console.log(`start ${this.start}`);
      this.end = this.params.page * this.limit;
      console.log(`end ${this.end}`);
      console.log(`frist length ${length}`);
      if (this.end <= length) {
        console.log(`ok ${this.start}, ${this.end}`);
        const sales_data = slice.splice(this.start, this.limit);
        console.log(`ok length ${sales_data.length}`);
        const old_data = this.attendance_data;
        this.attendance_data = [...old_data, ...sales_data];
        console.log("ini final more :", this.attendance_data);
      }
      this.is_table_loading_sales = false;
    },
    getAttendanceDataMore() {
      // console.log("ini name: ");
      this.is_click_load_more = true;
      this.params.page += 1;
      // const params = {
      //   name: this.params.name,
      // };
      this.getAttendanceOffice();
      // this.getAttendanceSls();
      this.getAttendanceSalesMore();

      // console.log(`sales ${this.attendance_sales.length}`);
      // const sales_data = this.attendance_sales.splice(this.start, this.end);
      // console.log(`sales ${JSON.stringify(sales_data)}`);
    },

    handleChangeFilterDate() {
      //88
      const start = this.params.date_range[0] + " 00:00:00";
      const end = this.params.date_range[1] + " 23:59:59";
      this.params.start_date = format(new Date(start), "yyyy-MM-dd 00:00:00");
      this.params.end_date = format(new Date(end), "yyyy-MM-dd 23:59:59");
      this.params.page = 1;
      this.attendance_sales_data = [];
      this.attendance_data = [];
      this.attendance_office = [];
      this.attendance_sales = [];
      this.start = 0;
      this.end = 0;
      // this.params.name = "";
      this.getAttendanceOffice();
      // this.getAttendanceSales();
      this.getAttendanceSls();
    },
    handleExportOffice() {
      const start = format(new Date(this.params.start_date), "dd-MMM-yyyy");
      const end = format(new Date(this.params.end_date), "dd-MMM-yyyy");
      const format_name = `AttendanceOffice_${start}_${end}`;
      this.downloading_office = true;
      ElMessage({
        message: "Please wait downloading office attendance report..",
        type: "info",
        duration: 2000
      });
      const params = {
        start: this.params.start_date.split(" ")[0],
        end: this.params.end_date.split(" ")[0],
        type: "detail",
        search: this.params.filter
      };
      this.fetchAttendanceOfficeExcel(params).then(response => {
        this.downloading_office = false;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${format_name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        ElNotification({
          title: "Voila!",
          message: "Success download office attendance report!",
          type: "success"
        });
      }).catch(error => {
        this.downloading_office = false;
        ElNotification({
          title: "Oops!",
          message: `Error: ${error.message}`,
          type: "error",
          duration: 5000
        });
      });
    },
    handleExportSales() {
      this.downloading_sales = true;
      ElMessage({
        message: "Please wait downloading sales attendance report..",
        type: "info",
        duration: 2000
      });
      const params = {
        start_date: this.params.start_date,
        end_date: this.params.end_date
      };
      this.fetchAttendanceSalesExcel(params).then(response => {
        this.downloading_sales = false;
        window.open(response.result, "_blank");
        ElNotification({
          title: "Voila!",
          message: "Success download sales attendance report!",
          type: "success"
        });
      }).catch(error => {
        this.downloading_sales = false;
        ElNotification({
          title: "Oops!",
          message: `Error: ${error.message}`,
          type: "error",
          duration: 5000
        });
      });
    }
  },
  computed: {}
};