<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold"> Reporting </span>
        </div>
        <div>
          <argon-button
            variant="gradient"
            color="success"
            size="md"
            @click="handleExportReporting"
          >
            Export Excel
          </argon-button>
        </div>
      </div>
    </div>
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex flex-column">
          <div class="text-center">Start Date</div>
          <el-date-picker
            type="daterange"
            v-model="params.start_date.date_range"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            @change="handleChangeStartDate"
          />
        </div>
        <div class="d-flex flex-column">
          <div class="text-center">Due Date</div>
          <el-date-picker
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
          />
        </div>
      </div>
    </div>

    <div class="card p-3" v-if="is_loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="card p-3" v-else>
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              No
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Status
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              List
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Board
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Member
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Start Date
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Due Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getReporting" :key="item">
            <td>
              <div class="d-flex px-2 py-1">
                <span class="text-xs text-secondary mb-0">{{ index + 1 }}</span>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-xs text-secondary mb-0">
                {{ item.kanban_title }}
              </p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-xs text-secondary mb-0">{{ item.card_title }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-xs text-secondary mb-0">{{ item.board_title }}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="avatar-group">
                <a
                  href="javascript:;"
                  class="avatar avatar-xs border-0 rounded-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="13 New Messages"
                  v-for="items in item.collab"
                  :key="items"
                >
                  <img
                    alt="Image placeholder"
                    src="../../../assets/img/team-3.jpg"
                  />
                </a>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-xs text-secondary mb-0">
                {{ item.card_start_date }}
              </p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-xs text-secondary mb-0">
                {{ item.card_due_date }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";

export default {
  name: "GoappsAdminReporting",
  components: {
    ArgonButton,
  },

  data() {
    return {
      reporting: [],
      is_loading: false,
      params: {
        id_card: "",
        search: "",
        order: "",
        start_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        due_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
      },
      is_table_loading: false,
    };
  },

  beforeMount() {
    // this.fetchCardData();
    
  },

  mounted() {
    this.fetchReportingData();
  },

  methods: {
    ...mapActions({
      fetchCard: "gopro/fetchCard",
      fetchReporting: "gopro/fetchReporting",
      doExportReport: "gopro/doExportReport",
    }),

    fetchCardData() {
      this.is_table_loading = true;
      const payload = {
        id_card: this.params.id_card,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchCard(payload).then((response) => {
        this.is_table_loading = false;
      });
    },

    fetchReportingData() {
      this.is_loading = true;
      const payload = {
        id_card: this.params.id_card,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchReporting(payload).then((response) => {
        this.is_loading = false;
        this.reporting = response;
        console.log(this.reporting);
      });
    },

    handleChangeStartDate() {
      if (this.params.start_date.date_range == null) {
        this.params.start_date.date_range = ["", ""];
      }
      this.params.start_date.start_date = this.params.start_date.date_range[0];
      this.params.start_date.end_date = this.params.start_date.date_range[1];
      let payload = {
        start_date: this.params.start_date,
        end_date: this.params.end_date,
      };
    },

    handleExportReporting() {
      confirm("Yakin Mau Exports?");
      const payload = {
        start_date: this.params.start_date.start_date,
        end_date: this.params.start_date.end_date,
      };
      this.doExportReport(payload)
        .then((response) => {
          confirm(JSON.stringify(response));
          window.open(response.results, "_blank");
          this.params.start_date.start_date = "";
          this.params.start_date.end_date = "";
          this.params.start_date.date_range = ["", ""];
        })
        .catch((err) => {
          alert(JSON.stringify(err.message));
          this.params.start_date.start_date = "";
          this.params.start_date.end_date = "";
          this.params.start_date.date_range = ["", ""];
        });
    },
  },

  computed: {
    // getCard() {
    //   return this.$store.state.gopro.card;
    // },
    getReporting() {
      return this.$store.state.gopro.reporting;
    },
  },
};
</script>
