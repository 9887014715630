<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.client.title"
              :value="stats.client.value"
              :percentage="stats.client.percentage"
              :iconClass="stats.client.iconClass"
              :iconBackground="stats.client.iconBackground"
              :detail="stats.client.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.revenue.title"
              :value="`IDR ${stats.revenue.value}`"
              :percentage="stats.revenue.percentage"
              :iconClass="stats.revenue.iconClass"
              :iconBackground="stats.revenue.iconBackground"
              :detail="stats.revenue.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.active.title"
              :value="stats.active.value"
              :percentage="stats.active.percentage"
              :iconClass="stats.active.iconClass"
              :iconBackground="stats.active.iconBackground"
              :percentageColor="stats.active.percentageColor"
              :detail="stats.active.detail"
              directionReverse
            ></card>
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <card
              :title="stats.close.title"
              :value="stats.close.value"
              :percentage="stats.close.percentage"
              :iconClass="stats.close.iconClass"
              :iconBackground="stats.close.iconBackground"
              :percentageColor="stats.close.percentageColor"
              :detail="stats.close.detail"
              directionReverse
            ></card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7">
            <!-- line chart -->
            <div class="card">
              <DoughnutChart :detail2="details" />
            </div>
          </div>
          <div class="col-lg-5">
            <carousel />
          </div>
        </div>
      </div>
    </div>
    <!-- <Configurator @click="checkInOut" /> -->
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { format } from "date-fns";

import Card from "@/examples/Cards/Card.vue";
import DoughnutChart from "@/views/GooSales/components/DoughnutChart.vue";
import Carousel from "@/views/GooSales/components/HomeCarousel.vue";
import CategoriesCard from "@/views/components/CategoriesCard.vue";
import Configurator from "@/components/FloatingButton.vue";

import { mapActions, mapMutations } from "vuex";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "go-force",
  data() {
    return {
      stats: {
        client: {
          title: "New Client",
          value: "1",
          percentage: "",
          iconClass: "ni ni-world",
          detail: "",
          iconBackground: "bg-gradient-primary",
        },
        revenue: {
          title: "Revenue",
          value: "2,300",
          percentage: "",
          iconClass: "ni ni-money-coins",
          iconBackground: "bg-gradient-danger",
          detail: "",
        },
        close: {
          title: "Project Close Deal",
          value: "+3,462",
          percentage: "",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "",
        },
        active: {
          title: "Active Project",
          value: "+3,462",
          percentage: "",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-warning",
          detail: "",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
      details: "",
      location: null,
      gettingLocation: false,
      errorStr: null,
      dialogVisible: false,
    };
  },
  components: {
    Card,
    DoughnutChart,
    Carousel,
    CategoriesCard,
    Configurator,
  },
  mounted() {
    this.dashboardData();
    this.checkAttendanceStatus();
    this.userData();
  },

  methods: {
    ...mapActions({
      fetchDashboardData: "eraforce/fetchDashboardData",
      fetchCheckInList: "eraforce/fetchCheckInList",
      doCheckIn: "eraforce/doCheckIn",
      doCheckOut: "eraforce/doCheckOut",
      fetchCheckInStatus: "eraforce/fetchCheckInStatus",
      fetchUserLoginData: "eraforce/fetchUserLoginData",
    }),
    ...mapMutations({
      SET_ALREADY_CHECKIN: "eraforce/SET_ALREADY_CHECKIN",
      SET_NOT_CHECKIN: "eraforce/SET_NOT_CHECKIN",
      SET_CHECKIN_DATA: "eraforce/SET_CHECKIN_DATA",
    }),

    checkAttendanceStatus() {
      this.fetchCheckInStatus().then((response) => {
        if (response.isChecked) {
          this.SET_ALREADY_CHECKIN();
          this.SET_CHECKIN_DATA(response);
        } else {
          this.SET_NOT_CHECKIN();
        }
      });
    },

    userData() {
      this.fetchUserLoginData();
    },

    dashboardData() {
      this.fetchDashboardData().then((response) => {
        this.stats.client.value = response.new_company.total;
        this.stats.revenue.value = numeral(response.result.revenue).format(
          "0,0"
        );
        this.stats.active.value = response.active_project.total;
        this.stats.close.value = response.closed_project.total;
        this.details = response.message;
      });
    },

    checkInOut() {
      this.locateMe();
      if (this.location == null) {
        // this.locateMe();
        Swal.fire({
          title: "Oops... Failed get location!",
          text: "Do you want to try again?",
          icon: "error",
          showDenyButton: true,
          confirmButtonText: "Try Again",
          denyButtonText: `No`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.checkInOut();
          } else if (result.isDenied) {
            Swal.fire("Location get failed", "", "error");
          }
        });
      } else {
        let checkInStatus = this.$store.state.eraforce.is_already_checkin;

        if (!checkInStatus) {
          this.checkIn();
          console.log(`check in`);
        } else {
          this.checkOut();
          console.log(`check out`);
        }
      }
    },

    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error("Geolocation is not available."));
        }

        navigator.geolocation.getCurrentPosition(
          (pos) => {
            resolve(pos);
          },
          (err) => {
            reject(err);
          }
        );
      });
    },

    async locateMe() {
      this.gettingLocation = true;
      try {
        this.gettingLocation = false;
        this.location = await this.getLocation();
      } catch (e) {
        this.gettingLocation = false;
        Swal.fire({
          icon: "error",
          title: "Oops...Failed getting current location!",
          text: `${e.message}`,
        });
        this.errorStr = e.message;
      }
    },

    fetchTodayCheckIn() {
      const payload = {
        first_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        last_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
      };
      this.fetchCheckInList(payload).then((response) => {
        if (response.length > 0) {
          this.SET_ALREADY_CHECKIN();
        } else {
          this.SET_NOT_CHECKIN();
        }
      });
    },

    checkIn() {
      const payload = {
        id: this.getUserLoginData.id,
        latitude: this.location.coords.latitude,
        longitude: this.location.coords.longitude,
      };
      this.doCheckIn(payload)
        .then((response) => {
          Swal.fire({
            title: "Horay! Success Check In!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          this.SET_ALREADY_CHECKIN();
        })
        .catch((error) => {
          Swal.fire({
            title: "Ooops... Failed checkin!",
            text: `${error}`,
            icon: "error",
            showConfirmButton: true,
          });
          this.SET_NOT_CHECKIN();
        });
    },

    checkOut() {
      const payload = {
        latitude: this.location.coords.latitude,
        longitude: this.location.coords.longitude,
        check_id: this.getCheckInData.check_id,
        card_id: this.getCheckInData.card_activity,
      };
      this.doCheckOut(payload)
        .then((response) => {
          Swal.fire({
            title: "Horay! Success Check Out!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          this.SET_NOT_CHECKIN();
        })
        .catch((error) => {
          Swal.fire({
            title: "Ooops... Failed checkout!",
            text: `${error}`,
            icon: "error",
            showConfirmButton: false,
          });
          this.SET_ALREADY_CHECKIN();
        });
    },
  },

  computed: {
    getDashboardData() {
      return this.$store.state.eraforce.dashboard_data;
    },
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
    getCheckInData() {
      return this.$store.state.eraforce.check_in_data;
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
};
</script>
