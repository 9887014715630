<template>
  <div>
    <div>
      <div class="card p-3">
        <argon-input
          type="text"
          placeholder="Search apps..."
          name="apps"
          size="lg"
          fullWidth
          :value="search"
          v-model="search"
          @change="getAllApps"
        />
        <div class="mt-2">
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            @click="openAddApp"
          >
            <div><i class="fa fa-plus"> </i> Add New Apps</div>
          </argon-button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="card p-3">
        <el-table
          :data="appsData"
          height="400"
          style="width: 100%"
          v-loading="loading_data"
        >
          <el-table-column prop="apps" label="Apps" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button
                size="small"
                @click="handleEdit(scope.row)"
                class="bg-gradient-warning"
                >Edit</el-button
              >
              <el-button
                size="small"
                type="danger"
                @click="handleDeleteApp(scope.row.id_apps, scope.row.apps)"
                class="bg-gradient-danger"
                >Delete</el-button
              >
              <el-button
                size="small"
                type="info"
                @click="openMenuPermission(scope.row.id_apps, scope.row.apps)"
                class="bg-gradient-info"
                >Menu Permission</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- Add Dialog -->
    <el-dialog
      v-model="create_app_dialog"
      title="Create New App"
      width="40%"
      :show-close="false"
    >
      <div>
        <label for="">Apps Name</label>
        <argon-input
          type="text"
          placeholder="Input Apps Name"
          name="apps"
          size="lg"
          fullWidth
          :value="apps"
          v-model="apps"
        />
      </div>
      <argon-button
        class="mt-4"
        variant="gradient"
        color="success"
        fullWidth
        size="lg"
        @click="handleCreateApp"
        :disabled="apps == null || apps == ''"
      >
        <div>Save</div>
      </argon-button>
      <argon-button
        class="mt-2"
        variant="gradient"
        color="danger"
        fullWidth
        size="lg"
        @click="handleCancelName"
      >
        <div>Cancel</div>
      </argon-button>
    </el-dialog>

    <!-- Edit Dialog -->
    <el-dialog
      v-model="dialogVisible"
      title="Change App's Name"
      width="40%"
      :show-close="false"
    >
      <div>
        <label for="">Apps Name</label>
        <argon-input
          type="text"
          placeholder="Input Apps Name"
          name="apps"
          size="lg"
          fullWidth
          :value="apps"
          v-model="apps"
        />
      </div>
      <argon-button
        class="mt-4"
        variant="gradient"
        color="success"
        fullWidth
        size="lg"
        @click="handleUpdateApp"
        :disabled="apps == null || apps == ''"
      >
        <div>Save</div>
      </argon-button>
      <argon-button
        class="mt-2"
        variant="gradient"
        color="danger"
        fullWidth
        size="lg"
        @click="handleCancelName"
      >
        <div>Cancel</div>
      </argon-button>
    </el-dialog>

    <!-- Menu Permission Dialog -->
    <el-dialog
      v-model="menu_permission_dialog"
      title="Menu Permission"
      width="80%"
      :show-close="false"
    >
      <div class="row">
        <el-select
          v-model="selected_menu"
          class="m-2"
          placeholder="Select Menu"
          size="large"
          @change="handleChangeMenu"
        >
          <el-option
            v-for="item in list_menu"
            :key="item.id"
            :label="item.menu"
            :value="item.id"
          />
        </el-select>
      </div>

      <div class="row">
        <el-transfer
          v-model="value"
          :props="{
            key: 'id',
            label: 'sub_menu',
          }"
          :data="list_sub_menu.sub_menu"
          :titles="['All Sub Menu', 'Active']"
        />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="menu_permission_dialog = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleUpdateTeam"
            class="bg-gradient-success"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { mapActions } from "vuex";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  data() {
    return {
      search: "",
      dialogVisible: false,
      apps: null,
      selected_apps: null,
      create_app_dialog: false,
      loading_data: false,
      menu_permission_dialog: false,
      list_menu_app: [],
      list_menu: [],
      selected_menu: null,
      list_sub_menu: [],
      selected_active_sub_menu: [],
    };
  },

  components: {
    ArgonInput,
    ArgonButton,
  },

  mounted() {
    this.getAllApps();
  },

  methods: {
    ...mapActions({
      fetchApps: "administrator/fetchApps",
      doCreateApp: "administrator/doCreateApp",
      doUpdateApp: "administrator/doUpdateApp",
      doDeleteApp: "administrator/doDeleteApp",
      fetchMenuByApps: "administrator/fetchMenuByApps",
      fetchMenuByAppsAll: "administrator/fetchMenuByAppsAll",
    }),

    getAllApps() {
      this.loading_data = true;
      const payload = {
        search: this.search,
      };
      this.fetchApps(payload).then((response) => {
        this.loading_data = false;
      });
    },

    openAddApp() {
      this.create_app_dialog = true;
    },

    handleCancelName() {
      this.apps = null;
      this.dialogVisible = false;
    },

    handleEdit(scope) {
      this.selected_apps = scope;
      this.apps = scope.apps;
      this.dialogVisible = true;
    },

    handleCreateApp() {
      this.create_app_dialog = false;
      if (this.apps == null || this.apps == "") {
        return Swal.fire({
          icon: "error",
          title: `Oopss!`,
          text: `Error: Apps name is required!`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.create_app_dialog = true;
          }
        });
      }

      const payload = {
        apps: this.apps,
      };
      this.doCreateApp(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! Success create new app!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllApps();
        })
        .catch((error) => {
          return Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Error: ${error}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.create_app_dialog = true;
            }
          });
        });
    },

    handleUpdateApp() {
      this.dialogVisible = false;
      if (this.apps == null || this.apps == "") {
        return Swal.fire({
          icon: "error",
          title: `Oopss!`,
          text: `Error: Apps name is required!`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogVisible = true;
          }
        });
      }

      const payload = {
        apps: this.apps,
        id_apps: this.selected_apps.id_apps,
      };
      this.doUpdateApp(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! ${response}!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.getAllApps();
        })
        .catch((error) => {
          return Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Error: ${error}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.dialogVisible = true;
            }
          });
        });
    },

    handleDeleteApp(id, name) {
      Swal.fire({
        icon: "info",
        title: `Are you sure want to delete app: ${name}? This action can't be undone.`,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            id_apps: id,
          };
          this.doDeleteApp(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Voila! ${response}!`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getAllApps();
            })
            .catch((error) => {
              return Swal.fire({
                icon: "error",
                title: `Oopss!`,
                text: `Error: ${error}`,
              }).then((result) => {});
            });
        }
      });
    },

    openMenuPermission(row) {
      this.getMenuByApps(row);
      this.menu_permission_dialog = true;
    },

    getMenuByApps(id) {
      const payload = {
        apps: id,
      };
      this.fetchMenuByAppsAll(payload).then((response) => {
        this.list_menu_app = response.detail;
        response.detail.forEach((menu) => {
          const data = {
            id: menu.id_menu,
            menu: menu.menu,
          };
          this.list_menu.push(data);
        });
      });
    },

    handleChangeMenu() {
      const menu = this.selected_menu;
      [this.list_sub_menu] = this.list_menu_app.filter((item) => {
        if (item.id_menu == menu) {
          return item.sub_menu;
        }
      });

      console.log(`log ${JSON.stringify(this.list_sub_menu.sub_menu)}`);
    },
  },

  computed: {
    appsData() {
      return this.$store.state.administrator.apps;
    },
  },
};
</script>
