<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <el-input
        v-model="params.search"
        class="w-100 m-2"
        size="large"
        placeholder="Search by users name/nik/id"
        :prefix-icon="Search"
        @change="getEmployeeData"
      />
    </div>

    <div class="card mb-4">
      <el-scrollbar height="500px">
        <div class="card-header pb-0">
          <h6>Users</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
          <div class="table-responsive p-0">
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Name
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    NIK
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    ID
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Employed Status
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Account Status
                  </th>
                  <th class="text-secondary opacity-7"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getEmployee" :key="item.fullname">
                  <td>
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img
                          src="../../assets/img/team-2.jpg"
                          class="avatar avatar-sm me-3"
                          alt="user6"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ item.fullname }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          {{ item.email }}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p class="text-xs text-secondary mb-0">{{ item.nik }}</p>
                  </td>
                  <td class="align-middle text-center">
                    <span class="text-secondary text-xs font-weight-bold">{{
                      item.employee_no
                    }}</span>
                  </td>
                  <td class="align-middle text-center text-sm">
                    <span
                      v-if="item.delete_date == null"
                      class="badge badge-sm bg-gradient-success"
                    >
                      Active
                    </span>
                    <span v-else class="badge badge-sm bg-gradient-danger">
                      Not Active
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="item.is_activate == 1"
                      class="badge badge-sm bg-gradient-success"
                    >
                      Active
                    </span>
                    <span v-else class="badge badge-sm bg-gradient-danger">
                      Not Active
                    </span>
                  </td>
                  <td class="align-middle">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <i class="fas fa-caret-down"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item @click="detail(item.id_user)">
                            Detail
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="m-4 d-flex justify-content-center">
              <el-button
                v-if="!loading_more && !is_no_more && !is_table_loading"
                @click="getEmployeeDataMore"
              >
                Load More
              </el-button>
              <el-button v-if="loading_more"> Loading ... </el-button>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>

    <!-- Detail User -->
    <el-dialog v-model="modal_detail" title="" width="30%">
      <div class="card card-profile">
        <img
          src="../../assets/img/bg-profile.jpg"
          alt="Image placeholder"
          class="card-img-top"
        />
        <div class="row justify-content-center">
          <div class="col-4 col-lg-4 order-lg-2">
            <div class="mt-n4 mt-lg-n6 mb-4 mb-lg-0">
              <a href="javascript:;">
                <img
                  src="../../assets/img/team-2.jpg"
                  class="rounded-circle img-fluid border border-2 border-white"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="card-header text-center border-0 pt-0 pt-lg-2 pb-4 pb-lg-3">
          <div class="d-flex justify-content-between">
            <a
              href="javascript:;"
              class="btn btn-sm btn-info mb-0 d-block d-lg-none"
              ><i class="ni ni-collection"></i
            ></a>
            <a
              href="javascript:;"
              class="btn btn-sm btn-dark float-right mb-0 d-block d-lg-none"
              ><i class="ni ni-email-83"></i
            ></a>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="text-center mt-4">
            <h6>
              {{ detailUser.fullname
              }}<span class="font-weight-light"
                >, {{ tahun(detailUser.dob) }}</span
              >
            </h6>
            <div class="h6 font-weight-300">
              <i class="ni location_pin mr-2"></i>{{ detailUser.address }},
              {{ detailUser.city }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { format } from "date-fns";

import { mapActions, mapMutations } from "vuex";
import { ElNotification } from "element-plus";

export default {
  name: "GoappsAdminEmployee",

  data() {
    return {
      options: ["Male", "Female"],
      options_employeement: ["All", "Active", "Inactive"],
      options_edu: ["SMA/SMK", "SMA/SMK", "D3", "S1", "S2", "S3", "Others"],
      params_divisi: {
        id: "",
        search: "",
        order: "ASC",
      },
      params_office: {
        id: "",
        search: "",
        order: "ASC",
      },
      params: {
        id: "",
        search: "",
        page: 1,
        order: "ASC",
        join_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        out_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        employeement_status: "All",
        office_location: "All",
      },
      form: {
        id: null,
        id_sso: null,
        employee_no: null,
        id_departement: null,
        nik: null,
        fullname: null,
        province: null,
        city: null,
        address: null,
        dob: null,
        gender: null,
        start_date: null,
        end_date: null,
        delete_date: null,
        image: null,
        email: null,
        username: null,
        phone: null,
        edu: null,
        id_office_location: null,
      },
      edit_dialog: false,
      is_table_loading: false,
      loading_more: false,
      is_no_more: false,
      search_divisi: "",
      divisi_data: [],
      selected_divisi: null,
      selected_office: "",
      province_data: [],
      search_province: "",
      selected_province: null,
      city_data: [],
      search_city: "",
      selected_city: null,
      detailUser: {},
      modal_detail: false,
    };
  },

  beforeMount() {
    this.getEmployeeData();
    this.getDivisiData();
    this.getOfficeData();
    this.getProvinceData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchEmployee: "hrd/fetchEmployee",
      doUpdateEmployee: "hrd/doUpdateEmployee",
      doDeleteEmployee: "hrd/doDeleteEmployee",
      doActivationEmployee: "hrd/doActivationEmployee",
      fetchDivisi: "hrd/fetchDivisi",
      fetchOffice: "hrd/fetchOffice",
      fetchProvinceData: "fetchProvinceData",
      fetchCityByProvinceData: "fetchCityByProvinceData",
    }),
    ...mapMutations({
      SET_EMPLOYEE: "hrd/SET_EMPLOYEE",
    }),

    getProvinceData() {
      this.fetchProvinceData().then((resp) => {
        this.province_data = resp;
      });
    },

    getCityData(id) {
      this.fetchCityByProvinceData(id).then((resp) => {
        this.city_data = resp;
      });
    },

    getDivisiData() {
      const params = {
        id: this.params_divisi.id,
        search: this.params_divisi.search,
        order: "ASC",
      };
      this.fetchDivisi(params).then((response) => {
        this.divisi_data = response;
      });
    },

    getOfficeData() {
      const params = {
        id: this.params_office.id,
        search: this.params_office.search,
        order: "ASC",
      };
      this.fetchOffice(params);
    },

    tahun(arg) {
      let tanggalLahir = new Date(arg);
      let tanggalSekarang = new Date();
      let timeDiff = Math.abs(
        tanggalSekarang.getTime() - tanggalLahir.getTime()
      );
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      let result = Math.round(diffDays / 365);

      return result;
    },

    detail(user) {
      const params = {
        id: user,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
      };

      this.fetchEmployee(params)
        .then((response) => {
          console.log(response);
          this.detailUser = response[0];
          this.modal_detail = true;
          // alert(JSON.stringify(this.detailUser));
        })
        .catch((error) => {
          console.log(response);
          // alert(JSON.stringify(response));
        });
    },

    getEmployeeData() {
      this.is_table_loading = true;
      this.params.page = 1;
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
      };
      this.fetchEmployee(params)
        .then((response) => {
          if (response.length < 10) {
            this.is_no_more = true;
          } else {
            this.is_no_more = false;
          }
          this.SET_EMPLOYEE(response);
          this.is_table_loading = false;
        })
        .catch((error) => {
          this.is_no_more = true;
          this.is_table_loading = false;
          this.SET_EMPLOYEE([]);
        });
    },

    getEmployeeDataMore() {
      this.loading_more = true;
      const page = 1;
      this.params.page += page;
      const params = {
        id: this.params.id,
        search: this.params.search,
        page: this.params.page,
        order: this.params.order,
        join_start: this.params.join_date.start_date,
        join_end: this.params.join_date.end_date,
        out_start: this.params.out_date.start_date,
        out_end: this.params.out_date.end_date,
        employeement_status: this.params.employeement_status,
        office_location: this.params.office_location,
      };
      this.fetchEmployee(params)
        .then((response) => {
          if (response.length < 10) {
            this.is_no_more = true;
          } else {
            this.is_no_more = false;
          }
          let result = this.getEmployee;
          const merged = [...result, ...response];
          this.SET_EMPLOYEE(merged);
          this.loading_more = false;
        })
        .catch((error) => {
          this.is_no_more = true;
          this.loading_more = false;
        });
    },

    handleChangeJoinDate() {
      if (this.params.join_date.date_range == null) {
        this.params.join_date.date_range = ["", ""];
      }
      this.params.join_date.start_date = this.params.join_date.date_range[0];
      this.params.join_date.end_date = this.params.join_date.date_range[1];
      this.getEmployeeData();
    },

    handleChangeOuDate() {
      if (this.params.out_date.date_range == null) {
        this.params.out_date.date_range = ["", ""];
      }
      this.params.out_date.start_date = this.params.out_date.date_range[0];
      this.params.out_date.end_date = this.params.out_date.date_range[1];
      this.getEmployeeData();
    },

    handleOpenEdit(data) {
      if (data.id_departement != null) {
        const dept = this.getDivisi.filter(
          (div) => data.id_departement == div.id_departement
        );
        if (dept.length > 0) {
          this.search_divisi = dept[0].departement;
        }
      }
      this.form = {
        id: data.id_user,
        id_sso: data.id_sso,
        employee_no: data.employee_no,
        id_departement: data.id_departement,
        nik: data.nik,
        fullname: data.fullname,
        province: data.province,
        city: data.city,
        address: data.address,
        dob: data.dob,
        gender: data.gender,
        start_date: data.start_date,
        end_date: data.end_date,
        delete_date: data.delete_date,
        image: data.image,
        email: data.email,
        username: data.username,
        phone: data.phone,
        edu: data.edu,
        id_office_location: data.id_office_location,
      };
      this.edit_dialog = true;
    },

    handleUpdateEmployee() {
      const payload = {
        id_user: this.form.id,
        id_sso: this.form.id_sso,
        employee_no: this.form.employee_no,
        id_departement: this.form.id_departement,
        nik: this.form.nik,
        fullname: this.form.fullname,
        province: this.form.province,
        city: this.form.city,
        address: this.form.address,
        dob: this.form.dob,
        gender: this.form.gender,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        delete_date: this.form.delete_date,
        image: this.form.image,
        email: this.form.email,
        username: this.form.username,
        phone: this.form.phone,
        edu: this.form.edu,
        id_office_location: this.form.id_office_location,
      };
      this.doUpdateEmployee(payload)
        .then((response) => {
          this.handleCloseEdit();
          ElNotification({
            title: "Voila!",
            message: "Success update employee!",
            type: "success",
          });
          this.getEmployeeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCloseEdit() {
      this.form = {
        id: null,
        id_sso: null,
        employee_no: null,
        id_departement: null,
        nik: null,
        fullname: null,
        province: null,
        city: null,
        address: null,
        dob: null,
        gender: null,
        start_date: null,
        end_date: null,
        delete_date: null,
        image: null,
        email: null,
        username: null,
        phone: null,
        edu: null,
        id_office_location: null,
      };
      this.search_divisi = "";
      this.search_province = "";
      this.search_city = "";
      this.selected_divisi = null;
      this.selected_province = null;
      this.selected_city = null;
      this.edit_dialog = false;
    },

    handleRemoveEmployee(data) {
      let date;
      if (data.delete_date == null) {
        date = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      } else {
        date = null;
      }
      const payload = {
        id_user: data.id_user,
        date: date,
      };
      this.doDeleteEmployee(payload)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: "Success!",
            type: "success",
          });
          this.getEmployeeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleActivationEmployee(data) {
      let stat;
      if (data.is_activate == "1") {
        stat = "0";
      } else {
        stat = "1";
      }
      const payload = {
        id_sso: data.id_sso,
        status: stat,
      };
      this.doActivationEmployee(payload)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: "Success!",
            type: "success",
          });
          this.getEmployeeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    querySearch(query, cb) {
      var divisi_data = this.divisi_data;
      var results = query
        ? divisi_data.filter(this.createFilter(query))
        : divisi_data;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.departement}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilter(queryString) {
      return (divisi) => {
        return (
          divisi.departement.toLowerCase().indexOf(queryString.toLowerCase()) ==
          0
        );
      };
    },

    handleSelect(item) {
      this.selected_divisi = item.detail;
      this.form.id_departement = item.detail.id_departement;
      // this.search_project = "";
      // this.disable_project = true;
      // this.selectedCompany = item.detail;
      // this.form.state1 = this.selectedCompany.id;
      // this.fetchEmployeeProjects();
    },

    querySearchProvince(query, cb) {
      var province_data = this.province_data;
      var results = query
        ? province_data.filter(this.createFilterProvince(query))
        : province_data;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.nama}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilterProvince(queryString) {
      return (province) => {
        return (
          province.nama.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelectProvince(item) {
      this.selected_province = item;
      this.form.province = item.detail.nama;
      this.getCityData(item.detail.id);
    },

    querySearchCity(query, cb) {
      var city_data = this.city_data;
      var results = query
        ? city_data.filter(this.createFilterCity(query))
        : city_data;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.nama}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilterCity(queryString) {
      return (city) => {
        return city.nama.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
      };
    },

    handleSelectCity(item) {
      this.selected_city = item;
      this.form.city = item.detail.nama;
    },
  },

  computed: {
    getEmployee() {
      return this.$store.state.hrd.employee;
    },
    getDivisi() {
      return this.$store.state.hrd.divisi;
    },
    getOffice() {
      return this.$store.state.hrd.office;
    },
    getOfficeFilter() {
      const office = this.$store.state.hrd.office;
      let office_filter = [
        {
          id: "All",
          value: "All",
        },
      ];
      if (office.length > 0) {
        office.forEach((of) => {
          const data = {
            id: of.id_office_location,
            value: of.office_name,
          };
          office_filter.push(data);
        });
      }
      return office_filter;
    },
    getProvince() {
      return this.$store.state.province;
    },
  },
};
</script>
