<template>
  <button
    class="btn btn-sm btn-danger"
    @click="handleSelectedRejectRequest()"
    v-if="getUserData.status_name == 'Account Manager'"
  >
    Reject Request Selected
  </button>
  <button
    class="btn btn-sm btn-primary"
    @click="handleApproveSelected()"
    v-if="getUserData.status_name == 'Manager'"
  >
    Approve
  </button>
  <button
    class="btn btn-sm btn-danger ms-1"
    @click="handleRejectSelected()"
    v-if="getUserData.status_name == 'Manager'"
  >
    Reject
  </button>
  <div class="row">
    <div class="card mb-3" v-if="projectsData.length == 0">
      <div class="card-header px-3">
        <h6 class="mb-0 text-center">No data found</h6>
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <el-scrollbar height="400px">
          <div class="table-responsive">
            <el-table :data="projectsData" style="width: 100%">
              <el-table-column label="Select">
                <template #default="props">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="props.row.number"
                      id="flexCheckDefault"
                      v-model="selected_pipeline"
                      v-if="getUserData.status_name == 'Account Manager'"
                      :disabled="
                        props.row.deal == 1 ||
                        props.row.no_deal == 1 ||
                        props.row.reject_status == 0
                      "
                    />
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="props.row.id_reject_status"
                      id="flexCheckDefaults"
                      v-model="selected_pipeline_manager"
                      v-if="getUserData.status_name == 'Manager'"
                      :disabled="
                        props.row.deal == 1 ||
                        props.row.no_deal == 1 ||
                        props.row.reject_status == 1 ||
                        props.row.reject_status == 2 ||
                        props.row.pipeline == 1 && props.row.reject_status == null
                      "
                    />
                  </div>
                </template>
              </el-table-column>
              <el-table-column type="expand">
                <template #default="props">
                  <div m="1" class="mx-1">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-column">
                        <b>Account Manager</b>
                        <span>{{ props.row.employee_name }}</span>
                        <b>Project Name</b>
                        <span>{{ props.row.job }}</span>
                        <b>Company Name</b>
                        <span>{{ props.row.company_name }}</span>
                        <b>Latest Note</b>
                        <span>{{ props.row.project_notes }}</span>
                        <b>Created at</b>
                        <span>
                          {{ formatDate(props.row.start_date) }}
                        </span>
                        <b>Last update</b>
                        <span>
                          {{ formatDate(props.row.last_updated) }}
                        </span>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="mt-2">
                          <el-badge
                            :value="props.row.call_number"
                            class="item"
                            type="danger"
                          >
                            <el-button>Call</el-button>
                          </el-badge>
                        </div>
                        <div class="mt-2">
                          <el-badge
                            :value="props.row.email_number"
                            class="item"
                            type="danger"
                          >
                            <el-button>Email</el-button>
                          </el-badge>
                        </div>
                        <div class="mt-2">
                          <el-badge
                            :value="props.row.visit_number"
                            class="item"
                            type="danger"
                          >
                            <el-button>Visit</el-button>
                          </el-badge>
                        </div>
                        <div class="mt-2">
                          <el-badge
                            :value="props.row.online_meeting_number"
                            class="item"
                            type="danger"
                          >
                            <el-button>Online Meeting</el-button>
                          </el-badge>
                        </div>
                        <div class="mt-2">
                          <el-badge
                            :value="props.row.document_number"
                            class="item"
                            type="danger"
                          >
                            <el-button>Document</el-button>
                          </el-badge>
                        </div>
                      </div>
                      <div class="d-flex flex-column">
                        <el-dropdown trigger="click" class="mb-3">
                          <el-button type="primary">
                            Target
                            <el-icon class="el-icon--right"
                              ><arrow-down
                            /></el-icon>
                          </el-button>
                          <template #dropdown>
                            <div style="padding: 16px">
                              <div class="row">
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Quantity Target</b>
                                  <span
                                    >{{
                                      numeralFormat(props.row.quantity_target)
                                    }}
                                    unit</span
                                  >
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Revenue Target</b>
                                  <span
                                    >IDR
                                    {{
                                      numeralFormat(props.row.value_target)
                                    }}</span
                                  >
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Profit Target</b>
                                  <span
                                    >IDR
                                    {{
                                      numeralFormat(props.row.profit_target)
                                    }}</span
                                  >
                                </div>
                              </div>
                              <hr class="horizontal dark" />
                              <div class="row">
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Project Created</b>
                                  <span>
                                    {{ formatDate(props.row.start_date) }}
                                  </span>
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Estimated Close</b>
                                  <span>
                                    {{ formatDate(props.row.end_date) }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </el-dropdown>

                        <el-dropdown
                          trigger="click"
                          v-if="
                            (props.row.deal == 1 || props.row.no_deal == 1) &&
                            props.row != null
                          "
                        >
                          <el-button type="success">
                            Close Info
                            <el-icon class="el-icon--right"
                              ><arrow-down
                            /></el-icon>
                          </el-button>
                          <template #dropdown>
                            <div style="padding: 16px">
                              <div class="row">
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>PO ID</b>
                                  <span>{{ props.row.po_id ?? "-" }} </span>
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>SO ID</b>
                                  <span> {{ props.row.so_id ?? "-" }}</span>
                                </div>
                              </div>
                              <hr class="horizontal dark" />
                              <div class="row">
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Revenue</b>
                                  <span
                                    >IDR
                                    {{
                                      numeralFormat(props.row.so_value)
                                    }}</span
                                  >
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Deal Note</b>
                                  <span>{{ props.row.note }}</span>
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Close Time</b>
                                  <span>{{
                                    formatDate(props.row.close_time)
                                  }}</span>
                                </div>
                              </div>
                              <hr class="horizontal dark" />
                              <div class="row">
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Next Project</b>
                                  <span>
                                    {{ props.row.next_project_name ?? "-" }}
                                  </span>
                                </div>
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Next Project Value</b>
                                  <span>
                                    {{
                                      numeralFormat(
                                        props.row.next_project_value
                                      )
                                    }}
                                  </span>
                                </div>
                              </div>
                              <div class="row" v-if="props.row.no_deal == 1">
                                <hr class="horizontal dark" />
                                <div
                                  class="col-lg-6 col-md-12 d-flex flex-column mb-1"
                                >
                                  <b>Project Winner</b>
                                  <span>
                                    {{ props.row.project_winner ?? "-" }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Date">
                <template #default="scope">
                  <div>
                    {{ formatDate(scope.row.start_date) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="company_name" label="Company" />
              <el-table-column label="Project">
                <template #default="scope">
                  <div class="d-flex flex-column align-items-start">
                    <span>
                      {{ scope.row.job }}
                    </span>
                    <span class="text-xs text-primary text-bold">
                      {{ scope.row.number }}
                      <i
                        class="fas fa-copy cursor-pointer"
                        @click="copyToClipBoard(scope.row.number)"
                      ></i>
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="employee_name" label="Sales" />
              <el-table-column label="Status">
                <template #default="scope">
                  <div>
                    <!-- {{ scope.row }} -->
                    <!-- <el-icon><timer /></el-icon> -->
                  </div>
                  <div v-if="scope.row.pipeline == 1">
                    <span class="badge bg-primary">Active</span>
                  </div>

                  <div v-if="scope.row.no_deal == 1 && !scope.row.deal == 1">
                    <span class="badge bg-danger">Rejected</span>
                  </div>
                  <div v-if="scope.row.deal == 1">
                    <span class="badge bg-success">Deal</span>
                  </div>
                  <div
                    v-if="
                      scope.row.reject_status == 0 &&
                      !scope.row.deal == 1 &&
                      !scope.row.no_deal == 1
                    "
                  >
                    <span class="badge bg-warning">Pending Reject</span>
                  </div>
                  <!-- <div
                    v-if="
                      scope.row.reject_status == 0 &&
                      !scope.row.pipeline == 1 &&
                      !scope.row.deal == 1
                    "
                  >
                    <span class="badge bg-warning">Pending</span>
                  </div>

                  <div v-else-if="scope.row.no_deal == 1">
                    <span class="badge bg-danger">Rejected</span>
                  </div>
                  <div v-else-if="scope.row.deal == 1">
                    <span class="badge bg-success">Deal</span>
                  </div>
                  <div
                    v-else-if="
                      scope.row.pipeline == 1 && !scope.row.reject_status == 0
                    "
                  >
                    <span class="badge bg-primary">Active</span>
                  </div> -->
                </template>
              </el-table-column>
              <el-table-column
                label="Action"
                v-if="getUserData.status_name == 'Account Manager'"
              >
                <template #default="scope">
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-icon class="el-icon--right">
                        <arrow-down />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          @click="openUpdateInformationDialog(scope.row)"
                          >Update Information</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="scope.row.deal == 1 || scope.row.no_deal == 1"
                          divided
                          @click="openAddDocumentDialog(scope.row)"
                          >Add Documents</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="scope.row.deal == 1 || scope.row.no_deal == 1"
                          divided
                          @click="openUpdateNoteDialog(scope.row)"
                          >Update Notes</el-dropdown-item
                        >
                        <el-dropdown-item
                          divided
                          @click="openAddDocumentDialog(scope.row)"
                          >Submitted Documents</el-dropdown-item
                        >
                        <el-dropdown-item
                          divided
                          @click="sendEmailDialog(scope.row)"
                          v-if="scope.row.deal == 1"
                        >
                          Send Email
                        </el-dropdown-item>
                        <el-dropdown-item
                          divided
                          type="danger"
                          v-if="scope.row.deal == 1 || scope.row.no_deal == 1"
                          @click="deleteDialog(scope.row)"
                          >Cancel Project</el-dropdown-item
                        >
                        <el-dropdown-item
                          divided
                          @click="openUpdateDealDialog(scope.row)"
                          v-if="scope.row.deal == 1"
                          >Update Deal Information</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </template>
              </el-table-column>
              <el-table-column label="Close">
                <template #default="scope">
                  <div v-if="scope.row.pipeline == 1">
                    <div>
                      <el-button
                        class="badge bg-success cursor-pointer"
                        v-if="getUserData.status_name == 'Account Manager'"
                        :disabled="scope.row.reject_status == 0"
                      >
                        <span
                          class="text-light"
                          @click="handleCloseDeal(scope.row)"
                        >
                          Deal
                        </span>
                      </el-button>
                    </div>
                    <div>
                      <el-button
                        class="badge bg-danger cursor-pointer text-white"
                        :disabled="scope.row.reject_status == 0"
                        @click="openRejectDialog(scope.row)"
                        v-if="getUserData.status_name == 'Account Manager'"
                        >Request <br />
                        Reject</el-button
                      >
                      <el-button
                        class="badge bg-primary cursor-pointer text-white"
                        @click="initPipelineV2(scope.row)"
                        v-if="
                          getUserData.status_name == 'Manager' &&
                          scope.row.reject_status == 0 &&
                          scope.row.pipeline == 1
                        "
                        >Approve</el-button
                      >
                      <el-button
                        class="badge bg-danger cursor-pointer text-white"
                        @click="reject(scope.row)"
                        v-if="
                          getUserData.status_name == 'Manager' &&
                          scope.row.reject_status == 0 &&
                          scope.row.pipeline == 1
                        "
                        >Reject</el-button
                      >
                    </div>
                  </div>
                  <div class="align-items-center" v-else>-</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>

        <div class="d-flex justify-content-center mt-2">
          <slot></slot>
        </div>
      </div>
    </div>
    <UpdateNote
      v-model="$store.state.eraforce.update_pipeline_notes_dialog"
      :pipeline_id="current_pipeline_id"
    />
    <UpdateInformation
      v-model="$store.state.eraforce.update_pipeline_information_dialog"
      :pipeline_id="current_pipeline_id"
    />
    <UpdateDeal
      v-model="$store.state.eraforce.update_pipeline_deal_dialog"
      :current_project_data="selected_pipeline_data"
      :deal_id="deal_id"
    />
    <AddDocument
      v-model="$store.state.eraforce.add_document_dialog"
      :current_project_data="selected_pipeline_data"
    />
    <el-dialog
      v-model="send_email_dialog"
      title="Send Email to Sales Admin"
      width="40%"
      :show-close="false"
    >
      <div class="">
        <div class="mt-3">
          <span class="text-bold"> Project </span>
          <br />
          <div class="bg-info-soft p-3">
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-bold"> Pipeline ID </span>
              <span class="text-end">
                #{{ selected_project_data.number }}
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-bold"> Project Name </span>
              <span class="text-end">
                {{ selected_project_data.job }}
              </span>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-bold"> Company Name </span>
              <span class="text-end">
                {{ selected_project_data.company_name }}
              </span>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="d-flex justify-content-between align-items-center">
            <span class="text-bold"> Product Order </span>
            <argon-button
              color="info"
              size="sm"
              variant="gradient"
              @click="addMoreClose"
            >
              <i class="fa fa-plus"></i> Add More
            </argon-button>
          </div>
          <br />
          <el-scrollbar height="400px">
            <div v-for="(i, index) in count" :key="i">
              <div class="bg-info-soft p-3 mb-3">
                <div class="d-flex justify-content-between">
                  <span class="text-bold"> Product #{{ i }} </span>
                  <span
                    class="cursor-pointer"
                    @click="deleteExtraClose"
                    v-show="count >= 2 && index != 0"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label for="">Type</label>

                    <argon-input
                      placeholder="Type here..."
                      icon="fas fa-tv"
                      iconDir="left"
                      v-model="product_type[index]"
                      :value="product_type[index]"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label for="">Specification</label>

                    <argon-input
                      placeholder="Type here..."
                      icon="fas fa-info"
                      iconDir="left"
                      v-model="product_spec[index]"
                      :value="product_spec[index]"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label for="">Harga Modal</label>

                    <argon-input
                      placeholder="Type here..."
                      icon="fas fa-money-bill"
                      iconDir="left"
                      v-model="price_base[index]"
                      :value="price_base[index]"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label for="">Harga Jual</label>

                    <argon-input
                      placeholder="Type here..."
                      icon="fas fa-tag"
                      iconDir="left"
                      v-model="price_sell[index]"
                      :value="price_sell[index]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelSendEmailDialog">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleSendEmail"
            class="bg-gradient-success"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- No deal -->
    <el-dialog
      v-model="reject_dialogs"
      title="Describe your rejection"
      width="40%"
      :show-close="false"
    >
      <div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Winner's Names</label>
              <argon-input
                type="text"
                placeholder="Input Winner's Name"
                size="lg"
                :value="reject_form.winner_name"
                v-model="reject_form.winner_name"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Project Value</label>
              <argon-input
                type="text"
                placeholder="Input Project Value"
                size="lg"
                :value="value_text"
                v-model="value_text"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Note</label>
              <argon-input
                type="text"
                placeholder="Input Note"
                size="lg"
                :value="reject_form.note"
                v-model="reject_form.note"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <argon-button
            class="mb-3"
            variant="gradient"
            color="success"
            fullWidth
            size="md"
            @click="initPipelineV2"
          >
            <div>Confirm</div>
          </argon-button>
          <argon-button
            class=""
            variant="gradient"
            color="danger"
            fullWidth
            size="md"
            @click="handleCancelRejects"
          >
            <div>Cancel</div>
          </argon-button>
        </span>
      </template>
    </el-dialog>
    <!-- End No Deal -->

    <!-- Select Reject Deal -->
    <el-dialog
      v-model="reject_dialog_select"
      title="Describe your rejection"
      width="40%"
      :show-close="false"
    >
      <div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Winner's Name</label>
              <argon-input
                type="text"
                placeholder="Input Winner's Name"
                size="lg"
                :value="reject_form_selected.winner_name"
                v-model="reject_form_selected.winner_name"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Project Value</label>
              <argon-input
                type="text"
                placeholder="Input Project Value"
                size="lg"
                :value="value_text"
                v-model="value_text"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Note</label>
              <argon-input
                type="text"
                placeholder="Input Note"
                size="lg"
                :value="reject_form_selected.note"
                v-model="reject_form_selected.note"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <argon-button
            class="mb-3"
            variant="gradient"
            color="success"
            fullWidth
            size="md"
            @click="SubmitRejectRequestSelected"
          >
            <div>Confirm</div>
          </argon-button>
          <argon-button
            class=""
            variant="gradient"
            color="danger"
            fullWidth
            size="md"
            @click="handleCancelRejectSelect"
          >
            <div>Cancel</div>
          </argon-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Select Reject Deal -->

    <!-- Reject Deal -->
    <el-dialog
      v-model="reject_dialog"
      title="Describe your rejection"
      width="40%"
      :show-close="false"
    >
      <div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Winner's Name</label>
              <argon-input
                type="text"
                placeholder="Input Winner's Name"
                size="lg"
                :value="reject_form.winner_name"
                v-model="reject_form.winner_name"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Project Value</label>
              <argon-input
                type="text"
                placeholder="Input Project Value"
                size="lg"
                :value="value_text"
                v-model="value_text"
              />
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Note</label>
              <argon-input
                type="text"
                placeholder="Input Note"
                size="lg"
                :value="reject_form.note"
                v-model="reject_form.note"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <argon-button
            class="mb-3"
            variant="gradient"
            color="success"
            fullWidth
            size="md"
            @click="initPipeline"
          >
            <div>Confirm</div>
          </argon-button>
          <argon-button
            class=""
            variant="gradient"
            color="danger"
            fullWidth
            size="md"
            @click="handleCancelReject"
          >
            <div>Cancel</div>
          </argon-button>
        </span>
      </template>
    </el-dialog>

    <!-- Close Deal -->
    <el-dialog
      v-model="close_deal_dialog"
      title="Close Deal"
      width="50%"
      :show-close="false"
    >
      <div>
        <el-scrollbar height="200px">
          <div>
            <div
              class="card"
              style="margin: 10px"
              v-for="(i, index) in count"
              :key="i"
            >
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <span
                    class="cursor-pointer"
                    @click="deleteExtraClose"
                    v-show="count >= 2 && index != 0"
                  >
                    <i class="fa fa-trash text-danger"></i>
                  </span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <label for="">Revenue Value</label>
                    <argon-input
                      placeholder="Type here..."
                      icon="far fa-money-bill-alt"
                      iconDir="left"
                      v-model="revenue_value[index]"
                      :value="revenue_value[index]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <argon-button
            class="mb-1"
            color="success"
            size="md"
            variant="gradient"
            @click="proceedCloseDeal"
            fullWidth
          >
            Proceed
          </argon-button>
          <argon-button
            color="danger"
            size="md"
            variant="gradient"
            @click="cancleCloseDeal"
            fullWidth
          >
            Cancel
          </argon-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script defer>
import Chart from "chart.js/auto";
import { format } from "date-fns";
import { mapMutations, mapActions } from "vuex";
import Swal from "sweetalert2";

import UpdateNote from "@/components/GooSales/components/projects/UpdateNoteDialog.vue";
import UpdateInformation from "@/components/GooSales/components/projects/UpdateInformationDialog.vue";
import UpdateDeal from "@/components/GooSales/components/projects/UpdateDealDialog.vue";
import AddDocument from "@/components/GooSales/components/projects/AddDocumentDialog.vue";

import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { ElMessage } from "element-plus";
import { h } from "vue";

export default {
  components: {
    UpdateNote,
    UpdateInformation,
    UpdateDeal,
    AddDocument,
    ArgonButton,
    ArgonInput,
  },
  data() {
    return {
      update_notes_dialog: false,
      current_project_notes: "",
      current_pipeline_id: "",
      selected_project_data: null,
      selected_pipeline_data: {},
      cancel_note: "",
      deal_id: "",
      send_email_dialog: false,
      selected_sales_admin: null,
      selected_pipeline: [],
      selected_pipeline_manager: [],
      options: [
        {
          id: 1,
          name: "Diana",
          value: "diana@erakomp.com",
        },
        {
          id: 2,
          name: "Ajeng",
          value: "ajeng@erakomp.com",
        },
        {
          id: 3,
          name: "Anas",
          value: "anas@erakomp.com",
        },
      ],
      count: 1,
      product_type: [],
      product_spec: [],
      price_base: [],
      price_sell: [],
      outputUrl: "Type something",
      emailId: "you@example.com",
      email: {
        subject: "Pembuatan SO - ",
        // cc: "somebody@example.com",
        // bcc: "somebody.else@example.com",
      },
      close_deal_dialog: false,
      reject_dialog: false,
      reject_dialog_select: false,
      reject_dialogs: false,
      reject_form: {
        winner_name: "",
        project_value: 0,
        note: "",
      },
      reject_form_selected: {
        winner_name: "",
        project_value: 0,
        note: "",
      },
      po_number: [],
      so_number: [],
      revenue_value: [],
      notes: [],
      value_text: "IDR 0",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      doCancelProject: "eraforce/doCancelProject",
      fetchEmployeeProject: "eraforce/fetchEmployeeProject",
      fetchEmployeeProjectNew: "eraforce/fetchEmployeeProjectNew",
      doInitPipeline: "eraforce/doInitPipeline",
      doInitPipelineNew: "eraforce/doInitPipelineNew",
      doInitRejectPipeline: "eraforce/doInitRejectPipeline",
      doUpdatePotentialReject: "eraforce/doUpdatePotentialReject",
      doFinalizePipelineDeal: "eraforce/doFinalizePipelineDeal",
      rejectRequestSelect: "eraforce/rejectRequestSelect",
      rejectRequestRejectSelect: "eraforce/rejectRequestRejectSelect",
      rejectRequestApproveSelect: "eraforce/rejectRequestApproveSelect",
    }),
    ...mapMutations({
      OPEN_UPDATE_NOTES_DIALOG: "eraforce/OPEN_UPDATE_NOTES_DIALOG",
      OPEN_UPDATE_INFORMATION_DIALOG: "eraforce/OPEN_UPDATE_INFORMATION_DIALOG",
      OPEN_UPDATE_DEAL_DIALOG: "eraforce/OPEN_UPDATE_DEAL_DIALOG",
      OPEN_ADD_DOCUMENT_DIALOG: "eraforce/OPEN_ADD_DOCUMENT_DIALOG",
      SET_SELECTED_PROJECT_DATA: "eraforce/SET_SELECTED_PROJECT_DATA",
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
      SET_EMPLOYEE_PROJECT: "eraforce/SET_EMPLOYEE_PROJECT",
    }),

    handleProsesFeature() {
      Swal.fire({
        icon: "info",
        title: `Fitur sedang dibuatkan...`,
      });
    },

    handleApproveSelected() {
      const payload = {
        request_id: this.selected_pipeline_manager,
      };

      Swal.fire({
        title: "Apakah kamu yakin?",
        text: "Pilih 'Approve' jika ingin approve!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Approve!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.rejectRequestApproveSelect(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Success approved!`,
                showConfirmButton: false,
              });
              this.fetchEmployeeProjects();
              this.selected_pipeline_manager = [];
            })
            .catch((error) => {
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `Error rejected!`,
                showConfirmButton: false,
              });
              this.selected_pipeline_manager = [];
            });
        }
      });
    },

    handleRejectSelected() {
      const payload = {
        request_id: this.selected_pipeline_manager,
      };

      Swal.fire({
        title: "Apakah kamu yakin?",
        text: "Pilih 'Hapus' jika ingin reject!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.rejectRequestRejectSelect(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Success rejected!`,
                showConfirmButton: false,
              });
              this.fetchEmployeeProjects();
              this.selected_pipeline_manager = [];
            })
            .catch((error) => {
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `Error rejected!`,
                showConfirmButton: false,
              });
            });
        }
      });
    },

    SubmitRejectRequestSelected() {
      const payload = {
        pipeline_id: this.selected_pipeline,
        description: this.reject_form_selected.note,
        winner: this.reject_form_selected.winner_name,
        value: this.reject_form_selected.project_value,
      };

      this.rejectRequestSelect(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Success rejected!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.reject_form_selected = {
            winner_name: "-",
            project_value: 0,
            note: "",
          };
          this.reject_dialog_select = false;
          this.fetchEmployeeProjects();
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `Error rejected!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.reject_form_selected = {
            winner_name: "-",
            project_value: 0,
            note: "",
          };
        });

      // console.log(payload);
      // alert(JSON.stringify(payload));
    },

    handleSelectedRejectRequest() {
      this.reject_dialog_select = true;
    },

    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 1) return `checkbox-${rowIndex}`;
    },

    openRejectDialog(data) {
      this.selected_project_data = data;
      this.value_text =
        "IDR " + numeral(this.selected_project_data.value_target).format("0,0");
      this.reject_dialog = true;
    },

    handleOpenReject(data) {
      this.selected_project_data = data;
      this.value_text =
        "IDR " + numeral(this.selected_project_data.value_target).format("0,0");
      this.reject_dialogs = true;
    },

    reject(data) {
      const payload = {
        number: data.number,
      };

      this.doInitRejectPipeline(payload)
        .then((response) => {
          console.log(response);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Success not rejected!`,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.fetchEmployeeProjects();
          });
        })
        .catch((error) => {
          alert(JSON.stringify(error));
          console.log(error);
        });
    },

    initPipelineV2(data) {
      this.selected_project_data = data;
      setTimeout(() => {
        const payload_init = {
          card_customer: this.selected_project_data.number,
          count: 1,
        };

        this.doInitPipelineNew(payload_init)
          .then((response) => {
            const pipeline_id = response[0];
            const val_text = this.value_text.split(" ")[1];
            const val = val_text.split(",").join("");
            this.reject_form.project_value = parseInt(val);
            const payload_reject = {
              potential: 2,
              card_pipeline: pipeline_id,
              note: this.reject_form.note,
              project_value: this.reject_form.project_value,
              project_winner: this.reject_form.winner_name,
            };
            this.doUpdatePotentialReject(payload_reject)
              .then((res) => {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: `Success rejected!`,
                  showConfirmButton: false,
                  timer: 1500,
                }).then((result) => {
                  this.fetchEmployeeProjects();
                  // this.fetchEmployeeProjectNew();
                  this.selected_project_data = {};
                });
              })
              .catch((error) => {
                console.log(`error deal ${JSON.stringify(error)}`);
                Swal.fire({
                  icon: "error",
                  title: `Ooopss!`,
                  text: `Error: ${JSON.stringify(error)}`,
                  showDenyButton: false,
                  showCancelButton: false,
                  confirmButtonText: "OK",
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.selected_project_data = data;
                  }
                });
              });
          })
          .catch((error) => {
            console.log(`Error ${JSON.stringify(error)}`);
          });
      }, 3000);
    },

    initPipeline() {
      this.reject_dialog = false;
      const payload_init = {
        pipeline_id: this.selected_project_data.number,
        count: 1,
        description: this.reject_form.note,
        winner: this.reject_form.winner_name,
        value: this.reject_form.project_value,
      };

      this.doInitPipeline(payload_init)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Success rejected!`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.reject_form = {
            winner_name: "-",
            project_value: 0,
            note: "",
          };
          this.reject_dialog = false;
          this.fetchEmployeeProjects();
          // this.fetchEmployeeProjectNew();
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `Data Not Reject!`,
            timer: 1500,
          });
          console.log(`error ${JSON.stringify(error)}`);
          this.reject_form = {
            winner_name: "-",
            project_value: 0,
            note: "",
          };
        });
    },

    // initPipeline() {
    //   this.reject_dialog = false;
    //   const payload_init = {
    //     card_customer: this.selected_project_data.project.number,
    //     card_customer_id: this.selected_project_data.project.number,
    //     count: 1,
    //     status: 1,
    //     description: "test",
    //     winner: "1",
    //     value: "1",
    //   };

    //   this.doInitPipeline(payload_init)
    //     .then((response) => {
    //       const pipeline_id = response;
    //       console.log(pipeline_id);
    //       const val_text = this.value_text.split(" ")[1];
    //       const val = val_text.split(",").join("");
    //       this.reject_form.project_value = parseInt(val);
    //       const payload_reject = {
    //         potential: 2,
    //         card_pipeline: pipeline_id,
    //         note: this.reject_form.note,
    //         project_value: this.reject_form.project_value,
    //         project_winner: this.reject_form.winner_name,
    //       };
    //       this.doUpdatePotentialReject(payload_reject)
    //         .then((res) => {
    //           Swal.fire({
    //             position: "top-end",
    //             icon: "success",
    //             title: `Success rejected!`,
    //             showConfirmButton: false,
    //             timer: 1500,
    //           }).then((result) => {
    //             this.fetchEmployeeProjects();
    //             this.selected_project_data = {};
    //             this.initRejectForm();
    //           });
    //         })
    //         .catch((error) => {
    //           console.log(`error deal ${JSON.stringify(error)}`);
    //           Swal.fire({
    //             icon: "error",
    //             title: `Ooopss!`,
    //             text: `Error: ${JSON.stringify(error)}`,
    //             showDenyButton: false,
    //             showCancelButton: false,
    //             confirmButtonText: "OK",
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               this.reject_dialog = true;
    //               this.selected_project_data = data;
    //             }
    //           });
    //         });
    //     })
    //     .catch((error) => {
    //       console.log(`error ${JSON.stringify(error)}`);
    //     });
    // },

    handleCancelReject() {
      this.initRejectForm();
      this.reject_dialog = false;
    },

    handleCancelRejectSelect() {
      this.initRejectFormSelect();
      this.reject_dialog_select = false;
    },

    handleCancelRejects() {
      this.initRejectForm();
      this.reject_dialogs = false;
    },

    initRejectFormSelect() {
      this.reject_form_selected.winner_name = null;
      this.reject_form_selected.project_value = 0;
      this.value_text = 0;
      this.reject_form_selected.note = "";
    },

    initRejectForm() {
      this.reject_form.winner_name = null;
      this.reject_form.project_value = 0;
      this.value_text = 0;
      this.reject_form.note = "";
    },

    handleCloseDeal(project) {
      this.selected_project_data = project;
      this.revenue_value[0] =
        "IDR " + numeral(this.selected_project_data.value_target).format("0,0");
      this.close_deal_dialog = true;
    },

    cancleCloseDeal() {
      this.selected_project_data = {};
      this.close_deal_dialog = false;
      this.initDealForm();
    },

    proceedCloseDeal() {
      let revenue_dot = [];
      for (let i = 0; i < this.count; i++) {
        const val = this.revenue_value[i].split(",").join("");
        revenue_dot.push(val);
      }
      let data = revenue_dot[0];

      const close_deal_payload = {
        card_customer: this.selected_project_data.number,
        data: data,
      };

      this.close_deal_dialog = false;
      this.doFinalizePipelineDeal(close_deal_payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Success close deal!`,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.fetchEmployeeProjects();
          }, 2000);
          this.selected_project_data = {};
          this.initDealForm();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: `Ooopss!`,
            text: `Error: ${error}`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.close_deal_dialog = true;
            }
          });
        });
    },

    initRevenueNoteValue() {
      for (let i = 0; i < this.count; i++) {
        if (this.revenue_value[i] == null) {
          this.revenue_value[i] = 0;
        }
        if (this.notes[i] == null) {
          this.notes[i] = "-";
        }
      }
    },

    clickOnSoInput() {
      for (let i = 0; i < this.count; i++) {
        if (this.so_number[i] == null) {
          this.so_number[i] = "S";
        }
      }
    },

    initDealForm() {
      this.po_number = [];
      this.so_number = [];
      this.revenue_value = [];
      this.notes = [];
    },

    formatDate(date) {
      let dates = date;
      dates = format(new Date(dates), "EE, dd MMM yyyy HH:mm:ss");
      return dates;
    },

    addMoreClose() {
      this.count += 1;
    },

    deleteExtraClose() {
      this.count -= 1;
    },

    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);

      ElMessage({
        message: `Success copy Pipeline ID: ${textToCopy}!`,
        type: "success",
      });
    },

    sendEmailDialog(data) {
      this.send_email_dialog = true;
      this.selected_project_data = data;
    },

    cancelSendEmailDialog(data) {
      this.send_email_dialog = false;
      this.selected_project_data = null;
    },

    handleSendEmail() {
      this.updateOutputUrl();
      // const payload;
    },

    handleChangeType() {
      for (let i = 0; i < count; i++) {
        console.log(`type${i} = ${this.product_type[i]}`);
      }
    },

    updateOutputUrl() {
      let base_dot = [];
      for (let i = 0; i < this.count; i++) {
        const val = this.price_base[i].split(",").join("");
        base_dot.push(val);
      }

      let sell_dot = [];
      for (let i = 0; i < this.count; i++) {
        const val = this.price_sell[i].split(",").join("");
        sell_dot.push(val);
      }
      let body = [];
      for (let i = 0; i < this.count; i++) {
        const params = {
          product: `Product #${i + 1}`,
          tipe: `Tipe produk: ${this.product_type[i] ?? "-"}`,
          spec: `Spesifikasi: ${this.product_spec[i] ?? "-"}`,
          modal: `Harga modal: ${base_dot[i] ?? "-"}`,
          jual: `Harga jual: ${sell_dot[i] ?? "-"}`,
          note: `Note: `,
        };
        body.push(params);
      }

      this.email.subject = `${this.selected_project_data.project.number} - `;

      let email_opening =
        "Dear ,\n\n Mohon bantu proses PO terlampir ya dengan detail sebagai berikut:\n\n";

      let body_text = encodeURI(email_opening);

      body.forEach((data) => {
        const bodyKeys = Object.keys(data);
        const bodyRemaining = bodyKeys.filter(
          (key) => data[key].trim().length > 0
        );

        body_text += bodyRemaining
          .map((key) => `${encodeURI(data[key].trim())}`)
          .join("%0A");

        body_text += "%0A%0A";
      });

      this.outputUrl = "mailto:";
      const emailKeys = Object.keys(this.email);
      const remaining = emailKeys.filter(
        (key) => this.email[key].trim().length > 0
      );

      if (remaining.length > 0) {
        this.outputUrl += "?";
      }

      this.outputUrl += remaining
        .map((key) => `${key}=${encodeURI(this.email[key].trim())}`)
        .join("&");

      this.outputUrl += `&body=${body_text}`;

      window.open(this.outputUrl, "_blank");
      this.cancelSendEmailDialog();
    },

    async openUpdateNoteDialog(data) {
      if (data.pipeline != null) {
        const payload = {
          note: data.pipeline.note,
          id: data.pipeline.pipeline_id,
        };
        await this.SET_SELECTED_PROJECT_DATA(payload);
        this.current_pipeline_id = await data.pipeline.pipeline_id;
      } else {
        this.SET_SELECTED_PROJECT_DATA("");
      }
      if (this.current_pipeline_id != "") {
        console.log(`cek`);
        this.OPEN_UPDATE_NOTES_DIALOG();
      }
    },

    async openUpdateInformationDialog(data) {
      if (data != null) {
        await this.SET_SELECTED_PROJECT_DATA(data);
        this.current_pipeline_id = data.id;
        this.OPEN_UPDATE_INFORMATION_DIALOG();
      }
    },

    async openUpdateDealDialog(data) {
      if (data.pipeline != null) {
        this.deal_id = data.pipeline.pipeline_id;
        await this.SET_SELECTED_PROJECT_DATA(data.pipeline);
      } else {
        await this.SET_SELECTED_PROJECT_DATA(null);
      }
      this.OPEN_UPDATE_DEAL_DIALOG();
    },

    async openAddDocumentDialog(data) {
      this.selected_project_data = await data;
      this.OPEN_ADD_DOCUMENT_DIALOG();
    },

    async deleteDialog(data) {
      Swal.fire({
        title: `Are you sure want to cancel ${data.project.job}?`,
        text: `This action can't be undone, please write cancel note!`,
        input: "text",
        inputLabel: "Cancel Notes",
        inputValue: this.cancel_note,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            pipeline_id: data.pipeline.pipeline_id,
            note: result.value,
          };
          this.doCancelProject(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Voila! ${response}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.fetchEmployeeProjects();
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `Oopss!`,
                text: `Error: ${error}`,
                showConfirmButton: true,
              });
            });
        }
      });
    },

    fetchEmployeeProjects() {
      this.SET_ACTIVITY_CARD_LOADING();

      let payload = this.$store.state.eraforce.fetch_project_payload;
      this.fetchEmployeeProjectNew(payload).then((response) => {
        this.SET_EMPLOYEE_PROJECT(response);
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
      console.log("payload ", payload);
    },
  },
  computed: {
    projectsData() {
      return this.$store.state.eraforce.employee_project;
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
  watch: {
    cancel_note(val) {
      console.log(`val ${val}`);
    },

    value_text(val) {
      this.value_text = "IDR " + numeral(val).format("0,0");
    },

    revenue_value: {
      handler: function (val, oldVal) {
        for (let i = 0; i < val.length; i++) {
          this.revenue_value[i] = numeral(val[i]).format("0,0");
        }
      },
      deep: true,
    },
    so_number: {
      handler: function (val, oldVal) {
        for (let i = 0; i < val.length; i++) {
          if (val[i].charAt(0) !== "S") {
            console.log(`char 1 not S`);
            this.so_number[i] = "S" + val[i];
          }

          if (val[i].length >= 2 && val[i].charAt(2) != "/") {
            console.log(`char 2 not /`);
            const year = format(new Date(), "yy");

            this.so_number[i] = this.so_number[i].substring(0, 2) + "/" + year;
          }

          if (val[i].length >= 5 && val[i].charAt(5) != "/") {
            console.log(`char 5 not /`);
            this.so_number[i] = this.so_number[i].substring(0, 5) + "/";
          }

          if (val[i].length > 10) {
            this.so_number[i] = this.so_number[i].substring(0, 10);
          }
        }
      },
      deep: true,
    },
    price_base: {
      handler: function (val, oldVal) {
        for (let i = 0; i < val.length; i++) {
          this.price_base[i] = numeral(val[i]).format("0,0");
        }
      },
      deep: true,
    },
    price_sell: {
      handler: function (val, oldVal) {
        for (let i = 0; i < val.length; i++) {
          this.price_sell[i] = numeral(val[i]).format("0,0");
        }
      },
      deep: true,
    },
  },
};
</script>
