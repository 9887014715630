<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <div class="component-row-flex-space-between">
        <h6>{{ title }}</h6>
        <el-popover
          placement="bottom-end"
          title="Information"
          :width="400"
          trigger="hover"
          :content="detail2"
        >
          <template #reference>
            <el-icon class="cursor-pointer"><InfoFilled /></el-icon>
          </template>
        </el-popover>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="chart">
            <canvas
              id="chart-doughnut"
              class="chart-canvas"
              height="300"
            ></canvas>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="chart">
            <canvas
              id="chart-doughnut2"
              class="chart-canvas"
              height="300"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "gradient-doughnut-chart",
  data() {
    return {
      chart_data: {
        revenue: 300,
        profit: 20,
        closed: 2,
        client: 100,
      },
      showMenu: false,
    };
  },

  props: {
    title: {
      type: String,
      default: "Sales Overview",
    },
    detail1: {
      type: String,
      default: "",
    },
    detail2: {
      type: String,
      default: "",
    },
  },

  computed: {
    dashboardData() {
      return this.$store.state.eraforce.dashboard_data;
    },
  },

  methods: {
    updateOverviewValue() {
      //   this.chart_data.revenue = ;
      //   this.chart_data.profit = ;
      //   this.chart_data.closed = ;
      //   this.chart_data.client = ;
    },
  },

  mounted() {
    var ctx1 = document.getElementById("chart-doughnut").getContext("2d");
    var ctx2 = document.getElementById("chart-doughnut2").getContext("2d");

    new Chart(ctx1, {
      type: "doughnut",
      data: {
        labels: ["Profit", "Target Profit"],
        datasets: [
          {
            label: "Sales Profit Overview",
            data: [
              this.dashboardData.result.profit,
              this.dashboardData.manager_target.profit == 0
                ? 100
                : this.dashboardData.manager_target.profit,
            ],
            backgroundColor: ["rgb(45,206,154)", "rgb(245,66,80)"],
            hoverOffset: 4,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
        },
        borderWidth: 3,
        cutoutPercentage: 10,
      },
    });
    new Chart(ctx2, {
      type: "doughnut",
      data: {
        labels: ["Revenue", "Target Revenue"],
        datasets: [
          {
            label: "Sales Revenue Overview",
            data: [
              this.dashboardData.result.revenue,
              this.dashboardData.manager_target.revenue == 0
                ? 100
                : this.dashboardData.manager_target.revenue,
            ],
            backgroundColor: ["rgb(45,206,154)", "rgb(245,66,80)"],
            hoverOffset: 4,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
        },
        borderWidth: 3,
        cutoutPercentage: 10,
      },
    });
    this.updateOverviewValue();
  },
};
</script>
