import { ElTimeline } from "element-plus";
import { UserFilled, Message, Phone, Monitor } from "@element-plus/icons-vue";
import { format } from "date-fns";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions } from "vuex";
export default {
  name: "call-card",
  components: {
    ElButton,
    ElTimeline,
    ArgonButton
  },
  data() {
    return {
      image: ""
    };
  },
  methods: {
    ...mapActions({}),
    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },
    showClientMap(detail) {
      window.open(`https://maps.google.com/?q=${detail.lat},${detail.long}`, "_blank");
    },
    formatDate(date, type) {
      if (type == "daydatetime") {
        return format(new Date(date), "EEEE, dd MMMM yyyy HH:mm:ss");
      } else if (type == "daydate") {
        return format(new Date(date), "EEEE, dd MMMM yyyy");
      } else if (type == "time") {
        return format(new Date(date), "HH:mm ss");
      }
    }
  },
  computed: {
    activityData() {
      return this.$store.state.eraforce.activity_data;
    },
    activityIcon() {
      const id_activity = this.$store.state.eraforce.activity_id;
      let icon = "";
      if (id_activity == 3) {
        icon = "Phone";
      } else if (id_activity == 4) {
        icon = "UserFilled";
      } else if (id_activity == 5) {
        icon = "Message";
      } else if (id_activity == 9) {
        icon = "Monitor";
      } else {
        icon = "";
      }
      return icon;
    }
  },
  beforeMount() {}
};