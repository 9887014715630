<template>
  <div class="row mt-5">
    <Card :title="'Data Client'">
      <template #header>
        <div class="d-flex flex-column">
          <argon-button
            variant="gradient"
            color="info"
            fullWidth
            size="sm"
            class="mb-2"
            @click="handleAddClient()"
          >
            <div>Add New Client</div>
          </argon-button>
          <argon-button
            class="mb-3"
            variant="gradient"
            color="info"
            size="sm"
            fullWidth
            @click="handleSaveClient()"
            :disabled="$store.state.eraforce.is_customer_loading"
          >
            <div><i class="fa fa-print"></i> Save</div>
          </argon-button>
        </div>
      </template>
      <template #body>
        <div class="mt-2">
          <div class="row">
            <div class="col-12">
              <argon-input
                type="text"
                placeholder="Search by client name..."
                name="search"
                size="lg"
                :value="search"
                v-model="search"
                @change="handleSearchClient()"
              />
            </div>
          </div>
        </div>
        <div v-if="!this.$store.state.eraforce.is_customer_loading">
          <!-- ini cella render the data into the table and fetch it into clientData -->
          <el-table
            :data="clientData"
            style="width: 100%"
            @row-dblclick="handleRowClick"
            lazy
          >
            <!-- ini cella table header, to change header name, replace the label properties-->
            <el-table-column
              prop="full_name"
              label="Sales"
              style="width: 40%"
            />
            <el-table-column prop="name" label="Client" />
            <el-table-column label="Action">
              <template #default="scope">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon>
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        @click="handleClientPIC(scope.row.id, scope.row.name)"
                      >
                        Add PIC
                      </el-dropdown-item>
                      <el-dropdown-item
                        @click="
                          addClientKnowledge(scope.row.id, scope.row.name)
                        "
                      >
                        Add Knowledge
                      </el-dropdown-item>
                      <el-dropdown-item
                        @click="handleClientInformation(scope.row.id)"
                      >
                        Change Information
                      </el-dropdown-item>
                      <el-dropdown-item> Change Location </el-dropdown-item>
                      <el-dropdown-item
                        @click="handleAddClientPicture(scope.row.id)"
                      >
                        Change Picture
                      </el-dropdown-item>
                      <el-dropdown-item> Merge Company </el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
            <!--ini cella table header column setting end-->
          </el-table>
        </div>

        <div class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </template>
    </Card>

    <!-- Detail Modal -->
    <el-dialog
      v-model="dialogVisible"
      title="Detail Client"
      width="50%"
      :show-close="false"
    >
      <div>
        <!-- {{ selectedRecord }} -->
        <div>
          <!-- Company Info Section -->
          <div>
            <div class="flex-row-start">
              <!-- Image Section -->
              <div class="">
                <img
                  :src="selectedRecord.picture"
                  style="max-width: 100px; max-height;: 100px"
                  alt=""
                />
              </div>

              <div style="margin-left: 20px">
                <span class="mb-1">
                  <b>Company Name</b> <br />
                  {{ selectedRecord.name }}
                </span>
                <br />
                <span class="mb-1">
                  <b>Account Manager</b> <br />
                  {{ selectedRecord.full_name }}
                </span>
              </div>
            </div>
            <hr class="horizontal dark" />

            <div
              class="d-flex justify-content-between align-items-center align-content-center mb-1"
            >
              <div style="margin-right: 20px">
                <span class="">
                  <b>Address</b> <br />
                  {{ selectedRecord.address }}
                </span>
              </div>
              <div>
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="info"
                  size="md"
                  @click="showClientMap()"
                >
                  <div>
                    <i class="fas fa-map"></i>
                  </div>
                </argon-button>
              </div>
            </div>
            <!-- <br /> -->
            <span class="mb-1">
              <b>Phone</b> <br />
              {{ selectedRecord.phone }}
            </span>
            <br />
            <span class="mb-1">
              <b>Activated By</b> <br />
              {{ selectedRecord.activated_by ?? "No One" }}
            </span>
          </div>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>

    <AddClient v-model="this.$store.state.eraforce.add_client_dialog" />
    <AddLocation
      v-model="this.$store.state.eraforce.add_client_location_dialog"
    />
    <AddKnowledge
      v-model="this.$store.state.eraforce.add_client_knowledge_dialog"
      :client_name="company_name"
    />
    <AddInformation
      :company_id_information="company_id"
      v-model="$store.state.eraforce.add_client_information_dialog"
    />
    <AddPIC :company_id="company_id" :client_name="company_name" />
    <AddPicture
      :company_id_picture="company_id"
      v-model="$store.state.eraforce.add_client_picture_dialog"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Pagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import AddClient from "@/components/GooSales/components/customer/AddCustomerDialog.vue";
import AddLocation from "@/components/GooSales/components/customer/AddCustomerLocationDialog.vue";
import AddKnowledge from "@/components/GooSales/components/customer/AddCustomerKnowledgeDialog.vue";
import AddInformation from "@/components/GooSales/components/customer/AddCustomerInformationDialog.vue";
import AddPIC from "@/components/GooSales/components/customer/AddCustomerPICDialog.vue";
import AddPicture from "@/components/GooSales/components/customer/AddCustomerPictureDialog.vue";

import Card from "@/components/base/BaseCard.vue";
import Modal from "@/components/base/BaseModal.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      showModal: false,
      selectedRecord: {},
      company_id: 0,
      company_name: "",
      dialogVisible: false,
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    Pagination,
    ArgonInput,
    Modal,
    AddClient,
    AddLocation,
    AddKnowledge,
    AddInformation,
    AddPIC,
    AddPicture,
  },
  mounted() {},
  beforeMount() {
    // this.SET_SELECTED_CLIENT_LOCATION();
    // this.CLOSE_ADD_CLIENT_DIALOG();
    this.fetchAllClient();
    this.fetchClientStatus();
  },
  methods: {
    ...mapActions({
      fetchUserEraforceAuth: "eraforce/fetchUserEraforceAuth",
      doRegister: "auth/doRegister",
      appsPermission: "user/appsPermission",
      fetchListClient: "eraforce/fetchListClient",
      fetchClientKnowledge: "eraforce/fetchClientKnowledge",
      fetchClientInformation: "eraforce/fetchClientInformation",
      fetchClientPIC: "eraforce/fetchClientPIC",
      fetchClientStatus: "eraforce/fetchClientStatus",
      exportClientExcel: "eraforce/exportClientExcel",
    }),
    ...mapMutations({
      OPEN_ADD_CLIENT_DIALOG: "eraforce/OPEN_ADD_CLIENT_DIALOG",
      CLOSE_ADD_CLIENT_DIALOG: "eraforce/CLOSE_ADD_CLIENT_DIALOG",
      SET_SELECTED_CLIENT_LOCATION: "eraforce/SET_SELECTED_CLIENT_LOCATION",
      OPEN_ADD_CLIENT_KNOWLEDGE_DIALOG:
        "eraforce/OPEN_ADD_CLIENT_KNOWLEDGE_DIALOG",
      SET_SELECTED_CLIENT_KNOWLEDGE: "eraforce/SET_SELECTED_CLIENT_KNOWLEDGE",
      OPEN_ADD_CLIENT_INFORMATION_DIALOG:
        "eraforce/OPEN_ADD_CLIENT_INFORMATION_DIALOG",
      OPEN_ADD_CLIENT_PIC_DIALOG: "eraforce/OPEN_ADD_CLIENT_PIC_DIALOG",
      OPEN_ADD_CLIENT_PICTURE_DIALOG: "eraforce/OPEN_ADD_CLIENT_PICTURE_DIALOG",
      SET_CUSTOMER_LOADING: "eraforce/SET_CUSTOMER_LOADING",
      SET_CUSTOMER_NOT_LOADING: "eraforce/SET_CUSTOMER_NOT_LOADING",
      SET_PROJECT_PAYLOAD_DATA: "eraforce/SET_PROJECT_PAYLOAD_DATA",
    }),

    handleSaveClient() {
      Swal.fire({
        title: "Please wait!",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      this.exportClientExcel()
        .then((response) => {
          window.open(response, "_target");
          Swal.fire({
            position: "top-end",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...!",
            text: `Error: ${error}`,
          });
        });
    },

    handleSearchClient() {
      console.log(`this search ${this.search}`);
      this.fetchAllClient();
    },

    async handleRowClick(row) {
      this.selectedRecord = await row;
      console.log(`data ${JSON.stringify(row)}`);
      this.dialogVisible = true;
    },

    fetchAllClient() {
      //   this.SET_SELECTED_EMPLOYEE_DATA("");
      let payload = {
        team_id: "",
        company_name: this.search,
        page: this.page,
      };
      this.SET_CUSTOMER_LOADING();
      this.fetchListClient(payload).then((response) => {
        this.SET_CUSTOMER_NOT_LOADING();
        this.SET_PROJECT_PAYLOAD_DATA(payload);
        this.records = response;
        console.log("ini cella");
      });
    },

    addClientKnowledge(data, name) {
      const payload = {
        client_id: data,
      };
      this.fetchClientKnowledge(payload)
        .then(async (response) => {
          let resp = { ...payload, ...response };
          this.company_name = name;
          await this.SET_SELECTED_CLIENT_KNOWLEDGE(resp);
          this.OPEN_ADD_CLIENT_KNOWLEDGE_DIALOG();
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
          });
        });
    },

    handleClientInformation(data) {
      this.OPEN_ADD_CLIENT_INFORMATION_DIALOG();
      const payload = {
        company_id: data,
      };
      this.fetchClientInformation(payload)
        .then((response) => {
          this.SET_SELECTED_CLIENT_KNOWLEDGE(response);
          this.company_id = data;
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
          });
        });
    },

    handleClientPIC(data, name) {
      const payload = {
        company_id: data,
      };
      this.fetchClientPIC(payload)
        .then((response) => {
          this.company_id = data;
          this.company_name = name;
          this.OPEN_ADD_CLIENT_PIC_DIALOG();
          this.SET_SELECTED_CLIENT_KNOWLEDGE(response);
          console.log(`cek ${JSON.stringify(response)}`);
        })
        .catch((error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: error,
            showConfirmButton: false,
            timer: 5000,
          });
        });
    },

    handleAddClient() {
      this.OPEN_ADD_CLIENT_DIALOG();
    },

    handleAddClientPicture(data) {
      this.company_id = data;
      this.OPEN_ADD_CLIENT_PICTURE_DIALOG();
    },

    closeModal() {
      this.selectedRecord = {};
      this.showModal = false;
    },

    showClientMap() {
      window.open(
        `https://maps.google.com/?q=${this.selectedRecord.latitude},${this.selectedRecord.longitude}`,
        "_blank"
      );
    },
  },
  computed: {
    // ini cella render the client data model
    clientData() {
      return this.$store.state.eraforce.list_client;
    },
  },
  watch: {
    search(val) {
      // this.fetchAllClient();
    },
  },
};
</script>

<style scoped>
.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}
</style>
