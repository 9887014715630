<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold"> Add Pin Location </span>
        <argon-button
          variant="gradient"
          color="info"
          size="sm"
          @click="handleCancle"
        >
          <div>Cancel</div>
        </argon-button>
      </div>
    </div>
    <div class="card p-3">
      <div class="map-holder">
        <GMapAutocomplete
          placeholder="Search location..."
          @place_changed="setPlace"
          style="width: 100%"
          class="mb-3"
        >
        </GMapAutocomplete>
        <div
          class="d-flex justify-content-between align-items-center mb-3"
          v-if="location != null"
        >
          <div class="d-flex flex-column justify-content-start me-5">
            <span>
              Location: <span class="text-bold">{{ location_name }}</span>
            </span>
            <span>
              Address: <span class="text-bold">{{ location_address }}</span>
            </span>
          </div>
          <div>
            <argon-button
              variant="gradient"
              color="success"
              fullWidth
              size="sm"
              @click="confirmPinLocation"
            >
              <div>Confirm Location</div>
            </argon-button>
          </div>
        </div>
        <GMapMap
          :center="markers[0].position"
          :zoom="18"
          map-type-id="terrain"
          style="width: 100%; height: 500px"
        >
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="require('@/assets/location-pin.png').default"
            @place_changed="setPlace"
          />
        </GMapMap>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "GoappsAdminAddClientLocation",

  data() {
    return {
      is_map_loaded: true,
      markers: [
        {
          position: {
            lat: -6.163987599999999,
            lng: 106.8156184,
          },
        },
      ],
      location: null,
      location_name: null,
      location_address: null,
      location_city: null,
      location_lat: null,
      location_long: null,
    };
  },

  components: {
    ArgonButton,
  },

  mounted() {
    this.initData();
  },

  methods: {
    ...mapMutations({
      SET_SELECTED_CLIENT_LOCATION: "eraforce/SET_SELECTED_CLIENT_LOCATION",
      OPEN_ADD_CLIENT_DIALOG: "eraforce/OPEN_ADD_CLIENT_DIALOG",
    }),

    openMap() {
      this.is_map_loaded = false;
      // this.initMap();
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      // console.log(12121, routeArr[2]);
      return routeArr[1];
    },

    openAddClientLocationDialog() {
      const url = "/" + this.getRoute() + "/master-data/customer";
      this.$router.push(url);
    },

    confirmPinLocation() {
      const payload = {
        name: this.location_name,
        address: this.location_address,
        latitude: this.location_lat,
        longitude: this.location_long,
        city: this.location_city,
      };

      this.SET_SELECTED_CLIENT_LOCATION(payload);
      this.OPEN_ADD_CLIENT_DIALOG();
      this.openAddClientLocationDialog();
    },

    setPlace(place) {
      if (place) {
        this.location = place;
        this.location_name = place.name ?? "-";
        this.location_address = place.formatted_address ?? "-";
        this.location_city =
          place.address_components[7] != null
            ? place.address_components[7].long_name
            : "";
        // this.getLatLngFromString(place.geometry.location);
        this.location_lat = place.geometry.location.lat();
        this.location_long = place.geometry.location.lng();
        this.markers.pop();
        this.markers.push({
          position: place.geometry.location,
        });
      }
    },

    handleCancle() {
      if (this.selectedLocation == null) {
        console.log(`i`);
        this.openAddClientLocationDialog();
      } else {
        console.log(`h`);
        this.OPEN_ADD_CLIENT_DIALOG();
        this.openAddClientLocationDialog();
      }
    },

    initData() {
      if (this.selectedLocation != null) {
        this.location = this.selectedLocation;
        this.location_name = this.selectedLocation.name;
        this.location_address = this.selectedLocation.address;
        this.markers[0] = {
          position: {
            lat: this.selectedLocation.latitude,
            lng: this.selectedLocation.longitude,
          },
        };
      } else {
        this.location_name = null;
        this.location_address = null;
      }
    },
  },

  computed: {
    selectedLocation() {
      return this.$store.state.eraforce.selected_client_location;
    },
  },
};
</script>
