<template>
  <div class="py-4 container-fluid">
    <div v-if="getUrl() == '/T0NHY'">
      <Dashboard />
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Dashboard from "@/views/GoHRD/Dashboard.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "goo-administrator",
  data() {
    return {};
  },
  components: {
    Dashboard,
  },
  beforeMount() {
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.remove("virtual-reality");
    this.$store.state.isTransparent = "bg-transparent";
  },
  beforeUnmount() {
    this.$store.state.layout = "vr";
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.add("virtual-reality");

    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.add("g-sidenav-hidden");
      sidenav_show.classList.remove("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
    this.$store.state.isTransparent = "bg-white";
  },
  methods: {
    getUrl() {
      return this.$route.path;
    },
  },
};
</script>
