<template>
  <div>
    <div class="row">
      <Card :title="`Whistle Blower`">
        <template #header>
          <div>
            <argon-button
              variant="gradient"
              color="info"
              fullWidth
              size="sm"
              @click="add_dialog = true"
            >
              <div>
                <el-icon style="margin-right: 10px"><Plus /></el-icon>
                Add New Idea
              </div>
            </argon-button>
          </div>
        </template>
      </Card>
    </div>

    <div class="row mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <WhistleBlower v-else />
    </div>

    <el-dialog
      v-model="add_dialog"
      title="Create an Idea"
      width="40%"
      :show-close="false"
    >
      <div>
        <div class="mb-1">
          <label for="">Title</label>
          <argon-input
            type="text"
            placeholder="Title"
            size="lg"
            :value="form.title"
            v-model="form.title"
          />
        </div>
        <div class="mb-1">
          <label for="">Notes</label>
          <argon-input
            type="area"
            placeholder="Notes"
            size="lg"
            :value="form.notes"
            v-model="form.notes"
          />
        </div>
        <div class="mb-1">
          <input
            type="file"
            ref="file"
            id="forUploadImage"
            style="display: none"
            @change="handleChangeAvatar()"
          />
          <argon-button
            class="mt-3"
            variant="gradient"
            color="default"
            fullWidth
            size="sm"
            @click="clickOpenChangeAvatar"
            v-if="show_take_button"
          >
            <div class="text-primary">
              <i class="fas fa-camera ms-2"></i>
              Upload Picture
            </div>
          </argon-button>
        </div>

        <div class="container mb-1 mt-2 cursor-pointer">
          <img id="ideaImageOutput" class="image-output-style img" />
          <div
            class="overlay text-sm"
            @click="clickOpenChangeAvatar"
            v-if="!show_take_button"
          >
            Reupload Photo
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <argon-button
            class="mb-3"
            variant="gradient"
            color="success"
            fullWidth
            size="sm"
            @click="handleUploadIdea"
          >
            <div>
              <el-icon style="margin-right: 10px"><Plus /></el-icon>
              Save
            </div>
          </argon-button>
          <argon-button
            variant="gradient"
            color="danger"
            fullWidth
            size="sm"
            @click="cancleAdd"
          >
            <div>Cancel</div>
          </argon-button>
        </span>
      </template>
    </el-dialog>

    <!-- <el-dialog
      v-model="add_dialog"
      title="Edit Idea"
      width="40%"
      :show-close="false"
    >
      <div>
        <div class="mb-1">
          <label for="">Title</label>
          <argon-input
            type="text"
            placeholder="Title"
            size="lg"
            :value="form.title"
            v-model="form.title"
          />
        </div>
        <div class="mb-1">
          <label for="">Notes</label>
          <argon-input
            type="area"
            placeholder="Notes"
            size="lg"
            :value="form.notes"
            v-model="form.notes"
          />
        </div>
        <div class="mb-1">
          <input
            type="file"
            ref="file"
            id="forUploadImage"
            style="display: none"
            @change="handleChangeAvatar()"
          />
          <argon-button
            class="mt-3"
            variant="gradient"
            color="default"
            fullWidth
            size="sm"
            @click="clickOpenChangeAvatar"
            v-if="show_take_button"
          >
            <div class="text-primary">
              <i class="fas fa-camera ms-2"></i>
              Upload Picture
            </div>
          </argon-button>
        </div>

        <div class="container mb-1 mt-2 cursor-pointer">
          <img id="ideaImageOutput" class="image-output-style img" />
          <div
            class="overlay text-sm"
            @click="clickOpenChangeAvatar"
            v-if="!show_take_button"
          >
            Reupload Photo
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <argon-button
            variant="gradient"
            color="danger"
            fullWidth
            size="sm"
            @click="add_dialog = false"
          >
            <div>Cancel</div>
          </argon-button>
          <argon-button
            class="mt-3"
            variant="gradient"
            color="success"
            fullWidth
            size="sm"
            @click="handleUploadIdea"
          >
            <div>
              <el-icon style="margin-right: 10px"><Plus /></el-icon>
              Save
            </div>
          </argon-button>
        </span>
      </template>
    </el-dialog> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import WhistleBlower from "@/components/GooSales/components/activity/WhistleBlowerCard.vue";

import { mapActions, mapMutations } from "vuex";

import axios from "axios";

export default {
  name: "TeamKPI",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
      add_dialog: false,
      form: {
        title: null,
        notes: "I just wanna share my idea.",
      },
      show_take_button: true,
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    WhistleBlower,
  },
  mounted() {
    this.getDataWhistle();
  },
  beforeMount() {},
  methods: {
    ...mapActions({
      fetchWallOfIdea: "eraforce/fetchWallOfIdea",
      doUploadIdea: "eraforce/doUploadIdea",
    }),
    ...mapMutations({
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
    }),

    initForm() {
      this.form.title = "";
      this.form.notes = "I just wanna share my idea.";
      this.$refs.file.files = null;
    },

    cancleAdd() {
      this.add_dialog = false;
      this.show_take_button = true;
      this.initForm();
    },

    clickOpenChangeAvatar() {
      this.$refs.file.click();
    },

    handleChangeAvatar(event) {
      this.show_take_button = false;
      var output = document.getElementById("ideaImageOutput");
      output.src = URL.createObjectURL(this.$refs.file.files[0]);
      output.onload = function () {
        URL.revokeObjectURL(output.src); // free memory
      };
    },

    getDataWhistle() {
      const payload = {
        team_id: this.getUserLoginData.team_id,
      };
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchWallOfIdea(payload).then((response) => {
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },

    handleUploadIdea() {
      this.add_dialog = false;
      Swal.fire({
        title: "Please wait!",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const file = this.$refs.file.files[0];

      let formData = new FormData();
      if (file != null) {
        formData.append("file", file);
      }
      formData.append("name", this.getUserLoginData.full_name);
      formData.append("idea", this.form.notes);
      formData.append("title", this.form.title);
      formData.append("app", "goosales");
      this.doUploadIdea(formData)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success create idea!",
            showConfirmButton: false,
            timer: 3000,
          });
          this.getDataWhistle();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops... Failed create idea!",
            text: `Error: ${JSON.stringify(error)}`,
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.add_dialog = true;
              // this.SET_ACTIVITY_MODAL_ACTIVE();
            }
          });
        });
    },
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
  },
  watch: {},
};
</script>

<style scoped>
.image-output-style {
  width: 100%;
  border-radius: 20px;
}
.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  text-align: center;
  padding: 8px 0;
}

.container:hover .overlay {
  opacity: 1;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
</style>
