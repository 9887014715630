<template>
  <div>
    <div class="row">
      <Card :title="`Team's KPI`">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="row mb-3">
              <div class="col-12">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  start-placeholder="start date"
                  end-placeholder="end date"
                  value-format="YYYY-MM-DD"
                  @change="changeDateFilter"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="row mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <TeamKPICard v-else />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import TeamKPICard from "@/components/GooSales/components/TeamKPICard.vue";

import { mapActions, mapMutations } from "vuex";

import axios from "axios";

export default {
  name: "TeamKPI",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    TeamKPICard,
  },
  mounted() {
    this.getDataKPI();
  },
  beforeMount() {},
  methods: {
    ...mapActions({
      fetchEmployeeReportKPI: "eraforce/fetchEmployeeReportKPI",
    }),
    ...mapMutations({
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
    }),

    updateHandler(page) {},

    changeDateFilter(range) {
      if (range) {
        this.startDate = `${range[0]} 00:00:00`;
        this.endDate = `${range[1]} 23:59:59`;
      } else {
        const today = format(new Date(), "yyyy-MM-dd");
        this.startDate = `${today} 00:00:00`;
        this.endDate = `${today} 23:59:59`;
      }
      this.getDataKPI();
    },

    checkloop() {
      console.log(`cek`);
    },

    getDataKPI() {
      const payload = {
        first_date: this.startDate,
        last_date: this.endDate,
        team_id: this.getUserLoginData.team_id,
        date_filter: null,
      };
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchEmployeeReportKPI(payload).then((response) => {
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
  },
  watch: {},
};
</script>
