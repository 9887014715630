<template>
  <div class="row">
    <Card :title="'Employee Data'">
      <template #body>
        <div>
          <div class="row">
            <div class="col-12">
              <argon-input
                type="text"
                placeholder="Search by employee name..."
                name="search"
                size="lg"
                :value="search"
                v-model="search"
              />
            </div>
          </div>
          <div
            class="loading d-flex justify-content-center mt-3"
            v-if="loading_employee"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="table-responsive p-0" v-else>
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                  >
                    Employee
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Status
                  </th>
                  <th
                    class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(record, index) of displayedRecords" :key="index">
                  <td>
                    <div class="d-flex flex-column">
                      <h6>{{ record.full_name }}</h6>
                      <span
                        >{{ getEmployeeStatus(record.status_id) }} -
                        {{ record.team_name }}</span
                      >
                    </div>
                  </td>
                  <td class="text-center">
                    <h6>
                      <span
                        class="badge bg-success"
                        v-if="record.active_status == 1"
                      >
                        Active
                      </span>
                      <span
                        class="badge bg-danger"
                        v-if="record.active_status == 0"
                      >
                        Inactive
                      </span>
                    </h6>
                  </td>
                  <td class="text-center">
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <i class="fas fa-ellipsis-v"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            @click="handleEmployeeAcount(record)"
                          >
                            {{
                              record.active_status == 0
                                ? "Activate Employee"
                                : "Deactivate Employee"
                            }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            @click="handleChangeTeam(record)"
                            v-if="getUserData.status_id == 7"
                          >
                            Change Team
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              v-model="page"
              :pages="getPages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="updateHandler(page)"
            />
          </div>
        </div>
      </template>
    </Card>

    <!-- Change Team Dialog -->
    <el-dialog
      v-model="dialogVisible"
      title="Change Team"
      width="40%"
      :show-close="false"
    >
      <div class="row">
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-bold"> Name </span>
          <span class=""> {{ selected_record.full_name }} </span>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <span class="text-bold"> Current Team </span>
          <span class=""> {{ selected_record.team_id }} </span>
        </div>
      </div>

      <div class="row mt-3">
        <el-select
          v-model="target_team"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="(item, index) in displayListTeam"
            :key="index"
            :label="item.full"
            :value="item.id"
          >
            <span style="float: left">{{ item.name }}</span>
            <span class="d-flex justify-content-end text-secondary">{{
              item.part
            }}</span>
          </el-option>
        </el-select>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleUpdateTeam"
            class="bg-gradient-success"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Pagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      loading_employee: false,
      dialogVisible: false,
      target_team: null,
      selected_record: null,
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    Pagination,
    ArgonInput,
  },
  mounted() {},
  beforeMount() {
    this.fetchAllEmployee();
    this.fetchClientStatus();
  },
  methods: {
    ...mapActions({
      fetchEmployeeV2: "eraforce/fetchEmployeeV2",
      fetchClientStatus: "eraforce/fetchClientStatus",
      doAccountActivation: "eraforce/doAccountActivation",
      fetchListTeam: "eraforce/fetchListTeam",
      doChangeTeam: "eraforce/doChangeTeam",
    }),
    ...mapMutations({}),
    fetchAllEmployee() {
      this.loading_employee = true;
      //   this.SET_SELECTED_EMPLOYEE_DATA("");
      let payload = {
        filter: "",
        name: this.search,
      };
      this.fetchEmployeeV2(payload).then((response) => {
        this.records = response;
        this.loading_employee = false;
      });
    },

    getEmployeeStatus(id) {
      const filter = this.getAllStatusData.filter((status) => status.id == id);
      if (filter.length > 0) {
        return filter[0].name;
      } else {
        return "-";
      }
    },

    updateHandler(page) {
      console.log(`hai ${JSON.stringify(page)}`);
    },

    handleEmployeeAcount(data) {
      const payload = {
        id: data.id,
      };
      this.doAccountActivation(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success!",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            this.fetchAllEmployee();
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...!",
            text: `Error: ${JSON.stringify(error)}`,
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
            }
          });
        });
    },

    handleChangeTeam(record) {
      this.selected_record = record;
      this.fetchListTeam().then((response) => {
        this.dialogVisible = true;
      });
    },

    initUpdateTeam() {
      this.target_team = null;
      this.selected_record = null;
    },

    handleUpdateTeam() {
      this.dialogVisible = false;
      if (this.target_team == null) {
        return Swal.fire({
          icon: "error",
          title: `Oopss!`,
          text: `Target team is required!`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.dialogVisible = true;
          }
        });
      }
      const payload = {
        user_id: this.getUserData.employee_id,
        team_id: this.target_team,
      };
      this.doChangeTeam(payload)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Voila! ${response}`,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            this.fetchAllEmployee();
            this.initUpdateTeam();
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `${error}`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.dialogVisible = true;
            }
          });
        });
    },
  },
  computed: {
    userData() {
      return this.$store.state.eraforce.user_eraforce;
    },
    getRecordsLength() {
      return (this.recordsLength = this.userData.length);
    },
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.userData.slice(startIndex, endIndex);
    },
    getPages() {
      let total_record = this.getRecordsLength;
      let num_of_page = Math.ceil(total_record / this.perPage);
      return num_of_page;
    },

    getAllStatusData() {
      return this.$store.state.eraforce.status_data;
    },

    getUserData() {
      return this.$store.state.eraforce.user_data;
    },

    getListTeam() {
      return this.$store.state.eraforce.list_team;
    },

    displayListTeam() {
      let result = [];
      this.getListTeam.forEach((team) => {
        const name = team.name.split(" (")[1];
        const part = team.name.split(" (")[0];
        result.push({
          id: team.id,
          part: part == team.name ? "-" : part,
          name: name ? name.split(")")[0] : team.name,
          full: team.name,
        });
      });
      return result;
    },
  },
  watch: {
    search(val) {
      this.fetchAllEmployee();
    },
  },
};
</script>
