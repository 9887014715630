import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import axios from 'axios';
export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      notification: [],
      detailMember: null,
      detail_card: false,
      detail_tasks: false,
      showTasks: {},
      params: {
        id_kanban: "",
        board_id: "",
        search: "",
        order: "",
        filter: "",
        start_date: "",
        end_date: "",
        date_range: [null, null]
      },
      editTasks: false,
      move: {
        id_kanban: "",
        id_card: ""
      },
      formEditTasks: {
        title: null
      },
      formMember: {
        id_user: ""
      },
      edit_description: false,
      file: "",
      attachment: false,
      comment: "",
      showDescription: "",
      form_comment: {
        card_id: "",
        description: ""
      },
      move_ticket: false,
      detailKanban: [],
      move: {
        id_kanban: "",
        id_card: ""
      },
      id_board: null,
      card_member: false,
      formTasksDuplicate: {
        kanban_id: null,
        title: null,
        type: null,
        description: null,
        start_date: new Date(),
        due_date: null
      },
      boardDuplicate: [],
      listCopy: [],
      copy_ticket: false
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  mounted() {
    this.fetchNotificationData();
    this.fetchBoardData();
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator", "SET_APP_PAGE_NAME", "toggleSidebarColor"]),
    ...mapActions(["toggleSidebarColor"]),
    ...mapActions({
      fetchNotification: "gopro/fetchNotification",
      fetchCard: "gopro/fetchCard",
      doUpdateCard: "gopro/doUpdateCard",
      doCreateComment: "gopro/doCreateComment",
      doDeleteComment: "gopro/doDeleteComment",
      fetchCollabolator: "gopro/fetchCollabolator",
      fetchBoardCollabolator: "gopro/fetchBoardCollabolator",
      fetchDetailKanban: "gopro/fetchDetailKanban",
      doChangeKanban: "gopro/doChangeKanban",
      fetchBoard: "gopro/fetchBoard",
      fetchKanban: "gopro/fetchKanban",
      doCreateCard: "gopro/doCreateCard"
    }),
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    fetchNotificationData() {
      axios.get("https://goopro.erakomp.co.id/api/notifications", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      }).then(results => {
        this.notification = results.data.data;
        console.log('SUCCESS');
      }).catch(error => {
        console.log('ERROR');
        console.log(error);
      });
    },
    addKanban(event) {
      this.formTasksDuplicate.kanban_id = event.target.value;
    },
    submitDuplicate() {
      console.log(this.boardDuplicate);
      const params = {
        kanban_id: this.formTasksDuplicate.kanban_id,
        title: this.formTasksDuplicate.title,
        description: this.formTasksDuplicate.description,
        start_date: this.formTasksDuplicate.start_date,
        due_date: this.formTasksDuplicate.due_date
      };
      this.doCreateCard(params).then(response => {
        this.handleCloseAddTaskDuplicate();
        console.log(response);
        ElNotification({
          title: "Viola!",
          message: "Success duplicate task",
          type: "success"
        });
        this.fetchKanbanData();
        setTimeout(() => {
          this.copy_ticket = false;
          this.detail_tasks = false;
        }, 2000);
      }).catch(err => {
        ElNotification({
          title: "Oops!",
          message: `Error: ${err.message}`,
          type: "error",
          duration: 5000
        });
      });
    },
    handleCloseAddTaskDuplicate() {
      this.formTasksDuplicate.title = null;
      this.formTasksDuplicate.description = null;
      this.formTasksDuplicate.start_date = null;
      this.formTasksDuplicate.due_date = null;
    },
    async getList(event) {
      const id_board = event.target.value;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: id_board,
        search: this.params.search,
        order: this.params.order
      };
      await this.fetchKanban(payload).then(response => {
        this.listCopy = response;
      }).catch(err => {
        console.log(`Error -->> ${JSON.stringify(err)}`);
      });
    },
    handleProcess() {
      ElNotification({
        title: "Viola!",
        message: "Fitur masih dalam process",
        type: "info",
        timer: 4000
      });
    },
    fetchBoardData() {
      const payload = {
        id_board: this.$route.params.id,
        search: "",
        order: ""
      };
      this.fetchBoard(payload).then(response => {
        this.boards = response.data[0];
        console.log(this.boards);
      }).catch(error => {
        console.log(error);
      });
    },
    fetchBoardDuplicate() {
      const payload = {
        id_board: "",
        search: "",
        order: ""
      };
      this.fetchBoard(payload).then(response => {
        this.boardDuplicate = response.data;
        console.log(this.boards);
      }).catch(error => {
        console.log(error);
      });
    },
    handleCopy() {
      this.copy_ticket = true;
      this.formTasksDuplicate.title = this.showTasks[0].title;
      this.formTasksDuplicate.description = this.showTasks[0].description;
      this.fetchBoardDuplicate();
    },
    async fetchKanbanData() {
      // this.is_loading = true;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.$route.params.id,
        search: this.params.search,
        order: this.params.order,
        filter: this.params.filter,
        start_date: this.params.start_date,
        end_date: this.params.end_date
      };
      await this.fetchKanban(payload).then(response => {
        this.kanban = response;
        // this.is_loading = false;
      }).catch(err => {
        this.is_loading = false;
        console.log(`Error -->> ${JSON.stringify(err)}`);
      });
    },
    submitMove() {
      const payload = {
        kanban_id: this.move.id_kanban,
        id_card: this.move.id_card
      };
      this.doChangeKanban(payload).then(response => {
        console.log(response);
        ElNotification({
          title: "Viola!",
          message: "Move Success",
          type: "success"
        });
        this.fetchKanbanData();
        setTimeout(() => {
          this.move_ticket = false;
          this.detail_tasks = false;
        }, 2000);
      }).catch(err => {
        console.log(err);
        ElNotification({
          title: "Viola!",
          message: "Move Failed",
          type: "error"
        });
      });
    },
    addIdList(event) {
      const id_kanban = event.target.value;
      const id_card = this.showTasks[0].id_card;
      this.move.id_kanban = id_kanban;
      this.move.id_card = id_card;
    },
    handleMove() {
      this.move_ticket = true;
      this.fetchDetailKanbanData();
    },
    fetchDetailKanbanData() {
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.id_board,
        search: this.params.search,
        order: this.params.order
      };
      console.log(payload);
      this.fetchDetailKanban(payload).then(response => {
        this.detailKanban = response;
        console.log('DETAIL KANBAN');
        console.log(this.detailKanban);
      }).catch(error => {
        console.log(error);
      });
    },
    showDates() {
      this.dates_model = true;
    },
    changeEditTasks(arg) {
      this.formEditTasks.title = arg;
      if (this.editTasks) {
        this.editTasks = false;
      } else {
        this.editTasks = true;
      }
    },
    handleEditDescription(arg) {
      this.edit_description = true;
      this.showDescription = arg;
    },
    handleRemoveEditDescription() {
      this.edit_description = false;
    },
    submitEditDescription(id_card) {
      let id = id_card.id_card;
      const payload = {
        id_card: id,
        description: this.showDescription
      };
      this.doUpdateCard(payload).then(response => {
        ElNotification({
          title: "Viola!",
          message: "Success",
          type: "success"
        });
        this.edit_description = false;
        this.showDescription = '';
        this.handleShowTasks(this.showTasks[0].id_card);
      }).catch(err => {
        ElNotification({
          title: "Viola!",
          message: "Error",
          type: "error"
        });
      });
    },
    showAttachment() {
      this.attachment = true;
      this.file = null;
    },
    onChangeFileUpload(event) {
      let test = event.target.files[0];
      this.file = test;
    },
    handleSubmitAttachment() {
      let id_card = this.showTasks[0].id_card;
      let file = this.file;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("card_id", id_card);
      axios.post("https://goopro.erakomp.co.id/api/attachment", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(results => {
        ElNotification({
          title: "Viola!",
          message: "Success",
          type: "success"
        });
        this.file = "";
        this.attachment = false;
        this.handleShowTasks(this.showTasks[0].id_card);
      }).catch(err => {
        console.log(err);
      });
    },
    handleAddComment() {
      this.form_comment.card_id = this.showTasks[0].id_card;
      this.form_comment.description = this.comment;
      let payload = {
        card_id: this.form_comment.card_id,
        description: this.form_comment.description,
        user_id: this.getUserLoginData.user.id_user
      };
      this.doCreateComment(payload).then(response => {
        ElNotification({
          title: "Viola!",
          message: "Success",
          type: "success"
        });
        this.form_comment.description = "";
        this.comment = "";
        this.handleShowTasks(this.form_comment.card_id);
      }).catch(err => {
        ElNotification({
          title: "Error!",
          message: "Error",
          type: "error"
        });
      });
    },
    showCardMembers(params) {
      let paramsId = this.id_board;
      const payload = {
        id_board_collabolator: null,
        board_id: paramsId,
        search: this.params.search,
        order: this.params.order
      };
      this.fetchBoardCollabolator(payload).then(response => {
        ElNotification({
          title: "Viola!",
          message: "Success",
          type: "success"
        });
        this.boardCollab = response;
        this.card_member = true;
      }).catch(err => {
        alert(JSON.stringify(err.message));
      });
    },
    fetchCollabolatorData() {
      let paramsId = this.id_board;
      const payload = {
        id_board_collabolator: null,
        board_id: paramsId,
        search: this.params.search,
        order: this.params.order
      };
      this.fetchBoardCollabolator(payload).then(response => {
        console.log('hello world fetch board');
        this.detailCollabolator = response;
        console.log(this.detailCollabolator);
        this.list_app_menu = response;
        console.log(this.list_app_menu);
        console.log('FETCH');
      }).catch(error => {
        console.log(error);
      });
    },
    goToHome() {
      this.SET_APP_PAGE_NAME("");
      this.$router.push({
        name: "Permisson"
      });
      const routeArr = this.$route.path.split("/");
      if (routeArr[1] == "2") {
        this.$store.dispatch("eraforce/removeEraforceData");
      }
    },
    timeAgo(date) {
      date = new Date(date);
      const seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + ' years ago';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months ago';
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' days ago';
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + ' hours ago';
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' minutes ago';
      }
      if (seconds < 10) return 'just now';
      return Math.floor(seconds) + ' seconds ago';
    },
    getDate(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();

      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }
      let result = day + "/" + monthNames[month] + "/" + year;
      return result;
    },
    getDateDue(date) {
      const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth();
      var year = dates.getFullYear();
      const minute = 1000 * 60;
      const hour = minute * 60;
      const days = hour * 24;
      const years = days * 365;
      let time = dates.getHours() + ":" + dates.getMinutes();
      let hasil = Math.round(dates.getTime() / year);

      // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
      let now = new Date();
      let exam = "";
      if (dates > now) {
        exam = "on going";
      } else {
        exam = "over due";
      }
      let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
      return result;
    },
    getColorDueDate(date, status) {
      let exam = "";
      if (date == null || date == '') {
        exam = "btn-danger";
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if (dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if (status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else {
        exam = "bg-gradient-danger";
        return exam;
      }
    },
    getImageComment(img) {
      // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },
    getColor(date, status) {
      // let dates = new Date(date);
      // let day = dates.getDay();
      // let month = dates.getMonth();
      // let now = new Date();
      // let exam = "";
      // if(status == 1) {
      //   exam = "bg-gradient-success";
      //   return exam;
      // } else {
      //   exam = "bg-gradient-info"
      //   return exam;
      // }

      let exam = "";
      if (date == null || date == '') {
        exam = "btn-danger";
        return exam;
      }
      let dates = new Date(date);
      let day = dates.getDay();
      let month = dates.getMonth();
      let now = new Date();
      if (dates > now && status == 0) {
        exam = "bg-gradient-info";
        return exam;
      } else if (dates == now && status == 0) {
        exam = "bg-gradient-warning";
        return exam;
      } else if (dates == now) {
        exam = "bg-gradient-warning";
        return exam;
      } else if (status == 1) {
        exam = "bg-gradient-success";
        return exam;
      } else if (dates > now) {
        exam = "bg-gradient-info";
        return exam;
      } else {
        exam = "bg-gradient-danger";
        return exam;
      }
    },
    handleShowTasks(data, board_id) {
      let payload = {
        id_card: data,
        search: null,
        order: null
      };
      this.id_board = board_id;
      const params = [`id_card=${data}`, `search=`, `order=`].join("&");
      axios.get(`https://goopro.erakomp.co.id/api/card?${params}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      }).then(response => {
        ElNotification({
          title: "Viola!",
          message: "Success",
          type: "success"
        });
        this.showTasks = response.data.data;
        this.detail_tasks = true;
      }).catch(err => {
        ElNotification({
          title: "Error!",
          message: "Tasks not found",
          type: "error"
        });
      });

      // this.fetchCard(payload)
    },

    getAttachment(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }
      return gambar;
    },
    getUrl(img) {
      let gambar = "";
      if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC") {
        gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
      } else {
        gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
      }
      return gambar;
    },
    // End

    goToProfile() {
      return;
      this.SET_APP_PAGE_NAME("");
      this.$router.push({
        name: "ProfileNonSales"
      });
    },
    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    }
  },
  components: {
    Breadcrumbs
  },
  computed: {
    currentRouteName() {
      return this.$store.state.dashboard_app_title;
    },
    currentSubRouteName() {
      var _this$$store$state$da;
      return (_this$$store$state$da = this.$store.state.dashboard_app_sub_title) !== null && _this$$store$state$da !== void 0 ? _this$$store$state$da : "none";
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    }
  }
};