<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold">Office Location</span>
        <div>
          <argon-button
            variant="gradient"
            color="info"
            size="md"
            @click="handleCreateOffice()"
          >
            Add Office
          </argon-button>
        </div>
      </div>
    </div>
    <div class="card p-3">
      <!-- cella for dev  -->
      <!-- <el-table
        :data="getOffice"
        style="width: 100%"
        height="500"
        v-loading="is_table_loading"
      > -->
      <el-table :data="getOffice" style="width: 100%" height="500">
        <el-table-column fixed prop="office_name" label="Office" />
        <el-table-column fixed prop="address" label="Address" />
        <el-table-column prop="zip" label="Action" width="150">
          <template #default="scope">
            <div class="d-flex justify-content-center">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="fas fa-caret-down"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="handleOpenEdit(scope.row)">
                      Edit
                    </el-dropdown-item>
                    <el-dropdown-item @click="handleOpenRemove(scope.row)">
                      Deactivate
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- Edit Office -->
    <el-dialog
      v-model="edit_dialog"
      title="Edit Office"
      width="30%"
      :before-close="handleCloseEdit"
    >
      <span>
        <el-input
          v-model="form.office"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input office name"
          :suffix-icon="EditPen"
        />
        <el-input
          v-model="form.address"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input address name"
          :suffix-icon="EditPen"
        />
        <el-input
          v-model="form.latitude"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input latitude name"
          :suffix-icon="EditPen"
        />
        <el-input
          v-model="form.longitude"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input longitude name"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="handleUpdateOffice"
            class="bg-gradient-success"
            :disabled="form.office == null || form.office == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Delete Office -->
    <el-dialog
      v-model="remove_dialog"
      title=""
      width="30%"
      :before-close="handleCloseRemove"
    >
      <span>
        Are you sure want to delete office: <b>{{ form.office }}</b> ?
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="handleDeleteOffice"
            class="bg-gradient-success"
            :disabled="form.office == null || form.office == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions } from "vuex";

export default {
  name: "GoappsAdminOffice",
  components: {
    ArgonButton,
  },

  data() {
    return {
      params: {
        id: "",
        search: "",
        order: "asc",
      },
      form: {
        id: null,
        office: null,
        address: null,
        latitude: null,
        longitude: null,
      },
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
    };
  },

  beforeMount() {
    this.fetchOfficeData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchOffice: "hrd/fetchOffice",
      doCreateOffice: "hrd/doCreateOffice",
      doUpdateOffice: "hrd/doUpdateOffice",
      doDeleteOffice: "hrd/doDeleteOffice",
    }),

    fetchOfficeData() {
      this.is_table_loading = true;
      const payload = {
        id: this.params.id,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchOffice(payload).then((response) => {
        this.is_table_loading = false;
      });
    },

    handleCreateOffice() {
      const url = "/" + this.getRoute + "/master-data/office-location/add";
      // console.log(`url ${JSON.stringify(url)}`);
      // return;
      this.$router.push(url);
    },

    handleUpdateOffice() {
      const params = {
        office: this.form.office,
        id: this.form.id,
        address: this.form.address,
        latitude: this.form.latitude,
        longitude: this.form.longitude,
        office: this.form.office,
      };
      this.doUpdateOffice(params)
        .then((response) => {
          this.handleCloseEdit();
          ElNotification({
            title: "Voila!",
            message: "Success update office!",
            type: "success",
          });
          this.fetchOfficeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleDeleteOffice() {
      const params = {
        id: this.form.id,
      };
      this.doDeleteOffice(params)
        .then((response) => {
          this.handleCloseRemove();
          ElNotification({
            title: "Voila!",
            message: "Success deactivate office!",
            type: "success",
          });
          this.fetchOfficeData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCloseRemove() {
      this.form.id = null;
      this.form.office = null;
      this.remove_dialog = false;
    },

    handleCloseAdd() {
      this.form.id = null;
      this.form.office = null;
      this.add_dialog = false;
    },

    handleCloseEdit() {
      this.form.id = null;
      this.form.office = null;
      this.form.address = null;
      this.form.latitude = null;
      this.form.longitude = null;
      this.edit_dialog = false;
    },

    handleOpenAdd() {
      this.add_dialog = true;
    },

    handleOpenEdit(data) {
      this.form = {
        id: data.id_office_location,
        office: data.office_name,
        address: data.address,
        latitude: data.latitude,
        longitude: data.longitude,
      };
      this.edit_dialog = true;
    },

    handleOpenRemove(data) {
      this.form = {
        id: data.id_office_location,
        office: data.office_name,
        address: data.address,
        latitude: data.latitude,
        longitude: data.longitude,
      };
      this.remove_dialog = true;
    },
  },

  computed: {
    getOffice() {
      return this.$store.state.hrd.office;
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
