<template>
  <div class="row">
    <div class="col-12">
      <el-radio-group
        v-model="form.interval"
        size="large"
        style="width: 100%"
        class="d-flex justify-content-center"
        @change="handleChangeFilter"
      >
        <el-radio-button label="1 Month" />
        <el-radio-button label="3 Month" />
        <el-radio-button label="6 Month" />
      </el-radio-group>
    </div>
    <div class="col-12 mt-3 mb-3">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <el-date-picker
            v-model="form.month"
            type="month"
            placeholder="Pick a month"
            style="width: 100%"
            value-format="MM"
            @change="handleChangeFilter"
          />
        </div>
        <div class="col-lg-6 col-md-12">
          <el-date-picker
            v-model="form.year"
            type="year"
            placeholder="Pick a year"
            style="width: 100%"
            value-format="YYYY"
            @change="handleChangeFilter"
          />
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <!-- Highlight Section -->
      <div class="mb-3">
        <span class="h4 text-bold text-light text-shadow mb-2">Highlight</span>
        <div v-if="!hightlight_loading" class="mt-3">
          <div class="row" v-if="highLightData.length > 0">
            <el-scrollbar height="180px">
              <div class="scrollbar-flex-content">
                <div v-for="(data, index) in displayHighlightData" :key="index">
                  <card
                    class="scrollbar-demo-item"
                    :title="data.title"
                    :value="data.value"
                    :percentage="data.percentage"
                    :status="data.status"
                    :detail="data.detail"
                    directionReverse
                    @click="goToProjects"
                  ></card>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <span class="text-bold">There's no data!</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <div class="text-center p-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- All Team Revenue -->
      <div class="mb-3">
        <span class="h4 text-bold text-dark text-shadow mb-2"
          >All Team Revenue</span
        >
        <div v-if="!all_team_revenue_loading" class="mt-3">
          <div class="row" v-if="teamRevenueData.length > 0">
            <el-scrollbar height="300px">
              <div class="scrollbar-flex-content">
                <div
                  v-for="(data, index) in displayTeamRevenueData"
                  :key="index"
                >
                  <AllTeamRevenueCard
                    class="scrollbar-demo-item"
                    :title="data.title"
                    :value="data.value"
                    :image="data.image"
                    directionReverse
                  ></AllTeamRevenueCard>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <span class="text-bold">There's no data!</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <div class="text-center p-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings -->
      <div class="mb-3">
        <span class="h4 text-bold text-dark text-shadow mb-2"> Earnings </span>
        <!-- Revenue -->
        <div>
          <div v-if="!earnings_revenue_loading" class="mt-3">
            <div class="row" v-if="earningsRevenueDataLength > 0">
              <div>
                <Earnings title="Revenue" />
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <div class="text-center p-3">
                  <span class="text-bold">There's no data!</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Profit -->
        <div>
          <div v-if="!earnings_profit_loading" class="mt-3">
            <div class="row" v-if="earningsProfitDataLength > 0">
              <div>
                <profit-chart title="Profit" />
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <div class="text-center p-3">
                  <span class="text-bold">There's no data!</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Rejected -->
        <div>
          <div v-if="!earnings_rejected_loading" class="mt-3">
            <div class="row" v-if="earningsRejectedDataLength > 0">
              <div>
                <rejected-chart title="Rejected Revenue" />
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <div class="text-center p-3">
                  <span class="text-bold">There's no data!</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Activity -->
      <div class="mb-3">
        <span class="h4 text-bold text-dark text-shadow mb-2"> Activity </span>
        <div v-if="!activity_loading" class="mt-3">
          <div class="row" v-if="activityDataLength > 0">
            <div class="card">
              <el-scrollbar height="400px">
                <div class="card-body">
                  <div class="scrollbar-flex-content">
                    <call-chart
                      class="scrollbar-demo-item"
                      title="Call"
                    ></call-chart>
                    <email-chart
                      class="scrollbar-demo-item"
                      title="Email"
                    ></email-chart>
                    <visit-chart
                      class="scrollbar-demo-item"
                      title="Visit"
                    ></visit-chart>
                    <online-meeting-chart
                      class="scrollbar-demo-item"
                      title="Online Meeting"
                    ></online-meeting-chart>
                    <document-chart
                      class="scrollbar-demo-item"
                      title="Document"
                    ></document-chart>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <span class="text-bold">There's no data!</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <div class="text-center p-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Arrival -->
      <div class="mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <span class="h4 text-bold text-dark text-shadow mb-2"> Arrival </span>
          <span
            class="h4 text-bold text-dark text-shadow mb-2 cursor-pointer"
            @click="goToArrivalDetail"
          >
            Details <i class="fas fa-angle-right"></i>
          </span>
        </div>
        <div>
          <div v-if="!arrival_loading" class="mt-3">
            <div class="row" v-if="arrivalData != null">
              <div>
                <arrival-chart title="Total Arrival" />
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-12">
                <div class="text-center p-3">
                  <span class="text-bold">There's no data!</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="text-center p-3">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "@/views/components/Carousel.vue";
import CategoriesCard from "@/views/components/CategoriesCard.vue";

import Card from "@/components/GooSales/components/statistic/HighlightCard.vue";
import AllTeamRevenueCard from "@/components/GooSales/components/statistic/AllTeamRevenueCard.vue";
import Earnings from "@/components/GooSales/components/statistic/Earnings.vue";
import ProfitChart from "@/components/GooSales/components/statistic/ProfitChart.vue";
import RejectedChart from "@/components/GooSales/components/statistic/RejectedChart.vue";
import CallChart from "@/components/GooSales/components/statistic/activity/CallChart.vue";
import EmailChart from "@/components/GooSales/components/statistic/activity/EmailChart.vue";
import VisitChart from "@/components/GooSales/components/statistic/activity/VisitChart.vue";
import OnlineMeetingChart from "@/components/GooSales/components/statistic/activity/OnlineMeetingChart.vue";
import DocumentChart from "@/components/GooSales/components/statistic/activity/DocumentChart.vue";
import ArrivalChart from "@/components/GooSales/components/statistic/ArrivalChart.vue";

import { mapActions, mapMutations } from "vuex";
import { format } from "date-fns";

export default {
  name: "go-force",
  data() {
    return {
      form: {
        interval: "1 Month",
        month: format(new Date(), "MM"),
        year: format(new Date(), "yyyy"),
      },
      hightlight_loading: false,
      all_team_revenue_loading: false,
      earnings_revenue_loading: false,
      earnings_profit_loading: false,
      earnings_rejected_loading: false,
      activity_loading: false,
      arrival_loading: false,
    };
  },
  components: {
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    Earnings,
    AllTeamRevenueCard,
    ProfitChart,
    RejectedChart,
    CallChart,
    EmailChart,
    VisitChart,
    OnlineMeetingChart,
    DocumentChart,
    ArrivalChart,
  },

  methods: {
    ...mapActions({
      fetchStatisticHighlight: "eraforce/fetchStatisticHighlight",
      fetchStatisticAllTeamRevenue: "eraforce/fetchStatisticAllTeamRevenue",
      fetchStatisticEarningsRevenue: "eraforce/fetchStatisticEarningsRevenue",
      fetchStatisticEarningsProfit: "eraforce/fetchStatisticEarningsProfit",
      fetchStatisticEarningsRejected: "eraforce/fetchStatisticEarningsRejected",
      fetchStatisticActivity: "eraforce/fetchStatisticActivity",
      fetchStatisticArrival: "eraforce/fetchStatisticArrival",
      fetchStatisticArrivalDetail: "eraforce/fetchStatisticArrivalDetail",
    }),

    ...mapMutations({
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
    }),

    getRoute() {
      const routeArr = this.$route.path.split("/");
      // console.log(12121, routeArr[2]);
      return routeArr[1];
    },

    goToArrivalDetail() {
      this.SET_ACTIVITY_CARD_LOADING();
      Swal.fire({
        title: "Please wait!",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticArrivalDetail(payload).then((response) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
        });
        const url =
          "/" + this.getRoute() + "/matrics/statistics/arrival-detail";
        this.$router.push(url);
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },

    goToProjects() {
      const url = "/" + this.getRoute() + "/master-data/projects";
      this.$router.push(url);
    },

    handleChangeFilter() {
      this.initFunction();
    },

    initFunction() {
      this.getHighlightData();
      this.getAllTeamRevenurData();
      this.getEarningsRevenueData();
      this.getEarningsProfitData();
      this.getEarningsRejectedData();
      this.getActivityData();
      this.getArrivalData();
    },

    getHighlightData() {
      this.hightlight_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticHighlight(payload).then((response) => {
        this.hightlight_loading = false;
      });
    },

    getEarningsRevenueData() {
      this.earnings_revenue_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticEarningsRevenue(payload).then((response) => {
        this.earnings_revenue_loading = false;
      });
    },

    getEarningsProfitData() {
      this.earnings_profit_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticEarningsProfit(payload).then((response) => {
        this.earnings_profit_loading = false;
      });
    },

    getEarningsRejectedData() {
      this.earnings_rejected_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticEarningsRejected(payload).then((response) => {
        this.earnings_rejected_loading = false;
      });
    },

    getActivityData() {
      this.activity_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticActivity(payload).then((response) => {
        this.activity_loading = false;
      });
    },

    getArrivalData() {
      this.arrival_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        team_id: this.getUserLoginData.team_id,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticArrival(payload).then((response) => {
        this.arrival_loading = false;
      });
    },

    getAllTeamRevenurData() {
      this.all_team_revenue_loading = true;
      let interval;
      if (this.form.interval == "1 Month") {
        interval = 1;
      } else if (this.form.interval == "3 Month") {
        interval = 3;
      } else {
        interval = 6;
      }

      const payload = {
        interval: interval,
        month: this.form.month,
        year: this.form.year,
      };

      this.fetchStatisticAllTeamRevenue(payload).then((response) => {
        this.all_team_revenue_loading = false;
      });
    },
  },

  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },

    highLightData() {
      return this.$store.state.eraforce.statistic_revenue_highlight;
    },

    displayHighlightData() {
      let result = [];
      this.highLightData.forEach((data) => {
        result.push({
          title: data.id,
          value: data.value,
          percentage: data.percent,
          status: data.status,
          detail: data.l_value,
        });
      });
      return result;
    },

    teamRevenueData() {
      return this.$store.state.eraforce.statistic_all_team_revenue_highlight;
    },

    displayTeamRevenueData() {
      let result = [];
      this.teamRevenueData.forEach((data) => {
        result.push({
          title: data.name,
          value: data.team_revenue,
          image: data.manager_image_url,
        });
      });
      return result;
    },

    earningsRevenueDataLength() {
      return this.$store.state.eraforce.statistic_earnings_revenue.sales_name
        .length;
    },

    earningsProfitDataLength() {
      return this.$store.state.eraforce.statistic_earnings_profit.sales_name
        .length;
    },

    earningsRejectedDataLength() {
      return this.$store.state.eraforce.statistic_earnings_rejected.sales_name
        .length;
    },

    activityDataLength() {
      return this.$store.state.eraforce.statistic_activity.length;
    },

    arrivalData() {
      return this.$store.state.eraforce.statistic_arrival.name;
    },
  },

  mounted() {
    this.initFunction();
  },
};
</script>

<style scoped>
.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  margin-right: 20px;
  cursor: pointer;
}
</style>
