import { format } from "date-fns";
import { mapActions } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { ElNotification } from "element-plus";
export default {
  name: "GoappsAdminMealAllowance",
  components: {
    ArgonButton,
    ArgonInput
  },
  data() {
    return {
      params: {
        date_range: [format(new Date(), "yyyy-MM-21 00:00:00"), format(new Date(), "yyyy-MM-dd 23:59:59")],
        start_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        end_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
        id_user: "",
        filter: "",
        page: 1
      },
      pop_over_content: "This is contain list meal allowance of sales and non-sales",
      allowance_sales: [],
      allowance_office: [],
      is_loading_sales: false,
      is_loading_office: false,
      selected_data: null,
      attendance_dialog: false,
      is_loading_office_more: false,
      downloading_sales: true,
      downloading_office: false
    };
  },
  beforeMount() {
    this.handleInitDate();
  },
  methods: {
    ...mapActions({
      fetchMealAllowanceOffice: "hrd/fetchMealAllowanceOffice",
      fetchAttendanceRequestSales: "hrd/fetchAttendanceRequestSales",
      doHandleAttendanceRequestOffice: "hrd/doHandleAttendanceRequestOffice",
      fetchEmployeeVisitData: "hrd/fetchEmployeeVisitData",
      doHandleAttendanceRequestSales: "hrd/doHandleAttendanceRequestSales",
      fetchMealAllowanceOfficeExcel: "hrd/fetchMealAllowanceOfficeExcel",
      fetchMealAllowanceSales: "hrd/fetchMealAllowanceSales"
    }),
    handleExportOffice() {
      const start = format(new Date(this.params.start_date), "dd-MMM-yyyy");
      const end = format(new Date(this.params.end_date), "dd-MMM-yyyy");
      const format_name = `MealAllowanceOffice_${start}_${end}`;
      // this.downloading_office = true;
      ElMessage({
        message: "Please wait downloading office meal allowance report..",
        type: "info",
        duration: 2000
      });
      const params = {
        start: this.params.start_date.split(" ")[0],
        end: this.params.end_date.split(" ")[0]
      };
      this.fetchMealAllowanceOfficeExcel(params).then(response => {
        // this.downloading_office = false;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${format_name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        ElNotification({
          title: "Voila!",
          message: "Success download office meal allowance report!",
          type: "success"
        });
      }).catch(error => {
        // this.downloading_office = false;
        ElNotification({
          title: "Oops!",
          message: `Error: ${error.message}`,
          type: "error",
          duration: 5000
        });
      });
    },
    async handleExportSales() {
      await import('@/plugins/Export2Excel.js').then(excel => {
        const start = format(new Date(this.params.start_date), "dd-MMM-yyyy");
        const end = format(new Date(this.params.end_date), "dd-MMM-yyyy");
        const format_name = `SalesMealAllowance_${start}_${end}`;
        ElMessage({
          message: "Please wait downloading sales meal allowance report..",
          type: "info",
          duration: 5000
        });
        const params = {
          start_date: this.params.start_date,
          end_date: this.params.end_date,
          //88
          search: this.params.filter
        };
        this.fetchMealAllowanceSales(params).then(response => {
          //excel
          const OBJ = response.result;
          const Header = ["Name", "Present", "Late", "Penalty", "Total"];
          const Field = ["employee", "total_on_time", "total_late", "total_penalty", "total"];
          const Data = this.formatJson(Field, OBJ);
          excel.export_json_to_excel({
            header: Header,
            data: Data,
            sheetName: "Sales Meal Allowance",
            filename: format_name,
            autoWidth: true,
            bookType: "xlsx"
          });
          ElNotification({
            title: "Voila!",
            message: "Success download sales meal allowance!",
            type: "success"
          });

          //end excel
        });
      }).catch(error => {
        console.log("This is error: ", error);
      });
    },
    formatJson(filterData, jsonData) {
      return jsonData.map(v => filterData.map(j => {
        return v[j];
      }));
    },
    handleInitDate() {
      let now = new Date();
      let month = now.getMonth();
      let date = now.getDate();
      let year = now.getFullYear();
      if (date > 20) {
        const s_m = month + 2 < 10 ? "0" + (month + 2) : month + 2;
        const last_date = year + "-" + s_m + "-" + "20" + " " + "00:00:00";
        this.params.date_range = [format(new Date(), "yyyy-MM-21 23:59:59"), last_date];
        this.params.start_date = format(new Date(), "yyyy-MM-21 23:59:59");
        this.params.end_date = last_date;
      } else {
        const l_m = month < 10 ? "0" + month : month;
        const last_date = year + "-" + l_m + "-" + "21" + " " + "00:00:00";
        this.params.date_range = [last_date, format(new Date(), "yyyy-MM-20 23:59:59")];
        this.params.start_date = last_date;
        this.params.end_date = format(new Date(), "yyyy-MM-20 23:59:59");
      }
      this.getMealAllowanceOffice();
      this.getMealAllowanceSales();
    },
    handleSearch() {
      this.getMealAllowanceOffice();
      this.getMealAllowanceSales();
    },
    formatDate(dates, type) {
      if (dates != null) {
        const date_input = dates.split(" ");
        let date;
        if (date_input.length == 1) {
          date = dates + " " + "00:00:00";
        } else {
          date = dates;
        }
        if (type == "fullset") {
          return format(new Date(date), "EEEE, dd MMMM yyyy HH:mm");
        } else if (type == "time") {
          return format(new Date(date), "HH:mm aa");
        } else if (type == "fullday") {
          return format(new Date(date), "EEEE, dd MMMM yyyy");
        } else {
          return "-";
        }
      } else {
        return "--";
      }
    },
    getMealAllowanceOffice() {
      this.is_loading_office = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      const params = {
        from: start,
        to: end,
        filter: this.params.filter,
        page: this.params.page
      };
      this.fetchMealAllowanceOffice(params).then(response => {
        this.allowance_office = response.data.data;
        this.is_loading_office = false;
      }).catch(error => {
        this.allowance_office = [];
        this.is_loading_office = false;
        ElNotification({
          title: "Oops!",
          message: `Error office: ${JSON.stringify(error)}`,
          type: "error",
          duration: 5000
        });
      });
    },
    getMealAllowanceOfficeMore() {
      this.is_loading_office_more = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      this.params.page += 1;
      const params = {
        from: start,
        to: end,
        filter: this.params.filter,
        page: this.params.page
      };
      this.fetchMealAllowanceOffice(params).then(response => {
        this.allowance_office = [...this.allowance_office, ...response.data.data];
        this.is_loading_office_more = false;
      }).catch(error => {
        this.allowance_office = [];
        this.is_loading_office_more = false;
        ElNotification({
          title: "Oops!",
          message: `Error office: ${JSON.stringify(error)}`,
          type: "error",
          duration: 5000
        });
      });
    },
    getMealAllowanceSales() {
      // 88 sales
      this.is_loading_sales = true;
      const start = this.params.start_date;
      const end = this.params.end_date;
      const params = {
        start_date: start,
        end_date: end,
        search: this.params.filter
      };
      this.fetchMealAllowanceSales(params).then(response => {
        this.allowance_sales = response.result;
        this.is_loading_sales = false;
      }).catch(error => {
        this.allowance_sales = [];
        this.is_loading_sales = false;
        ElNotification({
          title: "Oops!",
          message: `Error sales: ${JSON.stringify(error.data)}`,
          type: "error",
          duration: 5000
        });
      });
    },
    handleChangeFilterDate() {
      this.params.search = this.params.filter;
      const start = this.params.date_range[0] + " 00:00:00";
      const end = this.params.date_range[1] + " 23:59:59";
      this.params.start_date = format(new Date(start), "yyyy-MM-dd 00:00:00");
      this.params.end_date = format(new Date(end), "yyyy-MM-dd 23:59:59");
      this.getMealAllowanceSales();
      this.getMealAllowanceOffice();
    },
    handleRequestOffice(data, type) {
      const params = {
        request_id: data.id,
        approve: type
      };
      this.doHandleAttendanceRequestOffice(params).then(response => {
        ElNotification({
          title: "Voila!",
          message: `Success update presence request!`,
          type: "success"
        });
        this.getMealAllowanceOffice();
      }).catch(error => {
        ElNotification({
          title: "Oops!",
          message: `Error: ${error.message}`,
          type: "error",
          duration: 5000
        });
      });
    },
    handleRequestSales(data, type) {
      const params = {
        request_id: data.id,
        status: type
      };
      this.doHandleAttendanceRequestSales(params).then(response => {
        ElNotification({
          title: "Voila!",
          message: `Success update presence request!`,
          type: "success"
        });
        this.getMealAllowanceSales();
      }).catch(error => {
        ElNotification({
          title: "Oops!",
          message: `Error: ${error.message}`,
          type: "error",
          duration: 5000
        });
      });
    },
    handleOpenDetails(data) {
      this.selected_data = data;
      this.attendance_dialog = true;
    },
    handleCloseVisit() {
      this.selected_data = null;
      this.attendance_dialog = false;
    },
    handleOpenMap(latitude, longitude) {
      window.open(`https://maps.google.com/?q=${latitude},${longitude}`, "_blank");
    },
    handleLoadMore() {
      this.getMealAllowanceOfficeMore();
      this.getMealAllowanceSales();
    }
  }
};