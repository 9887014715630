<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold">Position</span>
        <div>
          <argon-button
            variant="gradient"
            color="info"
            size="md"
            @click="handleOpenAdd()"
          >
            Add Position
          </argon-button>
        </div>
      </div>
    </div>
    <div class="card p-3">
      <!-- cella for dev  -->
      <!-- <el-table
        :data="getPosition"
        style="width: 100%"
        height="500"
        v-loading="is_table_loading"
      > -->
      <el-table :data="getPosition" style="width: 100%" height="500">
        <el-table-column fixed prop="position" label="Position" />
        <el-table-column prop="zip" label="Action" width="150">
          <template #default="scope">
            <div class="d-flex justify-content-center">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="fas fa-caret-down"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="handleOpenEdit(scope.row)">
                      Edit
                    </el-dropdown-item>
                    <el-dropdown-item @click="handleOpenRemove(scope.row)">
                      Deactivate
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- Add Position -->
    <el-dialog
      v-model="add_dialog"
      title="Add New Position"
      width="30%"
      :before-close="handleCloseAdd"
    >
      <span>
        <el-input
          v-model="form.position"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input position name"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="handleCreatePosition"
            class="bg-gradient-success"
            :disabled="form.position == null || form.position == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Edit Position -->
    <el-dialog
      v-model="edit_dialog"
      title="Edit Position"
      width="30%"
      :before-close="handleCloseEdit"
    >
      <span>
        <el-input
          v-model="form.position"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input position name"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="handleUpdatePosition"
            class="bg-gradient-success"
            :disabled="form.position == null || form.position == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Delete Position -->
    <el-dialog
      v-model="remove_dialog"
      title=""
      width="30%"
      :before-close="handleCloseRemove"
    >
      <span>
        Are you sure want to delete position: <b>{{ form.position }}</b> ?
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="handleDeletePosition"
            class="bg-gradient-success"
            :disabled="form.position == null || form.position == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions } from "vuex";

export default {
  name: "GoappsAdminPosition",
  components: {
    ArgonButton,
  },

  data() {
    return {
      params: {
        id: "",
        search: "",
        order: "asc",
      },
      form: {
        id: null,
        position: null,
      },
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
    };
  },

  beforeMount() {
    this.fetchPositionData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchPosition: "hrd/fetchPosition",
      doCreatePosition: "hrd/doCreatePosition",
      doUpdatePosition: "hrd/doUpdatePosition",
      doDeletePosition: "hrd/doDeletePosition",
    }),

    fetchPositionData() {
      this.is_table_loading = true;
      const payload = {
        id: this.params.id,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchPosition(payload).then((response) => {
        this.is_table_loading = false;
      });
    },

    handleCreatePosition() {
      const params = {
        position: this.form.position,
      };
      this.doCreatePosition(params)
        .then((response) => {
          this.handleCloseAdd();
          ElNotification({
            title: "Voila!",
            message: "Success create position!",
            type: "success",
          });
          this.fetchPositionData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleUpdatePosition() {
      const params = {
        position: this.form.position,
        id: this.form.id,
      };
      this.doUpdatePosition(params)
        .then((response) => {
          this.handleCloseEdit();
          ElNotification({
            title: "Voila!",
            message: "Success update position!",
            type: "success",
          });
          this.fetchPositionData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleDeletePosition() {
      const params = {
        id: this.form.id,
      };
      this.doDeletePosition(params)
        .then((response) => {
          this.handleCloseRemove();
          ElNotification({
            title: "Voila!",
            message: "Success deactivate position!",
            type: "success",
          });
          this.fetchPositionData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCloseRemove() {
      this.form.id = null;
      this.form.position = null;
      this.remove_dialog = false;
    },

    handleCloseAdd() {
      this.form.id = null;
      this.form.position = null;
      this.add_dialog = false;
    },

    handleCloseEdit() {
      this.form.id = null;
      this.form.position = null;
      this.edit_dialog = false;
    },

    handleOpenAdd() {
      this.add_dialog = true;
    },

    handleOpenEdit(data) {
      this.form = {
        id: data.id_position,
        position: data.position,
      };
      this.edit_dialog = true;
    },

    handleOpenRemove(data) {
      this.form = {
        id: data.id_position,
        position: data.position,
      };
      this.remove_dialog = true;
    },
  },

  computed: {
    getPosition() {
      return this.$store.state.hrd.position;
    },
  },
};
</script>
