import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "GoappsAdminAddOffice",
  components: {
    ArgonButton
  },
  data() {
    return {
      is_map_loaded: true,
      markers: [{
        position: {
          lat: -6.163987599999999,
          lng: 106.8156184
        }
      }],
      location: null,
      location_name: null,
      location_address: null,
      location_city: null,
      location_lat: null,
      location_long: null
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      doCreateOffice: "hrd/doCreateOffice"
    }),
    ...mapMutations({
      SET_SELECTED_CLIENT_LOCATION: "eraforce/SET_SELECTED_CLIENT_LOCATION",
      OPEN_ADD_CLIENT_DIALOG: "eraforce/OPEN_ADD_CLIENT_DIALOG"
    }),
    openMap() {
      this.is_map_loaded = false;
      // this.initMap();
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      // console.log(12121, routeArr[2]);
      return routeArr[1];
    },
    openAddClientLocationDialog() {
      const url = "/" + this.getRoute() + "/master-data/office-location";
      this.$router.push(url);
    },
    confirmPinLocation() {
      const payload = {
        office: this.location_name,
        address: this.location_address,
        latitude: this.location_lat,
        longitude: this.location_long,
        city: this.location_city
      };
      this.doCreateOffice(payload).then(response => {
        ElNotification({
          title: "Voila!",
          message: "Success create office!",
          type: "success"
        });
        this.initData();
        this.openAddClientLocationDialog();
      }).catch(error => {
        ElNotification({
          title: "Oops!",
          message: `Error: ${error.message}`,
          type: "error",
          duration: 5000
        });
      });
    },
    setPlace(place) {
      if (place) {
        var _place$name, _place$formatted_addr;
        this.location = place;
        this.location_name = (_place$name = place.name) !== null && _place$name !== void 0 ? _place$name : "-";
        this.location_address = (_place$formatted_addr = place.formatted_address) !== null && _place$formatted_addr !== void 0 ? _place$formatted_addr : "-";
        this.location_city = place.address_components[7] != null ? place.address_components[7].long_name : "";
        // this.getLatLngFromString(place.geometry.location);
        this.location_lat = place.geometry.location.lat();
        this.location_long = place.geometry.location.lng();
        this.markers.pop();
        this.markers.push({
          position: place.geometry.location
        });
      }
    },
    handleCancle() {
      if (this.selectedLocation == null) {
        console.log(`i`);
        this.openAddClientLocationDialog();
      } else {
        console.log(`h`);
        this.OPEN_ADD_CLIENT_DIALOG();
        this.openAddClientLocationDialog();
      }
    },
    initData() {
      this.location = null;
      this.location_name = null;
      this.location_address = null;
      this.location_city = null;
      this.location_lat = null;
      this.location_long = null;
    }
  }
};