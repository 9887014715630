<template>
  <div>
    <div>
      <div class="card p-3">
        <argon-input
          type="text"
          placeholder="Search menu..."
          name="apps"
          size="lg"
          fullWidth
          :value="search"
          v-model="search"
          @change="getAllMenus"
        />
        <div class="mt-2">
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            @click="openAddApp"
          >
            <div><i class="fa fa-plus"> </i> Add New Menu</div>
          </argon-button>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div class="card p-3">
        <el-table
          :data="menuData"
          height="400"
          style="width: 100%"
          v-loading="loading_data"
        >
          <el-table-column prop="menu" label="Menu" />
          <el-table-column label="Actions">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                @click="handleDeleteApp(scope.row)"
                class="bg-gradient-danger"
                >Delete</el-button
              >
              <el-button
                size="small"
                type="info"
                @click="openMenuPermission(scope.row)"
                class="bg-gradient-info"
                >Detail</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { mapActions } from "vuex";

import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  data() {
    return {
      search: "",
      dialogVisible: false,
      menu: null,
      selected_menu: null,
      create_app_dialog: false,
      loading_data: false,
      detail_dialog: false,
    };
  },

  components: {
    ArgonInput,
    ArgonButton,
  },

  mounted() {
    this.getAllMenus();
  },

  methods: {
    ...mapActions({
      fetchMenu: "administrator/fetchMenu",
      doCreateApp: "administrator/doCreateApp",
      doUpdateApp: "administrator/doUpdateApp",
      doDeleteApp: "administrator/doDeleteApp",
    }),

    getAllMenus() {
      this.loading_data = true;
      const payload = {
        search: this.search,
      };
      this.fetchMenu(payload).then((response) => {
        this.loading_data = false;
      });
    },

    openMenuPermission() {
      this.dialogVisible = true;
    },
  },

  computed: {
    menuData() {
      return this.$store.state.administrator.menu;
    },
  },
};
</script>
