// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    department_data: [],
  },
  getters: {
    data: (state) => state.data,
    getDepartmentData: (state) => {
      return state.department_data;
    },
  },
  actions: {
    fetchDepartment({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        rootState.ax.get(`departement`).then(
          (response) => {
            commit("SET_ALL_DEPARTMENT_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            commit("SET_ALL_DEPARTMENT_DATA", []);
            reject(error);
          }
        );
      });
    },
    // fetchEmployeeById({ commit, rootState }, body) {
    //   return new Promise((resolve, reject) => {
    //     rootState.ax.post(`user/get-user-by-id`, body).then(
    //       (response) => {
    //         commit("SET_SELECTED_EMPLOYEE_DATA", response.data.data.user);
    //         resolve(response.data.data.user);
    //       },
    //       (error) => {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Something Wrong!",
    //           text: `${error.response.data.message}`,
    //         });
    //         reject(error);
    //       }
    //     );
    //   });
    // },
  },
  mutations: {
    SET_ALL_DEPARTMENT_DATA(state, data) {
      state.department_data = data;
    },
  },
};
