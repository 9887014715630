<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="''"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 mb-2 container-fluid">
      <breadcrumbs
        :currentDirectory="currentRouteName"
        :currentPage="currentSubRouteName"
        textWhite="text-white"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center ms-2">
            <div
              @click="goToHome"
              class="px-0 nav-link font-weight-bold text-white on-hover-mouse-clicked"
            >
              <i class="fa fa-home" :class="'me-sm-2'"></i>
              <span lass="d-sm-inline d-none">Home</span>
            </div>
          </li>
          <li class="nav-item d-flex align-items-center ms-2" v-if="this.currentRouteName == 'Goo Project (Goo Pro)'">
            <a href="javascript:;" class="nav-link text-white p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-bell cursor-pointer"></i>
            </a>
            <ul class="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" style="cursor: pointer;" @click="handleShowTasks(JSON.parse(items.data)['extra']['card_id'], JSON.parse(items.data)['extra']['board_id'])" v-for="items in notification"
                    :key="items">
                  <div class="d-flex py-1">
                    <!-- <div class="my-auto">
                      JSON.parse(items.data)['extra']['card_id']
                      <img src="../../assets/img/team-2.jpg" class="avatar avatar-sm  me-3 ">
                    </div> -->
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="text-sm font-weight-normal mb-1">
                        <span class="font-weight-bold">{{ JSON.parse(items.data)['title'] }}</span> 
                      </h6>
                      <p class="text-xs text-secondary mb-0">
                        <span class="font-weight-bold">{{ JSON.parse(items.data)['body'] }}</span>  <br><br>
                        <i class="fa fa-clock me-1"></i>
                        {{ timeAgo(items.created_at) }} 
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <!-- <li
            class="px-3 nav-item dropdown d-flex align-items-center"
            :class="'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link text-white"
              :class="[showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
              Notification
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div
                      class="my-auto avatar avatar-sm bg-gradient-secondary me-3"
                    >
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 43 36"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2169.000000, -745.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                  class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743"
                                />
                                <path
                                  class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li> -->
          <li class="nav-item d-flex align-items-center">
            <div
              @click="goToProfile"
              class="px-2 nav-link font-weight-bold text-white on-hover-mouse-clicked d-flex align-items-center"
            >
              <!-- <i class="fas fa-user" :class="'me-sm-2'"></i> -->
              <div class="">
                <img
                  :src="getUserData.photo_url"
                  alt=""
                  class="img-navbar"
                  @error="imageLoadError"
                />
              </div>
              <span lass="d-sm-inline d-none">
                Hi,
                {{
                  getUserLoginData.user.fullname
                    ? getUserLoginData.user.fullname
                    : ""
                }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Detail Tasks -->
    <el-dialog v-model="detail_tasks" title="Detail Tasks" width="60%">
      <div class="row">
        <div class="col-md-9">
          <p>Created By <strong>{{ (showTasks[0].user.length > 0) ? showTasks[0].user[0].fullname : '--' }}</strong></p>
          <div class="card card-primary card-outline shadow p-3">
            <div class="mb-5">
              <div class="h5"
                ><i class="fas fa-chalkboard"></i> <span v-if="editTasks == false">{{ showTasks[0].title }}</span> <span v-else><input type="text" v-model="formEditTasks.title"/></span>
                <span v-if="editTasks == false">
                  <i class="fas fa-edit" style="cursor: pointer; margin-left: 10px" @click="changeEditTasks(showTasks[0].title)"></i>
                </span>
                <span v-else>
                  <i class="fas fa-check-circle" style="cursor: pointer; margin-left: 10px" @click="handleUpdateTasks"></i>
                  <i class="fas fa-times-circle" style="cursor: pointer; margin-left: 10px" @click="changeEditTasks(showTasks[0].title)"></i>
                </span>
              </div>
              <!-- <div class="row">
                <div class="col-md-8">
                  <i class="fas fa-chalkboard"></i>
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
                </div>
              </div> -->
              <!-- <span class="h5">
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" />
              </span> -->
              <br />
              <span>in List</span>

              <!-- Member Card -->
              <div class="m-3" v-if="showTasks[0].member_card.length > 0">
                <h6>Member Ticket</h6>
                <div class="d-flex">
                  <div class="avatar-group ms-2">
                    <a
                      href="#"
                      @click="detailMemberTicket(showTasks[0])"
                      class="avatar avatar-xs border-0 rounded-circle"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      v-for="thems in showTasks[0].member_card"
                      :key="thems.id"
                      style="pointer: cursor;"
                    >
                      <img
                        alt="Image placeholder"
                        src="../../assets/img/team-3.jpg"
                      />
                    </a>
                  </div>
                  <div>
                    Total Members: {{ showTasks[0].member_card.length }}
                  </div>
                </div>
              </div>
              <!-- End Member Card -->

              <!-- Due Date -->
              <div class="m-3">
                <div class="row">
                  <div class="col-md-6">
                    <h6>Start Date</h6>
                    <button type="button" class="btn btn-info btn-sm">
                      <i class="fas fa-clock"></i>
                      {{ getDate(showTasks[0].start_date) }}
                    </button>
                  </div>
                  <div class="col-md-6">
                    <h6>Due Date</h6>
                    <!-- <div class="form-check">
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      
                    </div> -->
                    <button v-if="showTasks[0].due_date" @click="showDates()" type="button" :class="getColorDueDate(showTasks[0].due_date, showTasks[0].status) + ' btn btn-sm'">
                        <i class="fas fa-clock"></i>
                        {{ getDateDue(showTasks[0].due_date) }}
                      </button>
                      <button v-else type="button" class="btn btn-sm btn-warning" @click="showDates()">
                        <i class="fas fa-clock"></i>
                        Update Due Date
                      </button>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" :value="showTasks[0].status" v-if="showTasks[0].status == 0 || showTasks[0].status == '' || showTasks[0].status == null" @change="updateChecklist($event)" />
                      <input class="form-check-input" type="checkbox" id="flexCheckChecked" :value="showTasks[0].status" v-if="showTasks[0].status == 1" checked @change="updateChecklist($event)" />
                      <label class="form-check-label" for="flexCheckChecked">
                        Complete
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Description -->
            <div>
              <div class="mb-5">
                <span class="h5">
                  <i class="fas fa-th-list"></i> Description</span>
                  <i class="fas fa-edit ml-2" style="cursor: pointer; margin-left: 10px" @click="handleEditDescription(showTasks[0].description)"></i>
                <br />
                <div
                  class="m-4"
                  style="cursor: pointer"
                  v-if="!edit_description"
                >
                  <strong>{{ (showTasks[0].so_id != null) ? showTasks[0].so_id : '' }}</strong><br />
                  {{ showTasks[0].description }}
                </div>

                <div class="m-4" v-else>
                  <textarea
                    class="form-control"
                    rows="2"
                    placeholder="Description"
                    v-model="showDescription"
                  ></textarea>
                  <div class="mt-3">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="submitEditDescription(showTasks[0])"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm ms-1"
                      @click="handleRemoveEditDescription()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Description -->

            <!-- Attachment -->
            <div>
              <div class="mb-5">
                <span class="h5"
                  ><i class="fas fa-paperclip"></i> Attachment</span
                >
                <br />
                <div class="mt-2">
                  <ul class="list-group">
                    <li
                      class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                      v-for="attach in showTasks[0].attachments"
                      :key="attach.id_attach"
                    >
                      <div class="d-flex px-2 py-1">
                        <div>
                          <a :href="getUrl(attach)" target="_blank">
                            <img
                              :src="getAttachment(attach)"
                              class="avatar avatar-xl me-3 position-relative"
                              alt="user1"
                            />
                          </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ attach.name }}</h6>
                          <p class="text-xs text-secondary mb-0">
                            {{ attach.created_at }}
                          </p>
                        </div>
                      </div>
                      <div class="ms-auto text-end">
                        <div class="justify-content-center">
                          <el-dropdown>
                            <span class="el-dropdown-link">
                              <i class="fas fa-caret-down"></i>
                            </span>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item
                                  @click="HandleEditAttachment(attach)"
                                >
                                  Edit
                                </el-dropdown-item>
                                <el-dropdown-item
                                  @click="HandleDeleteAttachment(attach)"
                                >
                                  Delete
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                          </el-dropdown>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- End Attachment -->

            <!-- Activity  -->
            <div>
              <span class="h5"><i class="fas fa-user-clock"></i> Activity</span>
              <br />
              <div class="mt-3">
                <div class="d-flex form-group">
                  <img
                    src="../../assets/img/team-2.jpg"
                    class="avatar avatar-sm me-3"
                    alt="user1"
                  />
                  <textarea
                    class="form-control"
                    rows="2"
                    v-model="comment"
                    placeholder="Write a comments.."
                  ></textarea>
                </div>
                <button
                  type="button"
                  v-if="comment != ''"
                  class="btn btn-primary btn-sm"
                  @click="handleAddComment()"
                >
                  Submit
                </button>
              </div>
              <div>
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                    v-for="cmt in showTasks[0].comments"
                    :key="cmt.id_comment"
                  >
                    <div class="d-flex px-2 py-1">
                      <div>
                        <img
                          :src="getImageComment(cmt.image)"
                          class="avatar avatar-sm me-3"
                          alt="user1"
                        />
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm">{{ cmt.user }}</h6>
                        <p class="text-xs text-secondary mb-0">
                          {{ cmt.created_at }}
                        </p>

                        <div class="mt-2" v-if="cmt.type == 'MP3'">
                          <label>
                            <button class="btn btn-primary btn-sm" @click="handleSound(cmt.description)">
                              <span class="fa fa-play-circle-o fa-lg" v-if="!sound"></span>
                              <span class="fa fa-pause" v-else></span>
                            </button>
                          </label>
                        </div>
                        <div class="mt-2" v-else>
                          {{ cmt.description }}
                        </div>

                      </div>
                    </div>
                    <div class="ms-auto text-end" v-if="cmt.user_id == getUserLoginData.user.id_user">
                      <div>
                        <el-dropdown>
                          <span class="el-dropdown-link">
                            <i class="fas fa-caret-down"></i>
                          </span>
                          <template #dropdown>
                            <el-dropdown-menu>
                              <el-dropdown-item
                                @click="handleEditComments(cmt)"
                                v-if="!cmt.type == 'MP3'"
                              >
                                Edit
                              </el-dropdown-item>
                              <el-dropdown-item
                                @click="handleDeleteComment(cmt)"
                              >
                                Delete
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <label>Add To Card</label>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showCardMembers(this.$route.params.id)"
          >
            <i class="fas fa-users"></i>
            Members
          </button>
          <!-- <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showLabels()"
          >
            <i class="fas fa-tags"></i> Labels
          </button> -->
          <!-- <button type="button" class="btn btn-secondary w-100 btn-sm">
            <i class="fas fa-check-square"></i> Checklist
          </button> -->
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showDates()"
            v-if="!showTasks[0].due_date"
          >
            <i class="fas fa-calendar-alt"></i> Dates
          </button>
          <button
            type="button"
            class="btn btn-secondary w-100 btn-sm"
            @click="showAttachment()"
          >
            <i class="fas fa-paperclip"></i> Attachment
          </button>

          <div class="mt-2">
            <label>Actions</label>
            <button type="button" class="btn btn-secondary w-100 btn-sm" @click="handleMove()">
              <i class="fas fa-solid fa-arrow-right"></i> Move
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" @click="handleCopy()">
              <i class="fas fa-calendar-alt"></i> Copy
            </button>
            <button type="button" class="btn btn-secondary w-100 btn-sm" @click="handleProcess()">
              <i class="fas fa-calendar-alt"></i> Archive
            </button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- End Detail Tasks -->

    <!-- Attachment -->
    <el-dialog v-model="attachment" title="Attachment" width="30%">
      <div class="form-group">
        <label>New Attachment</label>
        <input
          type="file"
          class="form-control-file"
          v-on:change="onChangeFileUpload($event)"
        />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleSubmitAttachment()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Attachment -->

    <!-- Dates -->
    <el-dialog v-model="dates_model" title="Dates" width="30%">
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label"
          >Due Date</label
        >
        <input type="datetime-local" class="form-control" v-model="due_date" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleUpdateDueDate(showTasks[0].id_card)"
            :disabled="!due_date"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Dates -->

    <!-- Move -->
    <el-dialog v-model="move_ticket" title="Move List" width="30%">
      <div class="form-group">
        <label>Select List</label>
          <select class="form-control" @change="addIdList($event)">
            <option value="0">Choose List</option>
            <option v-for="item in detailKanban" :value="item.id_kanban" :fullname="item.title" :key="item">
              {{ item.title }}
            </option>
          </select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="submitMove()"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Move -->

    <!-- Card Member -->
    <el-dialog v-model="card_member" title="Card Member" width="30%">
      <div class="form-group">
        <label>Board Member</label>
        <select class="form-control" @change="handleChooseTicket($event)">
          <option disabled="true" selected="true">
            Choose Member Ticket
          </option>
          <option v-for="items in boardCollab" :key="items" v-bind:value="items.user[0].id_user">{{ items.user[0].nm_user }} - {{ items.user[0].divisi }}</option>
        </select>
      </div>
      <!-- <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Members</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in boardCollab" :key="item.user_id">
              <th scope="row">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                    name="user_id"
                    id="flexCheckDefault"
                    v-model="checkMembers"
                  />
                </div>
              </th>
              <td>
                <div class="d-flex px-2 py-1">
                  <div>
                    <img
                      src="../../assets/img/team-2.jpg"
                      class="avatar avatar-sm me-3"
                      alt="user1"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ item.user[0].nm_user }}</h6>
                    <p class="text-xs text-secondary mb-0">
                      Division :
                      <b>
                        {{
                          item.user[0].divisi == null
                            ? "Kosong"
                            : item.user[0].divisi
                        }}
                      </b>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="handleMemberCards"
          >
            Add Member
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Card Member -->

    <!-- Copy -->
    <el-dialog v-model="copy_ticket" title="Duplicate Tasks" width="30%">
      <span>
        <!-- <el-input
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Title"
          :suffix-icon="EditPen"
          v-model="formTasksDuplicate.title"
        /> -->
        <div class="m-2 w-100">
          <label for="exampleFormControlInput1" class="form-label"
            >Title <span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Title"
            v-model="formTasksDuplicate.title"
          />
        </div>
        <div class="m-2 w-100">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Description <span class="text-danger">*</span></label
          >
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            placeholder="Description"
            rows="3"
            v-model="formTasksDuplicate.description"
          ></textarea>
        </div>
        <div class="m-2 w-100">
          <label for="exampleFormControlInput1" class="form-label"
            >Due Date <span class="text-danger">*</span></label
          >
          <input
            type="datetime-local"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="Due Date"
            v-model="formTasksDuplicate.due_date"
          />
        </div>
      </span>
      <div class="form-group">
        <label>Select Board <span class="text-danger">*</span></label>
          <select class="form-control" @change="getList($event)">
            <option value="0" selected disabled>Choose Board</option>
            <option v-for="item in boardDuplicate" :value="item.id_board" :key="item">
              {{ item.nm_board }}
            </option>
          </select>
      </div>
      <div class="form-group">
        <label>Select List <span class="text-danger">*</span></label>
          <select class="form-control" @change="addKanban($event)">
            <option value="0" selected disabled>Choose List</option>
            <option v-for="item in listCopy" :value="item.id_kanban" :key="item">
              {{ item.title }}
            </option>
          </select>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            class="bg-gradient-success"
            @click="submitDuplicate()"
            :disabled="formTasksDuplicate.title == null || formTasksDuplicate.title == '' || formTasksDuplicate.description == null || formTasksDuplicate.description == '' || formTasksDuplicate.due_date == null || formTasksDuplicate.due_date == '' || formTasksDuplicate.kanban_id == null"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Copy -->
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import axios from 'axios';

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      notification: [],
      detailMember: null,
      detail_card: false,
      detail_tasks: false,
      showTasks: {},
      
      params: {
        id_kanban: "",
        board_id: "",
        search: "",
        order: "",
        filter: "",
        start_date: "",
        end_date: "",
        date_range: [null, null],
      },
      editTasks: false,
      move: {
        id_kanban: "",
        id_card: "",
      },
      formEditTasks: {
        title: null
      },
      formMember: {
        id_user: "",
      },
      edit_description: false,
      file: "",
      attachment: false,
      comment: "",
      showDescription: "",
      form_comment: {
        card_id: "",
        description: "",
      },
      move_ticket: false,
      detailKanban: [],
      move: {
        id_kanban: "",
        id_card: "",
      },
      id_board: null,
      card_member: false,
      formTasksDuplicate: {
        kanban_id: null,
        title: null,
        type: null,
        description: null,
        start_date: new Date(),
        due_date: null,
      },
      boardDuplicate: [],
      listCopy: [],
      copy_ticket: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  mounted() {
    this.fetchNotificationData();
    this.fetchBoardData();
  },
  methods: {
    ...mapMutations([
      "navbarMinimize",
      "toggleConfigurator",
      "SET_APP_PAGE_NAME",
      "toggleSidebarColor",
    ]),
    ...mapActions(["toggleSidebarColor"]),
    ...mapActions({
      fetchNotification: "gopro/fetchNotification",
      fetchCard: "gopro/fetchCard",
      doUpdateCard: "gopro/doUpdateCard",
      doCreateComment: "gopro/doCreateComment",
      doDeleteComment: "gopro/doDeleteComment",
      fetchCollabolator: "gopro/fetchCollabolator",
      fetchBoardCollabolator: "gopro/fetchBoardCollabolator",
      fetchDetailKanban: "gopro/fetchDetailKanban",
      doChangeKanban: "gopro/doChangeKanban",
      fetchBoard: "gopro/fetchBoard",
      fetchKanban: "gopro/fetchKanban",
      doCreateCard: "gopro/doCreateCard",
    }),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    fetchNotificationData() {
      axios.get("https://goopro.erakomp.co.id/api/notifications", {
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token"),
        }
      })
      .then((results) => {
        this.notification = results.data.data;
        console.log('SUCCESS');
      })
      .catch((error) => {
        console.log('ERROR');
        console.log(error);
      })
    },

    addKanban(event) {
      this.formTasksDuplicate.kanban_id = event.target.value;
    },

    submitDuplicate() {
      console.log(this.boardDuplicate);
      const params = {
        kanban_id: this.formTasksDuplicate.kanban_id,
        title: this.formTasksDuplicate.title,
        description: this.formTasksDuplicate.description,
        start_date: this.formTasksDuplicate.start_date,
        due_date: this.formTasksDuplicate.due_date,
      };
      this.doCreateCard(params)
        .then((response) => {
          this.handleCloseAddTaskDuplicate();
          console.log(response);
          ElNotification({
            title: "Viola!",
            message: "Success duplicate task",
            type: "success",
          });
          this.fetchKanbanData();
          setTimeout(() => {
            this.copy_ticket = false;
            this.detail_tasks = false;
          }, 2000);
        })
        .catch((err) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${err.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCloseAddTaskDuplicate() {
      this.formTasksDuplicate.title = null;
      this.formTasksDuplicate.description = null;
      this.formTasksDuplicate.start_date = null;
      this.formTasksDuplicate.due_date = null;
    },

    async getList(event) {
      const id_board = event.target.value;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: id_board,
        search: this.params.search,
        order: this.params.order,
      };

      await this.fetchKanban(payload)
        .then((response) => {
          this.listCopy = response;
        })
        .catch((err) => {
          console.log(`Error -->> ${JSON.stringify(err)}`);
        });
    },

    handleProcess() {
      ElNotification({
        title: "Viola!",
        message: "Fitur masih dalam process",
        type: "info",
        timer: 4000
      });
    },

    fetchBoardData() {
      const payload = {
        id_board: this.$route.params.id,
        search: "",
        order: "",
      };

      this.fetchBoard(payload)
        .then((response) => {
          this.boards = response.data[0];
          console.log(this.boards);
        })
        .catch((error) => {
          console.log(error);
        })
    },
    
    fetchBoardDuplicate() {
      const payload = {
        id_board: "",
        search: "",
        order: "",
      };

      this.fetchBoard(payload)
        .then((response) => {
          this.boardDuplicate = response.data;
          console.log(this.boards);
        })
        .catch((error) => {
          console.log(error);
        })
    },
    
    handleCopy() {
      this.copy_ticket = true;
      this.formTasksDuplicate.title = this.showTasks[0].title;
      this.formTasksDuplicate.description = this.showTasks[0].description;
      this.fetchBoardDuplicate();
    },

    async fetchKanbanData() {
      // this.is_loading = true;
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.$route.params.id,
        search: this.params.search,
        order: this.params.order,
        filter: this.params.filter,
        start_date: this.params.start_date,
        end_date: this.params.end_date,
      };

      await this.fetchKanban(payload)
        .then((response) => {
          this.kanban = response;
          // this.is_loading = false;
        })
        .catch((err) => {
          this.is_loading = false;
          console.log(`Error -->> ${JSON.stringify(err)}`);
        });
    },

    submitMove() {
      const payload = {
        kanban_id: this.move.id_kanban,
        id_card: this.move.id_card,
      };

      this.doChangeKanban(payload)
        .then((response) => {
          console.log(response);
          ElNotification({
            title: "Viola!",
            message: "Move Success",
            type: "success",
          });
          this.fetchKanbanData();
          setTimeout(() => {
            this.move_ticket = false;
            this.detail_tasks = false;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          ElNotification({
            title: "Viola!",
            message: "Move Failed",
            type: "error",
          });
        });
    },

    addIdList(event) {
      const id_kanban = event.target.value;
      const id_card = this.showTasks[0].id_card;
      this.move.id_kanban = id_kanban;
      this.move.id_card = id_card;
    },

    handleMove() {
      this.move_ticket = true;
      this.fetchDetailKanbanData();
    },

    fetchDetailKanbanData() {
      const payload = {
        id_kanban: this.params.id_kanban,
        board_id: this.id_board,
        search: this.params.search,
        order: this.params.order,
      };

      console.log(payload);
      this.fetchDetailKanban(payload)
        .then((response) => {
          this.detailKanban = response
          console.log('DETAIL KANBAN');
          console.log(this.detailKanban);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 

    showDates() {
      this.dates_model = true;
    },

    changeEditTasks(arg) {
      this.formEditTasks.title = arg;
      if(this.editTasks) {
        this.editTasks = false;
      } else {
        this.editTasks = true;
      }
    },

    handleEditDescription(arg) {
      this.edit_description = true;
      this.showDescription = arg;
    },

    handleRemoveEditDescription() {
      this.edit_description = false;
    },

    submitEditDescription(id_card) {
      let id = id_card.id_card;
      const payload = {
        id_card: id,
        description: this.showDescription,
      };
      this.doUpdateCard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.edit_description = false;
          this.showDescription = '';
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error",
            type: "error",
          });

        });
    },

    showAttachment() {
      this.attachment = true;
      this.file = null;
    },

    onChangeFileUpload(event) {
      let test = event.target.files[0];
      this.file = test;
    },

    handleSubmitAttachment() {
      let id_card = this.showTasks[0].id_card;
      let file = this.file;

      let formData = new FormData();
      formData.append("file", file);
      formData.append("card_id", id_card);
      axios
        .post("https://goopro.erakomp.co.id/api/attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((results) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.file = "";
          this.attachment = false;
          this.handleShowTasks(this.showTasks[0].id_card);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleAddComment() {
      this.form_comment.card_id = this.showTasks[0].id_card;
      this.form_comment.description = this.comment;

      let payload = {
        card_id: this.form_comment.card_id,
        description: this.form_comment.description,
        user_id: this.getUserLoginData.user.id_user
      };

      this.doCreateComment(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.form_comment.description = "";
          this.comment = "";
          this.handleShowTasks(this.form_comment.card_id);
        })
        .catch((err) => {
          ElNotification({
            title: "Error!",
            message: "Error",
            type: "error",
          });
        });
    },

    showCardMembers(params) {
      let paramsId = this.id_board;
      const payload = {
        id_board_collabolator: null,
        board_id: paramsId,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchBoardCollabolator(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
          this.boardCollab = response;
          this.card_member = true;
        })
        .catch((err) => {
          alert(JSON.stringify(err.message));
        });
    },

    fetchCollabolatorData() {
      let paramsId = this.id_board;
      const payload = {
        id_board_collabolator: null,
        board_id: paramsId,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchBoardCollabolator(payload)
        .then((response) => {
          console.log('hello world fetch board');
          this.detailCollabolator = response;
          console.log(this.detailCollabolator);
          this.list_app_menu = response;
          console.log(this.list_app_menu);
          console.log('FETCH');
        })
        .catch((error) => {
          console.log(error);
        })
    },

    goToHome() {
      this.SET_APP_PAGE_NAME("");
      this.$router.push({ name: "Permisson" });
      const routeArr = this.$route.path.split("/");
      if (routeArr[1] == "2") {
        this.$store.dispatch("eraforce/removeEraforceData");
      }
    },

    timeAgo(date) {
      date = new Date(date);
      const seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + ' years ago';
      }

      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months ago';
      }

      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' days ago';
      }

      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + ' hours ago';
      }

      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' minutes ago';
      }

      if(seconds < 10) return 'just now';

      return Math.floor(seconds) + ' seconds ago';
    },

    getDate(date) {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let dates = new Date(date);
        let day = dates.getDate();
        let month = dates.getMonth();
        var year = dates.getFullYear();
        
        // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
        let now = new Date();
        let exam = "";
        if (dates > now) {
          exam = "on going";
        } else {
          exam = "over due";
        }

        let result = day + "/" + monthNames[month] + "/" + year ;
        return result;
      },

      getDateDue(date) {
        const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let dates = new Date(date);
        let day = dates.getDate();
        let month = dates.getMonth();
        var year = dates.getFullYear();
        const minute = 1000 * 60;
        const hour = minute * 60;
        const days = hour * 24;
        const years = days * 365;
        let time = dates.getHours() + ":" + dates.getMinutes();
        let hasil = Math.round(dates.getTime() / year);



        // confirm(dates.replace("-", "/").split("T")[0].replace("-", "/"));
        let now = new Date();
        let exam = "";
        if (dates > now) {
          exam = "on going";
        } else {
          exam = "over due";
        }

        let result = day + "/" + monthNames[month] + "/" + year + " : " + time;
        return result;
      },

      getColorDueDate(date, status) {
        let exam = "";
        if(date == null || date == '') {
          exam = "btn-danger"
          return exam;
        }
        let dates = new Date(date);
        let day = dates.getDay();
        let month = dates.getMonth();
        let now = new Date();
        if (dates > now && status == 0) {
          exam = "bg-gradient-info";
          return exam;
        } else if(dates == now && status == 0) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(status == 1) {
          exam = "bg-gradient-success";
          return exam;
        } else {
          exam = "bg-gradient-danger"
          return exam;
        }

        
      },

      getImageComment(img) {
        // return `https://salesforce.klikpersada.co.id/ns/employee/${img}`;
        return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
      },

      getColor(date, status) {
        // let dates = new Date(date);
        // let day = dates.getDay();
        // let month = dates.getMonth();
        // let now = new Date();
        // let exam = "";
        // if(status == 1) {
        //   exam = "bg-gradient-success";
        //   return exam;
        // } else {
        //   exam = "bg-gradient-info"
        //   return exam;
        // }

        let exam = "";
        if(date == null || date == '') {
          exam = "btn-danger"
          return exam;
        }
        let dates = new Date(date);
        let day = dates.getDay();
        let month = dates.getMonth();
        let now = new Date();
        if (dates > now && status == 0) {
          exam = "bg-gradient-info";
          return exam;
        } else if(dates == now && status == 0) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(dates == now) {
          exam = "bg-gradient-warning";
          return exam;
        } else if(status == 1) {
          exam = "bg-gradient-success";
          return exam;
        } else if(dates > now) {
          exam = "bg-gradient-info"
          return exam;
        } else {
          exam = "bg-gradient-danger"
          return exam;
        }
      },

      handleShowTasks(data, board_id) {
        let payload = {
          id_card: data,
          search: null,
          order: null,
        };
        this.id_board = board_id;
        const params = [
          `id_card=${data}`,
          `search=`,
          `order=`,
        ].join("&");
        axios.get(`https://goopro.erakomp.co.id/api/card?${params}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token"),
          }
        })
        .then((response) => {
            ElNotification({
              title: "Viola!",
              message: "Success",
              type: "success",
            });
            this.showTasks = response.data.data;
            this.detail_tasks = true;
          })
          .catch((err) => {
            ElNotification({
              title: "Error!",
              message: "Tasks not found",
              type: "error",
            });
          });

        // this.fetchCard(payload)
          
      },

      getAttachment(img) {
        let gambar = "";
        if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG") {
          gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
        } else {
          gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
        }

        return gambar;
      },

      getUrl(img) {
        let gambar = "";
        if (img.type == "IMG" || img.type == "JPG" || img.type == "PNG" || img.type == "PDF" || img.type == "DOC") {
          gambar = `https://goopro.erakomp.co.id/ns/attachment/${img.name}`;
        } else {
          gambar = `https://goopro.yukevajkt.com/ns/attachment/fileicon.png`;
        }

        return gambar;
      },
      // End

    goToProfile() {
      return;
      this.SET_APP_PAGE_NAME("");
      this.$router.push({ name: "ProfileNonSales" });
    },

    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$store.state.dashboard_app_title;
    },
    currentSubRouteName() {
      return this.$store.state.dashboard_app_sub_title ?? "none";
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
};
</script>

<style scoped>
.img-navbar {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #ededed;
  margin-right: 10px;
}
</style>
