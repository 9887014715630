<template>
  <Card title="Create Pipeline" />

  <div class="card mt-4 row">
    <div class="card-header">
      <div class="d-flex justify-content-center">
        <el-radio-group v-model="type" size="large" @change="handleChangeType">
          <el-radio-button label="Lead" />
          <el-radio-button label="Pipeline" />
        </el-radio-group>
      </div>
    </div>

    <div class="card-body" v-if="type == 'Lead'">
      <div class="d-flex flex-column">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Company Name</label>
              <el-form-item>
                <el-autocomplete
                  class="inline-input"
                  v-model="state1"
                  :fetch-suggestions="querySearch"
                  placeholder="Input Company Name"
                  clearable
                  @select="handleSelect"
                  @change="handleChangeCompany"
                  style="width: 100%"
                ></el-autocomplete>
              </el-form-item>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Project Name</label>
              <el-form-item>
                <el-input
                  class="inline-input"
                  v-model="state2"
                  placeholder="Input Project Name"
                  clearable
                  :disabled="disable_project"
                  @click="handleSelectProject"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Value Target</label>
              <argon-input
                type="text"
                placeholder="Value Target"
                size="lg"
                :value="value_text"
                v-model="value_text"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Notes</label>
              <argon-input
                type="text"
                placeholder="Input notes"
                size="lg"
                width="100%"
                :value="form.notes"
                v-model="form.notes"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <argon-button
              class="mt-4"
              variant="gradient"
              color="success"
              fullWidth
              size="md"
              @click="handleAddLeadPipeline"
            >
              <div>Save</div>
            </argon-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body" v-if="type == 'Pipeline'">
      <div class="d-flex flex-column">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Company Name</label>
              <el-form-item>
                <el-autocomplete
                  class="inline-input"
                  v-model="state1"
                  :fetch-suggestions="querySearch"
                  placeholder="Input Company Name"
                  clearable
                  @select="handleSelect"
                  style="width: 100%"
                ></el-autocomplete>
              </el-form-item>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Project Name</label>
              <el-form-item>
                <el-autocomplete
                  class="inline-input"
                  v-model="search_project"
                  :fetch-suggestions="querySearchProject"
                  placeholder="Input Project Name"
                  clearable
                  :disabled="disable_project"
                  @select="handleSelectProjectPipeline"
                  style="width: 100%"
                ></el-autocomplete>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="mb-1">
              <label for="">Value Target</label>
              <argon-input
                type="text"
                placeholder="Value Target"
                size="lg"
                :value="value_text"
                v-model="value_text"
              />
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="mb-1">
              <label for="">Profit Target</label>
              <argon-input
                type="text"
                placeholder="Input protif"
                size="lg"
                width="100%"
                :value="profit_text"
                v-model="profit_text"
              />
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="mb-1">
              <label for="">Quantity Target</label>
              <argon-input
                type="text"
                placeholder="Input quantity"
                size="lg"
                width="100%"
                :value="form.quantity"
                v-model="form.quantity"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Project Start</label>
              <div>
                <el-date-picker
                  v-model="form.date1"
                  type="datetime"
                  placeholder="Select date and time"
                  size="large"
                  width="100%"
                  style="width: 100%"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :disabled-date="disabledDateStart"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="mb-1">
              <label for="">Project Close</label>
              <div>
                <el-date-picker
                  v-model="form.date2"
                  type="datetime"
                  placeholder="Select date and time"
                  size="large"
                  width="100%"
                  style="width: 100%"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :disabled-date="disabledDateEnd"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-1">
              <label for="">Notes</label>
              <argon-input
                type="text"
                placeholder="Input notes"
                size="lg"
                :value="form.notes"
                v-model="form.notes"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <argon-button
              class="mt-4"
              variant="gradient"
              color="success"
              fullWidth
              size="md"
              @click="handleAddLeadPipeline()"
            >
              <div>Save</div>
            </argon-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="createProjectDialog"
      title="Input Project Name"
      width="50%"
      :show-close="false"
    >
      <div>
        <div class="row">
          <div class="col-12">
            <argon-input
              type="text"
              placeholder="Input Project Name"
              size="lg"
              width="100%"
              :value="search_project"
              v-model="search_project"
              @change="handleSearchProject()"
            />
          </div>
          <div class="col-12">
            <div class="mb-3">Project name that already exist:</div>
            <el-scrollbar max-height="300px" v-if="projects.length > 0">
              <div style="margin: 10px">
                <div
                  class="card mb-2"
                  v-for="(project, index) in projects"
                  :key="index"
                >
                  <div class="card-body">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>{{ project.project_name }}</h6>
                      <h6>
                        <span class="badge badge-pill badge-dark">
                          {{ project.project_progress }}%
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
            <h6 class="text-center" v-else>
              Project name not found for this company.
            </h6>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createProjectDialog = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="handleConfirmCreateProject()"
            :disabled="disabled_confirm_create_project"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>

  <!-- Added PIC -->
  <el-dialog
    v-model="add_pic"
    title="Tambah PIC"
    width="40%"
  >
    <span>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="formPic.name" placeholder="PIC Name">
      </div>
      <!-- <el-form-item label="PIC Name">
        <el-input v-model="formPic.name" type="text" />
      </el-form-item> -->
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Email <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="formPic.email" placeholder="PIC Email">
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Phone <span class="text-danger">*</span></label>
        <input type="number" class="form-control" v-model="formPic.phone" placeholder="PIC Phone">
        <input type="number" class="form-control" hidden v-model="formPic.employee_id" placeholder="PIC Phone">
      </div>
    </span>
    <select class="form-select" aria-label="Default select example" @change="getStatus($event)">
      <option selected disabled>Choose Status</option>
      <option value="1">Manager</option>
      <option value="2">Assistant Manager</option>
      <option value="3">Supervisor</option>
      <option value="4">Sales Admin</option>
      <option value="5">Account Manager</option>
      <option value="6">Outsource</option>
    </select>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="success"
          class="bg-gradient-success"
          @click="submitDataPic()"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- End -->
</template>

<script>
import Swal from "sweetalert2";

import Card from "@/components/base/BaseCard.vue";
import exportFromJSON from "export-from-json";

import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

import { format } from "date-fns";

export default {
  data() {
    return {
      type: "Lead",
      disable_project: true,
      form: {
        state1: "",
        state2: "",
        value: 0,
        profit: 0,
        quantity: 0,
        notes: "I just create a pipeline/lead",
        date1: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        date2: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      },
      value_text: "IDR 0",
      profit_text: "IDR 0",
      state1: "",
      state2: "",
      companies: [],
      projects: [],
      selectedCompany: {},
      selectedProject: {},
      createProjectDialog: false,
      search_project: "",
      disabled_confirm_create_project: false,
      formPic: {
        name: "",
        status: null,
        phone: null,
        email: "",
        employee_id: this.$store.state.eraforce.user_data.employee_id,
        customer_id: null,
      },
      add_pic: false,
    };
  },

  components: {
    Card,
    ArgonButton,
    ArgonInput,
  },

  methods: {
    ...mapActions({
      fetchEmployeeCompany: "eraforce/fetchEmployeeCompany",
      fetchEmployeeProject: "eraforce/fetchEmployeeProject",
      fetchProjectCompany: "eraforce/fetchProjectCompany",
      doCreateLeadPipeline: "eraforce/doCreateLeadPipeline",
      doUpdatePipeline: "eraforce/doUpdatePipeline",
      doAddClientPIC: "eraforce/doAddClientPIC",
    }),
    ...mapMutations({
      SET_EXISTING_PROJECT: "eraforce/SET_EXISTING_PROJECT",
    }),

    disabledDateStart(date) {
      const now = new Date();
      const prev = now.setDate(now.getDate() - 1);
      return date.getTime() < prev;
    },

    disabledDateEnd(date) {
      const now = new Date(this.form.date1);
      const prev = now.setDate(now.getDate() - 1);
      return date.getTime() < prev;
    },

    cleanFormPic() {
      this.formPic.name = "";
      this.formPic.status = null;
      this.formPic.phone = null;
      this.formPic.email = "";
      this.formPic.customer_id =  null;
    },

    getStatus(event) {
      const status = event.target.value;
      this.formPic.status = status;
    },

    submitDataPic() {
      const payload = {
        name: this.formPic.name,
        status: this.formPic.status,
        phone: this.formPic.phone,
        email: this.formPic.email,
        employee_id: this.formPic.employee_id,
        company_id: this.formPic.customer_id,
      }
      this.doAddClientPIC(payload)
        .then((response) => {
          alert('Created Success');
          console.log(response);
          this.add_pic = false;
          this.cleanFormPic();
          this.fetchCompanyData();
        })
        .catch((err) => {
          alert('failed');
          console.log(err);
        })
    },

    handleChangeCompany() {},

    handleSearchProject() {
      this.fetchEmployeeProjects();
    },

    handleConfirmCreateProject() {
      this.createProjectDialog = false;
      this.state2 = this.search_project;
      this.form.state2 = this.search_project;
    },

    resetInitData() {
      this.form.state1 = "";
      this.form.state2 = "";
      this.form.date1 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.form.date2 = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.form.value = 0;
      this.value_text = "IDR 0";
      this.form.quantity = 0;
      this.search_project = "";
      this.selectedCompany = {};
      this.selectedProject = {};
      this.state1 = "";
      this.state2 = "";
    },

    handleChangeType() {
      this.noteData;
      this.resetInitData();
    },

    fetchCompanyData() {
      this.fetchEmployeeCompany().then((response) => {
        this.companies = response;
      });
    },

    fetchEmployeeProjects() {
      if (this.type == "Lead") {
        this.disabled_confirm_create_project = true;
        let payload = {
          project_name: this.search_project,
          company_id: this.form.state1,
        };
        this.fetchProjectCompany(payload).then((response) => {
          this.projects = response;
          this.disable_project = false;
          if (response.length <= 0) {
            this.disabled_confirm_create_project = false;
          }
        });
      } else {
        let payload = {
          project_name: this.selectedCompany.name,
          employee_id: this.getUserLoginData.id,
          status_project: "active",
          page: 1,
          target_status: null,
        };
        this.fetchEmployeeProject(payload).then((response) => {
          this.projects = response;
          this.disable_project = false;
        });
      }
    },

    querySearch(query, cb) {
      var companies = this.companies;
      var results = query
        ? companies.filter(this.createFilter(query))
        : companies;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.name}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilter(queryString) {
      return (company) => {
        return (
          company.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelect(item) {
      const length = item.detail.detail.length;
      this.formPic.customer_id = item.detail.id;
      if(length == 0) {
        this.add_pic = true;
      }
      console.log(item);
      this.search_project = "";
      this.disable_project = true;
      this.selectedCompany = item.detail;
      this.form.state1 = this.selectedCompany.id;
      this.fetchEmployeeProjects();
    },

    handleSelectProject() {
      this.createProjectDialog = true;
    },

    querySearchProject(query, cb) {
      var projects = this.projects;
      var results = query
        ? projects.filter(this.createFilterProject(query))
        : projects;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.project.job} - ${result.project.progress}%`,
          detail: result,
        });
      });
      if (res.length == 0) {
        this.disable_project = true;
      }
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilterProject(queryString) {
      return (project) => {
        return (
          project.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelectProjectPipeline(item) {
      this.selectedProject = item.detail.project;
      this.form.state2 = this.selectedProject.job;
      this.form.value = this.selectedProject.value_target;
      this.form.quantity = this.selectedProject.quantity_target;
      this.form.profit = this.selectedProject.profit_target;
      this.form.date1 = this.selectedProject.start_date;
      this.form.date2 = this.selectedProject.end_date;
      this.value_text = "IDR " + numeral(this.form.value).format("0,0");
      this.profit_text = "IDR " + numeral(this.form.profit).format("0,0");
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },

    handleAddLeadPipeline() {
      let payload;
      const val_text = this.value_text.split(" ")[1];
      const val = val_text.split(",").join("");
      this.form.value = parseInt(val);

      const prof_text = this.profit_text.split(" ")[1];
      const prof = prof_text.split(",").join("");
      this.form.profit = parseInt(prof);
      if (this.form.state1 == "" && this.form.state2 == "") {
        return Swal.fire({
          title: `Oopss!`,
          text: `Company/Project Name is required!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        });
      } else {
        if (this.type == "Lead") {
          payload = {
            company_id: this.form.state1,
            project_name: this.form.state2,
            value_target: this.form.value,
            quantity_target: this.form.quantity,
            start_date: this.form.date1,
            end_date: this.form.date2,
            note: this.form.notes,
          };

          this.doCreateLeadPipeline(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Success create ${this.type}!`,
                showConfirmButton: false,
                timer: 3000,
              }).then((result) => {
                const url = "/" + this.getRoute() + "/master-data/projects";
                this.$router.push(url);
                this.resetInitData();
              });
            })
            .catch((error) => {
              Swal.fire({
                title: `Oopss! Something went wrong!`,
                text: `Error: ${error.message}`,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "OK",
              });
            });
        } else {
          payload = {
            project_id: this.selectedProject.number,
            project_name: this.form.state2,
            quantity_target: this.form.quantity,
            value_target: this.form.value,
            profit_target: this.form.profit,
            end_target_date: this.form.date2,
          };
          if (this.form.value == 0 && this.form.profit == 0) {
            return Swal.fire({
              title: `Oopss!`,
              text: `Pipeline value & profit can't be 0!`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: "OK",
            });
          }
          this.doUpdatePipeline(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Success create ${this.type}!`,
                showConfirmButton: false,
                timer: 3000,
              }).then((result) => {
                const url = "/" + this.getRoute() + "/master-data/projects";
                this.$router.push(url);
                this.resetInitData();
              });
            })
            .catch((error) => {
              Swal.fire({
                title: `Oopss! Something went wrong!`,
                text: `Error: ${error}`,
                showDenyButton: false,
                showCancelButton: false,
                confirmButtonText: "OK",
              });
            });
        }
      }
    },
  },
  mounted() {
    this.fetchCompanyData();
    this.noteData;
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
    noteData() {
      if (this.type == "Lead") {
        this.form.notes = "I just create a Lead.";
      } else {
        this.form.notes = "I just create a Pipeline.";
      }
    },
  },

  watch: {
    state1(val) {
      if (val.length == 0) {
        this.resetInitData();
        this.disable_project = true;
      }
    },

    value_text(val) {
      this.value_text = "IDR " + numeral(val).format("0,0");
    },

    profit_text(val) {
      this.profit_text = "IDR " + numeral(val).format("0,0");
    },
  },
};
</script>
