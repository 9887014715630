import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import PrivacyPolicy from "../views/privasi-policy.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import Permission from "../views/Permission.vue";
import ProfileNonSales from "@/views/GooSales/ProfileNonSales.vue";

import QRPage from "@/views/QRPage.vue";

// HRD Route
import GoHRD from "../views/GoHRD/BasePage.vue";
import DivisiHRD from "@/views/GoHRD/Divisi.vue";
import PositionHRD from "@/views/GoHRD/Position.vue";
import EmployeeHRD from "@/views/GoHRD/Employee.vue";
import AttendanceHRD from "../views/GoHRD/Attendance.vue";
import AttendanceRequestHRD from "../views/GoHRD/AttendanceRequest.vue";
import MealAllowanceHRD from "../views/GoHRD/MealAllowance.vue";
import OfficeHRD from "../views/GoHRD/Office.vue";
import AddOfficeHRD from "../views/GoHRD/AddOffice.vue";

// GooSales Route
import GooSales from "../views/GooSales/BasePage.vue";
import EmployeeSales from "@/views/GooSales/Employee.vue";
import CustomerSales from "@/views/GooSales/Customer.vue";
import ProfileSales from "@/views/GooSales/Profile.vue";
import StatisticsSales from "@/views/GooSales/Statistics.vue";
import CallActivitySales from "@/views/GooSales/activity/Call.vue";
import AttendanceSales from "@/views/GooSales/Attendance.vue";
import PipelineSales from "@/views/GooSales/Pipeline.vue";
import ProjectsSales from "@/views/GooSales/Projects.vue";
import AttendanceSalesById from "@/views/GooSales/components/PresenceRequestCard.vue";
import AttendanceRequestSales from "@/views/GooSales/AttendanceRequest.vue";
import CloseDealSales from "@/views/GooSales/CloseDeal.vue";
import TrackSOSales from "@/views/GooSales/TrackSO.vue";
import AddDocumentSales from "@/views/GooSales/AddDocument.vue";
import TeamKPISales from "@/views/GooSales/TeamKPI.vue";
import RecentTeamActivitySales from "@/views/GooSales/RecentTeamActivity.vue";
import WhistleBlowerSales from "@/views/GooSales/WhistleBlower.vue";
import ReviewsSales from "@/views/GooSales/Reviews.vue";
import ArrivalDetailSales from "@/views/GooSales/ArrivalDetail.vue";
import ProfitRankSales from "@/views/GooSales/ProfitRank.vue";
import MonthlyTargetSales from "@/views/GooSales/MonthlyTarget.vue";
import AddClientLocationSales from "@/views/GooSales/AddClientLocation.vue";

// Administrator Route
import GooAdministrator from "../views/GooAdministrator/BasePage.vue";
import AppsAdministrator from "../views/GooAdministrator/Apps.vue";
import MenuAdministrator from "../views/GooAdministrator/Menu.vue";
import EmployeeAdministrator from "../views/GooAdministrator/Employee.vue";
import PermissionAdministrator from "../views/GooAdministrator/Permission.vue";
// import UserHRD from "@/views/GooAdministrator/components/User.vue";

// GooPro Route
import GooPro from "../views/GooPro/BasePage.vue";
import BoardsGooPro from "../views/GooPro/Boards.vue";
import KanbanGooPro from "../views/GooPro/Kanbans.vue";
import BoardsTransGooPro from "../views/GooPro/BoardsTrans.vue";
import KanbanTransGooPro from "../views/GooPro/KanbanTrans.vue";
import DigitalReportingGooPro from "../views/GooPro/reporting/index.vue";
import ReportExcelGooPro from "../views/GooPro/reporting/export-excel.vue";
import UsersGooPro from "../views/GooPro/Users.vue";
import UserActivity from "../views/GooPro/userActivity.vue";
import NewTasksGooPro from "../views/GooPro/NewTasks.vue";
import NewProjectGooPro from "../views/GooPro/NewProject.vue";

import SoReportingGooPro from "../views/GooPro/reporting/so-reporting.vue";
import PoReportingGooPro from "../views/GooPro/reporting/po-reporting.vue";
import PbReportingGooPro from "../views/GooPro/reporting/pb-reporting.vue";
import SiReportingGooPro from "../views/GooPro/reporting/si-reporting.vue";
import EmployeeTasksReportingGooPro from "../views/GooPro/reporting/employee-tasks-reporting.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Permission,
  },
  {
    path: "/permission",
    name: "Permisson",
    component: Permission,
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
  },
  // {
  //   path: "/check",
  //   name: "QRPage",
  //   component: QRPage,
  // },

  // Sales
  {
    path: "/2",
    name: "GooSales",
    component: GooSales,
    children: [
      {
        path: "profile",
        name: "ProfileSales",
        component: ProfileSales,
      },
      {
        path: "master-data/employee",
        name: "Employee",
        component: EmployeeSales,
      },
      {
        path: "master-data/customer",
        name: "Customer",
        component: CustomerSales,
      },
      {
        path: "master-data/customer/add-location",
        name: "AddClientLocation",
        component: AddClientLocationSales,
      },
      {
        path: "master-data/projects",
        name: "Projects",
        component: ProjectsSales,
      },
      // MATRICS
      {
        path: "matrics/statistics",
        name: "Statistic",
        component: StatisticsSales,
      },
      {
        path: "matrics/statistics/arrival-detail",
        name: "Arrival Detail",
        component: ArrivalDetailSales,
      },
      // ACTIVITY
      {
        path: "activity/call",
        name: "Call Activity",
        component: CallActivitySales,
      },
      {
        path: "activity/visit",
        name: "Visit Activity",
        component: CallActivitySales,
      },
      {
        path: "activity/email",
        name: "Email Activity",
        component: CallActivitySales,
      },
      {
        path: "activity/online-meeting",
        name: "Online Meeting Activity",
        component: CallActivitySales,
      },
      {
        path: "activity/attendance",
        name: "Attendance",
        component: AttendanceSales,
      },
      {
        path: "activity/whistle-blower",
        name: "WhistleBlowerSales",
        component: WhistleBlowerSales,
      },
      // Transaksi
      {
        path: "transaksi/attendance/request",
        name: "Attendance ID",
        component: AttendanceSalesById,
      },
      {
        path: "transaksi/presence-request",
        name: "Attendance Requests",
        component: AttendanceRequestSales,
      },
      {
        path: "transaksi/pipeline",
        name: "PipelineSales",
        component: PipelineSales,
      },
      {
        path: "transaksi/close-deal",
        name: "CloseDealSales",
        component: CloseDealSales,
      },
      {
        path: "transaksi/track-so",
        name: "TrackSOSales",
        component: TrackSOSales,
      },
      {
        path: "transaksi/add-document",
        name: "AddDocumentSales",
        component: AddDocumentSales,
      },
      // REPORT
      {
        path: "report/team-kpi",
        name: "TeamKPISales",
        component: TeamKPISales,
      },
      {
        path: "report/recent-team-activity",
        name: "RecentTeamActivitySales",
        component: RecentTeamActivitySales,
      },
      {
        path: "report/ratings",
        name: "ReviewsSales",
        component: ReviewsSales,
      },
      {
        path: "report/profit-rank",
        name: "ProfitRankSales",
        component: ProfitRankSales,
      },
      {
        path: "report/monthly-targets",
        name: "MonthlyTargetSales",
        component: MonthlyTargetSales,
      },
    ],
  },

  // Administrator
  {
    path: "/T0NHY",
    name: "GooAdministrator",
    component: GooAdministrator,
    children: [
      {
        path: "master-data/employee",
        component: EmployeeAdministrator,
      },
      {
        path: "master-data/apps",
        component: AppsAdministrator,
      },
      {
        path: "master-data/menu",
        component: MenuAdministrator,
      },
      // TRANSAKSI
      {
        path: "transaksi/permission",
        component: PermissionAdministrator,
      },
    ],
  },

  // Goo Pro
  {
    path: "/GQGFX",
    name: "GooPro",
    component: GooPro,
    children: [
      {
        path: "master-data/boards",
        component: BoardsGooPro,
      },
      {
        path: "master-data/kanban",
        component: KanbanGooPro,
      },
      {
        path: "transaksi/boards",
        component: BoardsTransGooPro,
      },
      {
        path: "transaksi/kanbans/:id",
        name: "Kanban Transaction",
        component: KanbanTransGooPro,
      },
      {
        path: "report/digital-reporting",
        name: "Digital Reporting",
        component: DigitalReportingGooPro,
      },
      {
        path: "report/so-reporting",
        name: "SO Reporting",
        component: SoReportingGooPro,
      },
      {
        path: "report/po-reporting",
        name: "PO Reporting",
        component: PoReportingGooPro,
      },
      {
        path: "report/pb-reporting",
        name: "PB Reporting",
        component: PbReportingGooPro,
      },
      {
        path: "report/si-reporting",
        name: "SI Reporting",
        component: SiReportingGooPro,
      },
      {
        path: "report/employee-tasks-reporting",
        name: "Employee Tasks Reporting",
        component: EmployeeTasksReportingGooPro,
      },
      {
        path: "master-data/user",
        name: "Users",
        component: UsersGooPro,
      },
      {
        path: "activity/log-activity",
        name: "Users Activity",
        component: UserActivity,
      },
      {
        path: "transaksi/new-tasks",
        name: "New Tasks",
        component: NewTasksGooPro,
      },
      {
        path: "report/report-excel",
        name: "Report Excel",
        component: ReportExcelGooPro,
      },
      {
        path: "transaksi/new-project",
        name: "New Project",
        component: NewProjectGooPro,
      },
      {
        path: "transaksi/new-board",
        name: "New Board",
        component: NewProjectGooPro,
      },
    ],
  },

  // HRD
  {
    path: "/EDX09",
    name: "GoHRD",
    component: GoHRD,
    children: [
      {
        path: "master-data/divisi",
        name: "DivisiHRD",
        component: DivisiHRD,
      },
      {
        path: "master-data/position",
        name: "PositionHRD",
        component: PositionHRD,
      },
      {
        path: "master-data/employee",
        name: "EmployeeHRD",
        component: EmployeeHRD,
      },
      {
        path: "master-data/office-location",
        name: "OfficeHRD",
        component: OfficeHRD,
      },
      {
        path: "master-data/office-location/add",
        name: "AddOfficeHRD",
        component: AddOfficeHRD,
      },
      {
        path: "profile",
        name: "ProfileNonSales",
        component: ProfileNonSales,
      },
      {
        path: "transaksi/attendance",
        name: "AttendanceHRD",
        component: AttendanceHRD,
      },
      {
        path: "transaksi/attendance-request",
        name: "AttendanceRequestHRD",
        component: AttendanceRequestHRD,
      },
      {
        path: "report/meal-allowance",
        name: "MealAllowanceHRD",
        component: MealAllowanceHRD,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;
