// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";
import axios from "axios";

const ax = axios.create({
  baseURL: process.env.VUE_APP_BE_URL,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export default {
  namespaced: true,
  state: {
    apps: [],
    menu: [],
    user: [],
  },
  getters: {},
  actions: {
    fetchApps({ commit }, body) {
      const params = [`search=${body.search}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`apps?${params}`).then(
          (response) => {
            commit("SET_APPS_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchUserApps({ commit }, body) {
      const params = [`apps=${body.apps}`, `id_user=${body.id_user}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`permission/apps?${params}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doCreateApp({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`apps/create`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doUpdateApp({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`apps/update`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doDeleteApp({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`apps/delete`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchMenu({ commit }, body) {
      const params = [`search=${body.search}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`menu?${params}`).then(
          (response) => {
            commit("SET_MENU_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchMenuByApps({ commit }, body) {
      const params = [`apps=${body.apps}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`menu/menusub?${params}`).then(
          (response) => {
            commit("SET_MENU_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchMenuByAppsAll({ commit }, body) {
      const params = [`apps=${body.apps}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`menu/menusub/all?${params}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchUserMenuPermission({ commit }, body) {
      const params = [`id_user_apps=${body.id_user_apps}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`permission/menu?${params}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchUser({ commit }, body) {
      const params = [`search=${body.search}`].join("&");
      return new Promise((resolve, reject) => {
        ax.get(`user?${params}`).then(
          (response) => {
            commit("SET_USER_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doAddAppPermission({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`permission/insert-user-apps`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doRevokeAppPermission({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`permission/deactive/${body}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doAddMenuPermission({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`permission/menu`, body).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    doRevokePrevMenuPermission({ commit }, body) {
      return new Promise((resolve, reject) => {
        ax.post(`permission/menu/delete?id_user_apps=${body}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },

    fetchUserOffice({ commit }) {
      return new Promise((resolve, reject) => {
        ax.get(`user/office`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            reject(error.data);
          }
        );
      });
    },
  },
  mutations: {
    SET_APPS_DATA(state, data) {
      state.apps = data;
    },
    SET_MENU_DATA(state, data) {
      state.menu = data;
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    },
  },
};
