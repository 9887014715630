<template>
  <div class="row">
    <Card :title="'Track SO'">
      <template #body>
        <div>
          <div class="row">
            <div class="col-12">
              <argon-input
                type="text"
                placeholder="Search by SO Number..."
                name="search"
                size="lg"
                :value="search"
                v-model="search"
              />
            </div>
          </div>
          <div
            class="loading d-flex justify-content-center mt-3"
            v-if="loading_employee"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <el-scrollbar height="400px">
              <div style="margin: 10px">
                <div class="mb-3" v-for="data in so_data" :key="data.id">
                  <div class="card mb-2">
                    <div class="card-header">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <div class="d-flex">
                            <h3 style="margin-right: 20px">
                              <span class="badge bg-info"
                                ><i class="fas fa-chart-line"></i
                              ></span>
                            </h3>
                            <div class="d-flex flex-column">
                              <h6>{{ data.so_number }}</h6>
                              <span class="text-xxs">
                                {{ data.customer.name }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <argon-button
                            color="info"
                            size="md"
                            class="ms-auto"
                            @click="handleClickDetails(data)"
                          >
                            See Details
                          </argon-button>
                        </div>
                      </div>
                    </div>

                    <div
                      class="card-body"
                      style="
                        background-color: #efefef;
                        margin: 0 16px 16px 16px;
                        border-radius: 16px;
                      "
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="d-flex flex-column">
                          <h6>{{ data.histories[0].status }}</h6>
                          <span class="text-xxs">
                            {{
                              formatDate(
                                data.histories[0].created_at,
                                "datetime"
                              )
                            }}
                          </span>
                        </div>
                        <div>
                          <h3>
                            <span class="badge bg-success"
                              ><i class="fas fa-check"></i
                            ></span>
                          </h3>
                        </div>
                      </div>

                      <div>
                        <el-collapse @change="handleChange" class="mt-3">
                          <el-collapse-item
                            title="Timeline Details"
                            name="{{ data.id }}"
                          >
                            <div>
                              <el-timeline>
                                <el-timeline-item
                                  v-for="(history, index) in data.histories"
                                  :key="index"
                                  :timestamp="
                                    formatDate(history.created_at, 'datetime')
                                  "
                                >
                                  {{ history.status }}
                                </el-timeline-item>
                              </el-timeline>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </template>
    </Card>

    <!-- tracking_so_dialog -->
    <el-dialog
      v-model="tracking_so_dialog"
      title="SO DETAIL"
      width="50%"
      :show-close="false"
    >
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <!-- Summary -->
        <el-tab-pane label="Summary" name="first">
          <div class="bg-info-soft mb-3">
            <h6 class="text-center">{{ so_detail.customer.name }}</h6>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Sales </span>
              <span class="text-md text-bold text-end">
                <span v-if="selected_so_data.user != null">
                  {{ selected_so_data.user.name }}
                </span>
                <span v-else> - </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Team </span>
              <span class="text-md text-bold text-end">
                <span v-if="selected_so_data.user != null">
                  {{ selected_so_data.user.team.name }}
                </span>
                <span v-else> - </span>
              </span>
            </div>
            <div v-if="so_detail.po_img">
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md"> Attachment </span>
                <span class="text-md text-bold text-end">
                  <i
                    class="fas fa-link cursor-pointer"
                    @click="handleOpenAttachment(so_detail.po_img)"
                  ></i>
                </span>
              </div>
            </div>
          </div>
          <div
            class="bg-grey-soft mb-3"
            v-for="(product, index) in so_detail.purchaseorders"
            :key="index"
          >
            <h6 class="text-center">{{ product.product }}</h6>
            <hr class="horizontal dark" />
            <div class="bg-info-soft mb-3">
              <div class="d-flex justify-content-between">
                <span class="text-md text-bold"> PO ID </span>
                <span class="text-md text-bold text-end"> Quantity </span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="text-md"> {{ product.po_number }} </span>
                <span class="text-md text-end">
                  {{ product.qty }}
                </span>
              </div>
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md text-bold"> ETA </span>
                <span class="text-md text-bold text-end"> Created at </span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="text-md">
                  {{ formatDate(product.eta, "date") }}
                </span>
                <span class="text-md text-end">
                  {{ formatDate(product.created_at, "date") }}
                </span>
              </div>
            </div>

            <div class="bg-green-soft mb-3">
              <div class="d-flex justify-content-between">
                <span class="text-md text-bold"> Arrival Note </span>
                <span class="text-md text-bold text-end"> Received Qty </span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="text-md"> {{ product.arrival_note }} </span>
                <span class="text-md text-end">
                  {{ product.received_qty }}
                </span>
              </div>
            </div>

            <div class="bg-green-soft mb-3">
              <div class="d-flex justify-content-between">
                <span class="text-md text-bold"> QC Note </span>
                <span class="text-md text-bold text-end"> QC Qty </span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="text-md"> {{ product.qc_note }} </span>
                <span class="text-md text-end">
                  {{ product.qc_qty }}
                </span>
              </div>
            </div>

            <div class="bg-green-soft mb-3">
              <div class="d-flex justify-content-between">
                <span class="text-md text-bold"> Delivery Note </span>
                <span class="text-md text-bold text-end"> Delivery Qty </span>
              </div>
              <div class="d-flex justify-content-between">
                <span class="text-md"> {{ product.delivery_note }} </span>
                <span class="text-md text-end">
                  {{ product.delivery_qty }}
                </span>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!-- SO PO -->
        <el-tab-pane label="SO & PO" name="second">
          <div class="bg-info-soft mb-3">
            <div class="d-flex justify-content-between">
              <span class="text-md"> SO </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.so_number }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Note </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.note }}
                </span>
              </span>
            </div>
          </div>

          <div class="bg-info-soft">
            <h6 class="text-center">PO</h6>
            <div class="d-flex justify-content-between">
              <span class="text-md"> Customer PO ID </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.customer_po_number }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Note </span>
              <span class="text-md text-bold text-end">
                <span
                  v-if="
                    so_detail.po_note == 'Sumber data tidak ada dari erasoft'
                  "
                >
                  -
                </span>
                <span v-else>
                  {{ so_detail.po_note }}
                </span>
              </span>
            </div>
            <div v-if="so_detail.po_img">
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md"> Attachment </span>
                <span class="text-md text-bold text-end">
                  <span>
                    <i
                      class="fas fa-link cursor-pointer"
                      @click="handleOpenAttachment(so_detail.po_img)"
                    ></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!-- QC & Delivery -->
        <el-tab-pane label="QC & Delivery" name="third">
          <div class="bg-info-soft mb-3">
            <h6 class="text-center">QC</h6>
            <div class="d-flex justify-content-between">
              <span class="text-md"> Note </span>
              <span class="text-md text-bold text-end">
                <span
                  v-if="
                    so_detail.qc_note == 'Sumber data tidak ada dari erasoft'
                  "
                >
                  -
                </span>
                <span v-else>
                  {{ so_detail.qc_note }}
                </span>
              </span>
            </div>
          </div>
          <div class="bg-info-soft">
            <h6 class="text-center">Delivery</h6>
            <hr class="horizontal dark" />
            <div v-if="so_detail.delivery">
              <div class="d-flex justify-content-between">
                <span class="text-md"> ID </span>
                <span class="text-md text-bold text-end">
                  <span>
                    {{ so_detail.delivery.do_number }}
                  </span>
                </span>
              </div>
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md"> Address </span>
                <span class="text-md text-bold text-end">
                  <span> {{ so_detail.delivery.address }} </span>
                </span>
              </div>
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md"> PIC </span>
                <span class="text-md text-bold text-end">
                  <span> {{ so_detail.delivery.pic_name }} </span>
                </span>
              </div>
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md"> PIC Phone </span>
                <span class="text-md text-bold text-end">
                  <span> {{ so_detail.delivery.pic_phone }} </span>
                </span>
              </div>
              <hr class="horizontal dark" />
              <div class="d-flex justify-content-between">
                <span class="text-md"> Date </span>
                <span class="text-md text-bold text-end">
                  <span>
                    {{ formatDate(so_detail.delivery.date, "date") }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </el-tab-pane>

        <!-- INVOICE -->
        <el-tab-pane label="Invoice" name="fourth">
          <div class="bg-info-soft">
            <h6 class="text-center">Invoice</h6>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> ID </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.delivery ? so_detail.delivery.do_number : "-" }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> TAX ID </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{
                    so_detail.invoice
                      ? so_detail.invoice.tax_invoice_number
                      : "-"
                  }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Invoice Note </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.invoice_note ? so_detail.invoice_note : "-" }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Address </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.invoice ? so_detail.invoice.address : "-" }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> PIC </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.invoice ? so_detail.invoice.pic_name : "-" }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> PIC Division </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.invoice ? so_detail.invoice.pic_division : "-" }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Giro Term </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ so_detail.invoice ? so_detail.invoice.giro_term : "-" }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Docs </span>
              <span class="text-md text-bold text-end">
                <span>
                  <i
                    class="fas fa-link cursor-pointer"
                    @click="handleOpenAttachment(so_detail.invoice.invoice_img)"
                  ></i>
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> Created at </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{
                    so_detail.invoice
                      ? formatDate(so_detail.invoice.invoice_date, "date")
                      : "-"
                  }}
                </span>
              </span>
            </div>
          </div>
        </el-tab-pane>

        <!-- ETD & TOP -->
        <el-tab-pane label="ETD & TOP" name="fifth">
          <div class="bg-info-soft mb-3">
            <div class="d-flex justify-content-between">
              <span class="text-md"> ETD </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ formatDate(so_detail.etd, "date") }}
                </span>
              </span>
            </div>
            <hr class="horizontal dark" />
            <div class="d-flex justify-content-between">
              <span class="text-md"> TOP </span>
              <span class="text-md text-bold text-end">
                <span>
                  {{ formatDate(so_detail.top, "date") }}
                </span>
              </span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeHandleDetailDialog()">Close</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

import { format } from "date-fns";

export default {
  name: "Employee",
  data() {
    return {
      search: "",
      tracking_so_dialog: false,
      loading_employee: false,
      timeline_token: null,
      username: "",
      search: "",
      page: 1,
      so_data: [],
      selected_so_data: {},
      so_detail: {},
      activeName: "first",
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
  },
  mounted() {
    this.getToken();
  },
  beforeMount() {},
  methods: {
    ...mapActions({
      fetchUserEraforceAuth: "eraforce/fetchUserEraforceAuth",
      getTimelineToken: "eraforce/getTimelineToken",
      getTimelineData: "eraforce/getTimelineData",
      getTimelineDataDetail: "eraforce/getTimelineDataDetail",
    }),
    ...mapMutations({}),

    formatDate(date, type) {
      let dates = date;
      if (type == "datetime") {
        dates = format(new Date(dates), "EEEE, dd MMMM yyyy HH:mm");
      } else if (type == "date") {
        dates = format(new Date(dates), "EEEE, dd MMMM yyyy");
      }
      return dates;
    },

    handleOpenAttachment(link) {
      window.open(link, "_blank");
    },

    getToken() {
      this.getTimelineToken().then((response) => {
        this.timeline_token = response;
        this.getTimelineDataFetch();
        console.log(`get token ${JSON.stringify(response)}`);
      });
    },

    getTimelineDataFetch() {
      this.loading_employee = true;
      const payload = {
        username: this.loginData.full_name,
        search: this.search,
        page: this.page,
        token: this.timeline_token,
      };
      this.getTimelineData(payload).then((response) => {
        this.so_data = response.data;
        this.loading_employee = false;
      });
    },

    getTimelineDetailDataFetch() {},

    async handleClickDetails(data) {
      this.selected_so_data = await data;
      const payload = {
        username: this.loginData.full_name,
        search: this.search,
        page: this.page,
        token: this.timeline_token,
        id: this.selected_so_data.id,
      };
      this.getTimelineDataDetail(payload).then((response) => {
        this.so_detail = response;
        this.tracking_so_dialog = true;
      });
    },

    closeHandleDetailDialog() {
      this.tracking_so_dialog = false;
      // this.selected_so_data = {};
      // this.so_detail = {};
      this.activeName = "first";
    },
  },
  computed: {
    loginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
  },
  watch: {
    search(val) {
      this.getTimelineDataFetch();
    },
  },
};
</script>

<style>
.bg-info-soft {
  background-color: #a5e5fa;
  border-radius: 10px;
  padding: 16px;
}

.bg-grey-soft {
  background-color: #ededed;
  border-radius: 10px;
  padding: 16px;
}

.bg-green-soft {
  background-color: #acfac1;
  border-radius: 10px;
  padding: 16px;
}
</style>
