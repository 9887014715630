<template>
  <el-form :model="form" label-width="150px">
    <el-form-item label="Set Reminder">
      <el-date-picker
        v-model="form.date1"
        type="datetime"
        placeholder="Pick a date"
        style="width: 100%"
        value-format="YYYY-MM-DD HH:mm:ss"
        :disabled-date="disabledDate"
      />
      <small>(Optional)</small>
    </el-form-item>

    <el-form-item label="">
      <el-col>
        <div
          class="d-flex justify-content-between align-items-center align-content-center"
        >
          <div>
            <span v-if="address != ''"> Location: {{ address }} </span>
          </div>
          <div>
            <argon-button
              class=""
              type="button"
              @click="createMap"
              variant="gradient"
              color="info"
              size="md"
            >
              <div>
                <i class="fas fa-map"></i>
              </div>
            </argon-button>
          </div>
        </div>
      </el-col>
    </el-form-item>

    <el-form-item label="Company Name">
      <el-autocomplete
        class="inline-input"
        v-model="state1"
        :fetch-suggestions="querySearch"
        placeholder="Input Company Name"
        clearable
        @select="handleSelect"
        style="width: 100%"
      ></el-autocomplete>
    </el-form-item>
    <el-form-item label="Project Name">
      <el-autocomplete
        class="inline-input"
        v-model="state2"
        :fetch-suggestions="querySearchProject"
        placeholder="Input Project Name"
        clearable
        :disabled="disable_project"
        @select="handleSelectProject"
        style="width: 100%"
      ></el-autocomplete>
    </el-form-item>
    <el-form-item label="" v-if="selectedProject !== null">
      <el-col class="p-0">
        <span class="d-flex justify-content-between">
          <el-badge
            :value="selectedProject.call_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Call</el-button>
          </el-badge>
          <el-badge
            :value="selectedProject.email_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Email</el-button>
          </el-badge>
          <el-badge
            :value="selectedProject.visit_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Visit</el-button>
          </el-badge>
          <el-badge
            :value="selectedProject.online_meeting_number"
            class="item"
            type="danger"
          >
            <el-button type="info">Online Meeting</el-button>
          </el-badge>
        </span>
      </el-col>
    </el-form-item>
    <div v-if="add_estimate">
      <el-form-item label="Expected Finalization">
        <el-date-picker
          v-model="form.date2"
          type="month"
          placeholder="Pick a date"
          style="width: 100%"
          format="YYYY-MM"
        />
      </el-form-item>
      <el-form-item label="Project Value">
        <el-input v-model="form.value" placeholder="Please input value">
          <template #prepend>Rp</template>
        </el-input>
      </el-form-item>
    </div>
    <el-form-item label="Notes">
      <el-input v-model="form.desc" type="textarea" />
    </el-form-item>
    <el-form-item>
      <el-radio-group
        v-for="stage in fetchStagingData"
        :key="stage.id"
        v-model="form.stage"
      >
        <el-radio :label="stage.id">{{ stage.name }}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="Progress">
      <el-col class="p-0">
        <div class="d-flex justify-content-start align-items-center">
          <input
            style="width: 80%"
            type="range"
            placeholder="Input notes"
            @input="actionStep"
            v-model="progress_slide"
            min="0" 
            max="90" 
            step="30"
          />
          <span style="margin-left: 10px">{{ progress_slide }}%</span>
        </div>
        <span style="margin-left: 10px" v-if="selectedProject != null"
          >Last progress: {{ selectedProject.progress }}%</span
        >
      </el-col>
    </el-form-item>

    <el-form-item>
      <input type="file" accept="image/*" @change="toggleUpload" />
    </el-form-item>

    <el-form-item>
      <el-button type="warning" @click="addEstimate" v-if="!add_estimate">
        Add Estimate (Optional)
      </el-button>
      <el-button type="danger" @click="addEstimate" v-else>
        Cancel Estimate
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="onSubmit" class="bg-gradient-success"
        >Create</el-button
      >
      <el-button @click="closeAdd" class="bg-gradient-danger">Cancel</el-button>
    </el-form-item>
  </el-form>

  <!-- Map Dialog -->
  <el-dialog
    v-model="maps_dialog"
    title="Please Pin Location"
    width="50%"
    :before-close="handleClose"
  >
    <div class="map-holder">
      <div id="map" style="width: 100%; height: 50vh"></div>
      <div class="coordinates-header">
        <h3>Current Coordinates</h3>
        <p>Location: {{ location }}</p>
        <p>Address: {{ address }}</p>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="maps_dialog = false" class="bg-gradient-danger"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="confirmPinLocation"
          class="bg-gradient-success"
        >
          Add Location
        </el-button>
      </span>
    </template>
  </el-dialog>


  <!-- Added PIC -->
  <el-dialog
    v-model="add_pic"
    title="Tambah PIC"
    width="40%"
  >
    <span>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Name <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="formPic.name" placeholder="PIC Name">
      </div>
      <!-- <el-form-item label="PIC Name">
        <el-input v-model="formPic.name" type="text" />
      </el-form-item> -->
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Email <span class="text-danger">*</span></label>
        <input type="text" class="form-control" v-model="formPic.email" placeholder="PIC Email">
      </div>
      <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">PIC Phone <span class="text-danger">*</span></label>
        <input type="number" class="form-control" v-model="formPic.phone" placeholder="PIC Phone">
        <input type="number" class="form-control" hidden v-model="formPic.employee_id" placeholder="PIC Phone">
      </div>
    </span>
    <select class="form-select" aria-label="Default select example" @change="getStatus($event)">
      <option selected disabled>Choose Status</option>
      <option value="1">Manager</option>
      <option value="2">Assistant Manager</option>
      <option value="3">Supervisor</option>
      <option value="4">Sales Admin</option>
      <option value="5">Account Manager</option>
      <option value="6">Outsource</option>
    </select>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="success"
          class="bg-gradient-success"
          @click="submitDataPic()"
        >
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <!-- End -->
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { format } from "date-fns";
import Swal from "sweetalert2";
import ArgonButton from "@/components/ArgonButton.vue";

import { CircleCheckFilled, CircleClose } from "@element-plus/icons-vue";

import axios from "axios";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import ArgonInput from "@/components/ArgonInput.vue";

export default {
  data() {
    return {
      form: {
        date1: "",
        date2: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        desc: "I just create an activity",
        stage: "",
        state1: "",
        state2: "",
        progress_percent: 0,
        progress_star: 0,
        value: 0.0,
        upload: "",
      },

      formPic: {
        name: "",
        status: null,
        phone: null,
        email: "",
        employee_id: this.$store.state.eraforce.user_data.employee_id,
        customer_id: null,
      },
      add_pic: false,
      selectSlider: [30, 60, 100],
      progress_slide: 0,
      fileList: [],
      disable_project: true,
      state1: "",
      state2: "",
      companies: [],
      projects: [],
      selectedCompany: {},
      selectedProject: null,
      add_estimate: false,
      icons: [CircleCheckFilled, CircleCheckFilled, CircleCheckFilled],
      maps_dialog: false,
      location: "",
      address: "",
      // center: [0, 0],
      center: [106.8156433, -6.1640701], //longitude, latitude -> mapbox format
      map: {},
      access_token:
        "pk.eyJ1IjoiZ3JhY2VmaXJnaWEiLCJhIjoiY2xiYTVvMDZtMG43NzNwbWd1MXJpaXFybSJ9.7LtADeY0rjF_kx3VWU8I_Q",
    };
  },
  components: {
    ArgonButton,
    ArgonInput,
  },
  mounted() {
    this.companies = this.fetchEmployeeCompanyData;
    this.fetchStaging();
    this.activityName;
    // this.createMap();
  },

  methods: {
    ...mapActions({
      fetchStaging: "eraforce/fetchStaging",
      fetchEmployeeProject: "eraforce/fetchEmployeeProject",
      doVisitActivity: "eraforce/doVisitActivity",
      fetchAllActivity: "eraforce/fetchAllActivity",
      doAddClientPIC: "eraforce/doAddClientPIC",
      fetchEmployeeCompany: "eraforce/fetchEmployeeCompany",
    }),
    ...mapMutations({
      SET_ACTIVITY_MODAL_NOT_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_NOT_ACTIVE",
      SET_ACTIVITY_MODAL_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_ACTIVE",
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
      SELECTED_ACTIVITY_COORDINATE: "eraforce/SELECTED_ACTIVITY_COORDINATE",
    }),

    disabledDate(date) {
      const now = new Date();
      const prev = now.setDate(now.getDate() - 1);
      return date.getTime() < prev;
    },

    toggleUpload(uploadFile) {
      this.form.upload = uploadFile.target.files[0];
    },

    thisFetchDataEmployee() {
      this.fetchEmployeeCompany()
        .then((response) => {
          console.log('GET EMPLOYEE WORKS');
          this.companies = response;
          console.log(response);
        })
        .catch((err) => {
          console.log('FAILED');
        })
    },

    actionStep() {
      if (this.progress_slide == 60) {
        this.progress_slide = 70;
      }
      if(this.progress_slide == 90) {
        this.progress_slide = 100;
      }  
    },

    submitDataPic() {
      const payload = {
        name: this.formPic.name,
        status: this.formPic.status,
        phone: this.formPic.phone,
        email: this.formPic.email,
        employee_id: this.formPic.employee_id,
        company_id: this.formPic.customer_id,
      }
      this.doAddClientPIC(payload)
        .then((response) => {
          alert('Created Success');
          console.log(response);
          this.add_pic = false;
          this.cleanFormPic();
          this.thisFetchDataEmployee();
        })
        .catch((err) => {
          alert('failed');
          console.log(err);
        })
    },

    cleanFormPic() {
      this.formPic.name = "";
      this.formPic.status = null;
      this.formPic.phone = null;
      this.formPic.email = "";
      this.formPic.customer_id =  null;
    },

    getStatus(event) {
      const status = event.target.value;
      this.formPic.status = status;
    },
    
    openMap() {
      this.maps_dialog = true;
    },

    confirmPinLocation() {
      this.SET_ACTIVITY_MODAL_NOT_ACTIVE();
      Swal.fire({
        title: "Do you want to save the changes?",
        text: `Location: ${this.location}`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Confirm",
        denyButtonText: `Cancel`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // Swal.fire("Location Added!", "", "success");
          this.maps_dialog = false;
          this.SET_ACTIVITY_MODAL_ACTIVE();
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
          this.SET_ACTIVITY_MODAL_ACTIVE();
        }
      });
    },

    async createMap() {
      try {
        await this.openMap();
        this.getLocation();
        mapboxgl.accessToken = await this.access_token;
        // console.log(`token ${this.$store.state.eraforce.token_maps}`);
        this.map = new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: this.center,
          zoom: 11,
        });

        let geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false,
        });

        this.map.addControl(geocoder);

        let marker = new mapboxgl.Marker({
          draggable: true,
          color: "#D80739",
        })
          .setLngLat(this.center)
          .addTo(this.map);

        geocoder.on("result", (e) => {
          marker.remove();
          this.center = e.result.center;
          marker = new mapboxgl.Marker({
            draggable: true,
            color: "#D80739",
          })
            .setLngLat(this.center)
            .addTo(this.map);
          this.location = e.result.text;
          this.address = e.result.place_name;
          marker.on("dragend", (e) => {
            this.center = Object.values(e.target.getLngLat());
            this.getLocation();
          });
        });
      } catch (err) {
        console.log("map error", err);
      }
    },

    async getLocation() {
      try {
        this.loading = true;
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.center[0]},${this.center[1]}.json?access_token=${this.access_token}`
        );
        // console.log(`response api ${JSON.stringify(response)}`);
        this.loading = false;
        this.location = response.data.features[0].text;
        this.address = response.data.features[0].place_name;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    fetchEmployeeProjects() {
      let payload = {
        project_name: this.selectedCompany.name,
        employee_id: this.getUserLoginData.id,
        status_project: "active",
        page: 1,
        target_status: null,
      };
      this.fetchEmployeeProject(payload).then((response) => {
        this.projects = response;
        this.disable_project = false;
      });
    },

    closeAdd() {
      this.selectedProject = null;
      this.selectedCompany = {};
      this.SET_ACTIVITY_MODAL_NOT_ACTIVE();
    },

    querySearch(query, cb) {
      var companies = this.companies;
      var results = query
        ? companies.filter(this.createFilter(query))
        : companies;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.name}`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);

      //   cb(results);
    },

    createFilter(queryString) {
      return (company) => {
        return (
          company.name.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelect(item) {
      const length = item.detail.detail.length;

      this.formPic.customer_id = item.detail.id
      console.log(item.detail);

      if(length == 0) {
        this.add_pic = true;
      }

      this.disable_project = true;
      this.selectedCompany = item.detail;
      this.form.state1 = this.selectedCompany.id;

      this.fetchEmployeeProjects();
    },

    querySearchProject(query, cb) {
      var projects = this.projects;
      var results = query
        ? projects.filter(this.createFilterProject(query))
        : projects;
      var res = [];
      results.forEach((result) => {
        res.push({
          value: `${result.project.job} - ${result.project.progress}%`,
          detail: result,
        });
      });
      // call callback function to return suggestions
      cb(res);
    },

    createFilterProject(queryString) {
      return (project) => {
        return (
          project.project.name
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) == 0
        );
      };
    },

    handleSelectProject(item) {
      console.log(item);
      this.selectedProject = item.detail.project;
      this.form.state2 = this.selectedProject.number;
      this.form.stage = this.selectedProject.status;
      this.progress_slide = this.selectedProject.progress;
      this.form.desc =
        this.selectedProject.project_notes ?? "I just create an activity";
    },

    changeProgress() {
      let star = this.form.progress_star;
      let percent = (star / 5) * 100;
      this.form.progress_percent = percent;
    },

    addEstimate() {
      this.add_estimate = !this.add_estimate;
    },

    onSubmit() {
      this.SET_ACTIVITY_MODAL_NOT_ACTIVE();
      if (this.form.state1 == "" || this.form.state1 == null) {
        return Swal.fire({
          title: "Oopss! Company name is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.form.state2 == "" || this.form.state2 == null) {
        return Swal.fire({
          title: "Oopss! Project name is required!",
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.progress_slide == 0 || this.progress_slide == null) {
        return Swal.fire({
          title: `Oopss! Progress is required!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.progress_slide <= this.selectedProject.progress) {
        return Swal.fire({
          title: `Oopss! Progress must higher than ${this.selectedProject.progress}%!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.progress_slide = this.selectedProject.progress;
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      if (this.form.upload == "" || this.form.upload == null) {
        return Swal.fire({
          title: `Oopss! Image is required!`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SET_ACTIVITY_MODAL_ACTIVE();
          }
        });
      }
      const payload = [
        {
          field: "company_id",
          value: this.form.state1,
        },
        {
          field: "note",
          value: this.form.desc,
        },
        {
          field: "employee_id",
          value: this.getUserLoginData.id,
        },
        {
          field: "progress",
          value: this.progress_slide,
        },
        {
          field: "project_id",
          value: this.form.state2,
        },
        {
          field: "reminder_date",
          value: this.form.date1,
        },
        {
          field: "staging_id",
          value: this.form.stage,
        },
        {
          field: "activity_id",
          value: this.$store.state.eraforce.activity_id,
        },
        {
          field: "project_value",
          value: this.form.value,
        },
        {
          field: "expected_date",
          value: this.form.date2,
        },
        {
          field: "latitude",
          value: this.center[1],
        },
        {
          field: "longitude",
          value: this.center[0],
        },
      ];
      let formData = new FormData();
      payload.forEach((data) => {
        formData.append(data.field, data.value);
      });

      formData.append("photo", this.form.upload);
      this.doVisitActivity(formData)
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Success create activity!",
            showConfirmButton: false,
            timer: 3000,
          });
          this.SET_ACTIVITY_CARD_LOADING();
          this.getCallActivity();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops... Failed create activity!",
            text: `Error: ${JSON.stringify(error.message)}`,
            showConfirmButton: true,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.SET_ACTIVITY_MODAL_ACTIVE();
            }
          });
        });
    },

    initForm() {
      this.form = {
        date1: "",
        date2: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        desc: this.activityName,
        stage: "",
        state1: "",
        state2: "",
        progress_percent: 0,
        progress_star: 0,
        value: 0.0,
        upload: "",
      };
      this.progress_slide = 0;
      this.selectedCompany = {};
      this.selectedProject = null;
      this.location = "";
      this.address = "";
      this.state1 = "";
      this.state2 = "";
      this.disable_project = true;
      this.add_estimate = false;
    },

    getCallActivity() {
      const call_payload = {
        activity_id: this.$store.state.eraforce.activity_id || 3, //Call
        employee_id: null,
        first_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        last_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
      };
      this.fetchAllActivity(call_payload).then((response) => {
        this.initForm();
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },
  },

  computed: {
    fetchEmployeeCompanyData() {
      let data = this.$store.state.eraforce.employee_company;
      return data;
    },
    // fetchEmployeeProjectData() {
    //   let data = this.$store.state.eraforce.employee_project;
    //   return data;
    // },
    fetchStagingData() {
      return this.$store.state.eraforce.staging;
    },
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
    getUserData() {
      return this.$store.state.eraforce.user_data;
    },

    activityName() {
      let id_activity = this.$store.state.eraforce.activity_id;
      if (id_activity == 3) {
        this.form.desc = "I just Create Call Activity";
      } else if (id_activity == 4) {
        this.form.desc = "I just Create Visit Activity";
      } else if (id_activity == 5) {
        this.form.desc = "I just Create Email Activity";
      } else if (id_activity == 9) {
        this.form.desc = "I just Create Online Meeting Activity";
      } else {
        this.form.desc = "I just Create Activity";
      }
      return this.form.desc;
    },
  },

  watch: {},
};
</script>

<style scoped>
.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: lightblue;
}
</style>
