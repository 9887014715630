<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold">Divisi</span>
        <div>
          <argon-button
            variant="gradient"
            color="info"
            size="md"
            @click="handleOpenAdd()"
          >
            New Divisi
          </argon-button>
        </div>
      </div>
    </div>
    <div class="card p-3">
      <!-- cella for dev -->
      <!-- <el-table
        :data="getDivisi"
        style="width: 100%"
        height="500"
        v-loading="is_table_loading"
      > -->
      <el-table :data="getDivisi" style="width: 100%" height="500">
        <el-table-column fixed prop="departement" label="Divisi" />
        <el-table-column prop="zip" label="Action" width="150">
          <template #default="scope">
            <div class="d-flex justify-content-center">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="fas fa-caret-down"></i>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="handleOpenEdit(scope.row)">
                      Edit
                    </el-dropdown-item>
                    <el-dropdown-item @click="handleOpenRemove(scope.row)">
                      Deactivate
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- Add Divisi -->
    <el-dialog
      v-model="add_dialog"
      title="Add New Divisi"
      width="30%"
      :before-close="handleCloseAdd"
    >
      <span>
        <el-input
          v-model="form.divisi"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input divisi name"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="handleCreateDivisi"
            class="bg-gradient-success"
            :disabled="form.divisi == null || form.divisi == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Edit Divisi -->
    <el-dialog
      v-model="edit_dialog"
      title="Edit Divisi"
      width="30%"
      :before-close="handleCloseEdit"
    >
      <span>
        <el-input
          v-model="form.divisi"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Input divisi name"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="handleUpdateDivisi"
            class="bg-gradient-success"
            :disabled="form.divisi == null || form.divisi == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Delete Divisi -->
    <el-dialog
      v-model="remove_dialog"
      title=""
      width="30%"
      :before-close="handleCloseRemove"
    >
      <span>
        Are you sure want to delete divisi: <b>{{ form.divisi }}</b> ?
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="handleDeleteDivisi"
            class="bg-gradient-success"
            :disabled="form.divisi == null || form.divisi == ''"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions } from "vuex";

export default {
  name: "GoappsAdminDivisi",
  components: {
    ArgonButton,
  },

  data() {
    return {
      params: {
        id: "",
        search: "",
        order: "asc",
      },
      form: {
        id: null,
        divisi: null,
      },
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
    };
  },

  beforeMount() {
    this.fetchDivisiData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchDivisi: "hrd/fetchDivisi",
      doCreateDivisi: "hrd/doCreateDivisi",
      doUpdateDivisi: "hrd/doUpdateDivisi",
      doDeleteDivisi: "hrd/doDeleteDivisi",
    }),

    fetchDivisiData() {
      this.is_table_loading = true;
      const payload = {
        id: this.params.id,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchDivisi(payload).then((response) => {
        this.is_table_loading = false;
      });
    },

    handleCreateDivisi() {
      const params = {
        divisi: this.form.divisi,
      };
      this.doCreateDivisi(params)
        .then((response) => {
          this.handleCloseAdd();
          ElNotification({
            title: "Voila!",
            message: "Success create divisi!",
            type: "success",
          });
          this.fetchDivisiData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleUpdateDivisi() {
      const params = {
        departement: this.form.divisi,
        id: this.form.id,
      };
      this.doUpdateDivisi(params)
        .then((response) => {
          this.handleCloseEdit();
          ElNotification({
            title: "Voila!",
            message: "Success update divisi!",
            type: "success",
          });
          this.fetchDivisiData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleDeleteDivisi() {
      const params = {
        id: this.form.id,
      };
      this.doDeleteDivisi(params)
        .then((response) => {
          this.handleCloseRemove();
          ElNotification({
            title: "Voila!",
            message: "Success deactivate divisi!",
            type: "success",
          });
          this.fetchDivisiData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCloseRemove() {
      this.form.id = null;
      this.form.divisi = null;
      this.remove_dialog = false;
    },

    handleCloseAdd() {
      this.form.id = null;
      this.form.divisi = null;
      this.add_dialog = false;
    },

    handleCloseEdit() {
      this.form.id = null;
      this.form.divisi = null;
      this.edit_dialog = false;
    },

    handleOpenAdd() {
      this.add_dialog = true;
    },

    handleOpenEdit(data) {
      this.form = {
        id: data.id_departement,
        divisi: data.departement,
      };
      this.edit_dialog = true;
    },

    handleOpenRemove(data) {
      this.form = {
        id: data.id_departement,
        divisi: data.departement,
      };
      this.remove_dialog = true;
    },
  },

  computed: {
    getDivisi() {
      return this.$store.state.hrd.divisi;
    },
  },
};
</script>
