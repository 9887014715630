<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold">Attendance Request</span>
        </div>
        <div>
          <el-popover
            placement="bottom-end"
            title="Tips"
            :width="300"
            trigger="hover"
            :content="pop_over_content"
          >
            <template #reference>
              <i class="fas fa-info-circle cursor-pointer"></i>
            </template>
          </el-popover>
        </div>
      </div>
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex flex-column">
          <div class="text-center">Filter Date</div>
          <el-date-picker
            v-model="params.date_range"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            @change="handleChangeFilterDate"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-12 mb-3">
        <div class="card p-1 mb-1">
          <div class="row">
            <span class="text-center text-bold"> Sales </span>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="is_loading_sales">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_t9gkkhz4.json"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div
          class="card p-1 mb-1"
          v-if="!is_loading_sales && attendance_sales.length == 0"
        >
          <div class="row">
            <span class="text-center text-bold"> No Data Found. </span>
          </div>
        </div>
        <el-scrollbar
          height="500px"
          always
          class="px-3"
          v-if="!is_loading_sales && attendance_sales.length != 0"
        >
          <ul class="list-group">
            <li
              class="list-group-item border-0 p-3 mb-2 border-radius-lg"
              v-for="(data, index) in attendance_sales"
              :key="index"
            >
              <div
                class="d-flex justify-content-between mb-2 align-items-center"
              >
                <span
                  class="badge rounded-pill"
                  :class="
                    data.status == 1
                      ? 'bg-gradient-success'
                      : data.status == 0
                      ? 'bg-gradient-warning'
                      : 'bg-gradient-danger'
                  "
                >
                  {{
                    data.status == 1
                      ? "Approved"
                      : data.status == 0
                      ? "Pending"
                      : "Rejected"
                  }}
                </span>
                <span class="text-center text-sm">
                  {{ formatDate(data.created_at, "fullset") }}
                </span>
                <span class="">
                  <i class=""></i>
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <div>
                      <h6 class="mb-1 text-dark text-sm">
                        {{ data.full_name }}
                      </h6>
                      <span class="text-xs">
                        Notes: <br />
                        <span class="fst-italic">
                          {{ data.note }}
                        </span>
                      </span>
                    </div>
                    <hr class="horizontal dark" />
                    <div v-if="data.time_in_correct">
                      <span class="text-xs">
                        Time-in:
                        <span class="">
                          {{
                            data.time_in ? data.time_in.split(" ")[1] : "--:--"
                          }}
                        </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span class="text-warning">
                          {{
                            data.time_in_correct
                              ? data.time_in_correct.split(" ")[1]
                              : "--:--"
                          }}
                        </span>
                      </span>
                    </div>
                    <div v-if="data.time_out_correct">
                      <span class="text-xs">
                        Time-out:
                        <span class="">
                          {{
                            data.time_out
                              ? data.time_out.split(" ")[1]
                              : "--:--"
                          }}
                        </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span class="text-warning">
                          {{
                            data.time_out_correct
                              ? data.time_out_correct.split(" ")[1]
                              : "--:--"
                          }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        data.latitude_in_correct && data.longitude_in_correct
                      "
                    >
                      <span class="text-xs">
                        Location-in:
                        <span
                          class=""
                          v-if="data.latitude_in && data.longitude_in"
                        >
                          <GetLocation
                            :lat="data.latitude_in"
                            :long="data.longitude_in"
                          />
                        </span>
                        <span class="" v-else> - </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span
                          class=""
                          v-if="
                            data.latitude_in_correct &&
                            data.longitude_in_correct
                          "
                        >
                          <GetLocation
                            correct
                            :lat="data.latitude_in_correct"
                            :long="data.longitude_in_correct"
                          />
                        </span>
                        <span class="" v-else> - </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        data.latitude_out_correct && data.longitude_out_correct
                      "
                    >
                      <span class="text-xs">
                        Location-out:
                        <span
                          class=""
                          v-if="data.latitude_out && data.longitude_out"
                        >
                          <GetLocation
                            :lat="data.latitude_out"
                            :long="data.longitude_out"
                          />
                        </span>
                        <span class="" v-else> - </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span
                          class=""
                          v-if="
                            data.latitude_out_correct &&
                            data.longitude_out_correct
                          "
                        >
                          <GetLocation
                            :lat="data.latitude_out_correct"
                            :long="data.longitude_out_correct"
                          />
                        </span>
                        <span class="" v-else> - </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                  v-if="data.status == 0"
                >
                  <argon-button
                    color="success"
                    variant="outline"
                    size="sm"
                    class="mb-1 me-3 d-flex align-items-center justify-content-center"
                    @click="handleRequestSales(data, '1')"
                  >
                    Accept
                  </argon-button>
                  <argon-button
                    color="danger"
                    variant="outline"
                    size="sm"
                    class="mb-0 me-3 d-flex align-items-center justify-content-center"
                    @click="handleRequestSales(data, '2')"
                  >
                    Reject
                  </argon-button>
                </div>
              </div>
            </li>
          </ul>
        </el-scrollbar>
      </div>

      <!-- NON SALES -->
      <div class="col-lg-6 col-md-12 mb-3">
        <div class="card p-1 mb-1">
          <div class="row">
            <span class="text-center text-bold"> Non-Sales </span>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="is_loading_office">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_t9gkkhz4.json"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div
          class="card p-1 mb-1"
          v-if="!is_loading_office && attendance_office.length == 0"
        >
          <div class="row">
            <span class="text-center text-bold"> No Data Found. </span>
          </div>
        </div>
        <el-scrollbar
          height="500px"
          always
          class="px-3"
          v-if="!is_loading_office && attendance_office.length != 0"
        >
          <ul class="list-group">
            <li
              class="list-group-item border-0 p-3 mb-2 border-radius-lg"
              v-for="(data, index) in attendance_office"
              :key="index"
            >
              <div
                class="d-flex justify-content-between mb-2 align-items-center"
              >
                <span
                  class="badge rounded-pill"
                  :class="
                    data.status == 'Approved'
                      ? 'bg-gradient-success'
                      : data.status == 'Pending'
                      ? 'bg-gradient-warning'
                      : 'bg-gradient-danger'
                  "
                >
                  {{ data.status }}
                </span>
                <div class="d-flex flex-column justify-content-center">
                  <div class="d-flex justify-content-center">
                    <h6>
                      <span class="badge rounded-pill bg-gradient-info text-xs">
                        {{
                          data.type == 1
                            ? "Correction"
                            : data.type == 2
                            ? "Ijin/Sakit"
                            : data.type == 3
                            ? "On-Leave"
                            : ""
                        }}
                      </span>
                    </h6>
                  </div>
                  <span class="text-center text-sm">
                    {{
                      formatDate(
                        data.attendance_date + " " + data.time_in,
                        "fullset"
                      )
                    }}
                  </span>
                </div>
                <span
                  class="badge rounded-pill bg-light text-dark cursor-pointer"
                  @click="getVisitOffice(data)"
                >
                  <i class="fa fa-info"></i>
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <div>
                      <h6 class="mb-1 text-dark text-sm">
                        {{ data.name }}
                      </h6>
                      <span class="text-xs">
                        Notes: <br />
                        <span class="fst-italic">
                          {{ data.note }}
                        </span>
                      </span>
                    </div>
                    <hr class="horizontal dark" />
                    <div v-if="data.time_in_correct">
                      <span class="text-xs">
                        Time-in:
                        <span class="">
                          {{ data.time_in }}
                        </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span class="text-warning">
                          {{ data.time_in_correct }}
                        </span>
                      </span>
                    </div>
                    <div v-if="data.time_out_correct">
                      <span class="text-xs">
                        Time-out:
                        <span class="">
                          {{ data.time_out }}
                        </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span class="text-warning">
                          {{ data.time_out_correct }}
                        </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        data.latitude_in_correct && data.longitude_in_correct
                      "
                    >
                      <span class="text-xs">
                        Location-in:
                        <span
                          class=""
                          v-if="data.latitude_in && data.longitude_in"
                        >
                          <GetLocation
                            :lat="data.latitude_in"
                            :long="data.longitude_in"
                          />
                        </span>
                        <span class="" v-else> - </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span
                          class=""
                          v-if="
                            data.latitude_in_correct &&
                            data.longitude_in_correct
                          "
                        >
                          <GetLocation
                            correct
                            :lat="data.latitude_in_correct"
                            :long="data.longitude_in_correct"
                          />
                        </span>
                        <span class="" v-else> - </span>
                      </span>
                    </div>
                    <div
                      v-if="
                        data.latitude_out_correct && data.longitude_out_correct
                      "
                      class="mt-2"
                    >
                      <span class="text-xs">
                        Location-out:
                        <span
                          class=""
                          v-if="data.latitude_out && data.longitude_out"
                        >
                          <GetLocation
                            :lat="data.latitude_out"
                            :long="data.longitude_out"
                          />
                        </span>
                        <span class="" v-else> - </span>
                        <i class="fas fa-arrow-right mx-1"></i>
                        <span
                          class=""
                          v-if="
                            data.latitude_out_correct &&
                            data.longitude_out_correct
                          "
                        >
                          <GetLocation
                            correct
                            :lat="data.latitude_out_correct"
                            :long="data.longitude_out_correct"
                          />
                        </span>
                        <span class="" v-else> - </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="d-flex flex-column justify-content-center align-items-center ms-2"
                  v-if="data.status == 'Pending'"
                >
                  <argon-button
                    color="success"
                    variant="outline"
                    size="sm"
                    class="mb-1 me-3 d-flex align-items-center justify-content-center"
                    @click="handleRequestOffice(data, 1)"
                  >
                    Accept
                  </argon-button>
                  <argon-button
                    color="danger"
                    variant="outline"
                    size="sm"
                    class="mb-0 me-3 d-flex align-items-center justify-content-center"
                    @click="handleRequestOffice(data, 0)"
                  >
                    Reject
                  </argon-button>
                </div>
              </div>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </div>

    <el-dialog
      v-model="visit_dialog"
      title="Visit Data"
      width="30%"
      :before-close="handleCloseVisit"
    >
      <span v-if="visit_data.length != 0">
        <el-scrollbar height="500px" always class="px-3">
          <ul class="list-group" :class="''">
            <li
              class="mb-2 border-0 list-group-item d-flex justify-content-between border-radius-lg align-items-center"
              :class="'ps-0'"
              v-for="(data, index) in visit_data"
              :key="index"
            >
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center">
                  <div
                    class="text-center shadow icon icon-shape icon-sm bg-gradient-dark"
                    :class="'me-4'"
                  >
                    <img
                      :src="
                        'https://cdn.erakomp.co.id/eraoffice/visits/' +
                        data.visit_photo +
                        '?x-oss-process=image/resize,w_512,h_512'
                      "
                      class="rounded"
                      alt="Avatar"
                      width="50"
                      height="50"
                    />
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="mb-1 text-sm text-dark">
                      SO/PO: {{ data.visit_so ?? "-" }}/{{
                        data.visit_po ?? "-"
                      }}
                    </h6>
                    <span class="text-xs"> {{ data.visit_date }} </span>
                  </div>
                </div>
                <div class="mt-3 text-xs">
                  {{ data.visit_note }}
                </div>
                <hr class="horizontal dark" />
              </div>
              <div class="d-flex">
                <button
                  class="my-auto btn btn-link btn-icon-only btn-rounded btn-sm text-dark icon-move-right"
                  @click="handleOpenMap(data)"
                >
                  <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
                </button>
              </div>
            </li>
          </ul>
        </el-scrollbar>
      </span>
      <span v-else> There's no visit data. </span>
    </el-dialog>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapActions } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import { ElNotification } from "element-plus";
import axios from "axios";

import GetLocation from "@/components/GooHRD/GetLocation.vue";

export default {
  name: "GoappsAdminAttendanceRequest",

  components: {
    ArgonButton,
    GetLocation,
  },

  data() {
    return {
      params: {
        date_range: [
          format(new Date(), "yyyy-MM-dd 00:00:00"),
          format(new Date(), "yyyy-MM-dd 23:59:59"),
        ],
        start_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        end_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
        id_user: "",
        filter: "",
        page: 1,
      },
      pop_over_content:
        "This is contain list attendance request of sales and non-sales",
      attendance_sales: [],
      attendance_office: [],
      is_loading_sales: false,
      is_loading_office: false,
      visit_data: [],
      visit_dialog: false,
    };
  },

  mounted() {
    this.getAttendanceOffice();
    this.getAttendanceSales();
  },

  methods: {
    ...mapActions({
      fetchAttendanceRequestOffice: "hrd/fetchAttendanceRequestOffice",
      fetchAttendanceRequestSales: "hrd/fetchAttendanceRequestSales",
      doHandleAttendanceRequestOffice: "hrd/doHandleAttendanceRequestOffice",
      fetchEmployeeVisitData: "hrd/fetchEmployeeVisitData",
      doHandleAttendanceRequestSales: "hrd/doHandleAttendanceRequestSales",
      fetchLocationMaps: "hrd/fetchLocationMaps",
    }),

    formatDate(date, type) {
      if (
        date != null &&
        Date.parse(date) != null &&
        !isNaN(Date.parse(date))
      ) {
        if (type == "fullset") {
          return format(new Date(date), "EEEE, dd MMMM yyyy HH:mm");
        } else if (type == "time") {
          return format(new Date(date), "HH:mm:ss");
        } else {
          return "-";
        }
      } else {
        return "--";
      }
    },

    getVisitOffice(data) {
      // this.is_loading_office = true;
      const start = data.attendance_date + " 00:00:00";
      const end = data.attendance_date + " 23:59:59";
      const params = {
        first_date: start,
        last_date: end,
        filter: "",
        user_id: data.user_id,
      };
      this.fetchEmployeeVisitData(params)
        .then((response) => {
          this.visit_data = response.data;
          this.visit_dialog = true;
          // this.attendance_office = response.data;
          // this.is_loading_office = false;
        })
        .catch((error) => {
          this.visit_dialog = false;
          console.log(`err ${JSON.stringify(error)}`);
        });
    },

    getAttendanceOffice() {
      this.is_loading_office = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      const params = {
        first_date: start,
        last_date: end,
        request_type: "attendance",
        page: this.params.page,
      };
      this.fetchAttendanceRequestOffice(params)
        .then((response) => {
          this.attendance_office = response.data;
          this.is_loading_office = false;
        })
        .catch((error) => {
          this.is_loading_office = false;
          ElNotification({
            title: "Oops!",
            message: `Error office: ${JSON.stringify(error)}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    getAttendanceSales() {
      this.is_loading_sales = true;
      const start = this.params.start_date;
      const end = this.params.end_date;
      const params = {
        first_date: start,
        last_date: end,
        employee_id: this.params.id_user,
      };
      this.fetchAttendanceRequestSales(params)
        .then((response) => {
          this.attendance_sales = response.result;
          this.is_loading_sales = false;
        })
        .catch((error) => {
          this.is_loading_sales = false;
          ElNotification({
            title: "Oops!",
            message: `Error sales: ${JSON.stringify(error.data)}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleChangeFilterDate() {
      const start = this.params.date_range[0] + " 00:00:00";
      const end = this.params.date_range[1] + " 23:59:59";
      this.params.start_date = format(new Date(start), "yyyy-MM-dd 00:00:00");
      this.params.end_date = format(new Date(end), "yyyy-MM-dd 23:59:59");
      this.getAttendanceSales();
      this.getAttendanceOffice();
    },

    handleRequestOffice(data, type) {
      const params = {
        request_id: data.id,
        approve: type,
      };
      this.doHandleAttendanceRequestOffice(params)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: `Success update presence request!`,
            type: "success",
          });
          this.getAttendanceOffice();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleRequestSales(data, type) {
      const params = {
        request_id: data.id,
        status: type,
      };
      this.doHandleAttendanceRequestSales(params)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: `Success update presence request!`,
            type: "success",
          });
          this.getAttendanceSales();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleCloseVisit() {
      this.visit_data = [];
      this.visit_dialog = false;
    },

    handleOpenMap(data) {
      window.open(
        `https://maps.google.com/?q=${data.visit_latitude},${data.visit_longitude}`,
        "_blank"
      );
    },
  },
};
</script>
