<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>Age</h6>
      <p class="text-sm">Employee distribution by age.</p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="hrd-chart-age" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { mapActions } from "vuex";

export default {
  name: "gradient-line-chart",

  props: {
    title: {
      type: String,
      default: "Sales overview",
    },
    detail1: {
      type: String,
      default: "4% more",
    },
    detail2: {
      type: String,
      default: "in 2021",
    },
  },

  computed: {
    getData() {
      return this.$store.state.hrd.age_chart;
    },
  },

  mounted() {
    var ctx1 = document.getElementById("hrd-chart-age").getContext("2d");

    var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

    gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
    gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
    gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");
    new Chart(ctx1, {
      plugins: [ChartDataLabels],
      type: "bar",
      data: {
        labels: this.getData.label,
        datasets: [
          {
            datalabels: {
              align: "end",
              anchor: "end",
              display: function (context) {
                var index = context.dataIndex;
                var value = context.dataset.data[index];
                return value != 0; // display labels with an odd index
              },
            },
            label: "Count",
            tension: 0.4,
            borderWidth: 0,
            pointRadius: 0,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
              "rgba(10, 203, 207, 0.2)",
              "rgba(50, 100, 100, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
              "rgb(10, 203, 207)",
              "rgb(50, 100, 100)",
            ],
            // eslint-disable-next-line no-dupe-keys
            borderWidth: 1,
            fill: true,
            data: this.getData.result,
            // maxBarThickness: 6,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: true,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#000000",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    });
  },
};
</script>
