<template>
  <div class="card mb-4 mt-3">
    <div class="card-header pb-0">
      <!-- <h6>Completed Tasks Based on Employee - {{currentDate()}}</h6> -->
      <h6>Completed Tasks Based on Employee</h6>
      <p>Total: {{ total }}</p>
    </div>
    <div style="display:none">{{ getPeriod }}</div>
    <!-- {{ getPeriod }} -->
    <!-- {{ getEmployee }}  -->
    <!-- <div class="card-body px-0 pt-0 pb-2">
      
        <el-table :data="getEmployee" style="width: 100%" height="500">
          <el-table-column fixed type="index" label="No" width="60"/>
          
          <el-table-column
            prop="fullname"
            label="Name"
            sortable
          >
            <template #default="scope">
              <h6
                class=""
                v-if="scope.row.end_date == null"
                >{{ scope.row.fullname }}</h6
              >
              <h6 class="text-danger" v-else
                >{{ scope.row.fullname }}</h6
              >
            </template>
          </el-table-column>

          <el-table-column prop="departement" label="Department" sortable/>

        </el-table>
    </div> -->

    <div class="tableFixHead card-body px-0 pt-0 pb-2">
       
        <!-- <el-table :data="getEmployee" :default-sort = "{prop: 'countTaskByAssignee', order: 'descending'}" style="width: 100%" height="500" :header-cell-style="{ background: '#ccffcc' }"></el-table> -->
        <el-table :data="getEmployee" :default-sort = "{prop: 'countTaskByAssignee', order: 'descending'}" style="width: 100%" :header-cell-style="{ background: '#ccffcc' }">
          <el-table-column fixed type="index" label="No" width="60"/>
          
          <el-table-column
            prop="fullname"
            label="Name"
            sortable
          >
            <template #default="scope">
              <h6
                class=""
                v-if="scope.row.end_date == null"
                >{{ scope.row.fullname }}</h6
              >
              <h6 class="text-danger" v-else
                >{{ scope.row.fullname }}</h6
              >
            </template>
          </el-table-column>

          <el-table-column prop="departement" label="Department" sortable/>

          <el-table-column prop="countTaskByAssignee" label="Completed" sortable />
        </el-table>
    </div>
  </div>

      
</template>

<script>
import { format } from "date-fns";

import { ElNotification } from "element-plus";
import { mapActions, mapMutations, useStore } from "vuex";

export default {
  name: "GoAppsAdminActiveUsers",
  data() {
    return {
      total: '',
      role: '',
      dept: '',
      isAuthorized:'',
      params: {
        id: "",
        search: "",
        page: 1,
        order: "ASC",
        join_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        start_date: "",
        end_date: "",
        out_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        employeement_status: "All",
        office_location: "All",
        dept: "",
      },
      edit_dialog: false,
      is_table_loading: false,
      loading_more: false,
      is_no_more: false,
      search_divisi: "",
      divisi_data: [],
      selected_divisi: null,
      selected_office: "",
      province_data: [],
      search_province: "",
      selected_province: null,
      city_data: [],
      search_city: "",
      selected_city: null,
    };
  },
  props: {
    title: {
      type: String,
      default: "Active Users",
    },
    detail: {
      type: String,
      default: "4% more",
    },
  },

  mounted() {
    // this.getUserLoginRole();
    this.getEmployeeCard();
  },

  methods: {
    ...mapActions({
      // fetchEmployee: "hrd/fetchEmployee",
      fetchExcelEmployee: "hrd/fetchExcelEmployee", 
      // Card
      fetchUserTask: "gopro/fetchMemberCardSummary",
    }),

    ...mapMutations({
      // SET_EMPLOYEE: "hrd/SET_EMPLOYEE",
      SET_EMP_CARD: "gopro/SET_EMP_CARD",
      SET_ST_ED: "gopro/SET_ST_ED",
    }),

    currentDate() {
      const current = new Date();
      const date = new Date().toLocaleString('en-us',{month:'short', year:'numeric'})
      return date;
    },

    getEmployeeCard() {
      this.getUserLoginRole();
      // this.is_table_loading = true;
      // this.params.page = 1;

      
      // const params = {
      //   // dept: this.$store.state.dashboard_app_dept,
      //   dept: this.params.dept,
      //   start_date: this.$store.state.gopro.period.start_date,
      //   end_date: this.$store.state.gopro.period.end_date,
      // };
      // console.log("ini act param tbl: ", params);

      let params = {
        dept: '',
        start_date: '',
        end_date: '',
      };

      
      if (this.isAuthorized == "admin")
        {
          console.log("ini authorized adm: ", this.isAuthorized);
          params = {
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
              dept: '',
          };

        }
        else if (this.isAuthorized == "mgr" || this.isAuthorized == "staff")
        {
          // console.log("ini authorized mgr st: ", this.isAuthorized);
          this.params.page = 1;
          params = {
              dept: this.$store.state.dashboard_app_dept,
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
          };
        }

      this.fetchUserTask(params)
        .then((response) => {
          console.log("ini resp cla: ", response);
          // assign_to
          // : 
          // "26ITS"
          // countTaskByAssignee
          // : 
          // 8
          // created_by
          // : 
          // "UYUSB"
          // end_date
          // : 
          // null
          // departement
          // : 
          // "Tech. Solution"
          // fullname
          // : 
          // "Kusnadi"
          this.total = response.total;
          console.log("ini totalnya: ", this.total);
          this.SET_EMP_CARD(response.data);
          console.log("ini type state emp: ", response.data);
          // console.log("ini type state emp: ", typeof response);
          this.is_table_loading = false;
        })
        .catch((error) => {
          console.log("ini error: ", error);
          this.is_no_more = true;
          this.is_table_loading = false;
          this.SET_EMP_CARD([]);
        });
    },

    getUserLoginRole() {
      // console.log("ini role lgn: ", this.$store.state.dashboard_app_role);
      this.role = this.$store.state.dashboard_app_role;
      this.setUserPermission(this.$store.state.dashboard_app_role);
      // console.log("ini authorized lgn: ", this.isAuthorized);
      this.params.dept = '';
      console.log("ini dept lgn: ", this.params.dept);

      if (this.isAuthorized == "admin")
        {
          console.log("ini authorized adm: ", this.isAuthorized);
          this.params.dept = '';
        }
        else if (this.isAuthorized == "mgr" || this.isAuthorized == "staff")
        {
          console.log("ini authorized mgr st: ", this.isAuthorized);
          this.params.dept = this.$store.state.dashboard_app_dept;
        }
      return this.params.dept;
    },

    setUserPermission(data) {
      if (
        //if user is super admin
        data === "Super Admin" 
        // ||
        // data === "Manager"
      ) 
      {
        this.isAuthorized = "admin";
      }
      else if(data.toUpperCase().includes("MANAGER"))
      {
        this.isAuthorized = "mgr";
      }
      else
      {
        this.isAuthorized = "staff";
      }
      return this.isAuthorized;
    },

    // getEmployeeDataMore() {
    //   this.loading_more = true;
    //   const page = 1;
    //   this.params.page += page;
    //   const params = {
    //     id: this.params.id,
    //     search: this.params.search,
    //     page: this.params.page,
    //     order: this.params.order,
    //     join_start: this.params.join_date.start_date,
    //     join_end: this.params.join_date.end_date,
    //     out_start: this.params.out_date.start_date,
    //     out_end: this.params.out_date.end_date,
    //     employeement_status: this.params.employeement_status,
    //     office_location: this.params.office_location,
    //   };
    //   // this.fetchEmployee(params)
    //   // this.fetchExcelEmployee(params)
    //   this.fetchUserTask(params)
    //     .then((response) => {
    //       // console.log("ini resp cla: ", response);
    //       // {
    //       //   rowIndex: 75,
    //       //   id_user: 'JZCRD',
    //       //   id_sso: '843WB',
    //       //   employee_no: '1802194',
    //       //   id_departement: 'KEDKD',
    //       //   id_office_location: null,
    //       //   nik: '3173011810880012',
    //       //   fullname: 'Geraldo Guntur Adhitama',
    //       //   province: null,
    //       //   city: 'Jakarta',
    //       //   address: 'Tmn Surya III Blok G 4 No.22 RT 004/018 Kel. Pegadungan Kec. Kali Deres Jak Bar',
    //       //   edu: null,
    //       //   dob: '1988-10-17',
    //       //   gender: 'Male',
    //       //   start_date: '2018-02-18 00:00:00',
    //       //   end_date: null,
    //       //   delete_date: null,
    //       //   image: 'uploaded-1642050132.jpeg',
    //       //   created_at: '2023-01-31 10:40:54',
    //       //   updated_at: null,
    //       //   email: 'geraldo@erakomp.com',
    //       //   username: 'geraldo',
    //       //   phone: '087788398399',
    //       //   is_activate: '1',
    //       //   departement: 'Legal',
    //       //   office_name: null,
    //       //   dob_fm: '17-10-1988',
    //       //   start_date_fm: '18-02-2018',
    //       //   end_date_fm: null
    //       // },


    //       if (response.length < 10) {
    //         this.is_no_more = true;
    //       } else {
    //         this.is_no_more = false;
    //       }
    //       let result = this.getEmployee;
    //       const merged = [...result, ...response];
    //       console.log(merged);
    //       this.SET_EMPLOYEE(merged);
    //       this.loading_more = false;
    //     })
    //     .catch((error) => {
    //       this.is_no_more = true;
    //       this.loading_more = false;
    //     });
    // },

    currentDate() {
      const current = new Date();
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      // const date = `${current.getMonth()+1}/${current.getFullYear()}`;
      const date = new Date().toLocaleString('en-us',{month:'short', year:'numeric'})
      return date;
    },

  },

  computed: {
    getEmployee() {
      const value = this.$store.state.gopro.emp_card;
      console.log(value);
      return value;
    },

    getPeriod() {
      // return this.$store.state.gopro.emp_card;
      // console.log("ini table period ", this.$store.state.gopro.period);
      this.getEmployeeCard();
      return this.$store.state.gopro.period;
    }

  },
};
</script>
<style scoped>
      .tableFixHead {
        overflow-y: auto; /* make the table scrollable if height is more than 200 px  */
        height: 500px; /* gives an initial height of 200px to the table */
      }
      .tableFixHead thead th {
        position: sticky; /* make the table heads sticky */
        top: 0px; /* table head will be placed from the top of the table and sticks to it */
      }
      table {
        border-collapse: collapse; /* make the table borders collapse to each other */
        width: 100%;
      }
      th,
      td {
        padding: 8px 16px;
        border: 1px solid #ccc;
      }
      th {
        background: #eee;
      }
    </style>
