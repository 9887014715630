<template>
    <!-- Hello World {{ getReporting }} -->
    <div class="mx-4">
      <div class="card p-3 mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <span class="text-bold"> PB Reporting </span>
          </div>
          <div>
            <el-date-picker
              type="daterange"
              v-model="date_range"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              size="large"
              style="margin-top: 2px; margin-right: 10px"
              value-format="YYYY-MM-DD"
              
            />
            <argon-button
                variant="gradient"
                color="primary"
                size="md"
                @click="fetchDate"
            >
                Show Data
            </argon-button> |
            <argon-button
                variant="gradient"
                color="primary"
                size="md"
                @click="exportExcel"
            >
                Export Excel
            </argon-button>
          </div>
        </div>
      </div>
  
      <div class="card p-3" v-if="is_loading">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
  
      <div class="card p-3" v-else>
        <div class="table-responsive">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  No
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Username
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  PB
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in so" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <span class="text-xs text-secondary mb-0">{{ index + 1 }}</span>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-xs text-secondary mb-0">
                    {{ item.username }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <button class="btn btn-sm btn-primary" @click="getData(item.list, item.username)">{{ item.list.length }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <el-dialog
        v-model="ModalDetailPO"
        :title="'Detail PB : ' + username"
        width="50%"
        >
        <span>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Detail</th>
                        <th scope="col">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(items, index) in detailSo" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                              <ul>
                                <li>Supplier Name : <strong>{{ items.supplier_name }}</strong></li>
                                <li>PB ID : <strong>{{ items.pb_id }}</strong></li>
                                <li>PO ID : <strong>{{ items.po_id }}</strong></li>
                              </ul>
                            </td>
                            <td>
                                {{ dateFormat(items.pb_created_at) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>
        </el-dialog>
    </div>
  </template>
  
  <script>
  import { ElNotification } from "element-plus";
  import ArgonButton from "@/components/ArgonButton.vue";
  import { mapActions, useStore } from "vuex";
  import axios from "axios";
  import { format, startOfMonth, endOfMonth, isDate, parse } from "date-fns";
  
  export default {
    name: "GoappsAdminReporting",
    components: {
      ArgonButton,
    },

    filters: {
        numberFormat(value) {
            if (!value) {
                return 0;
            }
            const formatOptions = {
                notation: "compact",
                compactDisplay: "short",
                minimumFractionDigits: 0,
            };
            return new Intl.NumberFormat("id-ID", formatOptions).format(value);
        },
        moneyFormat(value) {
        if (!value) {
            return 0;
        }
        const formatOptions = {
            // style: 'currency',
            // currency: 'IDR',
            minimumFractionDigits: 0,
        };
        return new Intl.NumberFormat("id-ID", formatOptions).format(value);
        },
    },
  
    data() {
      return {
        reporting: [],
        is_loading: false,
        is_table_loading: false,
        start_date: new Date(),
        end_date: new Date(),
        so: [],
        detailSo: [],
        ModalDetailPO: false,
        date_range: [null, null],
        username: null,
      };
    },
  
  
    mounted() {
      this.fetchSoReportingData();
    },
  
    methods: {
      ...mapActions({
        fetchCard: "gopro/fetchCard",
        fetchReporting: "gopro/fetchReporting",
        doExportReport: "gopro/doExportReport",
        fetchSO: "gopro/fetchSO",
        fetchPO: "gopro/fetchPO",
        fetchPB: "gopro/fetchPB",
  
        fetchBoard: "gopro/fetchBoard",
        fetchKanban: "gopro/fetchKanban",
        exportExcelPB: "gopro/exportExcelPB",
      }),

      dateFormat(datestr) {
        if (datestr) return format(new Date(datestr), "eeee, dd-MMMM-yyyy");
  
        return "";
      },

      numberFormats(value) {
          if (!value) {
            return 0;
          }
          const formatOptions = {
              notation: "compact",
              compactDisplay: "short",
              minimumFractionDigits: 0,
          };
          return new Intl.NumberFormat("id-ID", formatOptions).format(value);
      },

      fetchDate() {
        if (this.date_range == null) {
          this.date_range = ["", ""];
        }

        this.start_date = this.date_range[0];
        this.end_date = this.date_range[1];

        this.fetchSoReportingData();
      },

      exportExcel() {
        const payload = {
          start_date: this.start_date,
          end_date: this.end_date,
        };

        this.exportExcelPB(payload)
          .then((response) => {
            window.open(response.url, "_blank");
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
          })
      },

      async fetchSoReportingData() {
        this.is_loading = true;
        const payload = {
          start_date: this.start_date,
          end_date: this.end_date,
        };

        this.fetchPB(payload) 
            .then((response) => {
                console.log(response);
                this.is_loading = false;
                this.so = response.result;
            })
            .catch((err) => {
                this.is_loading = false;
                console.log(err);
            })
      },

      getData(data, username) {
        this.detailSo = data;
        this.ModalDetailPO = true;
        console.log('data detail SO');
        console.log(this.detailSo);
        this.username = username;
      },
    },
  
    computed: {
      getBoard() {
        return this.$store.state.gopro.board;
      },
      getReporting() {
        return this.$store.state.gopro.reporting;
      },
    },
  };
  </script>
  