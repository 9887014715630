<template>
  <div>
    <div class="row">
      <Card :title="`Presence Requests`">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="row mb-3">
              <div class="col-12">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  start-placeholder="start date"
                  end-placeholder="end date"
                  value-format="YYYY-MM-DD"
                  @change="changeDateFilter"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="row mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <PresenceCard v-else />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import PresenceCard from "@/components/GooSales/components/attendance/PresenceRequestCard.vue";

import { mapActions, mapMutations } from "vuex";

import axios from "axios";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
      access_token: this.$store.state.eraforce.token_maps_pk,
      location_in: "",
      location_in_correct: "",
      location_out: "",
      location_out_correct: "",
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    PresenceCard,
  },
  mounted() {},
  beforeMount() {
    this.getPresence();
  },
  methods: {
    ...mapActions({
      fetchPresenceRequestList: "eraforce/fetchPresenceRequestList",
    }),
    ...mapMutations({
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
      SET_PRESENCE_REQUEST_LIST: "eraforce/SET_PRESENCE_REQUEST_LIST",
    }),

    updateHandler(page) {},

    changeDateFilter(range) {
      if (range) {
        this.startDate = `${range[0]} 00:00:00`;
        this.endDate = `${range[1]} 23:59:59`;
      } else {
        const today = format(new Date(), "yyyy-MM-dd");
        this.startDate = `${today} 00:00:00`;
        this.endDate = `${today} 23:59:59`;
      }
      this.getPresence();
    },

    getPresence() {
      const payload = {
        first_date: this.startDate,
        last_date: this.endDate,
      };
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchPresenceRequestList(payload).then(async (response) => {
        this.SET_PRESENCE_REQUEST_LIST(response);
        // let list = [];
        // response.forEach(async (data) => {
        //   await this.getLocation(data.latitude_in, data.longitude_in, "in");
        //   await this.getLocation(
        //     data.latitude_in_correct,
        //     data.longitude_in_correct,
        //     "in_correct"
        //   );
        //   await this.getLocation(data.latitude_out, data.longitude_out, "out");
        //   await this.getLocation(
        //     data.latitude_out_correct,
        //     data.longitude_out_correct,
        //     "out_correct"
        //   );
        //   list.push({
        //     ...data,
        //     in: this.location_in,
        //     in_correct: this.location_in_correct,
        //     out: this.location_out,
        //     out_correct: this.location_out_correct,
        //   });
        // });
        // await this.SET_PRESENCE_REQUEST_LIST(list);
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },
  },
  computed: {},
  watch: {},
};
</script>
