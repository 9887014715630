<template>
  <div>
    <div class="row">
      <Card :title="`Recent Team Activity`" />
    </div>

    <div class="row mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <RecentTeamActivity v-else />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import RecentTeamActivity from "@/components/GooSales/components/activity/RecentTeamActivityCard.vue";

import { mapActions, mapMutations } from "vuex";

import axios from "axios";

export default {
  name: "TeamKPI",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    RecentTeamActivity,
  },
  mounted() {
    this.getDataActivity();
  },
  beforeMount() {},
  methods: {
    ...mapActions({
      fetchTeamActivity: "eraforce/fetchTeamActivity",
    }),
    ...mapMutations({
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
    }),

    getDataActivity() {
      const payload = {
        team_id: this.getUserLoginData.team_id,
      };
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchTeamActivity(payload).then((response) => {
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
  },
  watch: {},
};
</script>
