<template>
  <div class="mt-5">
    <div class="row">
      <Card :title="` Projects`">
        <template #body>
          <div class="row mx-5">
            <div
              class="d-flex justify-content-between align-items-center align-content-center"
            >
              <el-date-picker
                v-model="filter.month"
                type="month"
                start-placeholder="Pick a month"
                value-format="YYYY-MM"
                @change="changeDateFilter"
                size="large"
                :disabled="!use_date"
              />
              <el-checkbox v-model="use_date" @change="handleUseDate()"
                >Use Date</el-checkbox
              >

              <el-radio-group
                v-model="filter.status_project_text"
                size="large"
                @change="handleStatusProject($event)"
              >
                <el-radio-button label="All" value="all" />
                <el-radio-button label="Active" value="active" />
                <el-radio-button label="Deal" value="deal" />
                <el-radio-button label="Rejected" value="no_deal" />
                <el-radio-button label="Pending" value="pending" />
              </el-radio-group>

              <el-radio-group
                v-model="filter.target_status_text"
                size="large"
                @change="handleTargetProject()"
              >
                <el-radio-button label="All" value="all" />
                <el-radio-button label="Filled Target" value="filled" />
                <el-radio-button label="Empty Target" value="empty" />
              </el-radio-group>
            </div>
          </div>
          <div class="row mb-3 d-flex justify-content-end">
            <div class="col-12">
              <argon-button
                class="mt-3"
                variant="gradient"
                color="info"
                size="sm"
                fullWidth
                @click="handleSaveProjects()"
                :disabled="$store.state.eraforce.is_activity_card_loading"
              >
                <div><i class="fa fa-print"></i> Save</div>
              </argon-button>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="row mt-3">
      <!-- <Card> -->
      <div class="card p-3">
        <el-input
          v-model="filter.project_name"
          class="w-95 m-2"
          size="large"
          placeholder="Search project name..."
          style="width: 100%"
          :suffix-icon="Search"
          @change="handleFilterSearch()"
        />
      </div>
      <!-- </Card> -->
    </div>

    <div class="mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <ProjectsCard v-else>
        <div v-if="!is_no_more && projectsData.length > 5">
          <el-button
            class="mx-2"
            v-if="!is_load_more"
            @click="fetchEmployeeProjectsMore"
          >
            <i class="fa fa-undo"></i>
            Load More
          </el-button>
          <el-button class="mx-2" disabled v-else>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </el-button>
        </div>
      </ProjectsCard>
    </div>
  </div>
</template>

<script>
import {
  ArrowLeft,
  ArrowRight,
  Delete,
  Edit,
  Share,
  Search,
} from "@element-plus/icons-vue";
import { ElButton } from "element-plus";
import Swal from "sweetalert2";
import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import ProjectsCard from "@/views/GooSales/components/ProjectsCard.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      use_date: true,
      dialogVisible: false,
      location: null,
      gettingLocation: false,
      errorStr: null,
      status: "",
      filter: {
        month: format(new Date(), "yyyy-MM"),
        month_only: format(new Date(), "MM"),
        year_only: format(new Date(), "yyyy"),
        project_name: "",
        status_project: "All",
        status_project_text: "All",
        page: 1,
        target_status: "All",
        target_status_text: "All",
      },
      is_load_more: false,
      is_no_more: false,
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    ProjectsCard,
    ElButton,
  },
  mounted() {
    this.fetchEmployeeProjects();
  },
  beforeMount() {
    this.CLOSE_UPDATE_INFORMATION_DIALOG();
  },
  methods: {
    ...mapActions({
      fetchEmployeeProject: "eraforce/fetchEmployeeProject",
      fetchEmployeeProjectNew: "eraforce/fetchEmployeeProjectNew",
      doCheckIn: "eraforce/doCheckIn",
      exportProjectExcel: "eraforce/exportProjectExcel",
    }),
    ...mapMutations({
      SET_ALREADY_CHECKIN: "eraforce/SET_ALREADY_CHECKIN",
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
      SET_ACTIVITY_MODAL_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_ACTIVE",
      SET_ACTIVITY_MODAL_NOT_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_NOT_ACTIVE",
      SET_EMPLOYEE_PROJECT: "eraforce/SET_EMPLOYEE_PROJECT",
      CLOSE_UPDATE_INFORMATION_DIALOG:
        "eraforce/CLOSE_UPDATE_INFORMATION_DIALOG",
      SET_PROJECT_PAYLOAD_DATA: "eraforce/SET_PROJECT_PAYLOAD_DATA",
    }),

    handleFilterSearch() {
      this.filter.page = 1;
      this.fetchEmployeeProjects();
    },

    handleUseDate() {
      this.filter.page = 1;
      if (this.use_date) {
        this.filter.month_only = format(new Date(this.filter.month), "MM");
        this.filter.year_only = format(new Date(this.filter.month), "yyyy");
      } else {
        this.filter.month_only = null;
        this.filter.year_only = null;
      }
      this.fetchEmployeeProjects();
    },

    handleStatusProject(value) {
      this.filter.page = 1;
      this.filter.status_project = this.filter.status_project_text;
      if (this.filter.status_project == "Rejected") {
        this.filter.status_project = "no_deal";
      }

      if (value == "Active") {
        this.status = "active";
      } else if (value == "Deal") {
        this.status = "deal";
      } else if (value == "All") {
        this.status = "";
      } else if (value == "Rejected") {
        this.status = "no_deal";
      } else if (value == "Pending") {
        this.status = "pending";
      }

      this.fetchEmployeeProjects();
    },

    handleTargetProject() {
      this.filter.page = 1;
      this.filter.target_status = this.filter.target_status_text;
      if (this.filter.target_status == "Filled Target") {
        this.filter.target_status = "filled";
      } else if (this.filter.target_status == "Empty Target") {
        this.filter.target_status = "empty";
      } else {
        this.filter.target_status = "all";
      }
      this.fetchEmployeeProjects();
    },

    changeDateFilter() {
      this.filter.page = 1;
      this.filter.month_only = format(new Date(this.filter.month), "MM");
      this.filter.year_only = format(new Date(this.filter.month), "yyyy");
      this.fetchEmployeeProjects();
    },

    fetchEmployeeProjects() {
      this.is_no_more = false;
      this.SET_ACTIVITY_CARD_LOADING();

      let payload = {
        project_name: this.filter.project_name.toString().toLowerCase(),
        filter: this.filter.project_name.toString().toLowerCase(),
        employee_id: this.getUserLoginData.id,
        status_project: this.filter.status_project.toString().toLowerCase(),
        page: this.filter.page,
        target_status: this.filter.target_status.toString().toLowerCase(),
        month: this.filter.month_only,
        year: this.filter.year_only,
        status: this.status,
      };
      // this.fetchEmployeeProject(payload).then((response) => {
      //   if (response.length < 5) {
      //     this.is_no_more = true;
      //   }
      //   console.log(response);
      //   this.SET_EMPLOYEE_PROJECT(response);
      //   this.SET_PROJECT_PAYLOAD_DATA(payload);
      //   this.SET_ACTIVITY_CARD_NOT_LOADING();
      // });
      this.fetchEmployeeProjectNew(payload).then((response) => {
        if (response.length < 5) {
          this.is_no_more = true;
        }
        console.log(response);
        this.SET_EMPLOYEE_PROJECT(response);
        this.SET_PROJECT_PAYLOAD_DATA(payload);
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },

    fetchEmployeeProjectsMore() {
      this.filter.page = this.filter.page + 1;
      let current_project_data = [];
      current_project_data = this.$store.state.eraforce.employee_project.slice();
      this.is_load_more = true;
      let payload = {
        project_name: this.filter.project_name.toString().toLowerCase(),
        filter: this.filter.project_name.toString().toLowerCase(),
        employee_id: this.getUserLoginData.id,
        status_project: this.filter.status_project.toString().toLowerCase(),
        page: this.filter.page,
        target_status: this.filter.target_status.toString().toLowerCase(),
        month: this.filter.month_only,
        year: this.filter.year_only,
      };
      this.fetchEmployeeProjectNew(payload).then((response) => {
        if (response.length > 0) {
          response.forEach(async (resp) => {
            await current_project_data.push(resp);
          });
          this.SET_EMPLOYEE_PROJECT(current_project_data);
          this.is_load_more = false;
        } else {
          this.is_load_more = false;
          this.is_no_more = true;
        }
        console.log(`is no more ${this.is_no_more}`);
      });
      // this.fetchEmployeeProject(payload).then((response) => {
      //   if (response.length > 0) {
      //     response.forEach(async (resp) => {
      //       await current_project_data.push(resp);
      //     });
      //     this.SET_EMPLOYEE_PROJECT(current_project_data);
      //     this.is_load_more = false;
      //   } else {
      //     this.is_load_more = false;
      //     this.is_no_more = true;
      //   }
      //   console.log(`is no more ${this.is_no_more}`);
      // });
    },

    handleSaveProjects() {
      Swal.fire({
        title: "Please wait!",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const payload = {
        employee_id: this.getUserLoginData.id,
        project_name: this.filter.project_name.toString().toLowerCase(),
        month: this.filter.month_only,
        year: this.filter.year_only,
        status_project: this.filter.status_project.toString().toLowerCase(),
        target_status: this.filter.target_status.toString().toLowerCase(),
      };
      this.exportProjectExcel(payload)
        .then((response) => {
          window.open(response, "_target");
          Swal.fire({
            position: "top-end",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...!",
            text: `Error: ${error}`,
          });
        });
    },
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },

    projectsData() {
      return this.$store.state.eraforce.employee_project;
    },

    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
  watch: {},
};
</script>
