<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row mb-3">
        <el-scrollbar height="120px">
          <div class="scrollbar-flex-content">
            <div v-for="(data, index) in getUserSummary.label" :key="index">
              <card
                class="scrollbar-demo-item"
                :title="getUserSummary.label[index]"
                :value="getUserSummary.result[index]"
                directionReverse
              ></card>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="row">
        <div class="col-lg-6 mb-lg">
          <div class="card z-index-2">
            <AgeChart />
          </div>
        </div>
        <div class="col-lg-6">
          <GenderChart />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-7 mb-lg-0 mb-4">
          <DepartementChart />
        </div>
        <div class="col-lg-5">
          <EducationChart />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <LengthOfServiceChart />
        </div>
        <div class="col-lg-6">
          <OfficeChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/examples/Cards/Card.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "@/views/components/Carousel.vue";
import CategoriesCard from "@/views/components/CategoriesCard.vue";

import AgeChart from "@/components/GooHRD/AgeChart.vue";
import GenderChart from "@/components/GooHRD/GenderChart.vue";
import DepartementChart from "@/components/GooHRD/DepartementChart.vue";
import EducationChart from "@/components/GooHRD/EducationChart.vue";
import LengthOfServiceChart from "@/components/GooHRD/LengthOfServiceChart.vue";
import OfficeChart from "@/components/GooHRD/OfficeChart.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

const body = document.getElementsByTagName("body")[0];

import { mapActions } from "vuex";

export default {
  name: "go-force",
  data() {
    return {
      stats: {
        money: {
          title: "Today's Money",
          value: "$53,000",
          percentage: "+55%",
          iconClass: "ni ni-money-coins",
          detail: "since yesterday",
          iconBackground: "bg-gradient-primary",
        },
        users: {
          title: "Today's Users",
          value: "2,300",
          percentage: "+3%",
          iconClass: "ni ni-world",
          iconBackground: "bg-gradient-danger",
          detail: "since last week",
        },
        clients: {
          title: "New Clients",
          value: "+3,462",
          percentage: "-2%",
          iconClass: "ni ni-paper-diploma",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-success",
          detail: "since last quarter",
        },
        sales: {
          title: "Sales",
          value: "$103,430",
          percentage: "+5%",
          iconClass: "ni ni-cart",
          iconBackground: "bg-gradient-warning",
          detail: "than last month",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },

  components: {
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    AgeChart,
    GenderChart,
    DepartementChart,
    EducationChart,
    LengthOfServiceChart,
    OfficeChart,
  },

  computed: {
    getUserSummary() {
      var cc = this.$store.state.hrd.summary;
      // console.log("ini cla: ", cc.result[0]);
      return this.$store.state.hrd.summary;
    },
  },

  methods: {
    ...mapActions({
      fetchAgeChart: "hrd/fetchAgeChart",
      fetchUserSummary: "hrd/fetchUserSummary",
    }),
  },

  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.layout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
    this.fetchAgeChart();
    this.fetchUserSummary();
    this.$store.dispatch("fetchWeatherData");
  },
};
</script>

<style scoped>
.scrollbar-flex-content {
  display: flex;
}
.scrollbar-demo-item {
  flex-shrink: 0;
  margin-right: 20px;
  /* cursor: pointer; */
}
</style>
