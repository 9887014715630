<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold"> New Tasks </span>
        </div>
      </div>
    </div>

    <!-- <div class="card p-3 mb-3">
      <el-scrollbar height="500px">{{ getBoard.data }} </el-scrollbar>
    </div> -->

    <el-scrollbar height="500px">
      <div class="card p-3 mb-3">
        <div class="row">
          <div class="col-md-7">
            <div class="form-group">
              <label class="form-control-label" for="basic-url">Title <span class="text-danger">*</span></label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="basic-addon3"
                  placeholder="Title"
                  v-model="formAddTasks.title"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="form-control-label" for="basic-url"
                >Description <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <textarea
                  class="form-control"
                  aria-label="With textarea"
                  style="height: 300px"
                  placeholder="Description"
                  v-model="formAddTasks.description"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label class="form-control-label" for="basic-url"
                >Deadline (Not Necessary) <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  type="datetime-local"
                  class="form-control"
                  aria-describedby="basic-addon3"
                  placeholder="Title"
                  v-model="formAddTasks.due_date"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Project <span class="text-danger">*</span></label>
              <select class="form-control" @change="handleBoard($event)">
                <option value="0">Choose Project</option>
                <option v-for="item in board" :value="item.id_board" :key="item">
                  {{ item.nm_board }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Position <span class="text-danger">*</span></label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                :disabled="id_board == 0"
                @change="handleKanban($event)"
              >
                <option value="0">Choose List <span class="text-danger"></span></option>
                <option v-for="kbn in kanban" :value="kbn.id_kanban" :key="kbn">
                  {{ kbn.title }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Assign To <span class="text-danger">*</span></label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                :disabled="id_board == 0"
                @change="getAssign($event)"
              >
                <option value="0">Assign To</option>
                <option v-for="usr in user" :value="usr.user.id_user" :key="usr">{{ usr.user }}</option>
              </select>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              style="margin-top: 30px"
              :disabled="id_board == 0 || id_kanban == 0"
              @click="handleAddTasks()"
            >
              Create Task
            </button>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";

export default {
  name: "GoappsAdminBoards",
  components: {
    ArgonButton,
  },

  data() {
    return {
      params: {
        id_board: "",
        search: "",
        order: "",
      },
      form: {
        id_board: null,
        nm_board: null,
        background: null,
        public: null,
      },
      formBoard: {
        id_board: "Choose Project",
      },
      formAddTasks: {
        title: "",
        description: "",
        due_date: "",
        type: "text",
        kanban_id: "",
      },
      id_board: 0,
      id_kanban: 0,
      board: [],
      kanban: [],
      user: [],
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
      assign: "",
    };
  },

  beforeMount() {
    this.fetchBoardData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      // Board
      fetchBoard: "gopro/fetchBoard",
      doCreateBoard: "gopro/doCreateBoard",
      doUpdateBoard: "gopro/doUpdateBoard",
      doDeleteDivisi: "gopro/doDeleteBoard",

      fetchKanban: "gopro/fetchKanban",
      fetchCollabolator: "gopro/fetchCollabolator",

      doCreateCard: "gopro/doCreateCard",
    }),

    handleAddTasks() {
      const payload = {
        kanban_id: this.id_kanban,
        title: this.formAddTasks.title,
        type: this.formAddTasks.type,
        description: this.formAddTasks.description,
        start_date: new Date(),
        due_date: this.formAddTasks.due_date,
      };

      this.doCreateCard(payload).then((response) => {
        ElNotification({
          title: "Viola!",
          message: "Success Create Tasks",
          type: "success",
        });
        this.id_board = 0;
        this.id_kanban = 0;
        this.formAddTasks.title = "";
        this.formAddTasks.description = "";
        this.formAddTasks.due_date = "";
      });
    },

    getAssign(event) {
      this.assign = event.target.value;
      console.log(this.assign);
    },

    handleBoard(event) {
      this.id_board = event.target.value;
      console.log(this.id_board);
      if (this.id_board == 0) {
        this.kaban = [];
      }
      this.fetchKanbanData();
      this.boardCollabData();
    },

    handleKanban(event) {
      this.id_kanban = event.target.value;
      console.log("ID KANBAN", this.id_kanban);
    },

    boardCollabData() {
      const payload = {
        id_board_collabolator: "",
        board_id: this.id_board,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchCollabolator(payload)
        .then((response) => {
          this.user = response;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchKanbanData() {
      const payload = {
        id_kanban: "",
        board_id: this.id_board,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchKanban(payload)
        .then((response) => {
          this.kanban = response;
          ElNotification({
            title: "Viola!",
            message: "Success",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    fetchBoardData() {
      const payload = {
        id_board: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchBoard(payload)
        .then((response) => {
          this.board = response.data;
          console.log("SUCCESS GET BOARD");
          console.log(this.board);
        })
        .catch((err) => {
          this.board = null;
          console.log("Error GET BOARD");
          console.log(err);
        });
    },
  },
};
</script>
