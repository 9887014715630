<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold"> Boards </span>
        </div>
        <!-- <div>
          <argon-button
            variant="gradient"
            color="info"
            size="md"
            @click="handleOpenAdd()"
          >
            Add Boards
          </argon-button>
        </div> -->
      </div>
    </div>

    <!-- <div class="card p-3 mb-3">
      <el-scrollbar height="500px">{{ getBoard.data }} </el-scrollbar>
    </div> -->

    <div class="card p-3" v-if="is_loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="card p-3 mb-3" v-else>
      <!-- <el-scrollbar height="500px">{{ getBoard }} </el-scrollbar> -->
      <table class="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Board Name
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Background
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Public
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Collabolator
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Status
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in boards" :key="item.id_board">
            <td>
              <div class="d-flex px-2">
                <div class="my-auto">
                  <h6 class="mb-0 text-sm">{{ item.nm_board }}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="my-auto text-sm font-weight-bold mb-0 text-white text-center" :style="{'background-color': item.background }">Colors</p>
              <!-- <p class="text-sm font-weight-bold mb-0">{{ item.background }}</p> -->
            </td>
            <td v-if="item.public == 0">
              <span class="badge badge-sm bg-gradient-danger">Private</span>
            </td>
            <td v-else>
              <span class="badge badge-sm bg-gradient-success">Public</span>
            </td>

            <td class="align-middle">
              <div class="avatar-group">
                <a
                  href="javascript:;"
                  class="avatar avatar-xs border-0 rounded-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  v-for="collab in item.collab"
                  :key="collab"
                >
                  <img
                    alt="Image placeholder"
                    src="../../assets/img/team-3.jpg"
                  />
                </a>
              </div>
            </td>
            <td class="align-middle text-center">
              <argon-button
                variant="gradient"
                color="primary"
                size="sm"
                class="m-1"
                @click="handleOpenEdit(item)"
              >
                Edit
              </argon-button>
              <argon-button
                variant="gradient"
                color="danger"
                size="sm"
                class="m-1"
                @click="handleOpenRemove(item)"
              >
                Delete
              </argon-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Board -->
    <el-dialog
      v-model="add_dialog"
      title="Add New Board"
      width="30%"
      :before-close="handleCloseAdd()"
    >
      <span>
        <el-input
          v-model="form.nm_board"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Board Name"
          :suffix-icon="EditPen"
        />
        <div>
          <label>Background Board: </label>
          <input type="color" class="m-2 w-100" v-model="form.color" />
        </div>
        <el-input
          v-model="form.background"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Public"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="handeUpdateBoard()"
            class="bg-gradient-success"
            :disabled="
              formEdit.nm_board == null ||
              formEdit.nm_board == '' ||
              formEdit.background == null ||
              formEdit.color == null
            "
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Delete Board -->
    <el-dialog
      v-model="remove_dialog"
      title=""
      width="30%"
      :before-close="handleCloseRemove()"
    >
      <span> Are you sure want to delete board: <b>{{deleteBoard.title}}</b> ? </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" class="bg-gradient-success" @click="submitDeleteBoard()">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- Update Board -->
    <el-dialog
      v-model="edit_dialog"
      title="Update Board"
      width="30%"
    >
      <span>
        <el-input
          v-model="formEdit.nm_board"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Board Name"
          :suffix-icon="EditPen"
        />
        <div>
          <label>Background Board: </label>
          <input type="color" class="m-2 w-100" v-model="formEdit.color" />
        </div>
        <!-- <el-input
          v-model="form.background"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Public"
          :suffix-icon="EditPen"
        /> -->
        <select class="form-control m-2 w-100" @change="handlePublic($event)">
          <option disabled="true" selected="true">
            Choose Public
          </option>
          <option value="1">Public</option>
          <option value="0">Private</option>
        </select>
        Public Type: <span class="badge badge-sm bg-gradient-danger" v-if="formEdit.public == 0">Private</span><span class="badge badge-sm bg-gradient-success" v-else>Public</span>
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="handeUpdateBoard()"
            class="bg-gradient-success"
            :disabled="
              formEdit.nm_board == null ||
              formEdit.nm_board == '' ||
              formEdit.color == null
            "
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";
import Card from "@/components/base/BaseCard.vue";

// const store = useStore();
// const board = computed(() => {
//   return store.state.board;
// })

export default {
  name: "GoappsAdminBoards",
  components: {
    ArgonButton,
  },

  data() {
    return {
      boards: [],
      is_loading: false,
      params: {
        id_board: "",
        search: "",
        order: "asc",
      },
      form: {
        id_board: '',
        nm_board: null,
        background: null,
        public: null,
      },
      formEdit: {
        id_board: "",
        nm_board: null,
        background: null,
        public: null,
        color: null,
      },
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
      deleteBoard: {
        id_board: "",
        title: "",
      },
    };
  },

  mounted() {
    this.fetchBoardData();
  },

  methods: {
    ...mapActions({
      fetchBoardV2: "gopro/fetchBoardV2",
      doCreateBoard: "gopro/doCreateBoard",
      doUpdateBoard: "gopro/doUpdateBoard",
      doDeleteBoard: "gopro/doDeleteBoard",
    }),

    fetchBoardData() {
      this.is_loading = true;
      const payload = {
        id_board: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchBoardV2(payload).then((response) => {
        this.boards = response.data;
        console.log(this.boards);
        this.is_loading = false;
      });
    },

    handleTest() {
      console.log("Hello World");
    },

    handlePublic(event) {
      let value = event.target.value;
      this.formEdit.public = value;
    },

    handeUpdateBoard() {
      const payload = {
        id_board: this.formEdit.id_board,
        nm_board: this.formEdit.nm_board,
        public: this.formEdit.public,
        color: this.formEdit.color,
      };

      this.doUpdateBoard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success update board!",
            type: "success",
          });
          this.formEdit.id_board = "";
          this.formEdit.nm_board = "";
          this.formEdit.public = "";
          this.formEdit.color = "";
          this.edit_dialog = false;
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        })
        .catch((error) => {
          ElNotification({
            title: "Viola!",
            message: "Error update board!",
            type: "error",
          });
          this.formEdit.id_board = "";
          this.formEdit.nm_board = "";
          this.formEdit.public = "";
          this.formEdit.color = "";
          this.edit_dialog = false;
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        })
    },

    handleCreateBoard() {
      console.log("____ its works!");
      const params = {
        nm_board: this.form.nm_board,
        background: this.form.background,
        public: this.form.public,
      };
      this.doCreateBoard(params)
        .then((response) => {
          // this.handleCloseAdd();
          ElNotification({
            title: "Viola!",
            message: "Success create board!",
            type: "success",
          });
          console.log("___________ WKWK", response);
          // this.fetchBoardData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    submitDeleteBoard() {
      const payload = {
        id_board: this.deleteBoard.id_board
      };

      this.doDeleteBoard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success delete board!",
            type: "success",
          });
          this.deleteBoard.id_board = "";
          this.deleteBoard.title = "";
          this.remove_dialog = false;
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        })
        .catch((error) => {
          ElNotification({
            title: "Viola!",
            message: "Error delete board!",
            type: "error",
          });
          this.deleteBoard.id_board = "";
          this.deleteBoard.title = "";
          this.remove_dialog = false;
        })
    },

    // Handle Add
    handleOpenAdd() {
      this.add_dialog = true;
    },

    // Handle Open Edit
    handleOpenEdit(arg) {
      const id_board = arg.id_board;
      const nm_board = arg.nm_board;
      const color = arg.background;
      const publics = arg.public;
      this.formEdit.id_board = id_board;
      this.formEdit.nm_board = nm_board;
      this.formEdit.color = color;
      this.formEdit.public = publics;
      this.edit_dialog = true;
    },

    handleOpenRemove(arg) {
      this.deleteBoard.title = arg.nm_board;
      this.deleteBoard.id_board = arg.id_board;
      this.remove_dialog = true;
    },

    // Handle Close
    handleCloseAdd() {
      this.form.nm_board = null;
      this.form.background = null;
      this.form.public = null;
    },

    handleCloseEdit() {
      this.form.nm_board = null;
      this.form.background = null;
      this.form.public = null;
    },

    handleCloseRemove() {
      this.form.id_board = null;
      this.form.nm_board = null;
      this.form.background = null;
      this.form.public = null;
    },
  },

  // computed: {
  //   getBoard() {
  //     return this.$store.state.gopro.board;
  //   },
  // },
};
</script>
