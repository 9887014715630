<template>
  <div class="row">
    <Card :title="'Profit Rank'">
      <template #body>
        <div>
          <div class="row">
            <div class="row mb-3">
              <div class="col-12">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  start-placeholder="start date"
                  end-placeholder="end date"
                  value-format="YYYY-MM-DD"
                  @change="changeDateFilter"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
          <div
            class="loading d-flex justify-content-center mt-3"
            v-if="loading_employee"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="p-0" v-else>
            <el-scrollbar height="500px">
              <div class="p-3">
                <div
                  class="card p-3 mb-3"
                  v-for="(data, index) in getDataProfit"
                  :key="index"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div
                      class="d-flex justify-content-start align-items-center"
                    >
                      <div class="img">
                        <img :src="data.image" alt="" class="img-rank" />
                      </div>
                      <div class="d-flex flex-column">
                        <span class="text-bold">
                          {{ data.employee.name }}
                        </span>
                        <span class="text-start">
                          IDR {{ numeralFormat(data.total) }}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h1 v-if="index == 0">
                        <i class="fas fa-trophy text-warning"></i>
                      </h1>
                      <h4 v-else-if="index >= 1 && index <= 4">
                        <i class="fas fa-trophy text-info"></i>
                      </h4>
                      <h4 v-else-if="index >= 5 && index <= 8">
                        <i class="fas fa-trophy text-primary"></i>
                      </h4>
                      <h4 v-else-if="index >= 9">
                        <i class="fas fa-trophy text-secondary"></i>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { mapActions, mapMutations } from "vuex";

import { format } from "date-fns";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      search: "",
      loading_employee: false,
      dialogVisible: false,
      target_team: null,
      selected_record: null,
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
  },
  mounted() {},
  beforeMount() {
    this.fetchProfitRankData();
  },
  methods: {
    ...mapActions({
      fetchProfitRank: "eraforce/fetchProfitRank",
    }),
    ...mapMutations({}),
    fetchProfitRankData() {
      this.loading_employee = true;
      //   this.SET_SELECTED_EMPLOYEE_DATA("");
      let payload = {
        first_date: this.startDate,
        last_date: this.endDate,
        team_id: this.getUserData.team_id,
      };
      this.fetchProfitRank(payload).then((response) => {
        this.loading_employee = false;
      });
    },

    changeDateFilter(range) {
      if (range) {
        this.startDate = `${range[0]} 00:00:00`;
        this.endDate = `${range[1]} 23:59:59`;
      } else {
        const today = format(new Date(), "yyyy-MM-dd");
        this.startDate = `${today} 00:00:00`;
        this.endDate = `${today} 23:59:59`;
      }
      this.fetchProfitRankData();
    },
  },
  computed: {
    getDataProfit() {
      return this.$store.state.eraforce.list_profit_rank;
    },

    getUserData() {
      return this.$store.state.eraforce.user_data;
    },
  },
  watch: {
    search(val) {
      this.fetchAllEmployee();
    },
  },
};
</script>

<style scoped>
.img-rank {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 30px;
}
</style>
