<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold"> Boards</span>
        <div>
          <argon-button
            variant="gradient"
            color="info"
            size="md"
            @click="handleOpenAddBoard()"
          >
            Add Board 
          </argon-button>
        </div>
      </div>
    </div>

    <div class="card p-3" v-if="is_loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>

    <div class="card p-3 mb-3" v-else>
      <div class="row">
        <div
          class="col-xl-3 col-sm-6 mb-xl-0 mb-4 mt-2"
          v-for="item in getBoard.data"
          :key="item.id_board"
        >
          <a :href="`kanbans/${item.id_board}`">
            <div class="card bg-transparent shadow-xl">
              <div class="ribbon">
                <span :style="{ background: `${item.background}` }">Board</span>
              </div>
              <div
                class="overflow-hidden position-relative border-radius-xl"
                style="
                  background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/card-visa.jpg');
                "
              >
                <span class="mask bg-gradient-dark"></span>
                <div class="card-body position-relative z-index-1 p-3">
                  <a href="#" v-if="item.user_id == getUserLoginData.user.id_user" @click="handleDelete(item)">
                    <i class="fas fa-trash-alt text-white"></i>
                  </a>
                  <i class="fas fa-clipboard-list text-white p-2" v-else></i>
                  <h5 class="text-white mt-4 mb-2 pb-2">{{ item.nm_board }}</h5>
                  <div class="d-flex">
                    <div class="me-4">
                      <p class="text-white text-sm opacity-8 mb-0">
                        Created Date
                      </p>
                      <h6 class="text-white mb-0">
                        {{ getDate(item.updated_at) }}
                      </h6>
                    </div>
                    <div
                      class="ms-auto w-50 d-flex align-items-end justify-content-end"
                    >
                      <!-- <img
                        class="w-60 mt-2"
                        src="../../assets/img/logos/mastercard.png"
                        alt="logo"
                      /> -->
                      <div class="avatar-group">
                        <a
                          href="javascript:;"
                          class="avatar avatar-sm border-0 rounded-circle"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="13 New Messages"
                          v-for="collab in item.collab"
                          :key="collab.id_board_collab"
                        >
                          <img
                            alt="Image placeholder"
                            src="../../assets/img/team-3.jpg"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>

        <!-- <div
          class="col-xl-3 col-sm-6 mb-xl-0 mb-4 mt-2"
          v-for="item in getBoard.data"
        >
          <a :href="`kanbans/${item.id_board}`">
            <div class="card bg-success text-white" role="button">
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-8">
                    <div class="numbers">
                      <p class="text-sm mb-0 text-uppercase font-weight-bold">
                        {{ item.nm_board }}
                      </p>
                      <h5 class="font-weight-bolder"></h5>
                      <span class="mb-0"> {{ item.updated_at }}</span>
                    </div>
                  </div>
                  <div class="col-4 text-end">
                    <a href="#" @click="handleOpenRemoveBoard(item)">
                      <div
                        class="icon icon-shape bg-gradient-danger shadow-primary text-center rounded-circle"
                      >
                        <i
                          class="fas fa-trash-alt text-lg opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div> -->
      </div>
    </div>
    <!-- <draggable
      v-model="myArray"
      group="people"
      @start="drag = true"
      @end="drag = false"
      item-key="id"
    >
      <template #item="{ element }">
        <div>{{ element.content }}</div>
      </template>
    </draggable> -->

    <!-- Add Board -->
    <el-dialog
      v-model="add_dialog"
      title="Add New Board"
      width="50%"
      :before-close="handleCloseAddBoard()"
    >
      <span>
        <el-input
          v-model="form.nm_board"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Board Name"
          :suffix-icon="EditPen"
        />
        <div>
          <label>Color Board: </label>
          <input type="color" class="m-2 w-100" v-model="form.background" />
        </div>
        <!-- <el-input
          v-model="form.public"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Public"
          :suffix-icon="EditPen"
        /> -->
        <div class="form-group">
          <label>Public <span class="text-danger">*</span></label>
          <select class="form-control" @change="handlePublic($event)">
            <option value="0">Private</option>
            <option value="1">Public</option>
          </select>
        </div>
      </span>

      <hr />
      <div class="mb-2">
        <label>Divisi</label>
        <select
          class="form-select"
          aria-label="Default select example"
          @change="getUsers($event)"
        >
          <option selected="true" disabled="true">Divisi</option>
          <option v-for="divisi in getDivisi" :value="divisi.id_departement" :key="divisi.id_departement">
            {{ divisi.departement }}
          </option>
        </select>
      </div>
      <div>
        <label>Collabolator</label>
        <div v-if="selected_user_app != null">
          <span class="text-center" v-if="is_loading_menu_data">
            <h6>Please wait...</h6>
          </span>
          <el-transfer
            v-else
            v-model="selected_menu_apps"
            :props="{
              key: 'id_user',
              label: 'fullname',
            }"
            :titles="['Source', 'Target']"
            :data="list_app_menu"
            style="width: 100%"
          />
        </div>

        <!-- Baru -->
        <!-- <div class="form-group">
          <select class="form-control" @change="addedCollab($event)">
            <option value="0">Choose Users</option>
            <option v-for="item in fetchDepartement" :value="item.id_user" :fullname="item.fullname" :id="item.id_user" :key="item.id_user">
              {{ item.fullname }}
            </option>
          </select>
        </div> -->

        <!-- Lama -->
        <!-- <select
          class="form-select"
          size="3"
          aria-label="Default select example"
          v-model="form.user_id"
        >
          <option selected>Select a division first</option>
          <option
            v-for="testing in fetchDepartement"
            v-bind:value="testing.id_user" :key="testing.id_user"
          >
            {{ testing.fullname }}
          </option>
        </select> -->
      </div>
      <!-- <div v-if="user.length > 0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usr, index) in user" :key="usr">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ usr.fullname }}</td>
              <td>
                <span class="badge badge-danger" style="cursor: pointer;" @click="removeUser(index)">Delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <template #footer>
        <!-- handleCreateBoard -->
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="handleCreateBoard"
            class="bg-gradient-success"
            :disabled="
              form.nm_board == null ||
              form.nm_board == '' ||
              form.background == null
            "
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Add Board -->

    <!-- Delete Board -->
    <el-dialog v-model="remove_dialog" title="" width="30%">
      <span> Are you sure want to delete Board: <b>{{ deleteBoard.nm_board }}</b> ? </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            @click="submitDeleteBoard()"
            class="bg-gradient-success"
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- End Delete Board -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, mapMutations, useStore } from "vuex";

export default {
  name: "GoappsAdminDashboard",

  components: {
    draggable,
    ArgonButton,
  },

  data() {
    return {
      fetchDepartement: "",
      is_loading: false,
      is_loading_menu_data: false,
      selected_user_app: "",
      selected_menu_apps: [],
      user: [],
      list_app_menu: [],
      params: {
        id_board: "",
        search: "",
        order: "asc",
      },
      paramsDivisi: {
        id: "",
        search: "",
        order: "asc",
      },
      users: {
        id_user: "",
        id_departement: "",
        search: "",
        order: "desc",
      },
      paramsUsers: {
        id: "",
        search: "",
        page: 1,
        order: "ASC",
        join_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        end_date: "test",
        out_date: {
          start_date: "",
          end_date: "",
          date_range: [null, null],
        },
        employeement_status: "All",
        office_location: "All",
      },
      form: {
        id_board: null,
        nm_board: null,
        background: null,
        public: 0,
        user_id: null,
      },
      paramsDelete: {
        id_board: "",
        nm_board: "",
        background: "",
        public: "",
      },
      is_table_loading: false,
      is_table_loading_divisi: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
      drag: false,
      boards: [],
      deleteBoard: {
        id_board: "",
        nm_board: "",
      },
    };
  },

  beforeMount() {
    this.fetchBoardData();
    this.fetchDivisiData();
    // this.getEmployeeData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchBoard: "gopro/fetchBoard",
      doCreateBoard: "gopro/doCreateBoard",
      doUpdateBoard: "gopro/doUpdateBoard",
      doDeleteBoard: "gopro/doDeleteBoard",
      doDeleteDivisi: "gopro/doDeleteBoard",

      // Fetch Employee
      fetchEmployee: "hrd/fetchEmployee",

      // Fetch Divisi
      fetchDivisi: "hrd/fetchDivisi",

      // Fetch User
      fetchUser: "gopro/fetchUser",
    }),

    ...mapMutations({
      SET_EMPLOYEE: "hrd/SET_EMPLOYEE",
    }),

    getUsers(event) {
      this.users.id_departement = event.target.value;
      const params = {
        id_user: this.users.id_user,
        id_departement: this.users.id_departement,
        search: this.users.search,
        order: this.users.order,
      };
      this.fetchUser(params)
        .then((response) => {
          // Baru
          // this.fetchDepartement = response.data;
          // console.log(this.fetchDepartement);

          if(this.list_app_menu.length == 0) {
            this.list_app_menu = response.data;
          } else {
            let merge = [...response.data, ...this.list_app_menu];
            this.list_app_menu = merge;
          }
          console.log(this.list_app_menu);

          // Lama
          // if(this.fetchDepartement.length == 0) {
          //   this.fetchDepartement = response.data;
          // } else {
          //   let merge = [...response.data, ...this.fetchDepartement];
          //   this.fetchDepartement = merge;
          // }
        })
        .catch((err) => {
          console.log(err);
          ElNotification({
            title: "Oops!",
            message: `Data Not Found`,
            type: "error",
            duration: 5000,
          });
        });
    },

    submitDeleteBoard() {
      const payload = {
        id_board: this.deleteBoard.id_board
      };

      this.doDeleteBoard(payload)
        .then((response) => {
          console.log(response);
          ElNotification({
            title: "Viola!",
            message: "Success delete board!",
            type: "success",
          });
          this.fetchBoardData();
          this.deleteBoard.id_board = "";
          this.deleteBoard.nm_board = "";
          this.remove_dialog = false;
        })
        .catch((error) => {
          ElNotification({
            title: "Viola!",
            message: "Error delete board!",
            type: "error",
          });
        })
    },

    handleDelete(id) {
      console.log(id);
      this.deleteBoard.id_board = id.id_board;
      this.deleteBoard.nm_board = id.nm_board;
      this.remove_dialog = true;
    },

    checkList() {
      const params = {
        nm_board: this.form.nm_board,
        background: this.form.background,
        public: this.form.public,
        user_id: this.selected_menu_apps,
      };
      console.log(params);
      console.log(this.selected_menu_apps);
    },

    // addedCollab(event) {
    //   var res = event.target.value;
    //   var fullname = event.target.options[event.target.options.selectedIndex].getAttribute('fullname');
    //   var id = event.target.options[event.target.options.selectedIndex].getAttribute('id');
    //   console.log(event.target);
    //   console.log(fullname);
    //   const data = {
    //     id_user: res,
    //     fullname: fullname
    //   };
    //   console.log(data);
    //   this.user.push(data);
    // },

    // removeUser(arg) {
    //   console.log(arg);
    //   const array = this.user;
    //   array.slice(arg, 1);
    //   this.user = array;
    //   console.log(this.user);
    // },

    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },

    getDate(date) {
      let dates = new Date(date);
      let day = dates.getDate();
      let month = dates.getMonth() + 1;
      let year = dates.getFullYear();
      let result = day + "/" + month + "/" + year;

      return result;
    },

    fetchDivisiData() {
      this.is_table_loading_divisi = true;
      const payload = {
        id: this.paramsDivisi.id,
        search: this.paramsDivisi.search,
        order: this.paramsDivisi.order,
      };
      this.fetchDivisi(payload).then((response) => {
        this.is_table_loading_divisi = false;
      });
    },

    getEmployeeData() {
      this.paramsUsers.page = 1;
      const parameter = {
        id: this.paramsUsers.id,
        search: this.paramsUsers.search,
        page: this.paramsUsers.page,
        order: this.paramsUsers.order,
        employeement_status: this.paramsUsers.employeement_status,
        office_location: this.paramsUsers.office_location,
        join_start: this.paramsUsers.join_date.start_date,
        join_end: this.paramsUsers.join_date.end_date,
        out_start: this.paramsUsers.out_date.start_date,
        out_end: this.paramsUsers.out_date.end_date,
      };
      this.fetchEmployee(parameter)
        .then((response) => {
          this.SET_EMPLOYEE(response);
        })
        .catch((error) => {
          this.SET_EMPLOYEE([]);
        });
    },

    handlePublic(event) {
      this.form.public = null;

      setTimeout(() => {
        this.form.public = event.target.value;
      }, 1500);
    },

    fetchBoardData() {
      this.is_loading = true;
      const payload = {
        id_board: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };
      console.log("ini pay: ", payload);
      this.fetchBoard(payload).then((response) => {
        this.is_loading = false;
        this.boards = response;
        console.log("ini resp: ", response);
      });
    },

    // Handle Create
    handleCreateBoard() {
      const params = {
        nm_board: this.form.nm_board,
        background: this.form.background,
        public: this.form.public,
        user_id: this.selected_menu_apps,
      };
      console.log(params);

      this.doCreateBoard(params)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success create board!",
            type: "success",
          });
          console.log("_____Results ", response);
          this.add_dialog = false;
          this.fetchDepartement = null;
          this.fetchBoardData();
        })
        .catch((err) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${err.message}`,
            type: "Error",
            duration: 5000,
          });
          console.log("_____Error ", err);
        });
    },

    handleDeleteBoard() {
      console.log("Test ID Board");
      const params = {
        id_board: this.paramsDelete.id_board,
      };
      this.doDeleteBoard(params)
        .then((response) => {
          this.remove_dialog = false;
          ElNotification({
            title: "Viola!",
            message: "Success remove board!",
            type: "success",
          });
          this.fetchBoardData();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleOpenAddBoard() {
      this.add_dialog = true;
    },

    handleOpenRemoveBoard(data) {
      // this.form = {
      //   id_board: data.id_board,
      //   nm_board: data.nm_board,
      //   background: data.background,
      //   public: data.public,
      // };
      this.paramsDelete.id_board = data.id_board;
      this.paramsDelete.nm_board = data.nm_board;
      this.paramsDelete.background = data.background;
      this.paramsDelete.public = data.public;

      this.remove_dialog = true;
    },

    handleCloseAddBoard() {
      this.form.id_board = null;
      this.form.nm_board = null;
      this.form.background = null;
      this.form.public = null;
    },

    handleCloseRemove() {},
  },

  computed: {
    getBoard() {
      return this.$store.state.gopro.board;
    },
    getEmployee() {
      return this.$store.state.hrd.employee;
    },
    getDivisi() {
      return this.$store.state.hrd.divisi;
    },
    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },
  },
};
</script>
<style scoped>
.red span {
  background: linear-gradient(#f70505 0%, #8f0808 100%);
}
.red span::before {
  border-left-color: #8f0808;
  border-top-color: #8f0808;
}
.red span::after {
  border-right-color: #8f0808;
  border-top-color: #8f0808;
}
.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}
.ribbon span {
  font-size: 0.8rem;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  right: -29px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.foo {
  clear: both;
}

.bar {
  content: "";
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.baz {
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 2em;
  transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 100px;
  left: 1000px;
}
</style>
