<template>
  <div>
    <div class="row">
      <Card :title="`Presence Data`">
        <template #body>
          <div class="d-flex justify-content-evenly align-items-center">
            <div class="row mb-3">
              <div class="col-12">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  start-placeholder="start date"
                  end-placeholder="end date"
                  value-format="YYYY-MM-DD"
                  @change="changeDateFilter"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <argon-button
                  class="mb-3"
                  variant="gradient"
                  color="info"
                  size="sm"
                  fullWidth
                  @click="handleSavePresence()"
                  :disabled="$store.state.eraforce.is_activity_card_loading"
                >
                  <div><i class="fa fa-print"></i> Save</div>
                </argon-button>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="mt-3" v-if="isAdmin">
      <div class="card">
        <div class="m-3">
          <div class="col-12">
            <argon-input
              type="text"
              placeholder="Search employee.."
              name="password"
              size="lg"
              fullWidth
              :value="search_placeholder"
              v-model="search_placeholder"
              @click="handleOpenSearchEmployee"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <PresenceCard v-else />
    </div>

    <el-dialog
      v-model="open_list_employee"
      title="Select Employee"
      width="30%"
      :show-close="false"
    >
      <div>
        <argon-input
          type="text"
          placeholder="Search.."
          name="name"
          size="lg"
          fullWidth
          :value="search"
          v-model="search"
          @change="handleSearchEmployee"
        />

        <div
          class="d-flex justify-content-center"
          v-if="loading_search_employee"
        >
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <el-scrollbar height="400px" v-else>
          <div
            style="margin: 10px"
            v-for="(user, index) in getUserEraforce"
            :key="index"
          >
            <div
              class="bg-grey-soft p-3 cursor-pointer"
              @click="handleSelectEmployee(user)"
            >
              <span>
                {{ user.full_name }}
              </span>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleCancelSearch">Cancel</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import PresenceCard from "@/views/GooSales/components/PresenceCard.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "Employee",
  data() {
    return {
      page: 1,
      perPage: 10,
      records: [],
      recordsLength: 0,
      selected_employee: null,
      startDate: format(new Date(), "yyyy-MM-dd 00:00:00"),
      endDate: format(new Date(), "yyyy-MM-dd 23:59:59"),
      dateRange: [
        format(new Date(), "yyyy-MM-dd 00:00:00"),
        format(new Date(), "yyyy-MM-dd 23:59:59"),
      ],
      search: "",
      search_placeholder: "",
      open_list_employee: false,
      loading_search_employee: false,
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    PresenceCard,
  },
  mounted() {},
  beforeMount() {
    this.getPresence();
  },
  methods: {
    ...mapActions({
      fetchAllActivity: "eraforce/fetchAllActivity",
      fetchCheckInList: "eraforce/fetchCheckInList",
      exportPresenceExcel: "eraforce/exportPresenceExcel",
      fetchUserEraforceAuth: "eraforce/fetchUserEraforceAuth",
    }),
    ...mapMutations({
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
    }),

    handleCancelSearch() {
      this.open_list_employee = false;
      this.search = "";
      this.selected_employee = null;
    },

    handleSearchEmployee() {
      this.loading_search_employee = true;
      const payload = {
        filter: "",
        name: this.search,
      };
      this.fetchUserEraforceAuth(payload).then((response) => {
        this.loading_search_employee = false;
      });
    },

    handleSelectEmployee(data) {
      this.selected_employee = data.id;
      this.search_placeholder = data.full_name;
      this.open_list_employee = false;
      this.getPresence();
    },

    updateHandler(page) {},

    changeDateFilter(range) {
      if (range) {
        this.startDate = `${range[0]} 00:00:00`;
        this.endDate = `${range[1]} 23:59:59`;
      } else {
        const today = format(new Date(), "yyyy-MM-dd");
        this.startDate = `${today} 00:00:00`;
        this.endDate = `${today} 23:59:59`;
      }
      this.getPresence();
    },

    handleOpenSearchEmployee() {
      const payload = {
        filter: "",
        name: this.search,
      };
      this.fetchUserEraforceAuth(payload).then((response) => {
        this.open_list_employee = true;
      });
    },

    getPresence() {
      let payload;
      if (this.getUserLoginData.status_id == 5) {
        payload = {
          employee_id: this.getUserLoginData.id,
          first_date: this.startDate,
          last_date: this.endDate,
          flag: true,
        };
      } else {
        payload = {
          employee_id: this.selected_employee,
          first_date: this.startDate,
          last_date: this.endDate,
          flag: true,
        };
      }
      this.SET_ACTIVITY_CARD_LOADING();
      this.fetchCheckInList(payload).then((response) => {
        this.SET_ACTIVITY_CARD_NOT_LOADING();
      });
    },

    handleSavePresence() {
      Swal.fire({
        title: "Please wait!",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const payload = {
        employee_id: this.getUserLoginData.id,
        first_date: format(new Date(this.startDate), "yyyy-MM-dd"),
        last_date: format(new Date(this.endDate), "yyyy-MM-dd"),
      };
      this.exportPresenceExcel(payload)
        .then((response) => {
          window.open(response, "_target");
          Swal.fire({
            position: "top-end",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...!",
            text: `Error: ${error}`,
          });
        });
    },
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },
    isAdmin() {
      return this.$store.state.eraforce.eraforce_user_login[0].status_id == 7;
    },
    getUserEraforce() {
      return this.$store.state.eraforce.user_eraforce;
    },
  },
  watch: {},
};
</script>
