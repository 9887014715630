<template>
  <div class="mt-5">
    <div class="row">
      <Card :title="` Arrival Detail`"> </Card>
    </div>

    <div class="mt-3">
      <Card class="p-3" v-if="$store.state.eraforce.is_activity_card_loading">
        <template #body>
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </Card>
      <div v-else>
        <div class="card h-100 mb-4">
          <div class="card-body pt-4 p-3">
            <ul class="list-group">
              <li
                class="list-group-item border-0"
                v-for="(arrival, index) in arrivalData"
                :key="index"
              >
                <div
                  class="d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                >
                  <div class="d-flex align-items-center">
                    <div class="mb-0 me-3 containers-image-revenue">
                      <img
                        :src="arrival.employee.image"
                        alt=""
                        class="card-img-revenue"
                      />
                    </div>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">
                        {{ arrival.employee.name }}
                      </h6>
                      <span class="text-xs text-primary"
                        >On Time: {{ arrival.arrival.on_time.length }}</span
                      >
                      <span class="text-xs text-danger"
                        >Late: {{ arrival.arrival.late.length }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center text-success text-gradient text-sm font-weight-bold"
                  >
                    <argon-button
                      color="success"
                      variant="outline"
                      size="sm"
                      class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center"
                      @click="handleDetail(arrival)"
                    >
                      <i class="fas fa-arrow-right"></i>
                    </argon-button>
                  </div>
                </div>
                <hr class="horizontal dark" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="dialogVisible"
      :title="'Arrival Detail'"
      width="40%"
      :show-close="false"
    >
      <div>
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="On Time" name="first">
            <el-scrollbar height="400px">
              <div
                v-for="(on_time, index) in selectedData.arrival.on_time"
                :key="index"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-bold"> Date </span>
                  <span class="text-bold"> Time </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-sm"> {{ on_time.date_in }} </span>
                  <span class="text-sm"> {{ on_time.time_in }} </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-bold"> Dist Alaydrus </span>
                  <span class="text-bold"> Dist Office 88 </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-sm"> {{ on_time.dist_alaydrus }}m </span>
                  <div>
                    <div
                      class="text-center icon icon-shape icon-sm"
                      :class="`${background(
                        on_time.is_match
                      )} ${'border-radius-2xl'}`"
                    >
                      <i
                        class="text-lg opacity-10"
                        :class="icon(on_time.is_match)"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <span class="text-sm"> {{ on_time.dist_kokas }}m </span>
                </div>
                <hr class="horizontal dark" />
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="Late" name="second">
            <el-scrollbar height="400px">
              <div
                v-for="(late, index) in selectedData.arrival.late"
                :key="index"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-bold"> Date </span>
                  <span class="text-bold"> Time </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-sm"> {{ late.date_in }} </span>
                  <span class="text-sm"> {{ late.time_in }} </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-sm"> {{ late.dist_alaydrus }}m </span>
                  <div>
                    <div
                      class="text-center icon icon-shape icon-sm"
                      :class="`${background(
                        late.is_match
                      )} ${'border-radius-2xl'}`"
                    >
                      <i
                        class="text-lg opacity-10"
                        :class="icon(late.is_match)"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <span class="text-sm"> {{ late.dist_kokas }}m </span>
                </div>
                <hr class="horizontal dark" />
              </div>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="dialogVisible = false"
            class="bg-gradient-primary"
          >
            OK
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  ArrowLeft,
  ArrowRight,
  Delete,
  Edit,
  Share,
  Search,
} from "@element-plus/icons-vue";
import { ElButton } from "element-plus";
import Swal from "sweetalert2";
import { format } from "date-fns";

import Card from "@/components/base/BaseCard.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import CloseDealCard from "@/components/GooSales/components/CloseDealCard.vue";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "Employee",
  data() {
    return {
      dialogVisible: false,
      selectedData: {},
      activeName: "first",
    };
  },
  components: {
    Card,
    ArgonBadge,
    ArgonButton,
    ArgonInput,
    CloseDealCard,
    ElButton,
  },
  mounted() {},
  beforeMount() {},
  methods: {
    ...mapActions({}),
    ...mapMutations({
      SET_ALREADY_CHECKIN: "eraforce/SET_ALREADY_CHECKIN",
      SET_ACTIVITY_CARD_LOADING: "eraforce/SET_ACTIVITY_CARD_LOADING",
      SET_ACTIVITY_CARD_NOT_LOADING: "eraforce/SET_ACTIVITY_CARD_NOT_LOADING",
      SET_ACTIVITY_MODAL_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_ACTIVE",
      SET_ACTIVITY_MODAL_NOT_ACTIVE: "eraforce/SET_ACTIVITY_MODAL_NOT_ACTIVE",
      SET_EMPLOYEE_PROJECT: "eraforce/SET_EMPLOYEE_PROJECT",
      CLOSE_UPDATE_INFORMATION_DIALOG:
        "eraforce/CLOSE_UPDATE_INFORMATION_DIALOG",
      SET_PROJECT_PAYLOAD_DATA: "eraforce/SET_PROJECT_PAYLOAD_DATA",
    }),

    handleDetail(detail) {
      this.selectedData = detail;
      this.dialogVisible = true;
    },

    icon(match) {
      if (match == 1) {
        return "fas fa-map-pin";
      } else if (match == 0) {
        return "fas fa-map-pin";
      } else {
        return "fas fa-map-pin";
      }
    },
    background(match) {
      if (match == 1) {
        return "bg-gradient-success";
      } else if (match == 0) {
        return "bg-gradient-danger";
      } else {
        return "bg-gradient-info";
      }
    },
  },
  computed: {
    getUserLoginData() {
      return this.$store.state.eraforce.eraforce_user_login[0];
    },

    arrivalData() {
      return this.$store.state.eraforce.statistic_arrival_detail;
    },
  },
  watch: {},
};
</script>

<style scoped>
.card-img-revenue {
  border-radius: 10px;
  display: block;
  width: 100px;
  height: 100px;
  background-size: cover;
  object-fit: cover;
}

.containers-image-revenue {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
