<template>
  <!-- <div class="card mt-3"> -->
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <!-- <h6>{{ title }} {{currentDate()}}</h6> -->
      <h6>{{ title }}</h6>
      <div style="display:none">{{ getPeriod }}</div>
      <!-- {{ getPeriod }} -->
      <p class="text-sm">
        <i class="fa fa-arrow-up text-success"></i>
        <span class="font-weight-bold">{{ detail1 }}</span>
        {{ detail2 }}
      </p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="chart-lines" class="chart-canvas" height="300"></canvas>
        <!-- <canvas id="myChart" class="chart-canvas" height="300"></canvas> -->
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { mapActions, mapMutations, useStore } from "vuex";
export default {
  name: "gradient-line-chart",

  data() {
    return {
      role: '',
      dept: '',
      isAuthorized:'',
      is_loading: false,
      params: {
        dept: "",
        start_date: "",
        end_date: "",
      },
    };
  },


  props: {
    title: {
      type: String,
      default: "Completed Tasks Based on Department",
    },
    // detail1: {
    //   type: String,
    //   default: "4% more",
    // },
    // detail2: {
    //   type: String,
    //   default: "in 2021",
    // },
  },

  computed: {
    getEmployee() {
      // console.log("ini statenya emp: ", this.$store.state.gopro.employee);
      return this.$store.state.gopro.emp_card;
    },
    getPeriod() {
      // return this.$store.state.gopro.emp_card;
      console.log("ini table period ", this.$store.state.gopro.period);
      this.getDataChart();
      return this.$store.state.gopro.period;
    }
  },
  
  methods: {
    ...mapActions({
      fetchDepartementChart: "hrd/fetchDepartementChart",
      fetchDeptChart: "gopro/fetchDeptChart",

      fetchUserPieTask: "gopro/fetchMemberCardPie",
    }),

    ...mapMutations({
      // SET_EMPLOYEE: "hrd/SET_EMPLOYEE",
      SET_EMP_CARD: "gopro/SET_EMP_CARD",
      SET_ST_ED: "gopro/SET_ST_ED",
    }),

    // getEmployeeCard() {
    //   this.is_table_loading = true;
    //   this.params.page = 1;
    //   const params = {
    //     dept: this.params.dept,
    //     start_date: this.$store.state.gopro.period.start_date,
    //     end_date: this.$store.state.gopro.period.end_date,
    //   };
    //   // console.log("ini param dept: ", this.params.dept);
    //   // this.fetchEmployee(params)
    //   // this.fetchExcelEmployee(params)
    //   this.fetchUserTask(params)
    //     .then((response) => {
    //       this.SET_EMP_CARD(response);
    //       // console.log("ini state emp: ", response);
    //       // console.log("ini type state emp: ", typeof response);
    //       this.is_table_loading = false;
    //     })
    //     .catch((error) => {
    //       console.log("ini error: ", error);
    //       this.is_no_more = true;
    //       this.is_table_loading = false;
    //       this.SET_EMP_CARD([]);
    //     });
    // },

    getUserLoginRole() {
      // console.log("ini rolenya: ", this.$store.state.dashboard_app_role);
      this.role = this.$store.state.dashboard_app_role;
      this.setUserPermission(this.$store.state.dashboard_app_role);
      // console.log("ini authorized lgn: ", this.isAuthorized);
      this.params.dept = this.$store.state.dashboard_app_dept;
      // console.log("ini dept lgn: ", this.params.dept);
      return this.params.dept;
    },

    setUserPermission(data) {
      // console.log("ini data: ", data);
      const dataUp = data.toUpperCase();
      // console.log("ini data: ", dataUp);
      if (
        //if user is super admin
        dataUp === "SUPER ADMIN" 
        // ||
        // data === "Manager"
      ) 
      {
        this.isAuthorized = "admin";
      }
      else if(dataUp.includes("MANAGER"))
      {
        this.isAuthorized = "mgr";
      }
      else
      {
        this.isAuthorized = "staff";
      }
      // console.log("ini autho: ", this.isAuthorized);
      return this.isAuthorized;
    },

    async getDataChart() {
      this.is_loading = true;
      // this.fetchDepartementChart().then((resp) => {

      // const resp =this.fetchDeptChart().then((resp) => {
      //   return resp;   
      // });
        this.getUserLoginRole();

        let resp = [];

        // console.log("ini authorized: ", this.isAuthorized);
        if (this.isAuthorized == "admin")
        {
          console.log("ini authorized adm: ", this.isAuthorized);
          const params = {
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
              dept: '',
          };
          // resp = await this.fetchDeptChart(params);

          try {
            console.log("ini paramku: ", params);
            // resp = await this.fetchDeptChart(params);
            
            resp = await this.fetchUserPieTask(params);
            console.log("ini pie:", resp);           
          } catch (error) {
            console.log("error: ", error);
          }

        }
        else if (this.isAuthorized == "mgr" || this.isAuthorized == "staff")
        {
          // console.log("ini authorized mgr st: ", this.isAuthorized);
          this.params.page = 1;
          const params = {
              dept: this.params.dept,
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
          };

          try {
            resp = await this.fetchUserPieTask(params);
            console.log("ini pie:", resp);
          } catch (error) {
            console.log("error: ", error);
          }

        }

            if (
                window.myChart !== undefined
                &&
                window.myChart !== null
            ) {
                window.myChart.destroy();
            }
            
            var ctx1 = document.getElementById("chart-lines").getContext("2d");
            // var ctx1 = document.getElementById("myChart").getContext("2d");

            var gradientStroke1 = ctx1.createLinearGradient(0, 230, 0, 50);

            gradientStroke1.addColorStop(1, "rgba(94, 114, 228, 0.2)");
            gradientStroke1.addColorStop(0.2, "rgba(94, 114, 228, 0.0)");
            gradientStroke1.addColorStop(0, "rgba(94, 114, 228, 0)");
            window.myChart = new Chart(ctx1, {
              plugins: [ChartDataLabels],
              type: "pie",
              data: {
                // labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                labels: resp.label,
                datasets: [
                  {
                    // label: "Mobile apps",
                    tension: 0.4,
                    borderWidth: 0,
                    pointRadius: 0,
                    // borderColor: "rgba(204, 255, 204, 0.2)",
                    // backgroundColor: gradientStroke1,
                    backgroundColor: [
                      "rgba(255, 255, 204, 0.4)",
                      "rgba(255, 204, 153, 0.4)",
                      "rgba(255, 204, 204, 0.4)",
                      "rgba(255, 153, 204, 0.4)",
                      "rgba(255, 204, 255, 0.4)",
                      "rgba(204, 153, 255, 0.4)",
                      "rgba(204, 204, 255, 0.4)",                 
                      "rgba(153, 204, 255, 0.4)",
                      "rgba(204, 255, 255, 0.4)",
                      "rgba(153, 255, 204, 0.4)",
                      "rgba(204, 255, 204, 0.4)",
                      "rgba(204, 255, 153, 0.4)",

                    ],
                    // backgroundColor: ["#ffffcc", "#ffcc99", "ffcccc", "ff99cc", "ffccff", "cc99ff", "ccccff", "99ccff", "ccffff", "99ffcc", "ccffcc", "ccffcc"],
                    // backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"],
                    // eslint-disable-next-line no-dupe-keys
                    borderWidth: 1,
                    fill: true,
                    // data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                    data: resp.result,
                    maxBarThickness: 6,
                  },
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                  legend: {
                    display: true,
                  },
                  // datalabels: {
                  //   color: 'black',
                  //   textAlign: 'center',
                  //   label: 'label',
                  //   font: {
                  //     weight: "bold",
                  //     size: 16,
                  //   }
                  // },
                  datalabels: {
                    // display: true,
                    align: "end",
                    // anchor: "end",
                    color: 'black',
                    textAlign: 'center',
                    label: 'label',
                    font: {
                      weight: "bold",
                      size: 16,
                    }              
                  },
                  label: "Count",
                },
                interaction: {
                  intersect: true,
                  mode: "index",
                },
                scales: {
                  y: {
                    grid: {
                      drawBorder: false,
                      display: true,
                      drawOnChartArea: true,
                      drawTicks: false,
                      borderDash: [5, 5],
                    },
                    ticks: {
                      display: false,
                      padding: 10,
                      color: "#fbfbfb",
                      font: {
                        size: 11,
                        family: "Open Sans",
                        style: "normal",
                        lineHeight: 2,
                      },
                    },
                  },
                  x: {
                    grid: {
                      drawBorder: false,
                      display: false,
                      drawOnChartArea: false,
                      drawTicks: false,
                      borderDash: [5, 5],
                    },
                    ticks: {
                      display: false,
                      color: "#ccc",
                      padding: 20,
                      font: {
                        size: 11,
                        family: "Open Sans",
                        style: "normal",
                        lineHeight: 2,
                      },
                    },
                  },
                },
              },
              });
            this.is_loading = false;
   
    },



    currentDate() {
      const current = new Date();
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      // const date = `${current.getMonth()+1}/${current.getFullYear()}`;
      const date = new Date().toLocaleString('en-us',{month:'short', year:'numeric'})
      return date;
    },

  },

  mounted() {
    this.getDataChart();
    // this.getUserLoginRole();
    // this.getEmployeeCard();
  },

  // unmounted() {
  //   this.myChart.destroy();
  // },


};
</script>
