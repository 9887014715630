<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-bold"> New Board </span>
        <div>
          <argon-button
            variant="gradient"
            color="primary"
            size="md"
            :disabled="id_divisi == 0 || id_divisi == ''"
            @click="handleNewProject()"
          >
            Add Board
          </argon-button>
        </div>
      </div>
    </div>

    <!-- <div class="card p-3 mb-3">
      <el-scrollbar height="500px">{{ getBoard.data }} </el-scrollbar>
    </div> -->


      <div class="card p-3 mb-3">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-control-label" for="basic-url"
                    >Board Name <span class="text-danger">*</span></label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="basic-addon3"
                      placeholder="Title"
                      v-model="form.nm_board"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Background Color <span class="text-danger">*</span></label>
                  <div class="input-group">
                    <input
                      type="color"
                      class="form-control"
                      aria-describedby="basic-addon3"
                      placeholder="Title"
                      v-model="form.background"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Public <span class="text-danger">*</span></label>
                  <select class="form-control" @click="handlePublic($event)">
                    <option value="0">Privat</option>
                    <option value="1">Public</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Division <span class="text-danger">*</span></label>
              <select class="form-control" @change="handleDivisi($event)">
                <option value="0">Choose Divisi</option>
                <option
                  v-for="item in getDivisi"
                  :key="item.id_departement"
                  :value="item.id_departement"
                >
                  {{ item.departement }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Collabolator To <span class="text-danger">*</span></label>
              <div v-if="selected_user_app != null">
                <span class="text-center" v-if="is_loading_menu_data">
                  <h6>Please wait...</h6>
                </span>
                <el-transfer
                  v-else
                  v-model="selected_menu_apps"
                  :props="{
                    key: 'id_user',
                    label: 'fullname',
                  }"
                  :titles="['Source', 'Target']"
                  :data="list_app_menu"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";

export default {
  name: "GoappsAdminBoards",
  components: {
    ArgonButton,
  },

  data() {
    return {
      id_divisi: "",
      params: {
        id_user: "",
        search: "",
        order: "",
      },
      user: [],
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
      is_loading_menu_data: false,
      selected_user_app: "",
      selected_menu_apps: [],
      list_app_menu: [],
      form: {
        id_board: null,
        nm_board: null,
        background: null,
        public: null,
        user_id: null,
      },
    };
  },

  beforeMount() {},

  mounted() {},

  methods: {
    ...mapActions({
      // Board
      fetchBoard: "gopro/fetchBoard",
      doCreateBoard: "gopro/doCreateBoard",
      doUpdateBoard: "gopro/doUpdateBoard",
      doDeleteDivisi: "gopro/doDeleteBoard",

      fetchKanban: "gopro/fetchKanban",
      fetchCollabolator: "gopro/fetchCollabolator",

      doCreateCard: "gopro/doCreateCard",
      fetchUser: "gopro/fetchUser",
    }),

    handlePublic(event) {
      this.form.public = event.target.value;
    },

    handleDivisi(event) {
      this.id_divisi = event.target.value;

      const payload = {
        id_user: "",
        id_departement: this.id_divisi,
        search: this.params.search,
        order: this.params.order,
      };

      this.fetchUser(payload)
        .then((response) => {
          if(this.list_app_menu.length == 0) {
            this.list_app_menu = response.data;
          } else {
            let merge = [...response.data, ...this.list_app_menu];
            this.list_app_menu = merge;
          }
          console.log(this.list_app_menu);
          
          // if(this.user.length == 0) {
          //   this.user = response.data;
          // } else {
          //   let merge = [...response.data, ...this.user];
          //   console.log('Get merge per-division.');
          //   console.log(merge);
          //   this.user = merge;
          // }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    handleNewProject() {
      const payload = {
        nm_board: this.form.nm_board,
        background: this.form.background,
        public: this.form.public,
        user_id: this.selected_menu_apps,
      };

      this.doCreateBoard(payload)
        .then((response) => {
          ElNotification({
            title: "Viola!",
            message: "Success create project!",
            type: "success",
          });

          this.form.nm_board = "";
          this.form.public = "";
          this.form.background = "";
          this.form.user_id = "";

          setTimeout(() => {
            window.location.href = "https://app.gooritss.com/GQGFX/transaksi/boards";
          }, 3000);
        })
        .catch((err) => {
          ElNotification({
            title: "Viola!",
            message: "Error create project!",
            type: "error",
          });
          console.log(err);
        });
    },
  },

  computed: {
    getDivisi() {
      return this.$store.state.hrd.divisi;
    },
  },
};
</script>
