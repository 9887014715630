// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    menu_data: [],
    menu_path: "",
    menu_sub_menu: [],
    selected_user_menu_data: [],
  },
  getters: {
    data: (state) => state.data,
    getUserMenuData: (state) => {
      return state.menu_data;
    },
    getMenuPath: (state) => {
      return state.menu_path;
    },
  },
  actions: {
    fetchMenu({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.get(`permission/menu?id_user_apps=${body}`).then(
          (response) => {
            commit("SET_ALL_MENU_DATA", response.data.data);
            commit("SET_MENU_PATH", `/${body}`);
            commit("SET_SELECTED_USER_MENU_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            // Swal.fire({
            //   icon: "error",
            //   title: "Something Wrong!",
            //   text: `${error.response.data.message}`,
            // });
            console.log(12222, error.response.data);
            reject(error.response.data);
          }
        );
      });
    },

    fetchMenuUser({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.get(`permission/menu?id_user_apps=${body}`).then(
          (response) => {
            commit("SET_SELECTED_USER_MENU_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            commit("SET_SELECTED_USER_MENU_DATA", []);
            reject(error);
          }
        );
      });
    },

    fetchMenuSub({ commit, rootState }, body) {
      let params;
      if (body != null) {
        params = body;
      } else {
        params = null;
      }
      return new Promise((resolve, reject) => {
        rootState.ax.get(`menu/menusub?apps=${params}`).then(
          (response) => {
            commit("SET_ALL_MENU_SUB_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            // Swal.fire({
            //   icon: "error",
            //   title: "Something Wrong!",
            //   text: `${error.response.data.message}`,
            // });
            commit("SET_ALL_MENU_SUB_DATA", []);
            reject(error);
          }
        );
      });
    },

    addMenuPermission({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.post(`permission/menu`, body).then(
          (response) => {
            console.log(`add menu per ${JSON.stringify(response.data)}`);
            resolve(response.data);
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Something Wrong!",
              text: `${error.response.data.message}`,
            });
            reject(error);
          }
        );
      });
    },

    deletePreviousMenu({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        console.log(`id ${body}`);
        rootState.ax.post(`permission/menu/delete?id_user_apps=${body}`).then(
          (response) => {
            console.log(`delte ${JSON.stringify(response.data)}`);
            resolve(response.data);
          },
          (error) => {
            // Swal.fire({
            //   icon: "error",
            //   title: "Something Wrong!",
            //   text: `${error.response.data.message}`,
            // });
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchMenuSubByRole({ commit, rootState }, body) {
      const params = [
        `id_position=${body.id_position}`,
        `id_sub_menu=${body.id_sub_menu}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        rootState.ax.get(`permission/menu/role?${params}`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            // Swal.fire({
            //   icon: "error",
            //   title: "Something Wrong!",
            //   text: `${error.response.data.message}`,
            // });
            // commit("SET_ALL_MENU_SUB_DATA", []);
            reject(error);
          }
        );
      });
    },
  },
  mutations: {
    SET_ALL_MENU_SUB_DATA(state, data) {
      state.menu_sub_menu = data;
    },
    SET_ALL_MENU_DATA(state, data) {
      state.menu_data = data;
    },
    SET_MENU_PATH(state, data) {
      state.menu_path = data;
    },
    SET_SELECTED_USER_MENU_DATA(state, data) {
      state.selected_user_menu_data = data;
    },
  },
};
