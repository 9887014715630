<template>
  <div class="row">
    <div class="card mb-3" v-if="activityData.length == 0">
      <div class="card-header px-3">
        <h6 class="mb-0 text-center">No data found</h6>
      </div>
    </div>
    <div
      v-else
      class="card mb-3"
      v-for="(activity, index) in activityData"
      :key="index"
    >
      <div class="card-header pb-0 px-3">
        <!-- <h6 class="mb-0">{{ activity.detail[0].project.name }}</h6> -->
      </div>
      <div class="card-body">
        <el-timeline>
          <el-timeline-item
            v-for="(detail, index) in activity.detail"
            :key="index"
            :icon="activityIcon"
            size="large"
            :type="`${index == 0 ? 'primary' : ''}`"
            :color="`0bbd87`"
            :timestamp="
              formatDate(
                activity.date + ' ' + detail.activity.time.split(' ')[0],
                'daydatetime'
              )
            "
          >
            <div
              class="border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg align-items-center"
            >
              <img
                :src="
                  detail.activity.photo ??
                  'https://salesforce.klikpersada.co.id/ns/employee/default-image.png'
                "
                width="100"
                height="100"
                alt=""
                class="border-radius-md"
                style="margin-right: 10px"
                @error="imageLoadError"
              />
              <div class="d-flex flex-column">
                <h6 class="mb-3 text-sm">{{ detail.project.name }}</h6>
                <span class="mb-2 text-xs">
                  Company Name:
                  <span class="text-dark font-weight-bold ms-sm-2">
                    {{ detail.company.name }}
                  </span>
                </span>
                <span class="mb-2 text-xs">
                  Sales:
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    {{ detail.employee.name }}
                  </span>
                </span>
                <span class="text-xs">
                  Note:
                  <span class="text-dark ms-sm-2 font-weight-bold">
                    {{ detail.activity.note }}
                  </span>
                </span>
              </div>
              <div
                class="ms-auto text-end"
                v-if="$store.state.eraforce.activity_id == 4"
              >
                <argon-button
                  class="mt-4"
                  variant="gradient"
                  color="info"
                  size="md"
                  @click="showClientMap(detail.activity)"
                >
                  <div>
                    <i class="fas fa-map"></i>
                  </div>
                </argon-button>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { ElTimeline } from "element-plus";
import { UserFilled, Message, Phone, Monitor } from "@element-plus/icons-vue";
import { format } from "date-fns";

import ArgonButton from "@/components/ArgonButton.vue";

import { mapActions } from "vuex";
export default {
  name: "call-card",

  components: {
    ElButton,
    ElTimeline,
    ArgonButton,
  },

  data() {
    return {
      image: "",
    };
  },

  methods: {
    ...mapActions({}),

    imageLoadError() {
      return `https://salesforce.klikpersada.co.id/ns/employee/default-image.png`;
    },

    showClientMap(detail) {
      window.open(
        `https://maps.google.com/?q=${detail.lat},${detail.long}`,
        "_blank"
      );
    },

    formatDate(date, type) {
      if (type == "daydatetime") {
        return format(new Date(date), "EEEE, dd MMMM yyyy HH:mm:ss");
      } else if (type == "daydate") {
        return format(new Date(date), "EEEE, dd MMMM yyyy");
      } else if (type == "time") {
        return format(new Date(date), "HH:mm ss");
      }
    },
  },

  computed: {
    activityData() {
      return this.$store.state.eraforce.activity_data;
    },

    activityIcon() {
      const id_activity = this.$store.state.eraforce.activity_id;

      let icon = "";
      if (id_activity == 3) {
        icon = "Phone";
      } else if (id_activity == 4) {
        icon = "UserFilled";
      } else if (id_activity == 5) {
        icon = "Message";
      } else if (id_activity == 9) {
        icon = "Monitor";
      } else {
        icon = "";
      }
      return icon;
    },
  },

  beforeMount() {},
};
</script>
