<template>
  <div class="row">
    <Card :title="`Presence Correction`" class="row"> </Card>

    <!-- TIME CORRECTION -->
    <div class="card mb-3 mt-4">
      <div class="card-header pb-0">
        <b>Time Correction</b>
      </div>
      <div class="card-body">
        <div
          class="d-flex justify-content-between align-items-center align-content-center"
        >
          <div class="d-flex flex-column">
            <span>Check-In</span>
            <span>{{ time_in_real }}</span>
          </div>

          <div>
            <el-icon :size="20"><Right /></el-icon>
          </div>

          <div class="d-flex flex-column">
            <span>Correction</span>
            <span class="text-info">
              {{ in_correction }}
            </span>
          </div>

          <div class="cursor-pointer" @click="handleTimeInCorrection">
            <el-icon :size="20"><EditPen /></el-icon>
          </div>
        </div>

        <hr class="horizontal dark" />

        <div
          class="d-flex justify-content-between align-items-center align-content-center"
          v-if="selectedPresence.time_out != null"
        >
          <div class="d-flex flex-column">
            <span>Check-Out</span>
            <span>{{ time_out_real }}</span>
          </div>

          <div>
            <el-icon :size="20"><Right /></el-icon>
          </div>

          <div class="d-flex flex-column">
            <span>Correction</span>
            <span class="text-info">
              {{ out_correction }}
            </span>
          </div>

          <div class="cursor-pointer" @click="handleTimeOutCorrection">
            <el-icon :size="20"><EditPen /></el-icon>
          </div>
        </div>
      </div>

      <el-dialog
        v-model="in_correction_dialog"
        title="Time Correction"
        width="30%"
        :show-close="false"
      >
        <div>
          <el-time-picker
            v-model="time_in_correction"
            placeholder="Pick Time Correction"
          />
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="in_correction_dialog = false">Cancel</el-button>
            <el-button type="primary" @click="formatTimeInCorrection(`in`)">
              Confirm
            </el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog
        v-model="out_correction_dialog"
        title="Time Correction"
        width="30%"
        :show-close="false"
      >
        <div>
          <el-time-picker
            v-model="time_out_correction"
            placeholder="Pick Time Correction"
          />
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="out_correction_dialog = false">Cancel</el-button>
            <el-button type="primary" @click="formatTimeInCorrection(`out`)">
              Confirm
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <!-- ADDRESS CORECION -->
    <div class="card mb-3">
      <div class="card-header pb-0">
        <b>Address Correction</b>
      </div>
      <div class="card-body">
        <div
          class="d-flex justify-content-between align-items-center align-content-center"
        >
          <div class="d-flex flex-column">
            <span>Address-In</span>
            <span>{{ address_in }}</span>
          </div>
        </div>

        <div
          class="d-flex justify-content-between align-items-center align-content-center mt-2 mb-2"
        >
          <div></div>
          <div>
            <el-icon :size="20"><Bottom /></el-icon>
          </div>
          <div class="cursor-pointer" @click="handleAddressCorection(`in`)">
            <el-icon :size="20"><EditPen /></el-icon>
          </div>
        </div>

        <div
          class="d-flex justify-content-between align-items-center align-content-center"
        >
          <div class="d-flex flex-column">
            <span>Correction</span>
            <span class="text-info">{{ address_in_correction }}</span>
          </div>
        </div>

        <hr class="horizontal dark" />
        <div v-if="selectedPresence.time_out != null">
          <div
            class="d-flex justify-content-between align-items-center align-content-center"
          >
            <div class="d-flex flex-column">
              <span>Address-Out</span>
              <span>{{ address_out }}</span>
            </div>
          </div>

          <div
            class="d-flex justify-content-between align-items-center align-content-center mt-2 mb-2"
          >
            <div></div>
            <div>
              <el-icon :size="20"><Bottom /></el-icon>
            </div>
            <div class="cursor-pointer" @click="handleAddressCorection(`out`)">
              <el-icon :size="20"><EditPen /></el-icon>
            </div>
          </div>

          <div
            class="d-flex justify-content-between align-items-center align-content-center"
          >
            <div class="d-flex flex-column">
              <span>Correction</span>
              <span class="text-info">{{ address_out_correction }}</span>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        v-model="address_correction_dialog"
        title="Address Correction"
        width="60%"
        :show-close="false"
      >
        <div>
          <div id="map" style="width: 100%; height: 50vh"></div>
          <span class="mt-3"> Location: {{ address_correction_text }} </span>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="address_correction_dialog = false"
              >Cancel</el-button
            >
            <el-button type="primary" @click="handleConfirmLocationCorrection">
              Confirm
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <!-- NOTE CORRECTION -->
    <div class="card mb-3">
      <div class="card-header pb-0">
        <b>Request Note</b>
      </div>
      <div class="card-body">
        <argon-input
          type="text"
          placeholder="Insert request note (Example: Forgot to checkin)"
          size="lg"
          :value="note"
          v-model="note"
          fullWidth
        />
      </div>
    </div>

    <argon-button
      class="mb-3"
      variant="gradient"
      color="success"
      size="md"
      fullWidth
      @click="handleSubmitRequest(detail)"
    >
      <div>Submit</div>
    </argon-button>

    <argon-button
      variant="gradient"
      color="info"
      size="md"
      fullWidth
      @click="goToPresenceData()"
    >
      <div><i class="fas fa-undo mx-3"></i> Back</div>
    </argon-button>
  </div>
</template>

<script>
import Card from "@/components/base/BaseCard.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

import { format } from "date-fns";

import { mapActions, mapMutations } from "vuex";

import Swal from "sweetalert2";

import { Right, EditPen, Bottom } from "@element-plus/icons-vue";

import axios from "axios";

import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default {
  name: "GoappsAdminPreseceRequestCard",

  components: {
    Card,
    ArgonButton,
    ArgonInput,
  },

  data() {
    return {
      in_correction: "-",
      out_correction: "-",
      in_correction_dialog: false,
      out_correction_dialog: false,
      time_in_correction: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      time_out_correction: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      access_token: this.$store.state.eraforce.token_maps_pk,
      address_correction_dialog: false,
      address_in: "",
      address_out: "-",
      address_in_correction: "-",
      address_out_correction: "-",
      address_correction_text: "-",
      note: "",
      center: [106.8156433, -6.1640701], //longitude, laltitude -> mapbox format
      center_default: [106.8156433, -6.1640701], //longitude, laltitude -> mapbox format
      latitude_in_correction: null,
      longitude_in_correction: null,
      latitude_out_correction: null,
      longitude_out_correction: null,
      type: "in",
      time_in_real: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      time_out_real: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    };
  },

  mounted() {
    this.getLocationIn();
    this.getLocationOut();
    this.getTimeInDefault();
    this.getTimeOutDefault();
  },

  methods: {
    ...mapActions({
      doPresenceRequest: "eraforce/doPresenceRequest",
    }),
    ...mapMutations({
      SELECTED_PRESENCE_DATA: "eraforce/SELECTED_PRESENCE_DATA",
    }),
    handleTimeInCorrection() {
      this.in_correction_dialog = true;
    },

    handleTimeOutCorrection() {
      this.out_correction_dialog = true;
    },

    async handleAddressCorection(type) {
      this.address_correction_dialog = await true;
      this.createMap(type);
    },

    async createMap(type) {
      try {
        console.log(`halo`);
        await this.getLocation(type);
        mapboxgl.accessToken = await this.access_token;
        this.map = await new mapboxgl.Map({
          container: "map",
          style: "mapbox://styles/mapbox/streets-v11",
          center: this.center,
          zoom: 14,
        });

        let geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false,
        });

        this.map.addControl(geocoder);

        let marker = new mapboxgl.Marker({
          draggable: true,
          color: "#D80739",
        });

        marker.setLngLat(this.center).addTo(this.map);

        marker.on("dragend", (e) => {
          this.center = Object.values(e.target.getLngLat());
          this.getLocation(type);
        });

        geocoder.on("result", (e) => {
          marker.remove();
          this.center = e.result.center;
          this.getLocation(type);
          marker = new mapboxgl.Marker({
            draggable: true,
            color: "#D80739",
          })
            .setLngLat(this.center)
            .addTo(this.map);
          marker.on("dragend", (e) => {
            this.center = Object.values(e.target.getLngLat());
            this.getLocation(type);
          });
        });
      } catch (err) {
        console.log("map error", err);
      }
    },

    async getLocation(type) {
      try {
        this.type = type;
        this.loading = true;
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${this.center[0]},${this.center[1]}.json?access_token=${this.access_token}`
        );
        console.log(
          `response api ${JSON.stringify(response.data.features[0].place_name)}`
        );
        this.loading = false;
        this.address_correction_text = response.data.features[0].place_name;
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },

    handleConfirmLocationCorrection() {
      if (this.type == "in") {
        this.address_in_correction = this.address_correction_text;
        this.longitude_in_correction = this.center[0];
        this.latitude_in_correction = this.center[1];
      } else {
        this.address_out_correction = this.address_correction_text;
        this.longitude_out_correction = this.center[0];
        this.latitude_out_correction = this.center[1];
      }
      this.address_correction_dialog = false;
      this.center = this.center_default;
      this.type = "in";

      this.map.remove();
    },

    formatTimeInCorrection(type) {
      if (type == "in") {
        if (this.time_in_correction != "-") {
          let time_in = new Date(this.time_in_correction);
          this.time_in_correction = format(time_in, "yyyy-MM-dd HH:mm:ss");
          this.in_correction = format(time_in, "HH:mm a");
          this.in_correction_dialog = false;
        } else {
          return "-";
        }
      } else {
        if (this.time_out_correction != "-") {
          let time_out = new Date(this.time_out_correction);
          this.time_out_correction = format(time_out, "yyyy-MM-dd HH:mm:ss");
          this.out_correction = format(time_out, "HH:mm a");
          this.out_correction_dialog = false;
        } else {
          return "-";
        }
      }
    },

    async getLocationIn() {
      const latitude = this.selectedPresence.latitude_in;
      const longitude = this.selectedPresence.longitude_in;
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${this.access_token}`
      );
      this.address_in = response.data.features[0].place_name;
    },

    async getLocationOut() {
      if (this.selectedPresence.time_out != null) {
        const latitude = this.selectedPresence.latitude_out;
        const longitude = this.selectedPresence.longitude_out;
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${this.access_token}`
        );
        this.address_out = response.data.features[0].place_name;
      } else {
        this.address_out = "-";
      }
    },

    getTimeInDefault() {
      if (
        this.selectedPresence.time_in != null &&
        this.selectedPresence.time_in != ""
      ) {
        const old_in = this.selectedPresence.time_in;
        let time_in = format(new Date(old_in), "HH:mm a");
        this.time_in_real = time_in;
      } else {
        let time_in = format(new Date(), "HH:mm a");
        this.time_in_real = time_in;
      }
    },

    getTimeOutDefault() {
      if (
        this.selectedPresence.time_out != null &&
        this.selectedPresence.time_out != ""
      ) {
        const old_out = this.selectedPresence.time_out;
        let time_out = format(new Date(old_out), "HH:mm a");
        this.time_out_real = time_out;
      } else {
        let time_out = format(new Date(), "HH:mm a");
        this.time_out_real = time_out;
      }
    },

    getRoute() {
      const routeArr = this.$route.path.split("/");
      // console.log(12121, routeArr[2]);
      return routeArr[1];
    },

    async goToPresenceRequestData() {
      const url = "/" + this.getRoute() + "/transaksi/presence-request";
      this.$router.push(url);
      await this.SELECTED_PRESENCE_DATA(null);
    },

    async goToPresenceData() {
      await this.SELECTED_PRESENCE_DATA(null);
      const url = "/" + this.getRoute() + "/activity/attendance";
      this.$router.push(url);
    },

    handleSubmitRequest() {
      const payload = {
        card_id: this.getCheckInData.card_activity,
        time_in: this.in_correction != "-" ? this.time_in_correction : null,
        time_out: this.out_correction != "-" ? this.time_out_correction : null,
        latitude_in: this.latitude_in_correction ?? null,
        longitude_in: this.longitude_in_correction ?? null,
        latitude_out: this.latitude_out_correction ?? null,
        longitude_out: this.longitude_out_correction ?? null,
        note: this.note,
      };
      if (
        payload.time_in == null &&
        payload.time_out == null &&
        payload.latitude_in == null &&
        payload.longitude_in == null &&
        payload.latitude_out == null &&
        payload.longitude_out == null
      ) {
        return Swal.fire({
          icon: "error",
          title: `Oopss!`,
          text: `There's must be 1 input to request!`,
        });
      } else {
        if (payload.note == "") {
          return Swal.fire({
            icon: "error",
            title: `Oopss!`,
            text: `Request note is required!`,
          });
        } else {
          this.doPresenceRequest(payload)
            .then((response) => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Voila! ${response}`,
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                this.goToPresenceRequestData();
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: `Oopss!`,
                text: `Error: ${JSON.stringify(error)}!`,
              });
            });
        }
      }
      // console.log(`pays ${JSON.stringify(payload)}`);
    },
  },

  computed: {
    selectedPresence() {
      return this.$store.state.eraforce.selected_presence_data;
    },

    getCheckInData() {
      return this.$store.state.eraforce.check_in_data;
    },
  },
};
</script>
