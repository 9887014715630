// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";
import axios from "axios";

const axios_force = axios.create({
  baseURL: process.env.VUE_APP_ERAFORCE_URL,
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token_eraforce"),
  },
});

const axios_timeline = axios.create({
  baseURL: "https://timeline.klikpersada.co.id/api/",
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("token_eraforce"),
  },
});
// AIzaSyB4w_vGhQPa4G7VDj2b6_inn9Crn9CInAU And pk.eyJ1IjoiZ3JhY2VmaXJnaWEiLCJhIjoiY2ttdDE0ZmF3MDR2cTJvbzJnaXN6Zm05eCJ9.XvchqLqwdew16Wh5n_KOqQ
export default {
  namespaced: true,
  state: {
    token_maps_pk:
      "pk.eyJ1IjoiZ3JhY2VmaXJnaWEiLCJhIjoiY2ttdDE0ZmF3MDR2cTJvbzJnaXN6Zm05eCJ9.XvchqLqwdew16Wh5n_KOqQ",
    token_eraforce: [],
    user_eraforce: [],
    eraforce_user_login: {},
    list_client: [],
    user_data: {},
    project_highlight: [],
    dashboard_data: {},
    activity_id: 0,
    activity_data: [],
    is_activity_card_loading: false,
    is_already_checkin: false,
    list_check_in_data: [],
    check_in_data: {},
    employee_company: [],
    employee_project: [],
    create_activity_modal: false,
    staging_data: [],
    create_activity_response: "",
    selected_activity_coordinate: [],
    update_pipeline_notes_dialog: false,
    update_pipeline_information_dialog: false,
    update_pipeline_deal_dialog: false,
    add_document_dialog: false,
    add_client_dialog: false,
    add_client_location_dialog: false,
    selected_client_location: null,
    add_client_knowledge_dialog: false,
    selected_client_knowledge: null,
    add_client_information_dialog: false,
    add_client_pic_dialog: false,
    status_data: [],
    add_client_picture_dialog: false,
    is_customer_loading: false,
    selected_project_data: {},
    fetch_project_payload: {},
    selected_presence_data: {},
    presence_request_list: [],
    existing_project: [],
    token_timeline: null,
    document_data: [],
    report_kpi: [],
    team_activity: [],
    wall_of_idea_data: [],
    rating_data: [],
    statistic_revenue_highlight: [],
    statistic_all_team_revenue_highlight: [],
    statistic_earnings_revenue: {},
    statistic_earnings_profit: {},
    statistic_earnings_rejected: {},
    statistic_activity: [],
    statistic_arrival: [],
    statistic_arrival_detail: [],
    list_team: [],
    list_profit_rank: [],
    list_monthly_target: [],
  },
  getters: {
    data: (state) => state.user_eraforce,
    getEraforceUserLogin: (state) => state.eraforce_user_login,
    getListClient: (state) => state.list_client,
    getProjectHighlight: (state) => state.project_highlight,
    getDashboardData: (state) => state.dashboard_data,
  },
  actions: {
    fetchUserEraforceAuth({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force
          .get(`employee?filter=${body.filter}&employee_name=${body.name}`)
          .then(
            (response) => {
              commit("SET_ERAFORCE_USER", response.data.result);
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
      });
    },

    fetchEmployeeV2({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.post(`employee/team/v2`).then(
          (response) => {
            commit("SET_ERAFORCE_USER", response.data.result);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    loginEraforce({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`login`, body).then(
          (response) => {
            commit("SET_ERAFORCE_LOGIN_DATA", response.data.result);
            commit("SET_ERAFORCE_TOKEN", response.data.token);
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    fetchUserLoginData({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`employee/myInfo`).then(
          (response) => {
            commit("SET_USER_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchListClient({ commit }, body) {
      // ini cella render the client data model
      console.log("ini cella get client");
      return new Promise((resolve, reject) => {
        axios_force.post(`company/validation`, body).then(
          (response) => {
            commit("SET_LIST_CLIENT", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchProjectHighlight({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`project/highlight`).then(
          (response) => {
            commit("SET_PROJECT_HIGHLIGHT", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchDashboardData({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`dashboard`).then(
          (response) => {
            commit("SET_DASHBOARD_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchAllActivity({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`activity`, body).then(
          (response) => {
            commit("SET_ACTIVITY_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCheckIn({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`check/in`, body).then(
          (response) => {
            commit("SET_CHECKIN_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCheckOut({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`check/out`, body).then(
          (response) => {
            commit("SET_CHECKIN_DATA", {});
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    removeEraforceData({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_ERAFORCE_USER", []);
        commit("SET_DASHBOARD_DATA", {});
        commit("SET_PROJECT_HIGHLIGHT", []);
        commit("SET_USER_DATA", {});
        commit("SET_ERAFORCE_LOGIN_DATA", {});
        commit("SET_REMOVE_ERAFORCE_TOKEN");
      });
    },

    fetchCheckInList({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`check/list/manager`, body).then(
          (response) => {
            commit("SET_LIST_CHECKIN_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchCheckInStatus({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.post(`check`).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchEmployeeCompany({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/employee`).then(
          (response) => {
            commit("SET_EMPLOYEE_COMPANY", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchEmployeeProjectNew({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`project/list/v3`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchEmployeeProject({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`project/active/v2`, body).then(
          (response) => {
            // commit("SET_EMPLOYEE_PROJECT", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchStaging({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`staging`).then(
          (response) => {
            commit("SET_STAGING_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCallActivity({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`call`, body).then(
          (response) => {
            commit("SET_CREATE_ACTIVITY_RESPONSE", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doVisitActivity({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`visit`, body).then(
          (response) => {
            commit("SET_CREATE_ACTIVITY_RESPONSE", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdatePipelineNotes({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.patch(`pipeline`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdatePipelineInformation({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.patch(`pipeline/v2`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCreateNewClient({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchClientKnowledge({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/info`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdateKnowledge({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.patch(`company/info`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchClientInformation({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/detail`, body).then(
          (response) => {
            commit("SET_SELECTED_CLIENT_KNOWLEDGE", response.data.result);

            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdateCompanyInformation({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/update`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdateActiveEmployee({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.put(`employee/activate/v2`, body)
          .then((response) => {
            resolve(response.data.message);
          }, (error) => {
            reject(error.response.data);
          })
      });
    },

    doUpdateNonActiveEmployee({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.put(`employee/notActive`, body)
          .then((response) => {
            resolve(response.data.message);
          }, (error) => {
            reject(error.response.data);
          })
      })
    },
 
    fetchClientStatus({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.get(`status`, body).then(
          (response) => {
            commit("SET_STATUS_DATA", response.data.result);

            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchClientPIC({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/pic/list`, body).then(
          (response) => {
            commit("SET_SELECTED_CLIENT_KNOWLEDGE", response.data.result);

            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doAddClientPIC({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/pic`, body).then(
          (response) => {
            // commit("SET_SELECTED_CLIENT_KNOWLEDGE", response.data.result);

            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doChangeClientPicture({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`company/photo`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCancelProject({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.patch(`project/cancel`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doPresenceRequest({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`check/request`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchPresenceRequestList({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`check/request/list`, body).then(
          (response) => {
            // commit("SET_PRESENCE_REQUEST_LIST", response.data.result);

            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    fetchProjectCompany({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`project/company`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doCreateLeadPipeline({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`pipeline/v2`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdatePipeline({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.patch(`pipeline/v2`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doInitPipeline({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`project/reject/request`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    rejectRequestSelect({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`project/reject/request/select`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    rejectRequestRejectSelect({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`pipeline/reject/pipeline/select`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    rejectRequestApproveSelect({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`pipeline/reject/pipeline/selectApprove`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doInitPipelineNew({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`pipeline/init`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doInitRejectPipeline({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`pipeline/reject/pipeline`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doUpdatePotentialReject({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.put(`pipeline/potential/V2`, body).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error.response.data);
          }
        );
      });
    },

    doFinalizePipelineDeal({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`pipeline/final/v4`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    doUpdateAvatar({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.put(`employee/photo`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    doUpdateProfile({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.put(`employee`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    doUpdatePassword({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`employee/password`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    doAccountActivation({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.put(`employee/activate`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    getTimelineToken({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_timeline.get(`auth/getToken`, body).then(
          (response) => {
            resolve(response.data.access_token);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    exportPresenceExcel({ commit, state }, body) {
      const params = [
        `employee_id=${body.employee_id}`,
        `first_date=${body.first_date}`,
        `last_date=${body.last_date}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`check/excel?${params}`).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    exportProjectExcel({ commit, state }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`project/excel`, body).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    exportClientExcel({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`company/excel`).then(
          (response) => {
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    getTimelineData({ commit }, body) {
      const params = [
        `page=${body.page}`,
        `username=${body.username}`,
        `search=${body.search}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios
          .create({
            baseURL: "https://timeline.klikpersada.co.id/api/",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + body.token,
            },
          })
          .get(`po?${params}`)
          .then(
            (response) => {
              resolve(response.data.data);
            },
            (error) => {
              reject(error.response.data);
            }
          );
      });
    },

    getTimelineDataDetail({ commit }, body) {
      const params = [
        `page=${body.page}`,
        `username=${body.username}`,
        `search=${body.search}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios
          .create({
            baseURL: "https://timeline.klikpersada.co.id/api/",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + body.token,
            },
          })
          .get(`po/${body.id}?${params}`)
          .then(
            (response) => {
              resolve(response.data.data);
            },
            (error) => {
              reject(error.response.data);
            }
          );
      });
    },

    fetchAllDocument({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`document`).then(
          (response) => {
            commit("SET_DOCUMENT_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchEmployeeReportKPI({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`report/kpi/employee`, body).then(
          (response) => {
            commit("SET_REPORT_KPI_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    doAddDocument({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`document/add`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchTeamActivity({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.get(`report/manager/visit/v2`, body).then(
          (response) => {
            commit("SET_TEAM_ACTIVITY_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchWallOfIdea({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .create({
            baseURL: "https://goooffice.erakomp.co.id/api/",
            // headers: {
            //   Accept: "application/json",
            //   "Access-Control-Allow-Origin": "*",
            // },
          })
          .get("wall-idea/")
          .then(
            (response) => {
              commit("SET_WALL_IDEA_DATA", response.data.data);
              resolve(response.data.data);
            },
            (error) => {
              reject(error.response.data.message);
            }
          );
      });
    },

    doUploadIdea({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios
          .create({
            baseURL: "https://goooffice.erakomp.co.id/api/",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token_eraforce"),
            },
          })
          .post("wall-idea/create", body)
          .then(
            (response) => {
              if (response.data.status) {
                resolve(response.data.data);
              } else {
                reject(response.data.message);
              }
              // commit("SET_WALL_IDEA_DATA", response.data.data);
            },
            (error) => {
              reject(error.response.data.message);
            }
          );
      });
    },

    fetchRating({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`rating`).then(
          (response) => {
            commit("SET_RATING_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticHighlight({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`project/revenue?${params}`).then(
          (response) => {
            commit("SET_STAT_REVENUE_HIGHLIGHT_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticEarningsRevenue({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`project/revenue/chart/sales?${params}`).then(
          (response) => {
            commit("SET_STAT_EARNINGS_REVENUE_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticEarningsProfit({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`project/profit/chart/sales?${params}`).then(
          (response) => {
            commit("SET_STAT_EARNINGS_PROFIT_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticEarningsRejected({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`project/rejected/chart/sales?${params}`).then(
          (response) => {
            commit("SET_STAT_EARNINGS_REJECTED_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticActivity({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`employee/activity/total?${params}`).then(
          (response) => {
            commit("SET_STAT_ACTIVITY_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticArrival({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`check/arrival/total?${params}`).then(
          (response) => {
            commit("SET_STAT_ARRIVAL_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticArrivalDetail({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `team_id=${body.team_id}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.post(`check/arrival/total?${params}`).then(
          (response) => {
            commit("SET_STAT_ARRIVAL_DETAIL_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchStatisticAllTeamRevenue({ commit }, body) {
      const params = [
        `interval=${body.interval}`,
        `month=${body.month}`,
        `year=${body.year}`,
      ].join("&");
      return new Promise((resolve, reject) => {
        axios_force.get(`team/revenue?${params}`).then(
          (response) => {
            commit(
              "SET_STAT_ALL_TEAM_REVENUE_HIGHLIGHT_DATA",
              response.data.result
            );
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchListTeam({ commit }) {
      return new Promise((resolve, reject) => {
        axios_force.get(`team`).then(
          (response) => {
            commit("SET_LIST_TEAM_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    doChangeTeam({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`employee/team/update`, body).then(
          (response) => {
            resolve(response.data.message);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchProfitRank({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`kpi/profit`, body).then(
          (response) => {
            commit("SET_PROFIT_RANK_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },

    fetchMonthlyTarget({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios_force.post(`team/target/period`, body).then(
          (response) => {
            commit("SET_MONTHLY_TARGET_DATA", response.data.result);
            resolve(response.data.result);
          },
          (error) => {
            reject(error.response.data.message);
          }
        );
      });
    },
  },
  mutations: {
    SET_ERAFORCE_USER(state, data) {
      state.user_eraforce = data;
    },
    SET_ERAFORCE_LOGIN_DATA(state, data) {
      state.eraforce_user_login = data;
    },
    SET_LIST_CLIENT(state, data) {
      state.list_client = data;
    },
    SET_ERAFORCE_TOKEN(state, data) {
      state.token_eraforce = data;
      localStorage.setItem("token_eraforce", data);
    },
    SET_REMOVE_ERAFORCE_TOKEN(state, data) {
      localStorage.removeItem("token_eraforce");
    },
    SET_USER_DATA(state, data) {
      state.user_data = data;
    },
    SET_PROJECT_HIGHLIGHT(state, data) {
      state.project_highlight = data;
    },
    SET_DASHBOARD_DATA(state, data) {
      state.dashboard_data = data;
    },
    SET_ACTIVITY_ID(state, data) {
      state.activity_id = data;
    },
    SET_ACTIVITY_DATA(state, data) {
      state.activity_data = data;
    },
    SET_ACTIVITY_CARD_LOADING(state) {
      state.is_activity_card_loading = true;
    },
    SET_ACTIVITY_CARD_NOT_LOADING(state) {
      state.is_activity_card_loading = false;
    },
    SET_ALREADY_CHECKIN(state) {
      state.is_already_checkin = true;
    },
    SET_NOT_CHECKIN(state) {
      state.is_already_checkin = false;
    },
    SET_LIST_CHECKIN_DATA(state, data) {
      state.list_check_in_data = data;
    },
    SET_CHECKIN_DATA(state, data) {
      state.check_in_data = data;
    },
    SET_EMPLOYEE_COMPANY(state, data) {
      state.employee_company = data;
    },
    SET_EMPLOYEE_PROJECT(state, data) {
      state.employee_project = data;
    },
    SET_ACTIVITY_MODAL_ACTIVE(state) {
      state.create_activity_modal = true;
    },
    SET_ACTIVITY_MODAL_NOT_ACTIVE(state) {
      state.create_activity_modal = false;
    },
    SET_STAGING_DATA(state, data) {
      state.staging = data;
    },
    SET_CREATE_ACTIVITY_RESPONSE(state, data) {
      state.create_activity_response = data;
    },
    SELECTED_ACTIVITY_COORDINATE(state, data) {
      state.selected_activity_coordinate = data;
    },
    OPEN_UPDATE_NOTES_DIALOG(state) {
      state.update_pipeline_notes_dialog = true;
    },
    CLOSE_UPDATE_NOTES_DIALOG(state) {
      state.update_pipeline_notes_dialog = false;
    },
    OPEN_UPDATE_INFORMATION_DIALOG(state) {
      state.update_pipeline_information_dialog = true;
    },
    CLOSE_UPDATE_INFORMATION_DIALOG(state) {
      state.update_pipeline_information_dialog = false;
    },
    OPEN_UPDATE_DEAL_DIALOG(state) {
      state.update_pipeline_deal_dialog = true;
    },
    CLOSE_UPDATE_DEAL_DIALOG(state) {
      state.update_pipeline_deal_dialog = false;
    },
    OPEN_ADD_DOCUMENT_DIALOG(state) {
      state.add_document_dialog = true;
    },
    CLOSE_ADD_DOCUMENT_DIALOG(state) {
      state.add_document_dialog = false;
    },
    OPEN_ADD_CLIENT_DIALOG(state) {
      state.add_client_dialog = true;
    },
    CLOSE_ADD_CLIENT_DIALOG(state) {
      state.add_client_dialog = false;
    },
    OPEN_ADD_CLIENT_LOCATION_DIALOG(state) {
      state.add_client_location_dialog = true;
    },
    CLOSE_ADD_CLIENT_LOCATION_DIALOG(state) {
      state.add_client_location_dialog = false;
    },
    SET_SELECTED_CLIENT_LOCATION(state, data) {
      state.selected_client_location = data;
    },
    OPEN_ADD_CLIENT_KNOWLEDGE_DIALOG(state) {
      state.add_client_knowledge_dialog = true;
    },
    CLOSE_ADD_CLIENT_KNOWLEDGE_DIALOG(state) {
      state.add_client_knowledge_dialog = false;
    },
    SET_SELECTED_CLIENT_KNOWLEDGE(state, data) {
      state.selected_client_knowledge = data;
    },
    OPEN_ADD_CLIENT_INFORMATION_DIALOG(state) {
      state.add_client_information_dialog = true;
    },
    CLOSE_ADD_CLIENT_INFORMATION_DIALOG(state) {
      state.add_client_information_dialog = false;
    },
    OPEN_ADD_CLIENT_PIC_DIALOG(state) {
      state.add_client_pic_dialog = true;
    },
    CLOSE_ADD_CLIENT_PIC_DIALOG(state) {
      state.add_client_pic_dialog = false;
    },
    SET_STATUS_DATA(state, data) {
      state.status_data = data;
    },
    OPEN_ADD_CLIENT_PICTURE_DIALOG(state) {
      state.add_client_picture_dialog = true;
    },
    CLOSE_ADD_CLIENT_PICTURE_DIALOG(state) {
      state.add_client_picture_dialog = false;
    },
    SET_CUSTOMER_LOADING(state) {
      state.is_customer_loading = true;
    },
    SET_CUSTOMER_NOT_LOADING(state) {
      state.is_customer_loading = false;
    },
    SET_SELECTED_PROJECT_DATA(state, data) {
      state.selected_project_data = data;
    },
    SET_PROJECT_PAYLOAD_DATA(state, data) {
      state.fetch_project_payload = data;
    },
    SELECTED_PRESENCE_DATA(state, data) {
      state.selected_presence_data = data;
    },
    SET_PRESENCE_REQUEST_LIST(state, data) {
      state.presence_request_list = data;
    },
    SET_EXISTING_PROJECT(state, data) {
      state.existing_project = data;
    },
    SET_DOCUMENT_DATA(state, data) {
      state.document_data = data;
    },
    SET_REPORT_KPI_DATA(state, data) {
      state.report_kpi = data;
    },
    SET_TEAM_ACTIVITY_DATA(state, data) {
      state.team_activity = data;
    },
    SET_WALL_IDEA_DATA(state, data) {
      state.wall_of_idea_data = data;
    },
    SET_RATING_DATA(state, data) {
      state.rating_data = data;
    },
    SET_STAT_REVENUE_HIGHLIGHT_DATA(state, data) {
      state.statistic_revenue_highlight = data;
    },
    SET_STAT_ALL_TEAM_REVENUE_HIGHLIGHT_DATA(state, data) {
      state.statistic_all_team_revenue_highlight = data;
    },
    SET_STAT_EARNINGS_REVENUE_DATA(state, data) {
      state.statistic_earnings_revenue = data;
    },
    SET_STAT_EARNINGS_PROFIT_DATA(state, data) {
      state.statistic_earnings_profit = data;
    },
    SET_STAT_EARNINGS_REJECTED_DATA(state, data) {
      state.statistic_earnings_rejected = data;
    },
    SET_STAT_ACTIVITY_DATA(state, data) {
      state.statistic_activity = data;
    },
    SET_STAT_ARRIVAL_DATA(state, data) {
      state.statistic_arrival = data;
    },
    SET_STAT_ARRIVAL_DETAIL_DATA(state, data) {
      state.statistic_arrival_detail = data;
    },
    SET_LIST_TEAM_DATA(state, data) {
      state.list_team = data;
    },
    SET_PROFIT_RANK_DATA(state, data) {
      state.list_profit_rank = data;
    },
    SET_MONTHLY_TARGET_DATA(state, data) {
      state.list_monthly_target = data;
    },
  },
};
