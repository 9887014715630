// eslint-disable-next-line no-unused-vars
import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    employee_data: [],
    is_modal_user_show: false,
    selected_employee_data: {},
  },
  getters: {
    data: (state) => state.data,
    getEmployeeData: (state) => {
      return state.employee_data;
    },
  },
  actions: {
    fetchEmployee({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        rootState.ax.get(`user`).then(
          (response) => {
            commit("SET_ALL_EMPLOYEE_DATA", response.data.data);
            resolve(response.data.data);
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Something Wrong!",
              text: `${error.response.data.message}`,
            });
            reject(error);
          }
        );
      });
    },

    fetchEmployeeById({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.post(`user/get-user-by-id`, body).then(
          (response) => {
            commit("SET_SELECTED_EMPLOYEE_DATA", response.data.data);
            resolve(response.data.data.user);
          },
          (error) => {
            // Swal.fire({
            //   icon: "error",
            //   title: "Something Wrong!",
            //   text: `${error.response.data.message}`,
            // });
            // this.$router.go();
            reject(error.response.data);
          }
        );
      });
    },

    updateEmployee({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.post(`user/update-user-by-id`, body).then(
          (response) => {
            console.log(`response update ${JSON.stringify(response.data)}`);
            resolve(response.data);
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Something Wrong!",
              text: `${error.response.data.message}`,
            });
            reject(error);
          }
        );
      });
    },

    deleteEmployee({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.post(`user/delete-user-by-id`, body).then(
          (response) => {
            console.log(`response delete ${JSON.stringify(response.data)}`);
            resolve(response.data);
          },
          (error) => {
            Swal.fire({
              icon: "error",
              title: "Something Wrong!",
              text: `${error.response.data.message}`,
            });
            // this.$router.go();
            reject(error);
          }
        );
      });
    },

    appsPermission({ commit, rootState }, body) {
      return new Promise((resolve, reject) => {
        rootState.ax.post(`permission/insert-user-apps`, body).then(
          (response) => {
            console.log(`response apps ${JSON.stringify(response.data)}`);
            resolve(response.data);
          },
          (error) => {
            console.log(
              `error apps permission ${JSON.stringify(
                error.response.data.message
              )}`
            );
            // this.$router.go();
            reject(error);
          }
        );
      });
    },

    fetchPosition({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        rootState.ax.get(`position`).then(
          (response) => {
            resolve(response.data.data);
          },
          (error) => {
            console.log(
              `error apps permission ${JSON.stringify(
                error.response.data.message
              )}`
            );
            // this.$router.go();
            reject(error);
          }
        );
      });
    },
  },
  mutations: {
    SET_ALL_EMPLOYEE_DATA(state, data) {
      state.employee_data = data;
    },
    SET_SELECTED_EMPLOYEE_DATA(state, data) {
      state.selected_employee_data = data;
    },
    SET_USER_MODAL_SHOW(state) {
      state.is_modal_user_show = true;
    },
    SET_USER_MODAL_NOT_SHOW(state) {
      state.is_modal_user_show = false;
    },
  },
};
