<template>
  <div class="card">
    <div class="card-header">
      <button @click="doSync">Sync</button>
    </div>
    <div class="card-body">
      {{ user }}
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "GoappsAdminPermission",

  data() {
    return {
      user: [],
    };
  },

  mounted() {
    this.getUserOffice();
  },

  methods: {
    ...mapActions({
      fetchUserOffice: "administrator/fetchUserOffice",
    }),

    getUserOffice() {
      this.fetchUserOffice().then((response) => {
        this.user = response;
      });
    },

    doSync() {
      const data = this.user[0];
      const payload = {
        email: data.sso.email,
        username: data.sso.username,
        phone: data.user.sales_phone,
        password: data.sso.passowrd,
        fullname: data.user.sales_name,
        image: data.user.sales_photo,
        dob: data.user.sales_birthdate,
      };
      console.log(`data ${JSON.stringify(payload)}`);
    },
  },
};
</script>
