<template>
  <div class="mx-4">
    <div class="card p-3 mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold">Meal Allowance</span>
        </div>
        <div>
          <el-popover
            placement="bottom-end"
            title="Tips"
            :width="300"
            trigger="hover"
            :content="pop_over_content"
          >
            <template #reference>
              <i class="fas fa-info-circle cursor-pointer"></i>
            </template>
          </el-popover>
        </div>
      </div>
      <div class="d-flex justify-content-evenly align-items-center">
        <div class="d-flex flex-column">
          <div class="text-center">Filter Date</div>
          <el-date-picker
            v-model="params.date_range"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
            size="medium"
            value-format="YYYY-MM-DD"
            @change="handleChangeFilterDate"
          />
        </div>
        <div class="d-flex flex-column">
          <argon-button
            class="mb-1"
            variant="gradient"
            color="info"
            size="sm"
            @click="handleExportOffice"
          >
            <div>Export Office</div>
          </argon-button>
          <argon-button
            variant="gradient"
            color="info"
            size="sm"
            @click="handleExportSales"
          >
            <div>Export Sales</div>
          </argon-button>
        </div>
      </div>
    </div>

    <div class="card p-3 mb-4">
      <argon-input
        placeholder="Type here..."
        icon="fas fa-search"
        iconDir="left"
        v-model="params.filter"
        :value="params.filter"
        @change="handleSearch"
      />
    </div>

    <div class="row">
      <!-- 88 sales fill here -->
      <!-- SALES -->
      <div class="col-lg-6 col-md-12 mb-3">
        <div class="card p-1 mb-1">
          <div class="row">
            <span class="text-center text-bold"> Sales </span>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="is_loading_sales">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_t9gkkhz4.json"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div
          class="card p-1 mb-1"
          v-if="!is_loading_sales && allowance_sales.length == 0"
        >
          <div class="row">
            <span class="text-center text-bold"> No Data Found. </span>
          </div>
        </div>
        <el-scrollbar
          height="500px"
          always
          class="px-3"
          v-if="!is_loading_sales && allowance_sales.length != 0"
        >
          <!-- 8811 -->
          <div class="table-responsive">
            <table class="table align-items-center">
              <tbody>
                <tr v-for="(data, index) in allowance_sales" :key="index">
                  <td class="w-10">
                    <div class="align-items-center">
                      <div>
                        <!-- 88 -->
                        <img
                          :src="
                            'https://salesdashboard.erakomp.co.id/ns/employee/' +
                            data.photo_url
                          "
                          width="80"
                          height="80"
                          class="rounded"
                          alt="Avatar"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <div
                        class="d-flex justify-content-between align-items-center mb-1"
                      >
                        <p class="mb-0 text-xs font-weight-bold">
                          {{ data.employee }}
                        </p>
                        <p
                          class="mb-0 text-xs cursor-pointer"
                          @click="handleOpenDetails(data)"
                        >
                          Details <i class="fas fa-chevron-right"></i>
                        </p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Present:</p>
                          <h6 class="mb-0 text-sm">
                            {{ data.total_on_time }}
                          </h6>
                        </div>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Late:</p>
                          <h6 class="mb-0 text-sm">
                            {{ data.total_late }}
                          </h6>
                        </div>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Penalty:</p>
                          <h6 class="mb-0 text-sm">
                            IDR {{ numeralFormat(data.total_penalty) }}
                          </h6>
                        </div>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Total:</p>
                          <h6 class="mb-0 text-sm">
                            IDR
                            {{ numeralFormat(data.total) }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end 8811 -->
        </el-scrollbar>
      </div>

      <!-- NON SALES -->
      <div class="col-lg-6 col-md-12 mb-3">
        <div class="card p-1 mb-1">
          <div class="row">
            <span class="text-center text-bold"> Non-Sales </span>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="is_loading_office">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_t9gkkhz4.json"
            background="transparent"
            speed="1"
            style="width: 300px; height: 300px"
            loop
            autoplay
          ></lottie-player>
        </div>
        <div
          class="card p-1 mb-1"
          v-if="!is_loading_office && allowance_office.length == 0"
        >
          <div class="row">
            <span class="text-center text-bold"> No Data Found. </span>
          </div>
        </div>
        <el-scrollbar
          height="500px"
          always
          class="px-3"
          v-if="!is_loading_office && allowance_office.length != 0"
        >
          <div class="table-responsive">
            <table class="table align-items-center">
              <tbody>
                <tr v-for="(data, index) in allowance_office" :key="index">
                  <td class="w-10">
                    <div class="align-items-center">
                      <div>
                        <img
                          :src="
                            'https://cdn.erakomp.co.id/eraoffice/users/' +
                            data.sales_photo
                          "
                          width="80"
                          height="80"
                          class="rounded"
                          alt="Avatar"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <div
                        class="d-flex justify-content-between align-items-center mb-1"
                      >
                        <p class="mb-0 text-xs font-weight-bold">
                          {{ data.sales_name }}
                        </p>
                        <p
                          class="mb-0 text-xs cursor-pointer"
                          @click="handleOpenDetails(data)"
                        >
                          Details <i class="fas fa-chevron-right"></i>
                        </p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Present:</p>
                          <h6 class="mb-0 text-sm">
                            {{ data.attendance_count }}
                          </h6>
                        </div>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Late:</p>
                          <h6 class="mb-0 text-sm">
                            {{ data.attendance_late_count }}
                          </h6>
                        </div>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Penalty:</p>
                          <h6 class="mb-0 text-sm">
                            IDR {{ numeralFormat(data.attendance_fine) }}
                          </h6>
                        </div>
                        <div class="text-center">
                          <p class="mb-0 text-xs font-weight-bold">Total:</p>
                          <h6 class="mb-0 text-sm">
                            IDR
                            {{ numeralFormat(data.attendance_income_total) }}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Present:</p>
                      <h6 class="mb-0 text-sm">{{ data.attendance_count }}</h6>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Late:</p>
                      <h6 class="mb-0 text-sm">
                        {{ data.attendance_late_count }}
                      </h6>
                    </div>
                  </td>
                  <td class="text-sm align-middle">
                    <div class="text-center col">
                      <p class="mb-0 text-xs font-weight-bold">Penalty:</p>
                      <h6 class="mb-0 text-sm">{{ data.attendance_fine }}</h6>
                    </div>
                  </td>
                  <td class="text-sm align-middle">
                    <div class="text-center col">
                      <p class="mb-0 text-xs font-weight-bold">Total:</p>
                      <h6 class="mb-0 text-sm">
                        {{ data.attendance_income_total }}
                      </h6>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </el-scrollbar>
        <div
          class="d-flex justify-content-center mt-0"
          v-if="!is_loading_office"
        >
          <el-button @click="handleLoadMore" v-if="!is_loading_office_more">
            Load More
          </el-button>
          <el-button v-else> Loding... </el-button>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="attendance_dialog"
      title=""
      width="30%"
      :before-close="handleCloseVisit"
    >
      <span v-if="selected_data != null">
        <div class="d-flex align-items-center mb-3">
          <div class="me-2">
            <img
              :src="
                'https://cdn.erakomp.co.id/eraoffice/users/' +
                selected_data.sales_photo
              "
              width="50"
              height="50"
              class="rounded"
              alt="Avatar"
            />
          </div>
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-sm text-dark">
              {{ selected_data.sales_name }}
            </h6>
            <span class="text-xs">
              {{ selected_data.sales_email ?? "-" }}
            </span>
          </div>
        </div>
        <el-scrollbar height="300px" always>
          <ul
            class="list-group"
            :class="''"
            v-if="selected_data.attendance.length != 0"
          >
            <li
              class="mb-1 border-0 list-group-item border-radius-lg"
              :class="''"
              v-for="(data, index) in selected_data.attendance"
              :key="index"
            >
              <div class="card">
                <div class="card-header text-xs">
                  <i class="far fa-calendar-alt me-2"></i>
                  <span v-if="data.attendance_date">
                    {{ formatDate(data.attendance_date, "fullday") }}
                  </span>
                  <span v-else> - </span>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="text-xs">
                        <i class="far fa-clock me-2"></i> IN
                        <span v-if="data.time_in">
                          {{
                            formatDate(
                              data.attendance_date + " " + data.time_in,
                              "time"
                            )
                          }}
                        </span>
                        <span v-else> --:-- </span>
                      </div>
                      <div
                        class="text-xs text-bold cursor-pointer"
                        @click="
                          handleOpenMap(data.latitude_in, data.longitude_in)
                        "
                      >
                        <i
                          class="fas fa-map-marker-alt"
                          :class="
                            data.is_match_in == 1
                              ? 'text-success'
                              : 'text-danger'
                          "
                        ></i>
                        {{ data.is_match_in == 1 ? "Match" : "Not Match" }}
                      </div>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div class="text-xs">
                        <i class="far fa-clock me-2"></i> OUT
                        <span v-if="data.time_out">
                          {{
                            formatDate(
                              data.attendance_date + " " + data.time_out,
                              "time"
                            )
                          }}
                        </span>
                        <span v-else> --:-- </span>
                      </div>
                      <div
                        class="text-xs text-bold cursor-pointer"
                        @click="
                          handleOpenMap(data.latitude_out, data.longitude_out)
                        "
                      >
                        <i
                          class="fas fa-map-marker-alt"
                          :class="
                            data.is_match_out == 1
                              ? 'text-success'
                              : 'text-danger'
                          "
                        ></i>
                        {{ data.is_match_out == 1 ? "Match" : "Not Match" }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <h6 class="text-center align-items-center" v-else>Data not found.</h6>
        </el-scrollbar>
      </span>
      <span v-else> There's no data. </span>
    </el-dialog>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapActions } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import { ElNotification } from "element-plus";

export default {
  name: "GoappsAdminMealAllowance",

  components: {
    ArgonButton,
    ArgonInput,
  },

  data() {
    return {
      params: {
        date_range: [
          format(new Date(), "yyyy-MM-21 00:00:00"),
          format(new Date(), "yyyy-MM-dd 23:59:59"),
        ],
        start_date: format(new Date(), "yyyy-MM-dd 00:00:00"),
        end_date: format(new Date(), "yyyy-MM-dd 23:59:59"),
        id_user: "",
        filter: "",
        page: 1,
      },
      pop_over_content:
        "This is contain list meal allowance of sales and non-sales",
      allowance_sales: [],
      allowance_office: [],
      is_loading_sales: false,
      is_loading_office: false,
      selected_data: null,
      attendance_dialog: false,
      is_loading_office_more: false,
      downloading_sales: true,
      downloading_office: false,
    };
  },

  beforeMount() {
    this.handleInitDate();
  },

  methods: {
    ...mapActions({
      fetchMealAllowanceOffice: "hrd/fetchMealAllowanceOffice",
      fetchAttendanceRequestSales: "hrd/fetchAttendanceRequestSales",
      doHandleAttendanceRequestOffice: "hrd/doHandleAttendanceRequestOffice",
      fetchEmployeeVisitData: "hrd/fetchEmployeeVisitData",
      doHandleAttendanceRequestSales: "hrd/doHandleAttendanceRequestSales",
      fetchMealAllowanceOfficeExcel: "hrd/fetchMealAllowanceOfficeExcel",
      fetchMealAllowanceSales: "hrd/fetchMealAllowanceSales",
    }),

    handleExportOffice() {
      const start = format(new Date(this.params.start_date), "dd-MMM-yyyy");
      const end = format(new Date(this.params.end_date), "dd-MMM-yyyy");
      const format_name = `MealAllowanceOffice_${start}_${end}`;
      // this.downloading_office = true;
      ElMessage({
        message: "Please wait downloading office meal allowance report..",
        type: "info",
        duration: 2000,
      });
      const params = {
        start: this.params.start_date.split(" ")[0],
        end: this.params.end_date.split(" ")[0],
      };
      this.fetchMealAllowanceOfficeExcel(params)
        .then((response) => {
          // this.downloading_office = false;
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${format_name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          ElNotification({
            title: "Voila!",
            message: "Success download office meal allowance report!",
            type: "success",
          });
        })
        .catch((error) => {
          // this.downloading_office = false;
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    async handleExportSales() {
      await import('@/plugins/Export2Excel.js')
      .then((excel) => {
      const start = format(new Date(this.params.start_date), "dd-MMM-yyyy");
      const end = format(new Date(this.params.end_date), "dd-MMM-yyyy");
      const format_name = `SalesMealAllowance_${start}_${end}`;
      ElMessage({
        message: "Please wait downloading sales meal allowance report..",
        type: "info",
        duration: 5000,
      });

      const params = {
        start_date: this.params.start_date,
        end_date: this.params.end_date,
        //88
        search: this.params.filter,
      };

        this.fetchMealAllowanceSales(params)
        .then((response) => {
            //excel
            const OBJ = response.result; 
            const Header = ["Name", "Present", "Late", "Penalty", "Total"];
            const Field = ["employee", "total_on_time", "total_late", "total_penalty", "total"];
            const Data = this.formatJson(Field, OBJ);

            excel.export_json_to_excel({
              header: Header,
              data: Data,
              sheetName: "Sales Meal Allowance",
              filename: format_name,
              autoWidth : true,
              bookType : "xlsx",
            })
            ElNotification({
              title: "Voila!",
              message: "Success download sales meal allowance!",
              type: "success",
            });

            //end excel
          })

      })

      .catch((error) => {
        console.log("This is error: ", error)
      });
    },
    
    formatJson(filterData, jsonData){
        return jsonData.map((v) => 
        filterData.map(j => {
          return v[j];
        })
      )
    },

    handleInitDate() {
      let now = new Date();
      let month = now.getMonth();
      let date = now.getDate();
      let year = now.getFullYear();

      if (date > 20) {
        const s_m = month + 2 < 10 ? "0" + (month + 2) : month + 2;
        const last_date = year + "-" + s_m + "-" + "20" + " " + "00:00:00";
        this.params.date_range = [
          format(new Date(), "yyyy-MM-21 23:59:59"),
          last_date,
        ];
        this.params.start_date = format(new Date(), "yyyy-MM-21 23:59:59");
        this.params.end_date = last_date;
      } else {
        const l_m = month < 10 ? "0" + month : month;
        const last_date = year + "-" + l_m + "-" + "21" + " " + "00:00:00";
        this.params.date_range = [
          last_date,
          format(new Date(), "yyyy-MM-20 23:59:59"),
        ];
        this.params.start_date = last_date;
        this.params.end_date = format(new Date(), "yyyy-MM-20 23:59:59");
      }
      this.getMealAllowanceOffice();
      this.getMealAllowanceSales();
    },

    handleSearch() {
      this.getMealAllowanceOffice();
      this.getMealAllowanceSales();
    },

    formatDate(dates, type) {
      if (dates != null) {
        const date_input = dates.split(" ");
        let date;
        if (date_input.length == 1) {
          date = dates + " " + "00:00:00";
        } else {
          date = dates;
        }

        if (type == "fullset") {
          return format(new Date(date), "EEEE, dd MMMM yyyy HH:mm");
        } else if (type == "time") {
          return format(new Date(date), "HH:mm aa");
        } else if (type == "fullday") {
          return format(new Date(date), "EEEE, dd MMMM yyyy");
        } else {
          return "-";
        }
      } else {
        return "--";
      }
    },

    getMealAllowanceOffice() {
      this.is_loading_office = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      const params = {
        from: start,
        to: end,
        filter: this.params.filter,
        page: this.params.page,
      };
      this.fetchMealAllowanceOffice(params)
        .then((response) => {
          this.allowance_office = response.data.data;
          this.is_loading_office = false;
        })
        .catch((error) => {
          this.allowance_office = [];
          this.is_loading_office = false;
          ElNotification({
            title: "Oops!",
            message: `Error office: ${JSON.stringify(error)}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    getMealAllowanceOfficeMore() {
      this.is_loading_office_more = true;
      const start = this.params.start_date.split(" ")[0];
      const end = this.params.end_date.split(" ")[0];
      this.params.page += 1;
      const params = {
        from: start,
        to: end,
        filter: this.params.filter,
        page: this.params.page,
      };
      this.fetchMealAllowanceOffice(params)
        .then((response) => {
          this.allowance_office = [
            ...this.allowance_office,
            ...response.data.data,
          ];
          this.is_loading_office_more = false;
        })
        .catch((error) => {
          this.allowance_office = [];
          this.is_loading_office_more = false;
          ElNotification({
            title: "Oops!",
            message: `Error office: ${JSON.stringify(error)}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    getMealAllowanceSales() {
      // 88 sales
      this.is_loading_sales = true;
      const start = this.params.start_date;
      const end = this.params.end_date;
      const params = {
        start_date: start,
        end_date: end,
        search: this.params.filter,
      };
      this.fetchMealAllowanceSales(params)
        .then((response) => {
          this.allowance_sales = response.result;
          this.is_loading_sales = false;
        })
        .catch((error) => {
          this.allowance_sales = [];
          this.is_loading_sales = false;
          ElNotification({
            title: "Oops!",
            message: `Error sales: ${JSON.stringify(error.data)}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleChangeFilterDate() {
      this.params.search = this.params.filter;
      const start = this.params.date_range[0] + " 00:00:00";
      const end = this.params.date_range[1] + " 23:59:59";
      this.params.start_date = format(new Date(start), "yyyy-MM-dd 00:00:00");
      this.params.end_date = format(new Date(end), "yyyy-MM-dd 23:59:59");
      this.getMealAllowanceSales();
      this.getMealAllowanceOffice();
    },

    handleRequestOffice(data, type) {
      const params = {
        request_id: data.id,
        approve: type,
      };
      this.doHandleAttendanceRequestOffice(params)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: `Success update presence request!`,
            type: "success",
          });
          this.getMealAllowanceOffice();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleRequestSales(data, type) {
      const params = {
        request_id: data.id,
        status: type,
      };
      this.doHandleAttendanceRequestSales(params)
        .then((response) => {
          ElNotification({
            title: "Voila!",
            message: `Success update presence request!`,
            type: "success",
          });
          this.getMealAllowanceSales();
        })
        .catch((error) => {
          ElNotification({
            title: "Oops!",
            message: `Error: ${error.message}`,
            type: "error",
            duration: 5000,
          });
        });
    },

    handleOpenDetails(data) {
      this.selected_data = data;
      this.attendance_dialog = true;
    },

    handleCloseVisit() {
      this.selected_data = null;
      this.attendance_dialog = false;
    },

    handleOpenMap(latitude, longitude) {
      window.open(
        `https://maps.google.com/?q=${latitude},${longitude}`,
        "_blank"
      );
    },

    handleLoadMore() {
      this.getMealAllowanceOfficeMore();
      this.getMealAllowanceSales();
    },
  },
};
</script>
