<template>
  <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-lg-12">
            <!-- {{ getPeriod }} -->
            <div style="display:none">{{ getPeriod }}</div>
            <div class="card p-3 mb-3">
              <div class="d-flex justify-content-between align-items-center">
              <!-- <div>
                <span class="text-bold">Tasks Created - {{params.st}} - {{params.ed}} </span>
              </div> -->

                <div v-if="params.st && params.ed">
                  <span class="text-bold">Period: {{params.st}} - {{params.ed}} </span>
                </div>
                <div v-else>
                  <span class="text-bold">Period: {{currentDate()}} </span>
                </div>
              </div>
            </div>
            


            <div class="card p-3 mb-3">
            <div class="d-flex justify-content-between align-items-center">

              <!-- <div v-if="params.st && params.ed">
                <span class="text-bold">Tasks Created - {{params.st}} - {{params.ed}} </span>
              </div>
              <div v-else>
                <span class="text-bold">Tasks Created - {{currentDate()}} </span>
              </div> -->
            </div>
            <div class="d-flex justify-content-evenly align-items-center">

              <div class="d-flex flex-column">
                <div class="text-center">Filter Date</div>
                <el-date-picker
                  v-model="params.date_range"
                  type="monthrange"
                  range-separator="To"
                  start-placeholder="Start month"
                  end-placeholder="End month"
                  @change="handleChangeFilterDate"
                />
              </div>
        

            </div>
        </div>
        

        <div class="card p-3 mb-3">
          <div class="row">
                <div class="card-header pb-0">
                <h6>Task Based on Created Date</h6>
              </div>
              
                <div class="row">
                <!-- {{ role }} -->
                <div class="w-20">
                  <card
                    :title="stats.money.title"
                    :value="countData.countTasks"
                    :iconClass="stats.money.iconClass"
                    :iconBackground="stats.money.iconBackground"
                    directionReverse
                  >
                  </card>
                </div>
                <!-- <div class="col-lg-3 col-md-6 col-12"> -->
                <div class="w-20">
                  <card
                    :title="stats.users.title"
                    :value="countData.countSummary"
                    :iconClass="stats.users.iconClass"
                    :iconBackground="stats.users.iconBackground"
                    directionReverse
                  ></card>
                </div>
                <div class="w-20">
                  <card
                    :title="stats.clients.title"
                    :value="countData.countProgress"
                    :iconClass="stats.clients.iconClass"
                    :iconBackground="stats.clients.iconBackground"
                    :percentageColor="stats.clients.percentageColor"
                    directionReverse
                  ></card>
                </div>
                <div class="w-20">
                  <card
                    :title="stats.sales.title"
                    :value="countData.countOverdue"
                    :iconClass="stats.sales.iconClass"
                    :iconBackground="stats.sales.iconBackground"
                    directionReverse
                  ></card>
                </div>
                <div class="w-20">
                  <card
                    :title="stats.time.title"
                    :value="countData.countOnTime"
                    :iconClass="stats.time.iconClass"
                    :iconBackground="stats.time.iconBackground"
                    directionReverse
                  ></card>
                </div>
              </div>

        </div>
      </div>

        
        <div class="row">

          <div class="col-lg-7 mb-lg">
            <div class="card z-index-2">
              <TaskChart />
            </div>
            <div class="badge badge-success mt-3">{{role }}</div>
            <active-users />
            <!-- cella11 -->
            <!-- <div class="badge badge-success mt-3">{{role }}</div>
            <div v-if="isAuthorized === 'admin'">
              <active-users />
            </div>
            <div v-else-if="isAuthorized === 'mgr' || isAuthorized === 'staff'">
              <active-users />
            </div> -->
            
          </div>
          <div class="col-lg-5">
            <!-- cella11 -->
            <categories-card />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/examples/Cards/Card.vue";
import GradientLineChart from "./components/chartTaskLineCard.vue";
import Carousel from "../components/Carousel.vue";
import CategoriesCard from "./components/totalUsers.vue";
import ActiveUsers from "./components/activeUsers.vue";

import TaskChart from "@/components/GooPro/TasksChart.vue";

import { mapActions, mapMutations, useStore } from "vuex";
import ArgonButton from "@/components/ArgonButton.vue";
import { ElNotification } from "element-plus";
import { format } from "date-fns";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",
  data() {
    return {
      totalProject: 6,
      countData: {},
      dashboard: {},
      resultSumProject: [],
      role: '',
      dept: '',
      id_user:'',
      isAuthorized:'',
      params: {
        id_board: "",
        search: "",
        order: "asc",
        id_dept: "",
        id_user: "",
        date_range: [
          // format(new Date(), "yyyy-MM-dd"),
          // format(new Date(), "yyyy-MM-dd"),
          null,
          null
        ],
        // start_date: format(new Date(), "yyyy-MM-dd"),
        // end_date: format(new Date(), "yyyy-MM-dd"),
        start_date: "",
        end_date: "",

        st: "",
        ed: "",
      },
      paramsCard: {
        id_card: "",
        search: "",
        order: "asc",
      },
      is_table_loading: false,
      stats: {
        // money: {
        //   title: "Total Project",
        //   value: "6",
        //   percentage: "+55%",
        //   iconClass: "fas fa-project-diagram",
        //   detail: "update yesterday",
        //   iconBackground: "bg-gradient-primary",
        // },
        money: {
          title: "Tasks",
          value: "6",
          percentage: "+55%",
          iconClass: "fas fa-project-diagram",
          detail: "update yesterday",
          iconBackground: "bg-gradient-primary",
        },
        // users: {
        //   title: "Total Task",
        //   value: "5",
        //   percentage: "+3%",
        //   iconClass: "fas fa-tasks",
        //   iconBackground: "bg-gradient-danger",
        //   detail: "update yesterday",
        // },
        users: {
          title: "Completed",
          value: "5",
          percentage: "+3%",
          iconClass: "fas fa-clipboard-check",
          iconBackground: "bg-gradient-success",
          detail: "update yesterday",
        },
        // clients: {
        //   title: "Total List",
        //   value: "0",
        //   percentage: "-2%",
        //   iconClass: "fas fa-clipboard-check",
        //   percentageColor: "text-danger",
        //   iconBackground: "bg-gradient-success",
        //   detail: "update yesterday",
        // },
        clients: {
          title: "Progress",
          value: "0",
          percentage: "-2%",
          iconClass: "fas fa-tasks",
          percentageColor: "text-danger",
          iconBackground: "bg-gradient-warning",
          detail: "update yesterday",
        },
        sales: {
          title: "Overdue",
          value: "0",
          percentage: "+5%",
          iconClass: "fas fa-times-circle",
          iconBackground: "bg-gradient-danger",
          detail: "update yesterday",
        },
        time: {
          title: "Ontime",
          value: "0",
          percentage: "+5%",
          iconClass: "fas fa fa-clock-o",
          iconBackground: "bg-gradient-info",
          detail: "update yesterday",
        },
      },
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },

  beforeMount() {
    // this.fetchBoardData();
    // this.fetchCardData();
    // this.fetchDashboardData();
  },

  mounted() {
    // cella
    this.getUserLoginRole();
    this.fetchDashboardData();
  },

  computed: {
    getPeriod() {
      // console.log("ini dash period ", this.$store.state.gopro.period);
      return this.$store.state.gopro.period;
    },
  },

  methods: {

    ...mapActions({
      fetchBoard: "gopro/fetchBoard",

      // Kanban
      fetchKanban: "gopro/fetchKanban",
      fetchCard: "gopro/fetchCard",

      // Dashboard
      fetchDashboard: "gopro/fetchDashboard",
      // fetchDeptChart: "gopro/fetchDeptChart",
    }),

    ...mapMutations({
      SET_ST_ED: "gopro/SET_ST_ED",
    }),

    currentDate() {
      const current = new Date();
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      // const date = `${current.getMonth()+1}/${current.getFullYear()}`;
      const date = new Date().toLocaleString('en-us',{month:'short', year:'numeric'})
      return date;
    },

    handleChangeFilterDate() {
      //88
     
      // console.log("ini range date: ", this.params.date_range);
      if (this.params.date_range == null) {
        this.params.date_range = ["", ""];
      }

      let start = this.params.date_range[0];
      let end = this.params.date_range[1];

      // start = new Date(start.getFullYear(), start.getMonth(), 1);
      this.params.start_date = format(new Date(start), "yyyy-MM-dd 00:00:00");

      end = new Date(end.getFullYear(), end.getMonth()+1, 0);
      this.params.end_date = format(new Date(end), "yyyy-MM-dd 23:59:59");

      // console.log("ini start date: ", this.params.start_date);
      // console.log("ini end date: ", this.params.end_date);

      this.params.st = format(new Date(this.params.start_date), "MMM yyyy");
      this.params.ed = format(new Date(this.params.end_date), "MMM yyyy");

      const payload = {
        start_date: this.params.start_date,
        end_date: this.params.end_date,
      };
      // console.log("ini st ed:", payload);

      this.SET_ST_ED(payload);
      // this.getPeriod();

      this.fetchDashboardData();
    },
    
    getUserLoginRole() {
      console.log("ini role login: ", this.$store.state.dashboard_app_role);
      this.role = this.$store.state.dashboard_app_role;
      this.setUserPermission(this.$store.state.dashboard_app_role);
      // console.log("ini authorized login: ", this.isAuthorized);
      return this.$store.state.auth.dashboard_app_role;
    },

    getUserLoginData() {
      return this.$store.state.auth.login_data;
    },

    setUserPermissionOld(data) {
      if (
        //if user is admin or super admin
        data === "Super Admin" ||
        data === "Manager"
      ) 
      {
        this.isAuthorized = true;
      }
      else
      {
        this.isAuthorized = false;
      }
      return this.isAuthorized;
    },

    setUserPermission(data) {
      // console.log("ini data: ", data);
      const dataUp = data.toUpperCase();
      console.log("ini role dashboard: ", dataUp);
      if (
        //if user is super admin
        dataUp === "SUPER ADMIN" 
        // ||
        // data === "Manager"
      ) 
      {
        this.isAuthorized = "admin";
      }
      else if(dataUp.includes("MANAGER"))
      {
        this.isAuthorized = "mgr";
      }
      else
      {
        this.isAuthorized = "staff";
      }
      // console.log("ini autho: ", this.isAuthorized);
      return this.isAuthorized;
    },

    fetchBoardData() {
      this.is_table_loading = true;
      const payload = {
        id_board: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchBoard(payload).then((response) => {
        this.is_table_loading = false;
        this.resultSumProject = response.data;
      });
    },

    fetchCardData() {
      const payload = {
        id_card: this.paramsCard.id_card,
        search: this.paramsCard.search,
        order: this.paramsCard.order,
      };
      this.fetchCard(payload).then((response) => {
        // console.log("Hello World");
      });
    },

    fetchDashboardData() {
      console.log("sini");
      let payload = []
      if (this.isAuthorized == "admin")
        {
          // console.log("ini authorized adm: ", this.isAuthorized);
          payload = {
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
          };
        }
        else if (this.isAuthorized == "mgr")
        {
          // console.log("ini authorized mgr st: ", this.isAuthorized);
          console.log("ini dept login: ", this.$store.state.dashboard_app_dept);
          payload = {
              id_dept: this.$store.state.dashboard_app_dept,
              // id_user: null,
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
          };
        }

        else if (this.isAuthorized == "staff")
        {
          // console.log("ini authorized mgr st: ", this.isAuthorized);
          payload = {
              id_user: this.$store.state.auth.login_data.user.id_user,
              // id_dept: null,
              start_date: this.$store.state.gopro.period.start_date,
              end_date: this.$store.state.gopro.period.end_date,
          };
        }
        console.log("ini cache: ", this.$store.state.auth.login_data.user.id_user,);
        console.log("ini cache: ", this.$store.state.dashboard_app_dept);

      // console.log("ini paramsku:", payload);
      this.fetchDashboard(payload).then((response) => {
        this.countData = response;
        // console.log("ini view res:", this.countData.countProgress);
      });

      
    },

    
  },

  components: {
    // getBoard() {
    //   return this.$store.state.gopro.board;
    // },
    // getCard() {
    //   return this.$store.state.gopro.card;
    // },
    // getDashboard() {
    //   return this.$store.state.gopro.dashboard;
    // },
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    ActiveUsers,
    TaskChart,
  },
};
</script>
