<template>
  <span :class="correct ? 'text-warning' : ''">
    {{ address }}
  </span>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "GoappsAdminGetLocation",

  data() {
    return {
      address: "...",
    };
  },

  props: {
    lat: String,
    long: String,
    correct: Boolean,
  },

  mounted() {
    this.getLocation();
  },

  methods: {
    ...mapActions({
      fetchLocationMaps: "hrd/fetchLocationMaps",
    }),
    getLocation() {
      const params = {
        latitude: this.lat,
        longitude: this.long,
      };
      this.fetchLocationMaps(params)
        .then((response) => {
          if (response.results[0]) {
            this.address = response.results[0].formatted_address;
          } else {
            this.address = "-";
          }
        })
        .catch((error) => {
          this.address = "-";
        });
    },
  },
};
</script>
