<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>Created Tasks</h6>
      <p class="text-sm">Last 12 months from {{currentDate()}}</p>
    </div>
    <div class="p-3 card-body">
      <div class="d-flex justify-content-center" v-if="is_loading">
        <lottie-player
          src="https://assets10.lottiefiles.com/packages/lf20_t9gkkhz4.json"
          background="transparent"
          speed="1"
          style="width: 300px; height: 300px"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="chart">
        <canvas id="tasks-chart" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { mapActions } from "vuex";

export default {
  name: "gradient-line-chart",

  data() {
    return {
      is_loading: false,
      isAuthorized:'',
      params: {
        id_dept: "",
        id_user: "",
      },
    };
  },

  props: {
    title: {
      type: String,
      default: "Sales overview",
    },
    detail1: {
      type: String,
      default: "4% more",
    },
    detail2: {
      type: String,
      default: "in 2021",
    },
  },

  methods: {
    ...mapActions({
      fetchGenderChart: "hrd/fetchGenderChart",
      fetchTasksChart: "gopro/fetchTasksChart",
    }),

    // https://colorswall.com/palette/239046
    async getDataChart() {
      this.is_loading = true;

      this.getUserLoginRole();

        let resp = [];

        // console.log("ini authorized: ", this.isAuthorized);
        if (this.isAuthorized == "admin")
        {
          // console.log("ini authorized adm: ", this.isAuthorized);
          const params = {
            id_dept: "",
            id_user: "",
          };
          resp = await this.fetchTasksChart(params);
        }
        else if (this.isAuthorized == "mgr")
        {
          // console.log("ini authorized mgr st: ", this.isAuthorized);
          this.params.page = 1;
          const params = {
              id_dept: this.params.id_dept,
              // id_user: "",
          };
          // console.log("ini dept: ", this.params.dept);
          resp = await this.fetchTasksChart(params);
        }
        else if (this.isAuthorized == "staff")
        {
          // console.log("ini authorized mgr st: ", this.isAuthorized);
          this.params.page = 1;
          const params = {
              // id_dept: this.params.id_dept,
              id_user: this.params.id_user,
          };
          // console.log("ini dept: ", this.params.dept);
          resp = await this.fetchTasksChart(params);
        }


        var ctx1 = document.getElementById("tasks-chart").getContext("2d");
        new Chart(ctx1, {
          plugins: [ChartDataLabels],
          type: "bar",
          data: {
            labels: resp.label,
            datasets: [
              {
                datalabels: {
                  align: "end",
                  anchor: "end",
                  display: function (context) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value != 0; // display labels with an odd index
                  },
                },
                label: "Count",
                tension: 0.4,
                pointRadius: 0,
                backgroundColor: [
                  "rgba(255, 255, 204, 0.4)",
                  "rgba(255, 204, 153, 0.4)",
                  "rgba(255, 204, 204, 0.4)",
                  "rgba(255, 153, 204, 0.4)",
                  "rgba(255, 204, 255, 0.4)",
                  "rgba(204, 153, 255, 0.4)",
                  "rgba(204, 204, 255, 0.4)",                 
                  "rgba(153, 204, 255, 0.4)",
                  "rgba(204, 255, 255, 0.4)",
                  "rgba(153, 255, 204, 0.4)",
                  "rgba(204, 255, 204, 0.4)",
                  "rgba(204, 255, 153, 0.4)",
                ],
                // borderColor: [
                //   "rgb(255, 99, 132)",
                //   "rgb(255, 159, 64)",
                //   "rgb(255, 205, 86)",
                // ],
                // eslint-disable-next-line no-dupe-keys
                borderWidth: 1,
                fill: true,
                data: resp.result,
                // maxBarThickness: 6,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: true,
                  drawOnChartArea: true,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  padding: 10,
                  color: "#fbfbfb",
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                  borderDash: [5, 5],
                },
                ticks: {
                  display: true,
                  color: "#ccc",
                  padding: 20,
                  font: {
                    size: 11,
                    family: "Open Sans",
                    style: "normal",
                    lineHeight: 2,
                  },
                },
              },
            },
          },
        });
        this.is_loading = false;

      // this.fetchTasksChart().then((resp) => {
        
      // });
    },

    currentDate() {
      const current = new Date();
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      // const date = `${current.getMonth()+1}/${current.getFullYear()}`;
      const date = new Date().toLocaleString('en-us',{month:'short', year:'numeric'})
      return date;
    },

    getUserLoginRole() {
      // console.log("ini role login: ", this.$store.state.dashboard_app_role);
      this.role = this.$store.state.dashboard_app_role;
      this.setUserPermission(this.$store.state.dashboard_app_role);
      // console.log("ini authorized login: ", this.isAuthorized);
      return this.$store.state.auth.dashboard_app_role;
    },

    setUserPermission(data) {
      if (
        //if user is super admin
        data === "Super Admin" 
        // ||
        // data === "Manager"
      ) 
      {
        this.isAuthorized = "admin";
        // this.params.id_dept = this.$store.state.dashboard_app_dept;
        // this.dept = this.$store.state.dashboard_app_dept;
        // console.log("ini dept login: ", this.params.dept);
      }
      else if(data.toUpperCase().includes("MANAGER"))
      {
        this.isAuthorized = "mgr";
        this.params.id_dept = this.$store.state.dashboard_app_dept;
        this.dept = this.$store.state.dashboard_app_dept;
        // console.log("ini dept login: ", this.params.dept);
      }
      else
      {
        this.isAuthorized = "staff";
        this.params.id_dept = this.$store.state.dashboard_app_dept;
        this.dept = this.$store.state.dashboard_app_dept;
        console.log("ini dept login: ", this.params.id_dept);
        this.params.id_user = this.$store.state.auth.login_data.user.id_user;
        console.log("ini user login: ", this.params.id_user);
      }
      return this.isAuthorized;
    },

  },

  computed: {
    getData() {
      return this.$store.state.hrd.gender_chart;
    },
  },

  mounted() {
    // this.getUserLoginRole();
    this.getDataChart();
  },
};
</script>
