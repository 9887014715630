<template>
  <div class="mx-4">
    <div class="card p-3 mb-3">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span class="text-bold"> Boards </span>
        </div>
        <div>
          <argon-button
            variant="gradient"
            color="info"
            size="md"
            @click="handleOpenAdd()"
          >
            Add Boards
          </argon-button>
        </div>
      </div>
    </div>

    <!-- <div class="card p-3 mb-3">
      <el-scrollbar height="500px">{{ getBoard.data }} </el-scrollbar>
    </div> -->

    <div class="card p-3 mb-3">
      <!-- <el-scrollbar height="500px">{{ getBoard }} </el-scrollbar> -->
      <table class="table align-items-center justify-content-center mb-0">
        <thead>
          <tr>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Board Name
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Background
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Public
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Collabolator
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2"
            >
              Status
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in getBoard.data">
            <td>
              <div class="d-flex px-2">
                <div class="my-auto">
                  <h6 class="mb-0 text-sm">{{ item.nm_board }}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm font-weight-bold mb-0">{{ item.background }}</p>
            </td>
            <td v-if="item.public == 0">
              <span class="badge badge-sm bg-gradient-danger">False</span>
            </td>
            <td v-else>
              <span class="badge badge-sm bg-gradient-success">True</span>
            </td>

            <td class="align-middle text-center">
              <div class="d-flex align-items-center">
                <span class="me-2 text-xs font-weight-bold"
                  >{{ item.collabolator.length }} Collab</span
                >
                <div>
                  <div class="progress">
                    <div
                      class="progress-bar bg-gradient-info"
                      role="progressbar"
                      aria-valuenow="{{ item.collabolator.length }}"
                      aria-valuemin="0"
                      aria-valuemax="{{ item.collabolator.length }}"
                      style="width: {{ item.collabolator.length }}%"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <argon-button
                variant="gradient"
                color="primary"
                size="sm"
                class="m-1"
                @click="handleOpenEdit()"
              >
                Edit
              </argon-button>
              <argon-button
                variant="gradient"
                color="danger"
                size="sm"
                class="m-1"
                @click="handleOpenRemove()"
              >
                Delete
              </argon-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <el-dialog
      v-model="add_dialog"
      title="Add New Board"
      width="30%"
      :before-close="handleCloseAdd()"
    >
      <span>
        <el-input
          v-model="form.nm_board"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Board Name"
          :suffix-icon="EditPen"
        />
        <div>
          <label>Background Board: </label>
          <input type="color" class="m-2 w-100" v-model="form.color" />
        </div>
        <el-input
          v-model="form.background"
          class="m-2 w-100"
          style="width: 100%"
          placeholder="Public"
          :suffix-icon="EditPen"
        />
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="handleCreateBoard()"
            class="bg-gradient-success"
            :disabled="
              form.nm_board == null ||
              form.nm_board == '' ||
              form.background == null ||
              form.color == null
            "
          >
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";
import { mapActions, useStore } from "vuex";

// const store = useStore();
// const board = computed(() => {
//   return store.state.board;
// })

export default {
  name: "GoappsAdminBoards",
  components: {
    ArgonButton,
  },

  data() {
    return {
      params: {
        id_board: "",
        search: "",
        order: "asc",
      },
      form: {
        id_board: null,
        nm_board: null,
        background: null,
        public: null,
      },
      is_table_loading: false,
      add_dialog: false,
      edit_dialog: false,
      remove_dialog: false,
    };
  },

  beforeMount() {
    this.fetchBoardData();
  },

  mounted() {},

  methods: {
    ...mapActions({
      fetchBoard: "gopro/fetchBoard",
      doCreateBoard: "gopro/doCreateBoard",
      doUpdateBoard: "gopro/doUpdateBoard",
      doDeleteDivisi: "gopro/doDeleteBoard",
    }),

    fetchBoardData() {
      this.is_table_loading = true;
      const payload = {
        id_board: this.params.id_board,
        search: this.params.search,
        order: this.params.order,
      };
      this.fetchBoard(payload).then((response) => {
        this.is_table_loading = false;
      });
    },

    handleCreateBoard() {
      const params = {
        nm_board: this.form.nm_board,
        background: this.form.background,
        public: this.form.public,
      };
      this.doCreateBoard(params)
        .then((response) => {
          this.handleCloseAdd();
          ElNotification({
            title: "Viola!",
            message: "Success create board!",
            type: "success",
          });
          this.fetchBoardData();
        })
        .then((error) => {
          ElNotification({});
        });
    },

    // Handle Add
    handleOpenAdd() {
      this.add_dialog = true;
    },

    handleCloseAdd() {
      this.form.id_board = null;
      this.form.nm_board = null;
      this.form.background = null;
      this.form.public = null;
    },
  },

  computed: {
    getBoard() {
      return this.$store.state.gopro.board;
    },
  },
};
</script>
